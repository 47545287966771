import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../redux/store";
import * as React from "react";
import styled from "styled-components/macro";

interface DisplayOfferCountryProps {
  id: string | null;
}

export const Flag = styled.img`
  border-radius: 50%;
  width: 31px;
  height: 31px;
  object-fit: cover;
`;

export const FlagBig = styled.img`
  width: 67.5px;
  height: 45px;
  border: solid 1px ${({ theme }) => theme.palette.divider};
  object-fit: cover;
  object-position: 50% 50%;
`;

export const DisplayOfferCountry = ({ id }: DisplayOfferCountryProps) => {
  const countries = useSelector((state: AppStateType) => state.offer.countries);
  const country = (id && countries.find((item) => item.id === id)) || null;
  return !country ? (
    <>-</>
  ) : (
    <FlagBig src={`/static/img/flags/${country.imageName}.svg`} />
  );
};
