import { AutoRequestConfig } from "../../../core/connectionCreator/type/AutoRequestConfig";
import { Form } from "../../formsIframe/type/Form";
import { CustomPostback } from "../../../core/connectionCreator/type/CustomPostback";
import { OfferConnectionSendStep } from "./OfferConnectionSendStep";

export class OfferConnection {
  check: AutoRequestConfig | null = null;
  checkId: number | null = null;
  smsVerify: AutoRequestConfig | null = null;
  smsVerifyId: number | null = null;
  resendRendSmsCode: AutoRequestConfig | null = null;
  resendRendSmsCodeId: number | null = null;
  send: AutoRequestConfig | null = null;
  sendId: number | null = null;
  sendSmsCode: AutoRequestConfig | null = null;
  sendSmsCodeId: number | null = null;
  customPostback: CustomPostback | null = null;
  customPostbackId: number | null = null;
  sendAsSequence?: boolean = false;
  checkAsSequence?: boolean = false;
  name: string = "";
  systemToken: string = "";
  formId: number | null = null;
  form: Form | null = null;
  status: string = "creating";
  haveSmsVerify: boolean = false;
  haveCheck: boolean = false;
  debugMode: boolean = true;
  id: number | null = null;
  sendSteps: OfferConnectionSendStep[] = [];
  checkSteps: OfferConnectionSendStep[] = [];

  constructor(obj?: any, saveOrder: boolean = false) {
    obj && Object.assign(this, obj);
    if (obj?.check) {
      this.check = new AutoRequestConfig(obj.check);
    }
    if (Array.isArray(obj?.sendSteps)) {
      this.sendSteps = obj.sendSteps.map(
        (item: any) => new OfferConnectionSendStep(item)
      );
      if (saveOrder) {
        this.sendSteps = this.sendSteps.map((item, index) => {
          item.order = index;
          return item;
        });
      }
    }
    if (Array.isArray(obj?.checkSteps)) {
      this.checkSteps = obj.checkSteps.map(
        (item: any) => new OfferConnectionSendStep(item)
      );
      if (saveOrder) {
        this.checkSteps = this.checkSteps.map((item, index) => {
          item.order = index;
          return item;
        });
      }
    }
    if (obj?.smsVerify) {
      this.smsVerify = new AutoRequestConfig(obj.smsVerify);
    }
    if (obj?.customPostback) {
      this.customPostback = new CustomPostback(obj.customPostback);
    }
    if (obj?.sendSmsCode) {
      this.sendSmsCode = new AutoRequestConfig(obj.sendSmsCode);
    }
    if (obj?.send) {
      this.send = new AutoRequestConfig(obj.send);
    }
    if (obj?.form) {
      this.form = new Form(obj.form);
    }
    if (obj?.resendRendSmsCode) {
      this.resendRendSmsCode = new AutoRequestConfig(obj.resendRendSmsCode);
    }
  }

  toTemplateJson() {
    const {
      check,
      haveCheck,
      haveSmsVerify,
      resendRendSmsCode,
      name,
      send,
      smsVerify,
      sendSmsCode,
      status,
      systemToken,
      formId,
      customPostback,
      sendAsSequence,
      checkAsSequence,
      sendSteps,
      checkSteps,
      debugMode,
    } = this;
    return {
      sendAsSequence,
      checkAsSequence,
      sendSteps: sendSteps.map((item) => item.toTemplateJson()),
      checkSteps: checkSteps.map((item) => item.toTemplateJson()),
      check: check?.toTemplateJson() || null,
      customPostback: customPostback?.toTemplateJson() || null,
      haveCheck,
      haveSmsVerify,
      resendRendSmsCode: resendRendSmsCode?.toTemplateJson() || null,
      sendSmsCode: sendSmsCode?.toTemplateJson() || null,
      name,
      systemToken,
      send: send?.toTemplateJson() || null,
      smsVerify: smsVerify?.toTemplateJson() || null,
      status,
      formId,
      debugMode,
    };
  }

  toJson() {
    const {
      check,
      haveCheck,
      haveSmsVerify,
      resendRendSmsCode,
      name,
      send,
      smsVerify,
      sendSmsCode,
      status,
      systemToken,
      formId,
      customPostback,
      sendAsSequence,
      checkAsSequence,
      sendSteps,
      checkSteps,
      debugMode,
    } = this;
    return {
      sendAsSequence,
      checkAsSequence,
      sendSteps: sendSteps.map((item) => item.toJson()),
      checkSteps: checkSteps.map((item) => item.toJson()),
      check: check?.toJson() || null,
      customPostback: customPostback?.toJson() || null,
      haveCheck,
      haveSmsVerify,
      resendRendSmsCode: resendRendSmsCode?.toJson() || null,
      sendSmsCode: sendSmsCode?.toJson() || null,
      name,
      systemToken,
      smsVerify: smsVerify?.toJson() || null,
      send: send?.toJson() || null,
      status,
      formId,
      debugMode,
    };
  }
}
