import React, { createContext, useCallback, useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { GlobalStyleProps } from "../../../types/styles";
import { CssBaseline } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useHistoryLocation } from "../../../core/history";
import { useHeight } from "../../../core/utils/useWindowSize";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../redux/store";

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
    font-size: 20px;
  }

  body{
    background: ${(props) => props.theme.palette.background.default};
  }
  body, h6, h5, h4, h3, h2, h1, strong, p, input {
    font-family: 'Barlow', sans-serif !important;
  }
  h3{
    font-weight: 700 !important;
    font-size: 30px !important;
  }
  h6{
    font-weight: 400 !important;
    font-size: 23px  !important;
  }
  strong {
    font-weight: 700;
  }
  .iframe-check-box svg{
    width: 35px;
    height: 35px;
  }
  .form-submit-button svg{
    width: 40px;
    height: 40px;
  }
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")}{
    html,
    body,
    #root {
      height: 100%;
      font-size: 15.2px;
    }
    
    .form-submit-button svg{
      width: 35px;
      height: 35px;
      margin-right: 7px;
    }
  }

  #terms-and-condition {
    td,
    table,
    tr,
    tbody {
      border: 1px solid #d5d4d4;
      border-spacing: 0px;
    }
  }
`;

const AppConatiner = styled.div<{ $hideBg: boolean }>`
  max-width: ${({ $hideBg }) => ($hideBg ? "100%" : "1110px")};
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Root = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

export const ScrollUpContext = createContext<{ scrollUp?: () => void }>({});

const FormsLayout: React.FC = ({ children }) => {
  const scrollElement = useRef<HTMLDivElement>(null);
  const page = useSelector((state: AppStateType) => state.iframe.page);
  const ref = useRef<HTMLDivElement>(null);
  const scrollUp = useCallback(() => {
    setTimeout(() => {
      //need to wait to avoid stop scrolling bug
      if (scrollElement.current) {
        scrollElement.current.scrollTo(0, 0);
      }
    }, 20);
  }, [scrollElement]);
  useHeight(ref);

  const location = useHistoryLocation<{ hideBg: "true" | null }>();

  useEffect(() => {
    scrollUp();
  }, [page, scrollUp]);

  return (
    <Root ref={scrollElement} id={"here"}>
      <ScrollUpContext.Provider value={{ scrollUp }}>
        <AppConatiner ref={ref} $hideBg={location.search?.hideBg === "true"}>
          <Helmet>
            <link
              href="https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap"
              rel="stylesheet"
            />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
          </Helmet>
          <CssBaseline />
          <GlobalStyle />
          {children}
        </AppConatiner>
      </ScrollUpContext.Provider>
    </Root>
  );
};

export default FormsLayout;
