import * as React from "react";
import { FC } from "react";
import IframeTextFiled from "./inputs/iframeTextField/IframeTextField";
import { IframeSlider } from "./inputs/IframeSlider";
import { Form } from "../../type/Form";
import {
  TYPE_CHECKBOX,
  TYPE_CUSTOM_HTML,
  TYPE_DATE,
  TYPE_CZK_PRICE,
  TYPE_NUMBER,
  TYPE_PHONE,
  TYPE_PHONE_CZ,
  TYPE_SELECT,
  TYPE_SLIDER,
  TYPE_SLIDER_MONEY,
  TYPE_TEXT,
  TYPE_TEXT_TITLE,
  TYPE_BANK_CODE,
  TYPE_BANK_ACCOUNT,
  TYPE_EMAIL,
  TYPE_ONLY_TEXT,
  TYPE_ONLY_NUMBER,
  TYPE_CZ_ZIP,
  TYPE_SPACING,
  TYPE_HOUSE_NUMBER,
  TYPE_IDENTIFY_CARD_NUMBER,
  TYPE_NIN,
  TYPE_CHECKBOX_TERMS,
} from "../../constant/formFieldType";
import { Flex } from "../../../../core/components/layout/Flex";
import { FormField } from "../../type/FormField";
import { Grid } from "@material-ui/core";
import { Offer } from "../../type/Offer";
import { IframeSliderLoad } from "./inputs/IframeSliderLoad";
import { IframeCheckBox } from "./inputs/IframeCheckBox";
import { IframeTitle } from "./inputs/IframeTitle";
import { IframeSelect } from "./inputs/IframeSelect";
import styled from "styled-components";
import { IframeCustomHtml } from "./inputs/IframeCustomHtml";
import { IframeSpacing } from "./inputs/IframeSpacing";
import { IframeBankCode } from "./inputs/IframeBankCode";
import { Setting } from "../../../setting/type/Setting";
import { FormCustomTermsAndCondition } from "../../type/FormCustomTermsAndCondition";

const Root = styled.div`
  margin: 0 ${({ theme }) => theme.spacing(27)}px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: 0 0px;
  }
  @media (max-width: 1265px) {
    margin: 0 ${({ theme }) => theme.spacing(26)}px;
  }
  @media (max-width: 920px) {
    margin: 0 0px;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 0;
    padding: 0;
  }
`;

export interface IframeFiledProps {
  onChange: (newValue: any) => void;
  formField: FormField;
  form: Form;
  offer: Offer;
  value: any;
  settings: Setting[];
  userId: number;
}

interface IframeInputsListProps {
  form: Form;
  offer: Offer;
  onChange: (name: string, value: string) => void;
  formFields: FormField[];
  formValues: Record<string, any>;
  settings: Setting[];
  formCustomTermsAndConditions: FormCustomTermsAndCondition[];
  userId: number;
}

export const IframeInputsList: FC<IframeInputsListProps> = ({
  form,
  offer,
  formValues,
  formFields,
  onChange,
  formCustomTermsAndConditions,
  settings,
  userId,
}) => {
  const handleChange = (name: string) => (value: any) => {
    onChange(name, value);
  };
  return (
    <Root>
      <Flex flexWrap={"wrap"} marginLeft={"large"} marginRight={"large"}>
        <Grid container justify={"center"}>
          {formFields.map((item) => {
            switch (item.type) {
              case TYPE_CHECKBOX:
              case TYPE_CHECKBOX_TERMS:
                return (
                  <IframeCheckBox
                    key={item.systemName}
                    value={formValues[item.systemName]}
                    form={form}
                    userId={userId}
                    offer={offer}
                    formField={item}
                    settings={settings}
                    formCustomTermsAndConditions={formCustomTermsAndConditions}
                    onChange={handleChange(item.systemName)}
                  />
                );
              case TYPE_DATE:
                break;
              case TYPE_CUSTOM_HTML:
                return (
                  <IframeCustomHtml
                    noMargin
                    key={item.systemName}
                    value={null}
                    userId={userId}
                    form={form}
                    offer={offer}
                    settings={settings}
                    formField={item}
                    onChange={handleChange(item.systemName)}
                  />
                );
              case TYPE_NUMBER:
                break;
              case TYPE_TEXT_TITLE:
                return (
                  <IframeTitle
                    key={item.systemName}
                    value={null}
                    userId={userId}
                    form={form}
                    offer={offer}
                    formValues={formValues}
                    settings={settings}
                    formField={item}
                    variant={item.defaultValue || "h3"}
                    onChange={handleChange(item.systemName)}
                  />
                );
              case TYPE_SELECT:
                return (
                  <IframeSelect
                    key={item.systemName}
                    value={formValues[item.systemName]}
                    form={form}
                    userId={userId}
                    offer={offer}
                    formField={item}
                    settings={settings}
                    onChange={handleChange(item.systemName)}
                  />
                );
              case TYPE_SLIDER:
                return (
                  <IframeSlider
                    key={item.systemName}
                    value={formValues[item.systemName]}
                    form={form}
                    userId={userId}
                    offer={offer}
                    formField={item}
                    settings={settings}
                    onChange={handleChange(item.systemName)}
                  />
                );
              case TYPE_SLIDER_MONEY:
                return (
                  <IframeSliderLoad
                    key={item.systemName}
                    value={formValues[item.systemName]}
                    form={form}
                    offer={offer}
                    userId={userId}
                    formField={item}
                    settings={settings}
                    onChange={handleChange(item.systemName)}
                  />
                );
              case TYPE_SPACING:
                return (
                  <IframeSpacing
                    key={item.systemName}
                    value={formValues[item.systemName]}
                    form={form}
                    offer={offer}
                    userId={userId}
                    settings={settings}
                    formField={item}
                    onChange={handleChange(item.systemName)}
                  />
                );
              case TYPE_BANK_CODE:
                return (
                  <IframeBankCode
                    key={item.systemName}
                    value={formValues[item.systemName]}
                    form={form}
                    offer={offer}
                    formField={item}
                    userId={userId}
                    settings={settings}
                    onChange={handleChange(item.systemName)}
                  />
                );
              case TYPE_CZK_PRICE:
              case TYPE_PHONE_CZ:
              case TYPE_PHONE:
              case TYPE_TEXT:
              case TYPE_BANK_ACCOUNT:
              case TYPE_EMAIL:
              case TYPE_ONLY_NUMBER:
              case TYPE_CZ_ZIP:
              case TYPE_ONLY_TEXT:
              case TYPE_HOUSE_NUMBER:
              case TYPE_NIN:
              case TYPE_IDENTIFY_CARD_NUMBER:
                return (
                  <IframeTextFiled
                    key={item.systemName}
                    type={item.type}
                    value={formValues[item.systemName]}
                    form={form}
                    settings={settings}
                    userId={userId}
                    offer={offer}
                    formField={item}
                    onChange={handleChange(item.systemName)}
                  />
                );
            }
            return null;
          })}
        </Grid>
      </Flex>
    </Root>
  );
};
