import { Button } from "@material-ui/core";
import {
  Translate,
  useTranslateFunction,
} from "../../../core/translate/Translate";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../redux/store";
import { Flex } from "../../../core/components/layout/Flex";
import { MyAlert } from "../../../core/components/MyAlert";
import { sendNewVerifyTokenAction } from "../store/action/sendNewVerifyToken.action";

export const NotVerifyEmail = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppStateType) => state.auth.loggedUser);
  const t = useTranslateFunction();

  if (user?.emailVerified) {
    return null;
  }
  return (
    <Flex fullWidth={true} marginBottom={"xxl"}>
      <MyAlert
        $fullWidth={true}
        closeText={t("core.sendAgain")}
        onClose={(event) => event.stopPropagation()}
        elevation={6}
        variant="filled"
        severity={"warning"}
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              dispatch(sendNewVerifyTokenAction());
            }}
          >
            <Translate id={"core.sendAgain"} />
          </Button>
        }
      >
        <Translate id={"core.yourEmailIsNotVerified"} />
      </MyAlert>
    </Flex>
  );
};
