export const OFFER_ACCESS_TYPE_ALL = "all";
export const OFFER_ACCESS_TYPE_ONLY_INVITED = "only-invited";
export const OFFER_ACCESS_TYPE_NEED_APPROVAL = "need-approval";

export const OFFER_ACCESS_TYPES = [
  OFFER_ACCESS_TYPE_ALL,
  OFFER_ACCESS_TYPE_ONLY_INVITED,
  OFFER_ACCESS_TYPE_NEED_APPROVAL,
];

export type OfferAccessType =
  | typeof OFFER_ACCESS_TYPE_ALL
  | typeof OFFER_ACCESS_TYPE_ONLY_INVITED
  | typeof OFFER_ACCESS_TYPE_NEED_APPROVAL;
