import { Offer } from "./Offer";
import { User } from "./User";
import { Form } from "./Form";
import { Setting } from "../../setting/type/Setting";
import { FormCustomTermsAndCondition } from "./FormCustomTermsAndCondition";
import { OfferLoadingPage } from "../../offers/type/OfferLoadingPage";
import { API_ROOT } from "../../../core/api/constants";
import {
  OFFER_LEAD_TYPE_LINK,
  OfferLeadType,
} from "../../offers/type/OfferLeadType";
import { FormSetting } from "./FormSetting";
import { AlternativeOfferType } from "../../offers/type/enum/AlternativeOfferType";
import { File } from "../../file/type/File";

export const USER_OFFER_CONFIG_STATUS_ACTIVE = "active";
export const USER_OFFER_CONFIG_STATUS_NEED_APPROVE = "need-approve";
export const USER_OFFER_CONFIG_STATUS_REJECTED = "rejected";

export type UserOfferConfigStatus =
  | typeof USER_OFFER_CONFIG_STATUS_REJECTED
  | typeof USER_OFFER_CONFIG_STATUS_NEED_APPROVE
  | typeof USER_OFFER_CONFIG_STATUS_ACTIVE;

export const RECEIVE_LEAD_TYPE_EMAIL = "email";
export const RECEIVE_LEAD_TYPE_API = "api";

export type ReceiveLeadStatus =
  | typeof RECEIVE_LEAD_TYPE_EMAIL
  | typeof RECEIVE_LEAD_TYPE_API;

export const RECEIVE_LEAD_WHEN_DAILY = "daily";
export const RECEIVE_LEAD_WHEN_WEEKLY = "weekly";
export const RECEIVE_LEAD_WHEN_MONTHLY = "monthly";

export type ReceiveLeadHowOften =
  | typeof RECEIVE_LEAD_WHEN_MONTHLY
  | typeof RECEIVE_LEAD_WHEN_DAILY
  | typeof RECEIVE_LEAD_WHEN_WEEKLY;

export class UserOfferConfig extends FormSetting {
  id?: string;
  userId: number;
  offerId?: number;
  type: OfferLeadType = OFFER_LEAD_TYPE_LINK;
  status: UserOfferConfigStatus = USER_OFFER_CONFIG_STATUS_ACTIVE;
  formId?: number;
  ownToken: string | null = "";
  reason: string = "";
  offer?: Offer;
  owner?: User;
  form?: Form;
  loadingPages: OfferLoadingPage[] = [];
  settingNeedByForms: Setting[] = [];
  formCustomTermsAndConditions: FormCustomTermsAndCondition[] = [];
  created: string | null = null;
  alternativeOfferId: number | null = null;
  alternativeType: AlternativeOfferType | null = null;
  enableReceiveLead: boolean = false;
  receiveLeadTarget: string = "";
  receiveLeadType: ReceiveLeadStatus = RECEIVE_LEAD_TYPE_EMAIL;
  receiveLeadWhen: ReceiveLeadHowOften = RECEIVE_LEAD_WHEN_DAILY;
  lastReceiveLead: string | null = null;
  payoutStatus: string | null = null;
  payout: number | null = null;

  wsPort: string = "";

  //not api values
  enableAlternativeOffer: boolean = false;

  constructor(obj: any) {
    super();
    obj && Object.assign(this, obj);
    this.userId = obj.userId;
    if (this.form) {
      this.form = new Form(this.form);
    }
    if (this.owner) {
      this.owner = new User(this.owner);
    }
    if (this.offer) {
      this.offer = new Offer(this.offer);
    }
    if (this.leftLogo) {
      this.leftLogo = new File(this.leftLogo);
    }
    if (this.centerLogo) {
      this.centerLogo = new File(this.centerLogo);
    }
    if (this.loadingPages) {
      this.loadingPages = this.loadingPages.map(
        (item) => new OfferLoadingPage(item)
      );
    }
    if (obj.settingNeedByForms) {
      this.settingNeedByForms = obj.settingNeedByForms.map(
        (item: any) => new Setting(item)
      );
    } else {
      this.settingNeedByForms = [];
    }
    if (obj.formCustomTermsAndConditions) {
      this.formCustomTermsAndConditions = obj.formCustomTermsAndConditions.map(
        (item: any) => new FormCustomTermsAndCondition(item)
      );
    }
    this.enableAlternativeOffer =
      !!this.alternativeType && !!this.alternativeOfferId;
  }

  setPreview(preview: UserOfferConfig) {
    const listToSet: (keyof UserOfferConfig)[] = [
      "primaryColor",
      "secondaryColor",
      "border",
      "borderColor",
      "borderWidth",
      "centerLogo",
      "leftLogo",
      "centerLogoId",
      "leftLogoId",
      "showTimerOnSeconds",
      "headerText",
      "getMoneyText",
      "metaTitle",
      "metaDescription",
      "textUnderSubmit",
    ];
    listToSet.forEach((key: keyof UserOfferConfig) => {
      (this[key] as any) = preview[key];
    });
    this.status = USER_OFFER_CONFIG_STATUS_ACTIVE;
  }

  getPrimaryColor = (): string => {
    if (this.primaryColor) {
      return this.primaryColor;
    }
    if (this.offer?.primaryColor) {
      return this.offer.primaryColor;
    }
    if (!this.form) {
      throw new Error("This method ca use only user config with form!");
    }
    return this.form.primaryColor || "";
  };

  canUseReceiveLeads = () => {
    return !!this.formCustomTermsAndConditions?.length;
  };

  getSettingByName = (name: string) => {
    return this.settingNeedByForms.find((item) => item.name === name);
  };

  getSecondaryColor = (): string => {
    if (this.secondaryColor) {
      return this.secondaryColor;
    }
    if (this.offer?.secondaryColor) {
      return this.offer.secondaryColor;
    }
    if (!this.form) {
      throw new Error("This method ca use only user config with form!");
    }
    return this.form.secondaryColor || "";
  };
  getBorder = () => {
    if (this.border) {
      return this.border;
    }
    if (this.offer?.border) {
      return this.offer.border;
    }
    return this.form?.border;
  };
  getCenterLogoPath = () => {
    if ((this.centerLogo?.getPublicPath() || null) !== null) {
      return this.centerLogo?.getPublicPath();
    }
    if (this.offer?.centerLogo?.getPublicPath()) {
      return this.offer.centerLogo?.getPublicPath();
    }
    return this.form?.centerLogoPath;
  };
  getShowTimerOnSeconds = () => {
    if (this.showTimerOnSeconds !== null) {
      return this.showTimerOnSeconds;
    }
    if (this.offer?.showTimerOnSeconds) {
      return this.offer.showTimerOnSeconds;
    }
    return this.form?.showTimerOnSeconds || null;
  };
  getLeftPathLogo = () => {
    if ((this.leftLogo?.getPublicPath() || null) !== null) {
      return this.leftLogo?.getPublicPath();
    }
    if (this.offer?.leftLogo?.getPublicPath()) {
      return this.offer.leftLogo?.getPublicPath();
    }
    return this.form?.leftPathLogo;
  };
  getHeaderText = () => {
    if (this.headerText) {
      return this.headerText;
    }
    if (this.offer?.headerText) {
      return this.offer.headerText;
    }
    return this.form?.headerText;
  };
  getGetMoneyText = () => {
    if (this.getMoneyText) {
      return this.getMoneyText;
    }
    if (this.offer?.getMoneyText) {
      return this.offer.getMoneyText;
    }
    return this.form?.getMoneyText;
  };
  getMetaTitle = () => {
    if (this.metaTitle) {
      return this.metaTitle;
    }
    if (this.offer?.metaTitle) {
      return this.offer.metaTitle;
    }
    return this.form?.metaTitle;
  };
  getMetaDescription = () => {
    if (this.metaDescription) {
      return this.metaDescription;
    }
    if (this.offer?.metaDescription) {
      return this.offer.metaDescription;
    }
    return this.form?.metaDescription;
  };
  getBorderColor = () => {
    if (this.borderColor) {
      return this.borderColor;
    }
    if (this.offer?.borderColor) {
      return this.offer.borderColor;
    }
    return this.form?.borderColor;
  };
  getTextUnderSubmit = () => {
    if (this.textUnderSubmit) {
      return this.textUnderSubmit;
    }
    if (this.offer?.textUnderSubmit) {
      return this.offer.textUnderSubmit;
    }
    return this.form?.textUnderSubmit;
  };
  getBorderWidth = () => {
    if (this.borderWidth) {
      return this.borderWidth;
    }
    if (this.offer?.borderWidth) {
      return this.offer.borderWidth;
    }
    return this.form?.borderWidth;
  };

  getPageUrl(setting: Setting | null, pageId: number) {
    return (
      (setting?.value || "") +
      API_ROOT +
      "/l/" +
      this.id +
      "/" +
      pageId +
      "?affiliateClickId={affiliateClickId}"
    );
  }

  toJson(): any {
    const {
      status,
      reason,
      ownToken,
      primaryColor,
      secondaryColor,
      border,
      borderColor,
      borderWidth,
      centerLogoId,
      leftLogoId,
      showTimerOnSeconds,
      headerText,
      getMoneyText,
      metaTitle,
      metaDescription,
      textUnderSubmit,
      enableAlternativeOffer,
      receiveLeadWhen,
      receiveLeadType,
      receiveLeadTarget,
      enableReceiveLead,
      payout,
      payoutStatus,
    } = this;
    return {
      primaryColor: primaryColor || null,
      borderColor: borderColor || null,
      secondaryColor: secondaryColor || null,
      alternativeOfferId: enableAlternativeOffer
        ? this.alternativeOfferId || null
        : null,
      alternativeType: enableAlternativeOffer
        ? this.alternativeType || null
        : null,
      payout,
      payoutStatus,
      ownToken,
      status,
      reason,
      border,
      borderWidth,
      centerLogoId,
      leftLogoId,
      showTimerOnSeconds,
      headerText,
      getMoneyText,
      metaTitle,
      metaDescription,
      textUnderSubmit,
      receiveLeadWhen,
      receiveLeadType,
      receiveLeadTarget,
      enableReceiveLead,
    };
  }
}

export default UserOfferConfig;
