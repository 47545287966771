import { combineReducers } from "redux";
import {
  REDUX_CONNECTION_CREATOR_DETAIL,
  REDUX_CONNECTION_CREATOR_DIALOG,
  REDUX_CONNECTION_CREATOR_DIALOG_TEMPLATE,
  REDUX_CONNECTION_CREATOR_REQUEST_LOGS,
  REDUX_CONNECTION_CREATORS,
  REDUX_CONNECTION_PROPERTY_MODIFY_DIALOG,
  REDUX_CONNECTION_TEMPLATE_DIALOG,
} from "../../constants";
import { OfferConnection } from "../../type/OfferConnection";
import { createReducerDialog } from "../../../../redux/tools/createReducer/createReducerDialog";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";
import { createReducerApiArray } from "../../../../redux/tools/createReducer/createReducerApiArray";
import { RequestConfigLog } from "../../../../core/connectionCreator/type/RequestConfigLog";

export const connectionCreatorsReducer = combineReducers({
  //list
  connectionCreators: createReducerApiArray<OfferConnection>(
    REDUX_CONNECTION_CREATORS
  ),
  connectionCreatorDialog: createReducerDialog<OfferConnection | null>(
    REDUX_CONNECTION_CREATOR_DIALOG
  ),
  connectionCreatorDialogTemplate: createReducerDialog<OfferConnection | null>(
    REDUX_CONNECTION_CREATOR_DIALOG_TEMPLATE
  ),
  propertyModifyDialog: createReducerDialog<string | null>(
    REDUX_CONNECTION_PROPERTY_MODIFY_DIALOG
  ),
  connectionTemplateDialog: createReducerDialog<OfferConnection | null>(
    REDUX_CONNECTION_TEMPLATE_DIALOG
  ),
  requestLogs: createReducerApiArray<RequestConfigLog>(
    REDUX_CONNECTION_CREATOR_REQUEST_LOGS
  ),

  //detail
  connectionCreatorDetail: createReducerObject<OfferConnection | null>(
    REDUX_CONNECTION_CREATOR_DETAIL,
    null,
    OfferConnection
  ),
});
