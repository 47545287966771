import {
  ENDPOINT_OFFER,
  REDUX_OFFER_OFFERS,
  REDUX_OFFERLAST_PAGE,
  REDUX_OFFER_OFFERS_LIGHT,
  REDUX_OFFER_PAGE,
} from "../../../constants";
import { get, basicHandleError } from "../../../../../core/api/utils/fetchApi";
import { AppDispatchType } from "../../../../../redux/store";
import {
  actionLock,
  actionUnlock,
} from "../../../../../core/layouts/store/actions/lockable.actions";
import { LockKey } from "../../../../../core/layouts/utils/useIsLocked";
import { Offer } from "../../../../formsIframe/type/Offer";
import { actionReducerArraySet } from "../../../../../redux/tools/createReducer/createReducerArray";

export const getLockKeyActionGetOffers = (): LockKey => {
  return "gets" + ENDPOINT_OFFER;
};

export interface OffersActionFilter {
  country?: string[];
  search?: string;
}

export const getOffersAction = (
  page: number = 1,
  pageSize: number = 25,
  filter: OffersActionFilter = {},
  light = false
) => async (dispatch: AppDispatchType) => {
  dispatch(actionLock(getLockKeyActionGetOffers()));
  const res = await get(ENDPOINT_OFFER, {
    page: light ? undefined : page,
    pageSize: light ? undefined : pageSize,
    sort: "name",
    ...filter,
    light: light ? String(light) : undefined,
  });
  dispatch(actionUnlock(getLockKeyActionGetOffers()));
  if (res.ok) {
    const offers = res.data.map((item: any) => new Offer(item));
    if (light) {
      dispatch(actionReducerArraySet(REDUX_OFFER_OFFERS_LIGHT, offers));
    } else {
      dispatch(actionReducerArraySet(REDUX_OFFER_OFFERS, offers));
      dispatch(actionReducerArraySet(REDUX_OFFER_PAGE, res?.metadata?.page));
      dispatch(
        actionReducerArraySet(REDUX_OFFERLAST_PAGE, res?.metadata?.lastPage)
      );
    }
  } else {
    dispatch(basicHandleError(res));
  }
};
