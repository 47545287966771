import * as React from "react";
import styled from "styled-components/macro";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/store";
import { Flag } from "../../../../modules/offers/components/common/offer/DisplayOfferCountry";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function LanguagesDropdown() {
  const languages = useSelector(
    (state: AppStateType) => state.translate.languages
  );
  const currentLanguageIndex = useSelector(
    (state: AppStateType) => state.translate.currentLanguageIndex
  );
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };
  const selectLanguage = (index: number) => () => {};

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <Tooltip title="Languages">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
        >
          <Flag
            src={`/static/img/flags/${
              languages[currentLanguageIndex || 0]
            }.svg`}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {languages.map((language, index) => {
          return (
            <MenuItem onClick={selectLanguage(index)} key={language}>
              <Flag src={`/static/img/flags/${language}.svg`} />
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
}

export default LanguagesDropdown;
