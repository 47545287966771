import { ResponseConnectionProperty } from "./ResponseConnectionProperty";
import { ResponseConnectionPropertyCheck } from "./RequestConnectionPropertyCheck";

export class ResponseHandler {
  public static RESULT_MEANING_SUCCESS = "success";
  public static RESULT_MEANING_FAIL = "fail";
  public static RESULT_MEANING_BASE_ON_BODY = "base-on-body";
  public static RESULT_MEANING_GO_TO_STEP = "go-to-step";
  public static RESULT_MEANINGS = [
    ResponseHandler.RESULT_MEANING_SUCCESS,
    ResponseHandler.RESULT_MEANING_FAIL,
    ResponseHandler.RESULT_MEANING_BASE_ON_BODY,
  ];

  expectedHttpState: number | null = null;
  resultMeaning: string | null = null;
  resultAction: string | null = null;
  actionValue: string | null = null;
  responseConnectionProperties: ResponseConnectionProperty[] = [];
  responseConnectionPropertiesCheck: ResponseConnectionPropertyCheck[] = [];
  responseConnectionPropertiesMatch: ResponseConnectionPropertyCheck[] = [];
  order: number = 0;
  responseConfigId: null | number = null;
  id: null | number = null;

  constructor(obj?: any) {
    obj && Object.assign(this, obj);
    if (Array.isArray(obj?.responseConnectionProperties)) {
      this.responseConnectionProperties = obj.responseConnectionProperties.map(
        (item: any) => new ResponseConnectionProperty(item)
      );
    }
    if (Array.isArray(obj?.responseConnectionPropertiesCheck)) {
      this.responseConnectionPropertiesCheck = obj.responseConnectionPropertiesCheck.map(
        (item: any) => new ResponseConnectionPropertyCheck(item)
      );
    }
    if (Array.isArray(obj?.responseConnectionPropertiesMatch)) {
      this.responseConnectionPropertiesMatch = obj.responseConnectionPropertiesMatch.map(
        (item: any) => new ResponseConnectionPropertyCheck(item)
      );
    }
  }

  toJson() {
    const {
      id,
      expectedHttpState,
      resultMeaning,
      order,
      responseConnectionProperties,
      responseConnectionPropertiesCheck,
      responseConnectionPropertiesMatch,
      actionValue,
      resultAction,
    } = this;
    return {
      id,
      actionValue,
      resultAction,
      expectedHttpState,
      resultMeaning,
      order,
      responseConnectionProperties: responseConnectionProperties.map((item) =>
        item.toJson()
      ),
      responseConnectionPropertiesMatch: responseConnectionPropertiesMatch.map(
        (item) => item.toJson()
      ),
      responseConnectionPropertiesCheck: responseConnectionPropertiesCheck.map(
        (item) => item.toJson()
      ),
    };
  }

  toTemplateJson() {
    const {
      expectedHttpState,
      resultMeaning,
      order,
      responseConnectionProperties,
      responseConnectionPropertiesCheck,
      responseConnectionPropertiesMatch,
      actionValue,
      resultAction,
    } = this;
    return {
      actionValue,
      resultAction,
      expectedHttpState,
      resultMeaning,
      order,
      responseConnectionProperties: responseConnectionProperties.map((item) =>
        item.toTemplateJson()
      ),
      responseConnectionPropertiesMatch: responseConnectionPropertiesMatch.map(
        (item) => item.toTemplateJson()
      ),
      responseConnectionPropertiesCheck: responseConnectionPropertiesCheck.map(
        (item) => item.toTemplateJson()
      ),
    };
  }
}
