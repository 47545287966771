import { RouteType } from "../../../types/routes";
import { PostbackClickLeads, PostbackLeads, PostbackTracking } from "../container";
import { getUrlPostBackClickLead, getUrlPostBackLead, getUrlPostBackTracking } from "../utils/getUrlPostBack";

export const postBackRoutes: RouteType = {
  id: "postBack",
  path: "/postBack",
  children: [
    {
      path: getUrlPostBackClickLead(),
      component: PostbackClickLeads,
    },
    {
      path: getUrlPostBackLead(),
      component: PostbackLeads,
    },
    {
      path: getUrlPostBackTracking(),
      component: PostbackTracking,
    },
  ],
  component: null,
};
