import { AppDispatchType } from "../../../../redux/store";
import { post } from "../../../../core/api/utils/fetchApi";
import { ENDPOINT_VERIFY_SMS } from "../../../form/constants";
import { actionSnackbarShow } from "../../../../core/layouts/store/actions/snackbar.actions";

export const actionVerifySms = (
  accessToken: string,
  phoneNumber: string,
  pin: string,
  timeInMs: number,
) => async (dispatch: AppDispatchType) => {
  if (!phoneNumber) {
    dispatch(
      actionSnackbarShow({
        translateId: "iframe.smsWasResendFail",
        variant: "error",
      })
    );
    return;
  }
  const res = await post(ENDPOINT_VERIFY_SMS + "/" + accessToken, {
    phoneNumber,
    timeInMs,
    pin,
  });
  if (res.ok) {
    dispatch(
      actionSnackbarShow({
        translateId: "iframe.smsWasVerified",
        variant: "success",
      })
    );
  } else {
    if (res.code === 400) {
      dispatch(
        actionSnackbarShow({
          translateId: "iframe.smsWasNotVerified",
          variant: "error",
        })
      );
    } else {
      dispatch(
        actionSnackbarShow({
          translateId: "iframe.smsVerifyFail",
          variant: "error",
        })
      );
    }
  }
};
