// Auth components
import React from "react";

export const Offers = React.lazy(
  () => /* webpackChunkName: 'Offers'*/ import("./Offers")
);

export const OfferEdit = React.lazy(
  () => /* webpackChunkName: 'OfferEdit'*/ import("./OfferEdit")
);

export const OfferDetail = React.lazy(
  () => /* webpackChunkName: 'OfferDetail'*/ import("./OfferDetail")
);

export const OfferUserConfigsNeedToApprove = React.lazy(
  () =>
    /* webpackChunkName: 'OfferUserConfigsNeedToApprove'*/ import(
      "./OfferUserConfigsNeedToApprove"
    )
);
