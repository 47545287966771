export const TYPE_TEXT = "text";
export const TYPE_NUMBER = "number";
export const TYPE_CHECKBOX = "checkbox";
export const TYPE_CHECKBOX_TERMS = "checkbox-terms";
export const TYPE_SLIDER = "slider";
export const TYPE_SLIDER_MONEY = "slider-money";
export const TYPE_PHONE = "phone";
export const TYPE_PHONE_CZ = "phone-cz";
export const TYPE_TEXT_TITLE = "text-title";
export const TYPE_CUSTOM_HTML = "custom-html";
export const TYPE_USER_AGENT = "user-agent";
export const TYPE_ADDRESS = "ip-address";
export const TYPE_EMAIL = "email";
export const TYPE_BANK_CODE = "bank-code";
export const TYPE_BANK_ACCOUNT = "bank-account";
export const TYPE_CZK_PRICE = "czk-price";
export const TYPE_CZ_ZIP = "cz-zip";
export const TYPE_ONLY_TEXT = "only-text";
export const TYPE_ONLY_NUMBER = "only-number";
export const TYPE_HOUSE_NUMBER = "house-number";
export const TYPE_SPACING = "spacing";
export const TYPE_DATE = "date";
export const TYPE_SELECT = "select";
export const TYPE_TIMER = "timer";

export const IFRAME_INPUTS_TYPE = [
  TYPE_TEXT,
  TYPE_NUMBER,
  TYPE_CHECKBOX,
  TYPE_CHECKBOX_TERMS,
  TYPE_SLIDER,
  TYPE_SLIDER_MONEY,
  TYPE_PHONE,
  TYPE_PHONE_CZ,
  TYPE_ADDRESS,
  TYPE_EMAIL,
  TYPE_BANK_CODE,
  TYPE_BANK_ACCOUNT,
  TYPE_CZK_PRICE,
  TYPE_CZ_ZIP,
  TYPE_ONLY_TEXT,
  TYPE_ONLY_NUMBER,
  TYPE_HOUSE_NUMBER,
  TYPE_DATE,
  TYPE_TIMER,
  TYPE_SELECT,
];

export const TYPE_IDENTIFY_CARD_NUMBER = "identity-card-number";
export const TYPE_NIN = "nin";
