import { ReduxAction } from "../../../../../../redux/type";
import { CLEAR, RESET, SET } from "../../../../../../redux/constants";
import { ReduxActionArray } from "../../../../../../redux/tools/createReducer/createReducerArray";
import { ReducersMapObject } from "redux";

export interface ReduxActionForEntitySelectsClear extends ReduxAction {
  operation: typeof CLEAR | typeof RESET;
}

export type ReduxActionForEntitySelects = (
  | ReduxActionArray
  | ReduxActionForEntitySelectsClear
) & {
  selectName: string;
};

export const FOR_ENTITY_SELECTS_ACTION = "FOR_ENTITY_SELECTS_ACTION";

/**
 * Create reducer with dynamic size for select in formik
 */
export const createReducerForEntitySelects = () => (
  state: Record<string, ReducersMapObject> = {},
  action: ReduxActionForEntitySelects
): any => {
  if (action.type !== FOR_ENTITY_SELECTS_ACTION) {
    return state;
  }
  const selectName = action.selectName;
  switch (action.operation) {
    case CLEAR:
      return {
        ...state,
        [selectName]: undefined,
      };
    case SET:
      return {
        ...state,
        [selectName]: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
