import * as React from "react";
import UserOfferConfig from "../../../type/UserOfferConfig";
import { FC } from "react";
import { Flex } from "../../../../../core/components/layout/Flex";
import { TextField, Theme, useMediaQuery } from "@material-ui/core";

interface FinalPageInputProps {
  formData: UserOfferConfig;
  value?: string;
  systemName: string;
}

export const FinalPageInput: FC<FinalPageInputProps> = ({
  formData,
  value,
  systemName,
}) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const formField = formData?.form?.getFormFieldByName(systemName);
  if (!formField || !formData.offer) {
    return null;
  }
  return (
    <Flex
      width={smDown ? "100%" : "50%"}
      marginBottom={"medium"}
      paddingLeft={"mini"}
      paddingRight={"mini"}
    >
      <TextField
        InputProps={{ readOnly: true }}
        value={value || ""}
        variant={"outlined"}
        label={formField.visibleDescription}
      />
    </Flex>
  );
};
