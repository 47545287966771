import { ReduxAction } from "../../type";
import { RESET, SET } from "../../constants";

type ReduxActionOperationNumber = typeof SET | typeof RESET;

export interface ReduxActionNumber extends ReduxAction {
  operation: ReduxActionOperationNumber;
}

export interface ReduxActionNumberSet extends ReduxAction {
  operation: typeof SET;
}
export const actionReducerNumberSet = (
  type: string,
  payload: any
): ReduxActionNumberSet => ({
  type,
  operation: SET,
  payload,
});

export interface ReduxActionNumberReset extends ReduxAction {
  operation: typeof RESET;
}
export const actionReducerNumberReset = (
  type: string,
  payload: any
): ReduxActionNumberReset => ({
  type,
  operation: RESET,
  payload,
});

export const createReducerNumber = (
  actionType: string,
  defaultState: null | number
) => (state = defaultState, action: ReduxActionNumber): null | number => {
  if (action.type === actionType) {
    const actions: Record<ReduxActionOperationNumber, () => null | number> = {
      [SET]: () => {
        if (action.payload === undefined) {
          throw new Error(
            `Can not set undefined to reducer. Reducer: ${actionType}, operation: ${SET}`
          );
        }
        return action.payload;
      },
      [RESET]: () => defaultState,
    };
    if (actions[action.operation]) {
      return actions[action.operation]();
    } else {
      throw new Error(
        `Unknown operation ${action.operation} for reducer: ${action.type}`
      );
    }
  }
  return state;
};
