export const MESSAGE_REDIRECT = "MESSAGE_REDIRECT";
export const ERROR_IN_IFRAME = "ERROR_IN_IFRAME";
export const SHOW_SMS_VERIFY = "SHOW_SMS_VERIFY";
export const FAIL_TO_SEND_SMS = "FAIL_TO_SEND_SMS";
export const MESSAGE_SEARCH_IN = "MESSAGE_SEARCH_IN";
export const MESSAGE_CONFIRM_ALTERNATIVE_OFFER =
  "MESSAGE_CONFIRM_ALTERNATIVE_OFFER";
export const MESSAGE_FINAL_PAGE = "MESSAGE_FINAL_PAGE";
export const MESSAGE_OFFER_IS_NOT_SETUP = "MESSAGE_OFFER_IS_NOT_SETUP";
export const NO_OFFER_FOR_YOU = "NO_OFFER_FOR_YOU";
