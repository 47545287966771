import { AppDispatchType } from "../../../../redux/store";
import {
  ERROR_IN_IFRAME,
  FAIL_TO_SEND_SMS,
  MESSAGE_CONFIRM_ALTERNATIVE_OFFER,
  MESSAGE_FINAL_PAGE,
  MESSAGE_OFFER_IS_NOT_SETUP,
  MESSAGE_REDIRECT,
  MESSAGE_SEARCH_IN,
  NO_OFFER_FOR_YOU,
  SHOW_SMS_VERIFY,
} from "../../constant/websocket";
import { actionReducerStringSet } from "../../../../redux/tools/createReducer/createReducerString";
import {
  IFRAME_PAGE_FINAL_PAGE,
  IFRAME_PAGE_IFRAME,
  IFRAME_PAGE_ALTERNATIVE_OFFER,
  IFRAME_PAGE_REDIRECT,
  REDUX_IFRAME_ALTERNATIVE_OFFER_LAST_NAME,
  REDUX_IFRAME_ALTERNATIVE_OFFER_NEXT,
  REDUX_IFRAME_CURRENT_SEARCH,
  REDUX_IFRAME_FINALE_PAGE_TYPE,
  REDUX_IFRAME_LOADER_TIMER,
  REDUX_IFRAME_PAGE,
  REDUX_IFRAME_REDIRECT_URL,
  IFRAME_PAGE_SUCCESS,
  REDUX_IFRAME_FINALE_PAGE_LINKS,
  IFRAME_PAGE_SMS_VERIFY,
} from "../../constant";
import { actionReducerNumberSet } from "../../../../redux/tools/createReducer/createReducerNumber";
import { Offer } from "../../type/Offer";
import { actionSnackbarShow } from "../../../../core/layouts/store/actions/snackbar.actions";
import { FINAL_PAGE_RESULT_CASCADE } from "../../constant/cascadeResult";
import { iframeRedirect } from "../../utils/iframeRedirect";
import { actionReducerArraySet } from "../../../../redux/tools/createReducer/createReducerArray";
import { FinalePageLink } from "../../../form/type/FinalePageLink";
import { handleWebsocketMessageFinalePage } from "./handleWebsocketMessageFinalePage.action";

export const actionHandleWebsocketMessage = (e: MessageEvent) => (
  dispatch: AppDispatchType
) => {
  console.log(e);
  let data: any = null;
  try {
    data = JSON.parse(e.data);
  } catch (e) {
    console.log(e);
    return;
  }
  if (!data?.type) {
    console.error("Websocket message have not type!");
    return;
  }
  switch (data?.type) {
    case NO_OFFER_FOR_YOU:
      const finalPageLinks = (Array.isArray(data?.message?.finalPageLinks)
        ? data.message.finalPageLinks
        : []
      ).map((item: any) => new FinalePageLink(item));
      if (finalPageLinks.length === 0) {
        dispatch(
          actionReducerStringSet(
            REDUX_IFRAME_PAGE,
            IFRAME_PAGE_ALTERNATIVE_OFFER
          )
        );
      } else {
        dispatch(
          actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_FINAL_PAGE)
        );
        dispatch(
          actionReducerNumberSet(
            REDUX_IFRAME_FINALE_PAGE_TYPE,
            FINAL_PAGE_RESULT_CASCADE
          )
        );
        dispatch(
          actionReducerArraySet(REDUX_IFRAME_FINALE_PAGE_LINKS, finalPageLinks)
        );
      }
      break;
    case MESSAGE_REDIRECT:
      //redirect
      dispatch(redirectIframe(data?.message?.lead?.redirectUrl));
      break;
    case MESSAGE_OFFER_IS_NOT_SETUP:
      dispatch(actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_IFRAME));
      dispatch(
        actionSnackbarShow({
          translateId: "iframe.offerIsNotSetUp",
          variant: "error",
        })
      );
      break;
    case ERROR_IN_IFRAME:
      //show error
      dispatch(actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_IFRAME));
      if (data?.message?.errors?.length) {
        data?.message?.errors.forEach((error: string) => {
          dispatch(actionSnackbarShow({ variant: "error", message: error }));
        });
      } else {
        dispatch(
          actionSnackbarShow({
            translateId: "iframe.failToSaveLead",
            variant: "error",
          })
        );
      }
      break;
    case MESSAGE_SEARCH_IN:
      // set search in
      dispatch(
        actionReducerNumberSet(
          REDUX_IFRAME_CURRENT_SEARCH,
          data?.message?.offerName || "?"
        )
      );
      dispatch(
        actionReducerNumberSet(
          REDUX_IFRAME_LOADER_TIMER,
          data?.message?.time || "?"
        )
      );

      break;
    case MESSAGE_FINAL_PAGE:
      dispatch(handleWebsocketMessageFinalePage(data));
      break;
    case MESSAGE_CONFIRM_ALTERNATIVE_OFFER:
      //show alternative offer
      if (!data?.message?.offer) {
        return;
      }
      dispatch(
        actionReducerNumberSet(
          REDUX_IFRAME_ALTERNATIVE_OFFER_LAST_NAME,
          data?.message?.lastOfferName || "?"
        )
      );
      dispatch(
        actionReducerNumberSet(
          REDUX_IFRAME_ALTERNATIVE_OFFER_NEXT,
          new Offer(data.message.offer)
        )
      );
      dispatch(
        actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_ALTERNATIVE_OFFER)
      );
      break;
    case FAIL_TO_SEND_SMS:
      if (data?.message["message"]) {
        dispatch(
          actionSnackbarShow({
            variant: "error",
            message: data?.message["message"],
          })
        );
      } else {
        dispatch(
          actionSnackbarShow({
            variant: "error",
            translateId: "lead.failToSendSsm",
          })
        );
      }
      break;
    case SHOW_SMS_VERIFY:
      dispatch(
        actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_SMS_VERIFY)
      );
      break;
  }
};

export const redirectIframe = (url?: string | null) => (
  dispatch: AppDispatchType
) => {
  dispatch(actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_REDIRECT));
  dispatch(actionReducerStringSet(REDUX_IFRAME_REDIRECT_URL, url));
  setTimeout(() => {
    if (url) {
      iframeRedirect(url);
    } else {
      if (url === null) {
        dispatch(
          actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_SUCCESS)
        );
      } else {
        dispatch(
          actionSnackbarShow({
            translateId: "iframe.failToRedirect",
            variant: "error",
          })
        );
      }
    }
  });
};
