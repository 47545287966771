import React, { FC } from "react";
import { Flex } from "../../../../core/components/layout/Flex";
import {
  FormsIframePageProps,
  IframeRoot,
} from "../../container/FormsIframeContainer";
import IframeHeader, {
  useMoneySliderValue,
} from "../iframe/header/IframeHeader";
import { IframeDidYouKnow } from "../loading/IframeDidYouKnow";
import { Helmet } from "react-helmet";
import { useHistoryLocation } from "../../../../core/history";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/store";
import {
  IframeSubtitle,
  IframeTitle,
  SearchIconLeft,
  SearchIconRight,
  StrongPrimary,
} from "../../container/IframeLoading";
import { IframeHeaderLogos } from "../iframe/header/IframeHeaderLogos";
import { FinalPageFilledFields } from "./subComponents/FinallPageFilledFields";
import { OffersList } from "./subComponents/OffersList";
import { Translate } from "../../../../core/translate/Translate";
import { Price } from "../../../currency/components/Price";

const ContentContainer = styled.div`
  padding: 0 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    padding: 0 5px;
  }
`;

const IframeTitleStyled = styled(IframeTitle)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IframeSubtitleStyled = styled(IframeSubtitle)`
  margin-top: 0;
  margin-bottom: 24px;
`;

const RootPadding = styled.div<{
  $top?: boolean;
  $bottom?: boolean;
  $sides?: boolean;
}>`
  padding: ${({ theme, $sides, $bottom, $top }) =>
    theme.spacing(
      $top ? 4 : 0,
      $sides ? 4 : 0,
      $sides ? 4 : 0,
      $bottom ? 4 : 0
    )};
  width: 100%;
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    padding: 0;
  }
`;

export const IframeFinalPage: FC<FormsIframePageProps> = ({ formData }) => {
  const location = useHistoryLocation<{ hideBg: "true" | null }>();
  const formValues = useSelector(
    (state: AppStateType) => state.iframe.formValues
  );
  const resSuccess = useSelector(
    (state: AppStateType) => state.iframe.finalPage.resSuccess
  );
  const resFail = useSelector(
    (state: AppStateType) => state.iframe.finalPage.resFail
  );
  const finalPageLinks = useSelector(
    (state: AppStateType) => state.iframe.finalPage.finalPageLinks
  );
  const { moneyCzSlider } = useMoneySliderValue({
    formData,
  });
  const countToSearch = useSelector(
    (state: AppStateType) => state.iframe.loader.countToSearch
  );
  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle="">
        <title itemProp="name">{formData.getMetaTitle() || ""}</title>
        <meta
          name="description"
          content={formData.getMetaDescription() || ""}
        />
      </Helmet>
      <IframeRoot
        $formData={formData}
        $noMarginBottom={true}
        $noPadding={true}
        $hideBg={location?.search?.hideBg === "true"}
      >
        <Flex fullWidth={true} flexDirection={"column"} alignItems={"center"}>
          <RootPadding $top={true} $sides={true}>
            <IframeHeaderLogos formData={formData} />
          </RootPadding>
          <ContentContainer>
            <RootPadding $top={true} $sides={true}>
              <IframeTitleStyled variant={"h3"} align={"center"}>
                <SearchIconLeft />
                <span>
                  <Translate id={"iframe.finalPageCascadeTitle1"} />{" "}
                  <StrongPrimary>
                    <Translate id={"iframe.finalPageCascadeTitle2"} />
                  </StrongPrimary>{" "}
                  <Translate id={"iframe.finalPageCascadeTitle3"} />{" "}
                  <StrongPrimary>
                    <Price
                      price={moneyCzSlider || 0}
                      currency={formData?.offer?.currency}
                    />
                  </StrongPrimary>
                </span>
                <SearchIconRight />
              </IframeTitleStyled>

              <IframeSubtitle variant={"subtitle1"} align={"center"}>
                <Translate id={"iframe.finalPageCascadeSubTitle1"} />{" "}
                <strong>
                  {countToSearch}{" "}
                  <Translate id={"iframe.finalPageCascadeSubTitle21"} />{" "}
                </strong>
                <Translate id={"iframe.finalPageCascadeSubTitle22"} />{" "}
                <strong>{resSuccess.length} </strong>
                <Translate id={"iframe.finalPageCascadeSubTitle3"} />{" "}
                <strong>
                  <Translate id={"iframe.finalPageCascadeSubTitle4"} />
                </strong>
              </IframeSubtitle>

              <IframeSubtitleStyled variant={"subtitle1"} align={"center"}>
                <Translate id={"iframe.finalPageCascadeSubTitleInfo1"} /> &nbsp;
                <strong>
                  <Translate id={"iframe.finalPageCascadeSubTitleInfo2"} />{" "}
                </strong>
                &nbsp;
                <Translate id={"iframe.finalPageCascadeSubTitleInfo3"} />
              </IframeSubtitleStyled>
              <IframeHeader
                formData={formData}
                currentPage={2}
                formValues={formValues}
                onlyMoney={true}
              />

              <IframeSubtitle variant={"subtitle1"} align={"center"}>
                <Translate id={"iframe.finalPageOfferInfoTitle1"} />{" "}
                <strong>
                  <Translate id={"iframe.finalPageOfferInfoTitle2"} />
                </strong>{" "}
                <Translate id={"iframe.finalPageOfferInfoTitle3"} />{" "}
                <strong>
                  <Translate id={"iframe.finalPageOfferInfoTitle4"} />
                </strong>{" "}
                <Translate id={"iframe.finalPageOfferInfoTitle5"} />{" "}
                <strong>
                  <Translate id={"iframe.finalPageOfferInfoTitle6"} />
                </strong>
              </IframeSubtitle>

              <FinalPageFilledFields formData={formData} />
            </RootPadding>
          </ContentContainer>

          <OffersList
            disabled={false}
            result={resSuccess}
            formData={formData}
            finalPageLinks={finalPageLinks}
          />

          <ContentContainer>
            <RootPadding $top={true} $sides={true}>
              <IframeDidYouKnow />
            </RootPadding>
          </ContentContainer>
          <OffersList
            disabled={true}
            result={resFail}
            formData={formData}
            finalPageLinks={[]}
          />
        </Flex>
      </IframeRoot>
    </>
  );
};
