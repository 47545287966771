import { Breadcrumbs, Divider, Grid, Link } from "@material-ui/core";
import React, { FC } from "react";
import { Flex } from "../../../components/layout/Flex";
import { MyGrid } from "../../../components/MyGrid";
import { Body1, H3, Subtitle1 } from "../../../components/typography";
import { AppStateType } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { Translate } from "../../../translate/Translate";
import { NavLink } from "react-router-dom";

export const Header: FC = () => {
  const header = useSelector((state: AppStateType) => state.layout.header);
  const breadcrumbs = useSelector(
    (state: AppStateType) => state.layout.breadcrumbs
  );
  if (!header) {
    return null;
  }
  return (
    <Flex marginBottom={"xl"} flexDirection={"column"} fullWidth={true}>
      <MyGrid justify="space-between" container spacing={6}>
        <MyGrid item>
          {(header.title || header.titleTranslateId) && (
            <H3>
              {header.titleTranslateId ? (
                <Translate id={header.titleTranslateId} />
              ) : (
                header.title
              )}
            </H3>
          )}
          {!!(header.text || header.textTranslateId) && (
            <Subtitle1>
              {header.textTranslateId ? (
                <Translate id={header.textTranslateId} />
              ) : (
                header.text
              )}
            </Subtitle1>
          )}
        </MyGrid>

        <Grid item>{header.Action && <header.Action />}</Grid>
      </MyGrid>
      {breadcrumbs.length !== 0 && (
        <Flex marginBottom={"medium"}>
          <Breadcrumbs>
            {breadcrumbs.map((item, index) => {
              if (index === breadcrumbs.length - 1) {
                return (
                  <Body1 marginBottom={"unset"} key={item.translateId}>
                    {item.translateId ? (
                      <Translate id={item.translateId} />
                    ) : (
                      item.title
                    )}
                  </Body1>
                );
              }
              return (
                <Link component={NavLink} exact to={item.link} key={item.link}>
                  {item.translateId ? (
                    <Translate id={item.translateId} />
                  ) : (
                    item.title
                  )}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Flex>
      )}

      <Divider variant={"fullWidth"} />
    </Flex>
  );
};
