export class RequestConnectionPropertyModify {
  public static CONDITION_TYPE_EQUALS = "equals";
  public static CONDITION_TYPE_SMALLER_THEN = "smaller-then";
  public static CONDITION_TYPE_BIGGER_THAN = "bigger-than";

  valueToChange: string = "";
  conditionValue: null | string = null;
  conditionType: null | string = null;
  id: null | number = null;
  requestConnectionPropertyId: null | number = null;

  constructor(obj?: any) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    const { conditionType, conditionValue, id, valueToChange } = this;
    return {
      conditionType,
      conditionValue,
      id,
      valueToChange,
    };
  }

  toTemplateJson() {
    const { conditionType, conditionValue, valueToChange } = this;
    return {
      conditionType,
      conditionValue,
      valueToChange,
    };
  }
}
