import React, { FC, useEffect, useState } from "react";
import { Form } from "../../../type/Form";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../redux/store";

interface Props {
  form: Form;
  currentPage: number;
  formValues: Record<string, any>;
}

const Root = styled.div`
  padding: ${(props) => props.theme.spacing(1, 2)};
  border-radius: ${(props) => props.theme.spacing(1)}px;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.secondary.contrastText};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.theme.spacing(4)}px auto 0;
  z-index: 100;
`;

export const FormPercentageUi: FC = ({ children }) => (
  <Root>
    <Typography variant="h3">
      <strong>{children}</strong>
    </Typography>
  </Root>
);

const FormPercentages: FC<Props> = ({ form, formValues }) => {
  const filledFiledFields = useSelector(
    (state: AppStateType) => state.iframe.filledInputs
  );
  const [valueToShow, setValueToShow] = useState(0);
  useEffect(() => {
    const needToBeFill = form.formFields.filter((formField) => {
      if (formField.doNotUse(formValues)) {
        return false;
      }
      return formField.required;
    });
    let filled = 0;
    needToBeFill.forEach((formField) => {
      if (filledFiledFields.includes(formField.systemName)) {
        filled++;
      }
    });
    const targetValue = (filled / (needToBeFill.length || 1)) * 100;
    if (valueToShow !== targetValue) {
      setValueToShow(targetValue);
    }
  }, [
    filledFiledFields,
    setValueToShow,
    valueToShow,
    formValues,
    form.formFields,
  ]);
  return <FormPercentageUi>{Math.round(valueToShow)}&nbsp;%</FormPercentageUi>;
};

export default FormPercentages;
