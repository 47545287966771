import * as React from "react";
import { FC } from "react";
import { Route, RouteProps } from "react-router";
import FormsLayout from "../layout/FormsLayout";
import { FormsPreviewTerms } from "./index";

const FormsPreviewTermsRoute: FC<Omit<RouteProps, "render" | "component">> = (
  props
) => {
  return (
    <Route
      {...props}
      render={(props) => (
        <FormsLayout>
          <FormsPreviewTerms />
        </FormsLayout>
      )}
    />
  );
};

export default FormsPreviewTermsRoute;
