// Auth components
import React from "react";

export const SignIn = React.lazy(
  () => /* webpackChunkName: 'SignIn'*/ import("./SignIn")
);
export const SignUp = React.lazy(
  () => /* webpackChunkName: 'SignUp'*/ import("./SignUp")
);
export const ResendPassword = React.lazy(
  () => /* webpackChunkName: 'ResendPassword'*/ import("./ResendPassword")
);
export const ResetPassword = React.lazy(
  () => /* webpackChunkName: 'ResetPassword'*/ import("./ResetPassword")
);
export const Page404 = React.lazy(
  () => /* webpackChunkName: 'Page404'*/ import("./Page404")
);
export const Profile = React.lazy(
  () => /* webpackChunkName: 'Profile'*/ import("./Profile")
);
export const Page500 = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'Page500'*/
      "./Page500"
    )
);
export const VerifyEmail = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'VerifyEmail'*/
      "./VerifyEmail"
    )
);
