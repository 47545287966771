import * as React from "react";
import { LinkIframeCheckBox } from "../components/iframe/inputs/IframeCheckBox";

export const addSettingValueToText = (
  text: string,
  onOpen: (val: string) => void
) => {
  return text.split(/\${(.*)}/g).map((value, index) => {
    const valueSlit = value.split(":");
    if (valueSlit.length !== 3) {
      return <React.Fragment key={value}>{value}</React.Fragment>;
    }
    if (valueSlit[0] !== "privacyPolicy") {
      return <React.Fragment key={value}>{value}</React.Fragment>;
    }
    return (
      <LinkIframeCheckBox
        key={value}
        component={"span"}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          e.stopPropagation();
          onOpen(valueSlit[1]);
        }}
      >
        {valueSlit[2]}
      </LinkIframeCheckBox>
    );
  });
};
