import { combineReducers } from "redux";
import { createReducerArray } from "../../../../redux/tools/createReducer/createReducerArray";
import { TranslateItem } from "../../type";
import {
  REDUX_TRANSLATE_CURRENT_LANGUAGE_INDEX,
  REDUX_TRANSLATE_LANGUAGES,
  REDUX_TRANSLATE_TRANSLATES,
} from "../../contants";
import { createReducerNumber } from "../../../../redux/tools/createReducer/createReducerNumber";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";

export const translatesReducer = combineReducers({
  translates: createReducerObject<Record<string, TranslateItem>>(
    REDUX_TRANSLATE_TRANSLATES,
    {}
  ),
  languages: createReducerArray<string>(REDUX_TRANSLATE_LANGUAGES, []),
  currentLanguageIndex: createReducerNumber(
    REDUX_TRANSLATE_CURRENT_LANGUAGE_INDEX,
    null
  ),
});
