import { CascadeOfferItemFilterExternal } from "./CascadeOfferItemFilterExternal";
import { CascadeOfferItemFilterFormValue } from "./CascadeOfferItemFilterFormValue";
import { CascadeOfferItemFilterRatioBetweenFields } from "./CascadeOfferItemFilterRatioBetweenFields";
import { CascadeOfferItemFilterHistory } from "./CascadeOfferItemFilterHistory";
import { CascadeOfferItemFilterTimeBlackList } from "./CascadeOfferItemFilterTimeBlackList";
import { CascadeOfferItemFilterCapping } from "./CascadeOfferItemFilterCapping";

export const CASCADE_OPERATOR_AND = "AND";
export const CASCADE_OPERATOR_OR = "OR";

export type CascadeOfferItemFilterOperatorType =
  | typeof CASCADE_OPERATOR_AND
  | typeof CASCADE_OPERATOR_OR;

export const CASCADE_FILTER_FILTER_AFFILIATE = "filtersAffiliate";
export const CASCADE_FILTER_EXTERNAL = "external";
export const CASCADE_FILTER_FILTER_VALUE = "formValue";
export const CASCADE_FILTER_RATIO_BETWEEN_FIELDS = "ratio-between-fields";
export const CASCADE_FILTER_HISTORY = "history";
export const CASCADE_FILTER_GROUP = "group";
export const CASCADE_FILTER_TIME_BLACK_LIST = "time-black-list";
export const CASCADE_FILTER_CAPPING = "capping";
export const CASCADE_FILTER_FILTERS_AFFILIATE_WHITE_LIST =
  "filtersAffiliateWhiteList";

export type CascadeOfferFilterType =
  | typeof CASCADE_FILTER_FILTER_AFFILIATE
  | typeof CASCADE_FILTER_FILTER_VALUE
  | typeof CASCADE_FILTER_RATIO_BETWEEN_FIELDS
  | typeof CASCADE_FILTER_HISTORY
  | typeof CASCADE_FILTER_GROUP
  | typeof CASCADE_FILTER_CAPPING
  | typeof CASCADE_FILTER_TIME_BLACK_LIST
  | typeof CASCADE_FILTER_FILTERS_AFFILIATE_WHITE_LIST
  | typeof CASCADE_FILTER_EXTERNAL;

export class CascadeOfferItemFilter {
  id: number | null = null;
  cascadeOfferItemId: number | null = null;
  cappingId: number | null = null;
  operator: CascadeOfferItemFilterOperatorType = CASCADE_OPERATOR_AND;
  parentCascadeOfferItemFilterId: number | null = null;
  filtersAffiliate: number[] = [];
  external: CascadeOfferItemFilterExternal | null = null;
  formValue: CascadeOfferItemFilterFormValue | null = null;
  ratioBetweenFields: CascadeOfferItemFilterRatioBetweenFields | null = null;
  history: CascadeOfferItemFilterHistory | null = null;
  capping: CascadeOfferItemFilterCapping | null = null;
  filtersTimeBlackList: CascadeOfferItemFilterTimeBlackList[] = [];
  type: CascadeOfferFilterType = CASCADE_FILTER_FILTER_VALUE;

  constructor(obj: any | null) {
    obj && Object.assign(this, obj);
    if (obj.external) {
      this.external = new CascadeOfferItemFilterExternal(obj.external);
    }
    if (obj.formValue) {
      this.formValue = new CascadeOfferItemFilterFormValue(obj.formValue);
    }
    if (obj?.filtersTimeBlackList) {
      this.filtersTimeBlackList = obj.filtersTimeBlackList.map(
        (item: any) => new CascadeOfferItemFilterTimeBlackList(item)
      );
    }
    if (obj.ratioBetweenFields) {
      this.ratioBetweenFields = new CascadeOfferItemFilterRatioBetweenFields(
        obj.ratioBetweenFields
      );
    }
    if (obj.history) {
      this.history = new CascadeOfferItemFilterHistory(obj.history);
    }
    if (obj.capping) {
      this.capping = new CascadeOfferItemFilterCapping(obj.capping);
    }
  }

  getOptions(): CascadeOfferFilterType[] {
    return [
      CASCADE_FILTER_GROUP,
      CASCADE_FILTER_FILTER_AFFILIATE,
      CASCADE_FILTER_FILTERS_AFFILIATE_WHITE_LIST,
      CASCADE_FILTER_FILTER_VALUE,
      CASCADE_FILTER_RATIO_BETWEEN_FIELDS,
      CASCADE_FILTER_HISTORY,
      CASCADE_FILTER_EXTERNAL,
      CASCADE_FILTER_TIME_BLACK_LIST,
      CASCADE_FILTER_CAPPING,
    ];
  }

  setValue(value: CascadeOfferFilterType) {
    this.external = null;
    this.formValue = null;
    this.ratioBetweenFields = null;
    this.history = null;
    this.type = value;
    if (value === "external") {
      this.external = new CascadeOfferItemFilterExternal();
    }
    if (value === "formValue") {
      this.formValue = new CascadeOfferItemFilterFormValue();
    }
    if (value === CASCADE_FILTER_RATIO_BETWEEN_FIELDS) {
      this.ratioBetweenFields = new CascadeOfferItemFilterRatioBetweenFields();
    }
    if (value === "history") {
      this.history = new CascadeOfferItemFilterHistory();
    }
    if (value === CASCADE_FILTER_CAPPING) {
      this.capping = new CascadeOfferItemFilterCapping();
    }
  }

  getValue() {
    return this.type;
  }

  toJson() {
    const {
      cascadeOfferItemId,
      operator,
      parentCascadeOfferItemFilterId,
      filtersAffiliate,
      external,
      formValue,
      ratioBetweenFields,
      capping,
      type,
      history,
    } = this;

    return {
      cascadeOfferItemId,
      operator,
      type,
      parentCascadeOfferItemFilterId,
      filtersAffiliate: filtersAffiliate,
      external: external?.toJson() || null,
      capping: capping?.toJson() || null,
      formValue: formValue?.toJson() || null,
      ratioBetweenFields: ratioBetweenFields?.toJson() || null,
      history: history?.toJson() || null,
    };
  }
}
