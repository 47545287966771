import { combineReducers } from "redux";
import { createReducerArray } from "../../../../redux/tools/createReducer/createReducerArray";
import {
  REDUX_FORM_FIELDS,
  REDUX_FORM_FORMS,
  REDUX_FORM_LEAD_HANDLER,
} from "../../constants/form";
import { FormField } from "../../../formsIframe/type/FormField";
import { Form } from "../../../formsIframe/type/Form";

//TODO delete and use form reducer in form module
export const formReducer = combineReducers({
  fields: createReducerArray<FormField>(REDUX_FORM_FIELDS, []),
  forms: createReducerArray<Form>(REDUX_FORM_FORMS, []),
  leadHandlers: createReducerArray<string>(REDUX_FORM_LEAD_HANDLER, []),
});
