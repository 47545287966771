import * as React from "react";
import { withTheme } from "styled-components/macro";
import { Power } from "react-feather";
import LanguagesDropdown from "./LanguagesDropdown";
import { actionLogout } from "../../../../modules/auth/store/action/logout.action";
import { useDispatch } from "react-redux";
import { BaseIconButton } from "../../iconButtons";
import { Flex } from "../../layout/Flex";

type AppBarProps = {
  theme: {};
};

const AppBarComponent: React.FC<AppBarProps> = () => {
  const dispatch = useDispatch();
  return (
    <Flex marginLeft={"auto"} marginTop={"auto"} marginBottom={"auto"}>
      <LanguagesDropdown />
      <BaseIconButton
        onClick={() => dispatch(actionLogout())}
        Icon={Power}
        color={"inherit"}
        flexProps={{
          marginTop: "auto",
          marginBottom: "auto",
          marginLeft: "small",
          marginRight: "large",
        }}
        tooltipTranslateId={"core.logout"}
      />
    </Flex>
  );
};

export default withTheme(AppBarComponent);
