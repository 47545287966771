import { VsiBase } from "./VsiBase";
import * as yup from "yup";
import BaseSchema from "yup/lib/schema";
import { createVsiInstance } from "../../store/action/getValidation.action";

const searchArray = /\[[0-9]+]/;

export const formikValueAccessor = (name: string, values: any) => {
  const keys = name.split(".");
  return keys.reduce((all, key) => {
    if (key.search(searchArray) !== -1) {
      const keyOnly = key.substr(0, key.search(searchArray));
      const numberOnly = key
        .substr(key.search(searchArray))
        .replace("[", "")
        .replace("]", "");
      return all?.[keyOnly]?.[Number.parseInt(numberOnly)];
    } else {
      return all?.[key];
    }
  }, values);
};

export const formikVsiAccessor = (name: string, vsi: any) => {
  const keys = name.split(".");
  return keys.reduce((all, key) => {
    const keyToUse = key.replace(searchArray, "");
    return all?.[keyToUse]?.vsi || all?.[keyToUse]?.scheme || all?.[keyToUse];
  }, vsi);
};

export class VsiConnectedRef extends VsiBase {
  vsi: Record<string, VsiBase> = {};

  constructor(obj: any) {
    super();
    obj && Object.assign(this, obj);
    if (obj.vsi) {
      const keys = Object.keys(obj.vsi);
      this.vsi = keys.reduce((all, key) => {
        return {
          ...all,
          [key]: createVsiInstance(obj.vsi[key]),
        };
      }, {});
    }
  }

  toYup(key: string): BaseSchema {
    const keys = Object.keys(this.vsi);
    let ret: BaseSchema = yup.object().shape(
      keys.reduce((all, key) => {
        if (
          // (onlyForInputs && !onlyForInputs.includes(key)) ||
          !this.vsi?.[key]
        ) {
          return all;
        }
        return {
          ...all,
          [key]: this.vsi[key].toYup(key),
        };
      }, {})
    );
    if (this.nullable) {
      ret = ret.notRequired().nullable();
    }
    return ret;
  }
}
