import { ENDPOINT_OFFER_CATEGORY } from "../../../../constants";
import {
  get,
  basicHandleError,
} from "../../../../../../core/api/utils/fetchApi";
import { AppDispatchType } from "../../../../../../redux/store";
import { REDUX_OFFER_CATEGORIES } from "../../../../constants";
import { LockKey } from "../../../../../../core/layouts/utils/useIsLocked";
import {
  actionLock,
  actionUnlock,
} from "../../../../../../core/layouts/store/actions/lockable.actions";
import { actionReducerObjectSet } from "../../../../../../redux/tools/createReducer/createReducerObject";
import { OfferCategory } from "../../../../type/OfferCategory";

export const getLockKeyActionGetOfferCategories = (): LockKey => {
  return "get" + ENDPOINT_OFFER_CATEGORY;
};

export const getOfferCategoriesAction = () => async (
  dispatch: AppDispatchType
) => {
  dispatch(actionLock(getLockKeyActionGetOfferCategories()));
  const res = await get(ENDPOINT_OFFER_CATEGORY);
  dispatch(actionUnlock(getLockKeyActionGetOfferCategories()));
  if (res.ok) {
    const items = res.data.map((item: any) => new OfferCategory(item));
    dispatch(actionReducerObjectSet(REDUX_OFFER_CATEGORIES, items));
  } else {
    dispatch(basicHandleError(res));
  }
};
