export const OFFER_PAYOUT_STATUS_ACTIVE = "active";
export const OFFER_PAYOUT_STATUS_INACTIVE = "inactive";

export const OFFER_PAYOUT_STATUSES = [
  OFFER_PAYOUT_STATUS_INACTIVE,
  OFFER_PAYOUT_STATUS_ACTIVE,
];

export type OfferPayoutStatusType =
  | typeof OFFER_PAYOUT_STATUS_ACTIVE
  | typeof OFFER_PAYOUT_STATUS_INACTIVE;
