import { VsiBase } from "./VsiBase";
import * as yup from "yup";
import { Translate } from "../../../../core/translate/Translate";
import BaseSchema from "yup/lib/schema";

export class VsiBoolean extends VsiBase {
  requiredValue: null | boolean = null;

  constructor(obj: any) {
    super();
    obj && Object.assign(this, obj);
  }

  toYup(key: string): BaseSchema {
    const translatePrefix = "field";
    const basicTranslateParameter = [{ translateId: this.translateId }];
    let ret: BaseSchema = yup
      .boolean()
      .typeError(() => (
        <Translate
          id={"core." + translatePrefix + "IsNotBoolean"}
          params={basicTranslateParameter}
        />
      ));
    if (this.requiredValue !== null) {
      ret = ret.test(
        "must be",
        () => (
          <Translate
            id={
              this.requiredValue
                ? "core.mustBeChecked"
                : "core.mustNotBeChecked"
            }
            params={basicTranslateParameter}
          />
        ),
        (value) => value === this.requiredValue
      );
    }
    if (this.nullable) {
      ret = ret.nullable();
    }
    return ret;
  }
}
