import { Currency } from "../type/Currency";
import {
  AFTER_VALUE_NO_SPACE,
  AFTER_VALUE_SPACE,
  BEFORE_VALUE_NO_SPACE,
  BEFORE_VALUE_SPACE,
} from "../constants";

interface PriceProps {
  currency?: Currency;
  price?: number;
}

export const PriceString = ({ price, currency }: PriceProps): string => {
  if (!currency) {
    return `${price || "-"} ?`;
  }
  return PriceStringLowData({
    price,
    display: currency.display,
    howDisplay: currency.howDisplay,
  });
};

export const PriceStringLowData = ({
  price,
  display,
  howDisplay,
}: {
  price?: number;
  display?: string;
  howDisplay?: string;
}): string => {
  const zeros = (price || 0) % 1 !== 0;
  if (!display || !howDisplay) {
    return `${price || "-"} ?`;
  }

  const priceToShow =
    typeof price === "number"
      ? price.toLocaleString(undefined, {
          minimumFractionDigits: zeros ? 2 : 0,
          maximumFractionDigits: zeros ? 2 : 0,
        })
      : "-";
  switch (howDisplay) {
    case AFTER_VALUE_SPACE:
      return `${priceToShow} ${display}`;
    case BEFORE_VALUE_SPACE:
      return `${display} ${priceToShow}`;
    case AFTER_VALUE_NO_SPACE:
      return `${priceToShow}${display}`;
    case BEFORE_VALUE_NO_SPACE:
      return `${display}${priceToShow}`;
  }
  return `Wrong currency setting!`;
};
