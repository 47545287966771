import { RouteType } from "../../../types/routes";
import {
  getUrlInvoiceList,
  getUrlInvoicesAdminView,
} from "../utils/getUrlInvoiceList";
import { Invoices, InvoicesAdminView } from "../container";
import { useInitOfferList } from "../utils/init/useInitOfferList";

export const invoiceRoutes: RouteType = {
  id: "Invoice",
  path: "/offer",
  children: [
    {
      useInit: useInitOfferList,
      path: getUrlInvoiceList(),
      component: Invoices,
    },
    {
      useInit: useInitOfferList,
      path: getUrlInvoicesAdminView(),
      component: InvoicesAdminView,
    },
  ],
  component: null,
};
