import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { BaseInitHook } from "../../../../core/utils/baseInitHook";

export const useInitOfferList: BaseInitHook = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getOfferFilterOptions());
  }, [dispatch]);
};
