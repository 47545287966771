import React, { FC } from "react";
import { Flex } from "../../../../core/components/layout/Flex";
import {
  FormsIframePageProps,
  IframeRoot,
} from "../../container/FormsIframeContainer";
import IframeHeader from "../iframe/header/IframeHeader";
import { Helmet } from "react-helmet";
import { useHistoryLocation } from "../../../../core/history";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/store";
import { IframeTitle, StrongPrimary } from "../../container/IframeLoading";
import { Translate } from "../../../../core/translate/Translate";
import { IframeHeaderLogos } from "../iframe/header/IframeHeaderLogos";

const ContentContainer = styled.div`
  padding: 0 90px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    padding: 0 10px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
`;

export const IframeAlternativeOffer: FC<FormsIframePageProps> = ({
  formData,
}) => {
  const location = useHistoryLocation<{ hideBg: "true" | null }>();
  const formValues = useSelector(
    (state: AppStateType) => state.iframe.formValues
  );
  const oldOfferName = useSelector(
    (state: AppStateType) => state.iframe.alternativeOffer.oldOfferName
  );
  const currentSearchIn = useSelector(
    (state: AppStateType) => state.iframe.loader.currentSearchIn
  );
  // const dispatch = useDispatch()
  // const accessToken = useSelector(
  //   (state: AppStateType) => state.iframe.loader.accessToken
  // );
  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle="">
        <title itemProp="name">{formData.getMetaTitle() || ""}</title>
        <meta
          name="description"
          content={formData.getMetaDescription() || ""}
        />
      </Helmet>
      <IframeRoot
        $formData={formData}
        $noMarginBottom={true}
        $hideBg={location?.search?.hideBg === "true"}
      >
        <Flex fullWidth={true} flexDirection={"column"} alignItems={"center"}>
          <IframeHeaderLogos formData={formData} />
          <ContentContainer>
            <IframeTitle variant={"h3"} align={"center"}>
              <StrongPrimary>{oldOfferName || currentSearchIn}</StrongPrimary>
              &nbsp;
              <Translate id={"iframe.alternativeOfferLoan"} />
            </IframeTitle>
            {/*<IframeSubtitle variant={"subtitle1"} align={"center"}> TODO uncomment only if have alternative*/}
            {/*  <Translate id={"iframe.alternativeOfferLoanText"} />*/}
            {/*</IframeSubtitle>*/}
            <HeaderContainer>
              <IframeHeader
                onlyMoney={true}
                formData={formData}
                currentPage={2}
                formValues={formValues}
              />
            </HeaderContainer>
            {/*<IframeFormButton TODO uncomment only if have alternative*/}
            {/*  onClick={() => {*/}
            {/*    if (accessToken) {*/}
            {/*      dispatch(postAgreeAlternativeOffer(accessToken));*/}
            {/*    }*/}
            {/*  }}*/}
            {/*  underSubmitText={*/}
            {/*    <Translate id={"iframe.findAlternativeOfferText"} />*/}
            {/*  }*/}
            {/*  disabled={!accessToken}*/}
            {/*  text={formData.getGetMoneyText()}*/}
            {/*/>*/}
          </ContentContainer>
        </Flex>
      </IframeRoot>
    </>
  );
};
