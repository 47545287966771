import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { ThemeProvider } from "styled-components/macro";
import { create, Plugin } from "jss";

import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";
import createTheme from "./core/theme";
import Routes from "./core/routes/Routes";
import { AppStateType } from "./redux/store";
import { SnackbarProvider } from "notistack";
import { useInitApp } from "./core/intApp/useInitApp";
import SnackbarShower from "./core/components/layout/snackbar/SnackbarShower";
import { MyMuiPickersUtilsProvider } from "./core/components/dateContext/MyMuiPickersUtilsProvider";
import { EditFileDialog } from "./modules/file/components/EditFileDialog";
import { MyConfirmDialog } from "./core/components/MyConfirmDialog";

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins] as readonly Plugin[],
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

function App() {
  const theme = useSelector((state: AppStateType) => state.themeReducer);
  useInitApp();

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Material App"
        defaultTitle="Material App - React Admin & Dashboard Template"
      />
      <StylesProvider jss={jss}>
        <MyMuiPickersUtilsProvider>
          <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
            <ThemeProvider theme={createTheme(theme.currentTheme)}>
              <SnackbarProvider>
                <SnackbarShower />
                <EditFileDialog />
                <MyConfirmDialog />
                <Routes />
              </SnackbarProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </MyMuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
