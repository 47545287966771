export const addValueFromFormToText = (
  text: string,
  formValues: Record<string, any>
) =>
  text
    ? text.replace(/\${(.*)}/g, (fullTag, tag) => {
        const tagKey = tag.split(":");
        if (tagKey.length !== 2 || tagKey[0] !== "form-value") {
          return fullTag;
        }
        const value = formValues[tagKey[1]];
        return value === undefined ? "-" : value;
      })
    : "";
