export const VALIDATION_TYPE_ARRAY = "ARRAY";
export const VALIDATION_TYPE_BOOLEAN = "BOOLEAN";
export const VALIDATION_TYPE_COLOR = "COLOR";
export const VALIDATION_TYPE_DATE = "DATE";
export const VALIDATION_TYPE_ID = "ID";
export const VALIDATION_TYPE_NUMBER = "NUMBER";
export const VALIDATION_TYPE_ENUM = "ENUM";
export const VALIDATION_TYPE_IDS = "IDS";
export const VALIDATION_TYPE_STRING = "STRING";
export const VALIDATION_TYPE_EMAIL = "EMAIL";
export const VALIDATION_TYPE_CONNECTED_REF = "CONNECTED_REF";

export type ValidationType =
  | typeof VALIDATION_TYPE_ARRAY
  | typeof VALIDATION_TYPE_COLOR
  | typeof VALIDATION_TYPE_ENUM
  | typeof VALIDATION_TYPE_DATE
  | typeof VALIDATION_TYPE_ID
  | typeof VALIDATION_TYPE_EMAIL
  | typeof VALIDATION_TYPE_NUMBER
  | typeof VALIDATION_TYPE_STRING
  | typeof VALIDATION_TYPE_CONNECTED_REF
  | typeof VALIDATION_TYPE_IDS
  | typeof VALIDATION_TYPE_BOOLEAN;
