/** @var string user opened form or click link bud did not submit */
export const STATUS_VISITOR = "visitor";
/** @var string user not submitted but have some data */
export const STATUS_STARTED = "started";
/** @var string user submit form */
export const STATUS_LEAD = "lead";
/** @var string sending lead to partners or is in cascade */
export const STATUS_LEAD_PROCESSING = "lead-processing";
/** @var string lead was sold */
export const STATUS_LEAD_SOLD = "lead-sold";
/** @var string lead has bought some product */
export const STATUS_SALE = "sale";
/** @var string lead has bought product another product  */
export const STATUS_SALE_REPEATED = "sale-repeated";
/** @var string lead did not get any product */
export const STATUS_DECLINED = "declined";
/** @var string fail to process lead */
export const STATUS_ERROR = "error";

export const LEAD_STATUSES = [
  STATUS_VISITOR,
  STATUS_STARTED,
  STATUS_LEAD,
  STATUS_LEAD_PROCESSING,
  STATUS_LEAD_SOLD,
  STATUS_SALE,
  STATUS_SALE_REPEATED,
  STATUS_DECLINED,
  STATUS_ERROR,
];

export type LeadStatusType =
  | typeof STATUS_ERROR
  | typeof STATUS_DECLINED
  | typeof STATUS_SALE_REPEATED
  | typeof STATUS_SALE
  | typeof STATUS_LEAD_SOLD
  | typeof STATUS_LEAD_PROCESSING
  | typeof STATUS_LEAD
  | typeof STATUS_STARTED
  | typeof STATUS_VISITOR;
