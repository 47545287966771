// Auth components
import React from "react";

export const Forms = React.lazy(
  () => /* webpackChunkName: 'Forms'*/ import("./Forms")
);

export const FormDetail = React.lazy(
  () => /* webpackChunkName: 'FormDetail'*/ import("./FormDetail")
);
