import { VsiBase } from "./VsiBase";
import * as yup from "yup";
import { Translate } from "../../../../core/translate/Translate";
import BaseSchema from "yup/lib/schema";

export class VsiEmail extends VsiBase {
  contains: string[] = [];
  defaultValue: string | null = null;
  minLength: number | null = null;
  maxLength: number | null = null;
  regexMatch: string | null = null;

  constructor(obj: any) {
    super();
    obj && Object.assign(this, obj);
  }

  toYup(key: string): BaseSchema {
    const translatePrefix = "field";
    const basicTranslateParameter = [{ translateId: this.translateId }];

    let basic = yup
      .string()
      .notRequired()
      .transform((value) => (!value ? null : value))
      .email(() => (
        <Translate
          id={"core." + translatePrefix + "IsNotEmail"}
          params={basicTranslateParameter}
        />
      ))
      .default(this.defaultValue || "")
      .typeError(() => (
        <Translate
          id={"core." + translatePrefix + "IsNotEmail"}
          params={basicTranslateParameter}
        />
      ));
    if (this.nullable) {
      basic = basic.nullable() as any;
    }
    if (this.minLength !== null) {
      basic = basic.min(this.minLength, () => {
        return (
          <Translate
            id={"core." + translatePrefix + "CanNotBeShorter"}
            params={[{ translateId: this.translateId }, String(this.minLength)]}
          />
        );
      }) as any;
    }
    if (this.maxLength !== null) {
      basic = basic.max(this.maxLength, () => {
        return (
          <Translate
            id={"core." + translatePrefix + "CanNotBeLonger"}
            params={[{ translateId: this.translateId }, String(this.maxLength)]}
          />
        );
      }) as any;
    }
    return yup.lazy((value) => (!value ? basic : basic.email())) as any;
  }
}
