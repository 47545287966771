const getSubIdFromUrl = () => {
  const url = new URLSearchParams(window.location.search);
  return url.get("subId");
};

export const getSubIdToRequest = () => {
  const subIdUrl = getSubIdFromUrl();
  if (subIdUrl) {
    return "&subId=" + subIdUrl;
  }
  return "";
};
