import { AppDispatchType } from "../../../../redux/store";
import { post } from "../../../../core/api/utils/fetchApi";
import { ENDPOINT_RESEND_SMS } from "../../../form/constants";
import { actionSnackbarShow } from "../../../../core/layouts/store/actions/snackbar.actions";

export const actionResendSms = (
  phoneNumber: string,
  accessToken: string
) => async (dispatch: AppDispatchType) => {
  if (!phoneNumber) {
    dispatch(
      actionSnackbarShow({
        translateId: "iframe.smsWasResendFail",
        variant: "error",
      })
    );
    return;
  }
  const res = await post(ENDPOINT_RESEND_SMS + "/" + accessToken, {
    phoneNumber,
  });
  if (res.ok) {
    dispatch(
      actionSnackbarShow({
        translateId: "iframe.smsWasResend",
        variant: "success",
      })
    );
  } else {
    dispatch(
      actionSnackbarShow({
        translateId: "iframe.smsWasResendFail",
        variant: "error",
      })
    );
  }
};
