import { combineReducers } from "redux";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";
import { LeadPayout } from "../../../postbacks/type/LeadPayout";
import {
  REDUX_PAYOUT_MANAGER_EDIT_PAYOUT,
  REDUX_PAYOUT_MANAGER_OPEN_DIALOG,
  REDUX_PAYOUT_MANAGER_PAYOUTS,
} from "../../constants";
import { createReducerDialog } from "../../../../redux/tools/createReducer/createReducerDialog";
import { createReducerApiArray } from "../../../../redux/tools/createReducer/createReducerApiArray";

export const payoutManager = combineReducers({
  payouts: createReducerApiArray<LeadPayout>(REDUX_PAYOUT_MANAGER_PAYOUTS),

  payoutToEdit: createReducerObject<LeadPayout | null>(
    REDUX_PAYOUT_MANAGER_EDIT_PAYOUT,
    null
  ),
  openCreateDialog: createReducerDialog<LeadPayout>(
    REDUX_PAYOUT_MANAGER_OPEN_DIALOG
  ),
});
