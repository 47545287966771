import { RouteType } from "../../../types/routes";
import { ReportLeadHandlers, ReportLeads, ReportsList } from "../container";
import {
  getUrlReportLeadHandler,
  getUrlReportLeads,
  getUrlReports,
} from "../utils/getUrlPostBack";

export const reportRoutes: RouteType = {
  id: "test-report",
  path: "/test-report",
  children: [
    {
      path: getUrlReportLeads(),
      component: ReportLeads,
    },
    {
      path: getUrlReportLeadHandler(),
      component: ReportLeadHandlers,
    },
    {
      path: getUrlReports(),
      component: ReportsList,
    },
  ],
  component: null,
};
