import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { BaseInitHook } from "../../../../core/utils/baseInitHook";
import { useParams } from "react-router";
import { getOfferAction } from "../../store/action/offer/getOffer.action";
import { getCurrency } from "../../../currency/store/actions/getCurrency.action";
import { getOfferCategoriesAction } from "../../store/action/offer/category/getOfferCategories.action";
import { getOfferCountriesAction } from "../../store/action/offer/country/getOfferCountries.action";
import { getOfferProductsAction } from "../../store/action/offer/product/getOfferProducts.action";
import { getFormsAction } from "../../store/action/form/getForms.action";
import { getFormLeadHandlersAction } from "../../store/action/form/getFormLeadHandlers.action";
import { getOffersAction } from "../../store/action/offer/getOffers.action";

export interface OfferDetailParams {
  id: string;
}

/**
 * Init offer detail and edit page
 */
export const getUseInitOfferDetail = (detail: boolean): BaseInitHook => {
  return () => {
    const dispatch = useDispatch();
    const { id } = useParams<OfferDetailParams>();
    useEffect(() => {
      if (!detail) {
        dispatch(getFormsAction());
        dispatch(getFormLeadHandlersAction());
      }
      dispatch(getOffersAction(1, undefined, undefined, true));
      dispatch(getOfferAction(id));
      dispatch(getCurrency());
      dispatch(getOfferCategoriesAction());
      dispatch(getOfferCountriesAction());
      dispatch(getOfferProductsAction());
    }, [dispatch, id]);
  };
};
