import { PaletteOptions } from "@material-ui/core/styles/createPalette";

const overrides = (palette: PaletteOptions) => ({
  MuiCard: {
    root: {
      borderRadius: "6px",
      boxShadow:
        "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
    },
  },
  MuiCardHeader: {
    action: {
      marginTop: "-4px",
      marginRight: "-4px",
    },
  },
  MuiPickersDay: {
    day: {
      fontWeight: "400",
    },
  },
  MuiPickersYear: {
    root: {
      height: "64px",
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
  },
  MuiChip: {
    root: {
      borderRadius: "6px",
    },
  },
  //theme overrides
  MuiFormControlLabel: {
    label: {
      fontSize: "20px",
    },
  },
  MuiButton: {
    root: {
      fontSize: "27px",
      padding: "12px 26px",
    },
  },
  MuiTypography: {
    body1: {
      fontSize: "14px",
    },
  },
  PrivateNotchedOutline: {
    root: {
      borderWidth: 2,
    },
  },
  MuiInputLabel: {
    formControl: {
      fontSize: "21px",
      marginTop: "0px !important",
    },
    outlined: {
      background: palette.background?.paper,
      padding: "0 7px 0 0",
      margin: "-2px 0 0 0",
    },
  },
  MuiInputBase: {
    input: {
      height: "unset",
      lineHeight: 1,
      fontSize: "24px",
    },
  },
  MuiCheckbox: {
    root: {
      color: "#d1d1d1",
    },
  },
});

export default overrides;
