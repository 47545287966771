import { FC, useCallback, useEffect, useRef } from "react";
import useUnload from "../../../core/utils/useUnload";
import { useDispatch } from "react-redux";
import { actionHandleWebsocketMessage } from "../store/action/handleWebsocketMessage.action";

interface IframeWebsocketControllerProps {
  url: string;
  accessToken: string;
}

export const IframeWebsocketController: FC<IframeWebsocketControllerProps> = ({
  accessToken,
  url,
}) => {
  const dispatch = useDispatch();
  const connectionRef = useRef<WebSocket | null>(null);
  const shouldReconnect = (): boolean => {
    return !(
      !connectionRef.current ||
      connectionRef.current?.readyState === WebSocket.CLOSED
    );
  };
  const isConnected = (): boolean => {
    return !!(
      connectionRef.current &&
      connectionRef.current.readyState === WebSocket.OPEN
    );
  };

  const close = useCallback(() => {}, []);
  const send = (
    type: string,
    message: string | number | Record<string, any>
  ) => {
    if (!isConnected()) {
      console.log("notConnected", connectionRef.current?.readyState);
      return;
    }
    console.log("sending");
    connectionRef.current?.send(
      JSON.stringify({
        type,
        message,
      })
    );
  };
  useUnload(close);
  const connect = () => {
    if (shouldReconnect()) {
      return;
    }

    try {
      connectionRef.current = new WebSocket(url);
    } catch (e) {
      return;
    }

    connectionRef.current.onopen = () => {
      send("CONNECT", { accessToken });
    };

    connectionRef.current.onerror = (error) => {
      console.error("connection.onerror", error);
    };

    connectionRef.current.onmessage = (e) => {
      console.log(`Server: ${e.data}`);
      dispatch(actionHandleWebsocketMessage(e));
    };

    connectionRef.current.onclose = (event) => {
      console.log("WebSocket closed.");
      console.log(event);
      connect();
    };
  };
  useEffect(() => {
    //did mount
    connect();

    const interval = setInterval(() => {
      //try reconnect if needed
      connect();
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);
  return null;
};
