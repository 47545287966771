import React, { FC, useEffect, useState } from "react";
import {
  IFRAME_TIMER_LAST_START,
  REDUX_IFRAME_FORM_TIMER,
} from "../../../constant";
import styled from "styled-components";
import TimerIcon from "@material-ui/icons/Timer";
import UserOfferConfig from "../../../type/UserOfferConfig";
import { useDispatch } from "react-redux";
import { actionReducerNumberSet } from "../../../../../redux/tools/createReducer/createReducerNumber";

interface Props {
  formData: UserOfferConfig;
}

const getDefaultTimer = (fullTime: number | null) => {
  if (!fullTime) {
    return null;
  }
  const lastStart = localStorage.getItem(IFRAME_TIMER_LAST_START);
  if (!lastStart) {
    return fullTime;
  }
  const lastDate = Date.parse(lastStart);
  if (!lastDate || Number.isNaN(lastDate)) {
    return fullTime;
  }
  const timerIsRunningFor = new Date().getTime() / 1000 - fullTime;
  const enableTimeToRun = fullTime - timerIsRunningFor;
  if (enableTimeToRun > 0) {
    return enableTimeToRun;
  }
  return null;
};

const Timer = styled.div`
  padding: ${(props) => props.theme.spacing(2, 3)};
  border-radius: ${(props) => props.theme.spacing(1.5)}px;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 16px;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    max-width: calc(50% - 20px);
    margin: 20px auto auto auto;
    height: unset;
    padding: ${(props) => props.theme.spacing(1, 2)};
  }
`;

const StyledTimerIcon = styled(TimerIcon)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  width: 38px;
  height: 38px;
  ${({ theme }) => theme.breakpoints.down("xs")} {
    height: 30px;
    width: 30px;
  }
`;

const TimerText = styled.div`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  font-weight: 200;
  font-size: 48px;
  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 30px;
    margin-right: 0;
    width: 75px;
  }
`;

export const IframeTimer: FC = ({ children }) => {
  return (
    <Timer>
      <StyledTimerIcon />
      <TimerText>{children}</TimerText>
    </Timer>
  );
};

const IframeTimerMainForm: FC<Props> = ({ formData }) => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(
    getDefaultTimer(formData.getShowTimerOnSeconds())
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((timer) => {
        if (timer === null) {
          return null;
        }
        dispatch(actionReducerNumberSet(REDUX_IFRAME_FORM_TIMER, timer));
        if (timer <= 0) {
          return 0;
        }
        return timer - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const seconds = timer ? timer % 60 : 0;
  return (
    <IframeTimer>
      {!timer
        ? "0:00"
        : `${(timer - (timer % 60)) / 60}:${
            seconds < 10 ? "0" + seconds : seconds
          }`}
    </IframeTimer>
  );
};

export default React.memo(IframeTimerMainForm);
