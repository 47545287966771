import { RequestConnectionPropertyModify } from "./RequestConnectionPropertyModify";

export class RequestConnectionProperty {
  public static TYPE_FROM_OBJECT = "object";
  public static TYPE_FROM_CONSTANT = "constant";
  public static TYPES = [
    RequestConnectionProperty.TYPE_FROM_CONSTANT,
    RequestConnectionProperty.TYPE_FROM_OBJECT,
  ];

  accessor: string = "";
  value: string = "";
  type: string = "";
  useAsAccessor: string = "";
  systemName: null | string = null;
  id: null | number = null;

  requestConfigId: null | number = null;
  responseConnectionPropertyId: null | number = null;
  requestConnectionPropertyModify: RequestConnectionPropertyModify[] = [];

  constructor(obj?: any) {
    obj && Object.assign(this, obj);
    if (Array.isArray(obj?.requestConnectionPropertyModify)) {
      this.requestConnectionPropertyModify = obj.requestConnectionPropertyModify.map(
        (item: any) => new RequestConnectionPropertyModify(item)
      );
    }
  }

  toJson() {
    const {
      accessor,
      type,
      value,
      id,
      useAsAccessor,
      requestConnectionPropertyModify,
    } = this;
    return {
      accessor,
      type,
      value,
      id,
      useAsAccessor,
      requestConnectionPropertyModify: requestConnectionPropertyModify.map(
        (item) => item.toJson()
      ),
    };
  }

  toTemplateJson() {
    const {
      accessor,
      type,
      value,
      useAsAccessor,
      requestConnectionPropertyModify,
    } = this;
    return {
      accessor,
      type,
      value,
      useAsAccessor,
      requestConnectionPropertyModify: requestConnectionPropertyModify.map(
        (item) => item.toTemplateJson()
      ),
    };
  }
}
