import {
  ENDPOINT_VALIDATION,
  REDUX_VALIDATION_VALIDATIONS,
} from "../../constants";
import { get, basicHandleError } from "../../../../core/api/utils/fetchApi";
import { AppDispatchType } from "../../../../redux/store";
import {
  actionLock,
  actionUnlock,
} from "../../../../core/layouts/store/actions/lockable.actions";
import { LockKey } from "../../../../core/layouts/utils/useIsLocked";
import { actionReducerArraySet } from "../../../../redux/tools/createReducer/createReducerArray";
import {
  VALIDATION_TYPE_ARRAY,
  VALIDATION_TYPE_BOOLEAN,
  VALIDATION_TYPE_COLOR,
  VALIDATION_TYPE_CONNECTED_REF,
  VALIDATION_TYPE_DATE,
  VALIDATION_TYPE_EMAIL,
  VALIDATION_TYPE_ENUM,
  VALIDATION_TYPE_ID,
  VALIDATION_TYPE_IDS,
  VALIDATION_TYPE_NUMBER,
  VALIDATION_TYPE_STRING,
} from "../../type/ValidationType";
import { VsiDate } from "../../type/elemetnts/VsiDate";
import { VsiArray } from "../../type/elemetnts/VsiArray";
import { VsiColor } from "../../type/elemetnts/VsiColor";
import { VsiId } from "../../type/elemetnts/VsiId";
import { VsiNumber } from "../../type/elemetnts/VsiNumber";
import { VsiString } from "../../type/elemetnts/VsiString";
import { VsiBoolean } from "../../type/elemetnts/VsiBoolean";
import { VsiEnum } from "../../type/elemetnts/VsiEnum";
import { VsiConnectedRef } from "../../type/elemetnts/VsiConnectedRef";
import { VsiEmail } from "../../type/elemetnts/VsiEmail";
import { VsiIds } from "../../type/elemetnts/VsiIds";

export const getLockKeyActionGetValidation = (): LockKey => {
  return "gets" + ENDPOINT_VALIDATION;
};

export const createVsiInstance = (item: any) => {
  switch (item.type) {
    case VALIDATION_TYPE_DATE:
      return new VsiDate(item);
    case VALIDATION_TYPE_ARRAY:
      return new VsiArray(item);
    case VALIDATION_TYPE_COLOR:
      return new VsiColor(item);
    case VALIDATION_TYPE_ID:
      return new VsiId(item);
    case VALIDATION_TYPE_NUMBER:
      return new VsiNumber(item);
    case VALIDATION_TYPE_STRING:
      return new VsiString(item);
    case VALIDATION_TYPE_EMAIL:
      return new VsiEmail(item);
    case VALIDATION_TYPE_BOOLEAN:
      return new VsiBoolean(item);
    case VALIDATION_TYPE_ENUM:
      return new VsiEnum(item);
    case VALIDATION_TYPE_IDS:
      return new VsiIds(item);
    case VALIDATION_TYPE_CONNECTED_REF:
      return new VsiConnectedRef(item);
    default:
      return undefined;
  }
};

export const getValidationAction = () => async (dispatch: AppDispatchType) => {
  dispatch(actionLock(getLockKeyActionGetValidation()));
  const res = await get(ENDPOINT_VALIDATION);
  dispatch(actionUnlock(getLockKeyActionGetValidation()));
  if (res.ok) {
    const keys = Object.keys(res.data);
    const validation = keys.reduce((all, key: string) => {
      const subKeys = Object.keys(res.data[key]);
      return {
        ...all,
        [key]: subKeys.reduce(
          (allSubKey, subKey) => ({
            ...allSubKey,
            [subKey]: createVsiInstance(res.data[key][subKey]),
          }),
          {}
        ),
      };
    }, {});
    dispatch(actionReducerArraySet(REDUX_VALIDATION_VALIDATIONS, validation));
  } else {
    dispatch(basicHandleError(res));
  }
};
