import styled from "styled-components/macro";
import { Grid } from "@material-ui/core";
import { SystemMarginType } from "../theme/Spacing";

interface MyGridProps {
  $marginTop?: SystemMarginType;
  $marginBottom?: SystemMarginType;
  $marginLeft?: SystemMarginType;
  $marginRight?: SystemMarginType;
}

export const MyGrid = styled(Grid)<MyGridProps>`
  ${({ $marginTop, theme }) =>
    $marginTop && `margin-top: ${theme.margin($marginTop)};`}
  ${({ $marginBottom, theme }) =>
    $marginBottom && `margin-bottom: ${theme.margin($marginBottom)};`}
  ${({ $marginLeft, theme }) =>
    $marginLeft && `margin-left: ${theme.margin($marginLeft)};`}
  ${({ $marginRight, theme }) =>
    $marginRight && `margin-right: ${theme.margin($marginRight)};`}

  ${({ container, theme }) =>
    container && `max-width: unset;flex-basis: unset;`}
`;
