export const OFFER_TYPE_USER_CASCADE = "user-cascade";
export const OFFER_TYPE_ADVERTISER_CASCADE = "advertiser-cascade";
export const OFFER_TYPE_USER_CASCADE_EXCLUSIVE = "user-cascade-exclusive";
export const OFFER_TYPE_USER_CASCADE_MIX = "user-cascade-mix";
export const OFFER_TYPE_ADVERTISER_CASCADE_EXCLUSIVE =
  "advertiser-cascade-exclusive";

export const OFFER_CASCADE_TYPES = [
  OFFER_TYPE_USER_CASCADE,
  OFFER_TYPE_USER_CASCADE_EXCLUSIVE,
  OFFER_TYPE_USER_CASCADE_MIX,
];

export type OfferType =
  | typeof OFFER_TYPE_ADVERTISER_CASCADE_EXCLUSIVE
  | typeof OFFER_TYPE_USER_CASCADE
  | typeof OFFER_TYPE_ADVERTISER_CASCADE
  | typeof OFFER_TYPE_USER_CASCADE_MIX
  | typeof OFFER_TYPE_USER_CASCADE_EXCLUSIVE;
