import { IconButton, Link, PropTypes, Tooltip } from "@material-ui/core";
import { Translate } from "../../translate/Translate";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import Replay from "@material-ui/icons/Replay";
import React, { FC, MouseEventHandler } from "react";
import {
  AttachFile,
  Code,
  Link as LinkIcon,
  Colorize,
  Contacts,
  Delete,
  DeleteForever,
  FileCopy,
  Forward,
  InsertEmoticon,
  Save as SaveIcon,
  Settings,
  Visibility,
  VpnKey,
  Add, Description
} from "@material-ui/icons";
import { Flex, FlexProps } from "../layout/Flex";
import { useIsLocked } from "../../layouts/utils/useIsLocked";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { NavLink } from "react-router-dom";

interface IconButtonProps extends FlexProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  linkUrl?: string;
  isLockedKey?: string;
  disabled?: boolean;
  color?: PropTypes.Color;
}

export const IconButtonClose: FC<IconButtonProps> = ({
  onClick,
  disabled,
  isLockedKey,
  color,
  linkUrl,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      color={color}
      linkUrl={linkUrl}
      tooltipTranslateId={"core.close"}
      Icon={CloseIcon}
    />
  );
};

export const IconButtonEdit: FC<IconButtonProps> = ({
  onClick,
  disabled,
  color,
  linkUrl,
  isLockedKey,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      color={color}
      linkUrl={linkUrl}
      tooltipTranslateId={"core.edit"}
      Icon={EditIcon}
    />
  );
};

export const IconButtonSave: FC<IconButtonProps> = ({
  onClick,
  disabled,
  color,
  linkUrl,
  isLockedKey,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      color={color}
      linkUrl={linkUrl}
      tooltipTranslateId={"core.save"}
      Icon={SaveIcon}
    />
  );
};

export const IconButtonContact: FC<IconButtonProps> = ({
  onClick,
  disabled,
  color,
  linkUrl,
  isLockedKey,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      color={color}
      linkUrl={linkUrl}
      tooltipTranslateId={"core.contact"}
      Icon={Contacts}
    />
  );
};

export const IconButtonLink: FC<IconButtonProps> = ({
  onClick,
  disabled,
  color,
  linkUrl,
  isLockedKey,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      color={color}
      linkUrl={linkUrl}
      tooltipTranslateId={"core.link"}
      Icon={LinkIcon}
    />
  );
};

export const IconButtonConnect: FC<IconButtonProps> = ({
  onClick,
  disabled,
  color,
  linkUrl,
  isLockedKey,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      color={color}
      linkUrl={linkUrl}
      tooltipTranslateId={"core.connect"}
      Icon={Code}
    />
  );
};

export const IconApiToken: FC<IconButtonProps> = ({
  onClick,
  disabled,
  color,
  linkUrl,
  isLockedKey,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      color={color}
      linkUrl={linkUrl}
      tooltipTranslateId={"core.apiToken"}
      Icon={VpnKey}
    />
  );
};

export const IconButtonDeleteForever: FC<IconButtonProps> = ({
  onClick,
  disabled,
  color,
  linkUrl,
  isLockedKey,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      isLockedKey={isLockedKey}
      color={color}
      linkUrl={linkUrl}
      flexProps={flexProps}
      tooltipTranslateId={"core.delete"}
      Icon={DeleteForever}
    />
  );
};

export const IconButtonDelete: FC<IconButtonProps> = ({
  onClick,
  disabled,
  isLockedKey,
  color,
  linkUrl,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      color={color}
      linkUrl={linkUrl}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      tooltipTranslateId={"core.delete"}
      Icon={Delete}
    />
  );
};

export const IconButtonPickColor: FC<IconButtonProps> = ({
  onClick,
  disabled,
  isLockedKey,
  color,
  linkUrl,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      color={color}
      linkUrl={linkUrl}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      tooltipTranslateId={"core.pickColor"}
      Icon={Colorize}
    />
  );
};

export const IconButtonDownload: FC<IconButtonProps> = ({
  onClick,
  disabled,
  isLockedKey,
  color,
  linkUrl,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      isLockedKey={isLockedKey}
      color={color}
      linkUrl={linkUrl}
      flexProps={flexProps}
      tooltipTranslateId={"core.download"}
      Icon={CloudDownloadIcon}
    />
  );
};

export const IconPreviewDownload: FC<IconButtonProps> = ({
  onClick,
  disabled,
  color,
  linkUrl,
  isLockedKey,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      color={color}
      linkUrl={linkUrl}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      tooltipTranslateId={"core.preview"}
      Icon={Visibility}
    />
  );
};

export const IconButtonRetry: FC<IconButtonProps> = ({
  onClick,
  disabled,
  isLockedKey,
  color,
  linkUrl,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      color={color}
      linkUrl={linkUrl}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      tooltipTranslateId={"core.retry"}
      Icon={Replay}
    />
  );
};

export const IconButtonAdd: FC<IconButtonProps> = ({
  onClick,
  disabled,
  isLockedKey,
  color,
  linkUrl,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      disabled={disabled}
      color={color}
      linkUrl={linkUrl}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      tooltipTranslateId={"core.retry"}
      Icon={Add}
    />
  );
};

export const IconButtonOpenDetail: FC<IconButtonProps> = ({
  onClick,
  disabled,
  isLockedKey,
  color,
  linkUrl,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      color={color}
      linkUrl={linkUrl}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      tooltipTranslateId={"core.openDetail"}
      Icon={Forward}
    />
  );
};

export const IconButtonOpenTemplate: FC<IconButtonProps> = ({
  onClick,
  disabled,
  isLockedKey,
  color,
  linkUrl,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      color={color}
      linkUrl={linkUrl}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      tooltipTranslateId={"core.openTemplate"}
      Icon={Description}
    />
  );
};

export const IconButtonAttachFile: FC<IconButtonProps> = ({
  onClick,
  disabled,
  isLockedKey,
  color,
  linkUrl,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      color={color}
      linkUrl={linkUrl}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      tooltipTranslateId={"core.attachFile"}
      Icon={AttachFile}
    />
  );
};

export const IconButtonConfig: FC<IconButtonProps> = ({
  onClick,
  color,
  disabled,
  isLockedKey,
  linkUrl,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      color={color}
      linkUrl={linkUrl}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      tooltipTranslateId={"core.config"}
      Icon={Settings}
    />
  );
};

export const IconButtonCopy: FC<IconButtonProps> = ({
  onClick,
  disabled,
  isLockedKey,
  color,
  linkUrl,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      color={color}
      linkUrl={linkUrl}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      tooltipTranslateId={"core.copy"}
      Icon={FileCopy}
    />
  );
};

export const IconButtonPickEmoji: FC<IconButtonProps> = ({
  onClick,
  disabled,
  isLockedKey,
  linkUrl,
  color,
  ...flexProps
}) => {
  return (
    <BaseIconButton
      onClick={onClick}
      color={color}
      linkUrl={linkUrl}
      disabled={disabled}
      isLockedKey={isLockedKey}
      flexProps={flexProps}
      tooltipTranslateId={"core.pickEmoji"}
      Icon={InsertEmoticon}
    />
  );
};

interface BaseIconButtonProps {
  Icon: FC;
  flexProps?: FlexProps;
  tooltipTranslateId: string;
  isLockedKey?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  color?: PropTypes.Color;
  className?: string;
  linkUrl?: string;
}

export const BaseIconButton: FC<BaseIconButtonProps> = ({
  flexProps = {},
  onClick,
  Icon,
  isLockedKey,
  tooltipTranslateId,
  disabled,
  color,
  linkUrl,
  className,
}) => {
  const isLocked = useIsLocked(isLockedKey);
  return (
    <Tooltip title={<Translate id={tooltipTranslateId} />}>
      <Flex {...flexProps} className={className}>
        {linkUrl ? (
          <Link component={NavLink} to={linkUrl}>
            <IconButton color={color} disabled={isLocked || disabled}>
              <Icon />
            </IconButton>
          </Link>
        ) : (
          <IconButton
            color={color}
            disabled={isLocked || disabled}
            onClick={onClick}
          >
            <Icon />
          </IconButton>
        )}
      </Flex>
    </Tooltip>
  );
};
