import { LocalizationProvider } from "@material-ui/pickers";
import enUs from "date-fns/locale/en-US";
import React, { FC, useEffect, useState } from "react";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../redux/store";

export const MyMuiPickersUtilsProvider: FC = ({ children }) => {
  const [locale, setLocale] = useState<Locale>(enUs);
  const languages = useSelector(
    (state: AppStateType) => state.translate.languages
  );
  const currentLanguageIndex =
    useSelector(
      (state: AppStateType) => state.translate.currentLanguageIndex
    ) || 0;

  useEffect(() => {
    if (!languages?.[currentLanguageIndex]) {
      return;
    }
    switch (languages[currentLanguageIndex]) {
      case "cs":
        import("date-fns/locale/cs").then((cs: any) => {
          if (cs?.default) {
            setLocale(cs.default);
          }
        });
        break;
    }
  }, [currentLanguageIndex, languages]);

  return (
    <LocalizationProvider locale={locale} dateAdapter={DateFnsAdapter}>
      {children}
    </LocalizationProvider>
  );
};
