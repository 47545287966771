import { combineReducers } from "redux";
import {
  REDUX_IFRAME_FINAL_PAGE_DISTRIBUTION_TYPE,
  REDUX_IFRAME_FINALE_PAGE_LINKS,
  REDUX_IFRAME_FINALE_PAGE_RES_ALTERNATIVE,
  REDUX_IFRAME_FINALE_PAGE_RES_FAIL,
  REDUX_IFRAME_FINALE_PAGE_RES_SUCCESS,
  REDUX_IFRAME_FINALE_PAGE_TYPE,
  REDUX_IFRAME_SMS_VERIFY_CHANGE_PHONE
} from "../../constant";
import { createReducerString } from "../../../../redux/tools/createReducer/createReducerString";
import { Offer } from "../../type/Offer";
import { createReducerArray } from "../../../../redux/tools/createReducer/createReducerArray";
import { CascadeResult, CascadeResultType } from "../../type/CascadeResult";
import { FINAL_PAGE_RESULT_DEFAULT_PAGE } from "../../constant/cascadeResult";
import { FinalePageLink } from "../../../form/type/FinalePageLink";
import { createReducerDialog } from "../../../../redux/tools/createReducer/createReducerDialog";
import {
  CascadeOfferItemDistributionType,
  DISTRIBUTION_TYPE_EXCLUSIVE
} from "../../../offers/type/cascade/CascadeOfferItemDistributionType";

export const finalPageReducer = combineReducers({
  resFail: createReducerArray<CascadeResult>(
    REDUX_IFRAME_FINALE_PAGE_RES_FAIL,
    []
  ),
  resSuccess: createReducerArray<CascadeResult>(
    REDUX_IFRAME_FINALE_PAGE_RES_SUCCESS,
    []
  ),
  resAlternative: createReducerArray<Offer>(
    REDUX_IFRAME_FINALE_PAGE_RES_ALTERNATIVE,
    []
  ),
  type: createReducerString<CascadeResultType>(
    REDUX_IFRAME_FINALE_PAGE_TYPE,
    FINAL_PAGE_RESULT_DEFAULT_PAGE
  ),
  finalPageLinks: createReducerArray<FinalePageLink>(
    REDUX_IFRAME_FINALE_PAGE_LINKS,
    []
  ),

  changeSmsVerify: createReducerDialog<string>(
    REDUX_IFRAME_SMS_VERIFY_CHANGE_PHONE
  ),
  finalPageDistributionType: createReducerString<CascadeOfferItemDistributionType>(
    REDUX_IFRAME_FINAL_PAGE_DISTRIBUTION_TYPE,
    DISTRIBUTION_TYPE_EXCLUSIVE
  ),
});
