import { useSelector } from "react-redux";
import { AppStateType } from "../../../redux/store";
import * as Yup from "yup";
import { useMemo } from "react";

export const useValidation = (
  name: string,
  onlyForInputs: string[] | null = null
) => {
  const validation = useSelector(
    (state: AppStateType) => state.validation.validation
  );
  return {
    validation: validation?.[name],
    yup: useMemo(() => {
      if (!validation?.[name]) {
        return undefined;
      }
      const keys = Object.keys(validation?.[name]);
      return Yup.object().shape(
        keys.reduce((all, key) => {
          if (
            (onlyForInputs && !onlyForInputs.includes(key)) ||
            !validation?.[name]?.[key]
          ) {
            return all;
          }
          return {
            ...all,
            [key]: validation[name][key].toYup(key),
          };
        }, {})
      );
    }, [validation, name, onlyForInputs]),
  };
};
