import React, { FC } from "react";
import { Flex } from "../../../core/components/layout/Flex";
import { FormsIframePageProps, IframeRoot } from "./FormsIframeContainer";
import { useMoneySliderValue } from "../components/iframe/header/IframeHeader";
import { Typography } from "@material-ui/core";
import { IframeDidYouKnow } from "../components/loading/IframeDidYouKnow";
import { Helmet } from "react-helmet";
import { useHistoryLocation } from "../../../core/history";
import { FormPercentageUi } from "../components/iframe/header/FormPercentages";
import { IframeDownArrowSwgStyled } from "../components/iframe/IframeSubmitButton";
import { IframeCircularProgress } from "../components/loading/subComponent/IframeCircularProgress";
import { MoneyLoadingAnimation } from "../components/loading/subComponent/MoneyLoadingAnimation";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../redux/store";
import { Translate } from "../../../core/translate/Translate";
import { Price } from "../../currency/components/Price";
import { IframeLoaderTimer } from "../components/loading/subComponent/IframeLoaderTimer";
import { Search } from "@material-ui/icons";
import { IframeHeaderLogos } from "../components/iframe/header/IframeHeaderLogos";

export const IframeTitle = styled(Typography)`
  font-weight: 400 !important;
  margin-top: 0px;
`;

const ContentContainer = styled.div`
  padding: 0 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    padding: 0 10px;
  }
`;

export const IframeSubtitle = styled(Typography)`
  margin-top: 18px;
  margin-bottom: 0px;
`;

export const StrongPrimary = styled.strong`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const SearchIconRight = styled(Search)`
  margin-left: 16px;
  transform: rotateY(180deg);
  font-size: 28px;
  margin-bottom: -5px;
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    margin-left: 5px;
  }
`;

export const SearchIconLeft = styled(Search)`
  margin-right: 16px;
  font-size: 28px;
  margin-bottom: -5px;
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    margin-right: 5px;
  }
`;

export const IframeLoading: FC<FormsIframePageProps> = ({ formData }) => {
  const location = useHistoryLocation<{ hideBg: "true" | null }>();
  const { moneyCzSlider } = useMoneySliderValue({
    formData,
  });
  const countToSearch = useSelector(
    (state: AppStateType) => state.iframe.loader.countToSearch
  );
  const currentSearchIn = useSelector(
    (state: AppStateType) => state.iframe.loader.currentSearchIn
  );
  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle="">
        <title itemProp="name">{formData.getMetaTitle() || ""}</title>
        <meta
          name="description"
          content={formData.getMetaDescription() || ""}
        />
      </Helmet>
      <IframeRoot
        $formData={formData}
        $noMarginBottom={true}
        $hideBg={location?.search?.hideBg === "true"}
      >
        <Flex fullWidth={true} flexDirection={"column"} alignItems={"center"}>
          <IframeHeaderLogos
            formData={formData}
            timer={<IframeLoaderTimer />}
          />
          <ContentContainer>
            {countToSearch == null || countToSearch <= 1 ? (
              <>
                <IframeTitle variant={"h3"} align={"center"}>
                  <SearchIconLeft />
                  <Translate id={"iframe.leaderLoadPaydayTitle"} />{" "}
                  <StrongPrimary>
                    <Price
                      price={moneyCzSlider || 0}
                      currency={formData?.offer?.currency}
                    />
                  </StrongPrimary>
                  <SearchIconRight />
                </IframeTitle>
                <IframeSubtitle variant={"subtitle1"} align={"center"}>
                  <Translate id={"iframe.leaderLoadPaydaySubTitle"} />{" "}
                  <strong>{currentSearchIn}</strong>{" "}
                  <Translate id={"iframe.leaderLoadPaydaySubTitle2"} />
                </IframeSubtitle>
              </>
            ) : (
              <>
                <IframeTitle variant={"h3"} align={"center"}>
                  <SearchIconLeft />
                  <Translate id={"iframe.leanLoadingBestOffer1"} />{" "}
                  <StrongPrimary>
                    <Translate id={"iframe.leanLoadingBestOffer2"} />
                  </StrongPrimary>{" "}
                  <Translate id={"iframe.leanLoadingBestOffer3"} />{" "}
                  <StrongPrimary>
                    <Price
                      price={moneyCzSlider || 0}
                      currency={formData?.offer?.currency}
                    />
                  </StrongPrimary>
                  <SearchIconRight />
                </IframeTitle>

                <IframeSubtitle variant={"subtitle1"} align={"center"}>
                  <Translate id={"iframe.leanLoadingBestOfferText1"} />{" "}
                  <strong>{currentSearchIn}</strong>{" "}
                  <Translate id={"iframe.leanLoadingBestOfferText2"} />{" "}
                  <strong>{countToSearch}</strong>{" "}
                  <Translate id={"iframe.leanLoadingBestOfferText3"} />
                </IframeSubtitle>
              </>
            )}
            <FormPercentageUi>100%</FormPercentageUi>
            <IframeDownArrowSwgStyled height={95} marginTop={-14} />
            <IframeCircularProgress />
            <MoneyLoadingAnimation />
            <IframeDidYouKnow />
          </ContentContainer>
        </Flex>
      </IframeRoot>
    </>
  );
};
