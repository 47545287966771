import {
  ENDPOINT_SEND_NEW_EMAIL_VERIFY_TOKEN,
  REDUX_AUTH_LOGGED_USER,
} from "../../constants";
import { basicHandleError, post } from "../../../../core/api/utils/fetchApi";
import { AppDispatchType } from "../../../../redux/store";
import {
  actionLock,
  actionUnlock,
} from "../../../../core/layouts/store/actions/lockable.actions";
import { actionReducerObjectSet } from "../../../../redux/tools/createReducer/createReducerObject";
import { User } from "../../type/User";
import { actionSnackbarShow } from "../../../../core/layouts/store/actions/snackbar.actions";
import { LockKey } from "../../../../core/layouts/utils/useIsLocked";

export const getLockKeySendNewVerifyTokenAction = (): LockKey => {
  return "post" + ENDPOINT_SEND_NEW_EMAIL_VERIFY_TOKEN;
};

export const sendNewVerifyTokenAction = () => async (
  dispatch: AppDispatchType
) => {
  dispatch(actionLock(getLockKeySendNewVerifyTokenAction()));
  const res = await post(ENDPOINT_SEND_NEW_EMAIL_VERIFY_TOKEN, null);
  dispatch(actionUnlock(getLockKeySendNewVerifyTokenAction()));
  if (res.ok) {
    dispatch(
      actionSnackbarShow({
        translateId: "newVerifyTokenSend",
        variant: "success",
      })
    );
    return true;
  } else {
    dispatch(basicHandleError(res));
  }
};

export const actionUserToReducer = (user: User | null) => {
  return actionReducerObjectSet(REDUX_AUTH_LOGGED_USER, user);
};
