import React, { Suspense } from "react";
import {
  Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import AuthLayout from "../../modules/auth/layout/Auth";
import Page404 from "../../modules/auth/container/Page404";
import { RouteType } from "../../types/routes";
import FormsIframeRoute from "../../modules/formsIframe/container/FormsIframeRoute";
import {
  URL_IFRAME,
  URL_IFRAME_PREVIEW_TERMS,
} from "../../modules/formsIframe/constant";
import { getUrlAuth } from "../../modules/auth/utils/getUrlAuth";
import { authRoutes, profileRoutes } from "../../modules/auth/config";
import { historyOnlyForRouter } from "../history";
import AppLayout from "../layouts/appLayout/AppLayout";
import { dashboardRoutes } from "../../modules/dashboard/config/routes";
import { offerRoutes } from "../../modules/offers/config/routes";
import { InitPage } from "../components/InitPage";
import { BaseInitHook } from "../utils/baseInitHook";
import FormsPreviewTermsRoute from "../../modules/formsIframe/container/FormsPreviewTermsRoute";
import { formsRoutes } from "../../modules/form/config/routes";
import { postBackRoutes } from "../../modules/postbacks/config/routes";
import { reportRoutes } from "../../modules/reports/config/routes";
import { payoutRoutes } from "../../modules/payout/config/routes";
import { invoiceRoutes } from "../../modules/invoice/config/routes";
import { connectionCreatorRoutes } from "../../modules/connectionCreator/config/routes";

const defaultIniHook: BaseInitHook = () => null;

//TODO clean
const childRoutes = (Layout: React.ElementType, routes: Array<RouteType>) =>
  routes.map(
    ({ component: Component, children, path, useInit }, index: number) => {
      return children ? (
        children.map((element, index: number) => {
          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props: RouteComponentProps) => (
                <Layout>
                  <InitPage
                    useInit={element.useInit ? element.useInit : defaultIniHook}
                  />
                  {/*TODO loading*/}
                  <Suspense fallback={"Loding"}>
                    <element.component {...props} />
                  </Suspense>
                </Layout>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <InitPage useInit={useInit ? useInit : defaultIniHook} />
              {/*TODO loading*/}
              <Suspense fallback={"loding"}>
                <Component {...props} />
              </Suspense>
            </Layout>
          )}
        />
      ) : null;
    }
  );

const Routes = () => (
  <Router history={historyOnlyForRouter}>
    <Switch>
      {childRoutes(AppLayout, [
        dashboardRoutes,
        profileRoutes,
        offerRoutes,
        formsRoutes,
        postBackRoutes,
        reportRoutes,
        payoutRoutes,
        connectionCreatorRoutes,
        invoiceRoutes,
      ])}
      {childRoutes(AuthLayout, [authRoutes])}
      <FormsIframeRoute path={URL_IFRAME} />
      <FormsPreviewTermsRoute path={URL_IFRAME_PREVIEW_TERMS} />
      <Redirect to={getUrlAuth("login")} from={"/"} />
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);

export default Routes;
