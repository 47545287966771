import { RouteType } from "../../../types/routes";
import { getUrlFormDetail, getUrlForms } from "../utils/getUrls";
import { FormDetail, Forms } from "../container";

export const formsRoutes: RouteType = {
  id: "Offer",
  path: "/offer",
  children: [
    {
      path: getUrlForms(),
      component: Forms,
    },
    {
      path: getUrlFormDetail(":formId?"),
      component: FormDetail,
    },
  ],
  component: null,
};
