import { get, basicHandleError } from "../../../../../core/api/utils/fetchApi";
import { AppDispatchType } from "../../../../../redux/store";
import { LockKey } from "../../../../../core/layouts/utils/useIsLocked";
import {
  actionLock,
  actionUnlock,
} from "../../../../../core/layouts/store/actions/lockable.actions";
import { actionReducerObjectSet } from "../../../../../redux/tools/createReducer/createReducerObject";
import {
  ENDPOINT_FORM,
  ENDPOINT_FORM_FIELDS,
  ENDPOINT_FORM_HANDLERS,
  REDUX_FORM_LEAD_HANDLER,
} from "../../../constants/form";

export const getLockKeyActionGetFormLeadHandlersAction = (): LockKey => {
  return "get" + ENDPOINT_FORM_FIELDS;
};

export const getFormLeadHandlersAction = () => async (
  dispatch: AppDispatchType
) => {
  dispatch(actionLock(getLockKeyActionGetFormLeadHandlersAction()));
  const res = await get(`${ENDPOINT_FORM}/${null}${ENDPOINT_FORM_HANDLERS}`);
  if (res.ok) {
    dispatch(actionReducerObjectSet(REDUX_FORM_LEAD_HANDLER, res.data));
  } else {
    dispatch(basicHandleError(res));
  }
  dispatch(actionUnlock(getLockKeyActionGetFormLeadHandlersAction()));
};
