import { File } from "../../file/type/File";

export class FormSetting {
  primaryColor: string | null = "";
  secondaryColor: string | null = "";
  border: null | string = null;
  borderColor: null | string = null;
  borderWidth: null | number = null;
  centerLogo: null | File = null;
  leftLogo: null | File = null;
  centerLogoId: null | number = null;
  leftLogoId: null | number = null;
  showTimerOnSeconds: null | number = null;
  headerText: null | string = null;
  getMoneyText: null | string = null;
  metaTitle: null | string = null;
  metaDescription: null | string = null;
  textUnderSubmit: null | string = null;
}
