//TODO change or delete

import * as Yup from "yup";
import { getValidationScheme } from "./getValidationScheme";
import { Translate } from "../translate/Translate";

const fullFileValidationScheme = {
  name: Yup.string()
    .max(60)
    .required(() => <Translate id={"core.thisFieldIsRequired"} />),

  description: Yup.string(),
};

export const getFileValidationScheme = (fields?: string[]) =>
  getValidationScheme(fullFileValidationScheme)(fields);
