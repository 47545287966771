import { FormField } from "./FormField";
import { FormSetting } from "./FormSetting";
import { DocAttribute } from "../../../core/components/docCreator/Doc";

export class Form extends FormSetting {
  name: string = "";
  id: number | null = null;
  systemName?: string;
  centerLogoPath?: string;
  leftPathLogo?: string;

  //stats
  leadTotal: number | null = null;
  clickTotal: number | null = null;
  step20: number | null = null;
  step40: number | null = null;
  step60: number | null = null;
  step80: number | null = null;
  step100: number | null = null;
  leadCount: number | null = null;
  smsVerified: number | null = null;
  redirect: number | null = null;

  formFields: FormField[] = [];

  constructor(obj: any) {
    super();
    obj && Object.assign(this, obj);
    if (obj?.formFields) {
      this.formFields = obj.formFields.map(
        (item: FormField) => new FormField(item)
      );
    }
  }

  getAttributesFor(): DocAttribute[] {
    const ret: DocAttribute[] = [];

    for (let i = 0; i < this.formFields.length; i++) {
      const type = this.formFields[i].getType();
      switch (type) {
        case null:
          break;
        default:
          ret.push({
            name: this.formFields[i].systemName,
            note: this.formFields[i].docNote,
            example: this.formFields[i].exampleValue,
            required: !!this.formFields[i].required,
            type,
          });
      }
    }
    return ret;
  }

  isPageValid = (currentPage: number, values: Record<string, any>) => {
    const page = this.getPages(values);
    if (!page[currentPage]) {
      return false;
    }
    return page[currentPage].every((item) => {
      if (!item.required) {
        return true;
      }
      return !!values[item.systemName];
    });
  };

  getPages = (values: Record<string, any>) => {
    const { formFields } = this;

    const page: FormField[][] = [];
    for (let i = 0; i < formFields.length; i++) {
      if (formFields[i].doNotUse(values)) {
        continue;
      }
      if (!page[formFields[i].page]) {
        page[formFields[i].page] = [formFields[i]];
      } else {
        page[formFields[i].page].push(formFields[i]);
      }
    }
    return page.filter((item) => !!item);
  };

  getPagePercentages = (page: number) => {
    const count = this.formFields.reduce(
      (total, item, index) => (item.page <= page ? total + 1 : total),
      0
    );
    return (count / this.formFields.length) * 100;
  };

  getFormFieldByName = (name: string) => {
    return this.formFields.find((item) => item.systemName === name);
  };
}
