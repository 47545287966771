export const SET = "SET";
export const TOGGLE = "TOGGLE";
export const RESET = "RESET";
export const CLEAR = "CLEAR";
export const CLOSE = "CLOSE";
export const OPEN = "OPEN";

export const SET_PROPERTY = "SET_PROPERTY";
export const SET_ITEM_PROPERTY = "SET_ITEM_PROPERTY";
export const PUSH_TO_ARRAY = "PUSH_TO_ARRAY";
export const PUSH_ITEM_OR_UPDATE_LIST = "PUSH_ITEM_OR_UPDATE_LIST";
export const PUSH_TO_ARRAY_IF_NOT_EXIST = "PUSH_TO_ARRAY_IF_NOT_EXIST";
export const DELETE_FROM_ARRAY = "DELETE_FROM_ARRAY";
export const UPDATE_IN_ARRAY = "UPDATE_IN_ARRAY";
export const SET_PAGE = "SET_PAGE";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";

export const DELETE_ALL_ITEMS = "DELETE_ALL_ITEMS";
export const PUSH_ITEM_OR_UPDATE = "PUSH_ITEM_OR_UPDATE";
