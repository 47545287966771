import styled from "styled-components";
import { SystemMarginType } from "../../theme/Spacing";

export interface FlexProps {
  flexDirection?: "row" | "column" | "column-reverse";
  flexWrap?: "wrap" | "wrap-reverse";
  fullWidth?: boolean;
  inLine?: boolean;
  justifyContent?: string;
  alignItems?: string;
  position?: string;
  width?: number | string;
  maxWidth?: string;
  overflow?: "scroll" | "auto";
  minWidth?: string;
  flexGrow?: number;
  height?: number | string;
  background?: string;
  marginTop?: SystemMarginType;
  marginBottom?: SystemMarginType;
  marginLeft?: SystemMarginType;
  marginRight?: SystemMarginType;
  paddingTop?: SystemMarginType;
  paddingBottom?: SystemMarginType;
  paddingLeft?: SystemMarginType;
  paddingRight?: SystemMarginType;
}

export const Flex = styled.div<FlexProps>`
  display: ${({ inLine }) => (inLine ? `inline-flex` : "flex")};
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  ${({ width }) =>
    width && `width: ${typeof width !== "string" ? width + "px" : width};`}
  ${({ height }) =>
    height && `height: ${typeof height !== "string" ? height + "px" : height};`}
  ${({ position }) => position && `position: ${position};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ overflow }) => overflow && `overflow: ${overflow};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  
  ${({ marginTop, theme }) =>
    marginTop && `margin-top: ${theme.margin(marginTop)};`}
  ${({ marginBottom, theme }) =>
    marginBottom && `margin-bottom: ${theme.margin(marginBottom)};`}
  ${({ marginLeft, theme }) =>
    marginLeft && `margin-left: ${theme.margin(marginLeft)};`}
  ${({ marginRight, theme }) =>
    marginRight && `margin-right: ${theme.margin(marginRight)};`}
  
  ${({ paddingTop, theme }) =>
    paddingTop && `padding-top: ${theme.margin(paddingTop)};`}
  ${({ paddingBottom, theme }) =>
    paddingBottom && `padding-bottom: ${theme.margin(paddingBottom)};`}
  ${({ paddingLeft, theme }) =>
    paddingLeft && `padding-left: ${theme.margin(paddingLeft)};`}
  ${({ paddingRight, theme }) =>
    paddingRight && `padding-right: ${theme.margin(paddingRight)};`}
  
  ${({ maxWidth, theme }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ minWidth, theme }) => minWidth && `min-width: ${minWidth};`}
  ${({ background, theme }) => background && `background: ${background};`}
`;
