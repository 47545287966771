export class CascadeOfferItemFilterTimeBlackList {
  day: string | null = null;
  cascadeOfferItemFilterId: string | null = null;
  toMinutesInDay: number | null = 0;
  fromMinutesInDay: number | null = 0;
  fullDay: boolean = false;
  id: number | null = null;

  getMinutesAsString(field: "fromMinutesInDay" | "toMinutesInDay") {
    const hours = ((this[field] || 0) - ((this[field] || 0) % 60)) / 60;
    const minutes = (this[field] || 0) % 60;
    return (
      hours.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
      ":" +
      minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 })
    );
  }

  setMinutesAsString(
    field: "fromMinutesInDay" | "toMinutesInDay",
    value: string
  ) {
    const data = value.split(":");
    if (data.length !== 2) {
      return;
    }
    this[field] = parseInt(data[0]) * 60 + parseInt(data[1]);
  }

  constructor(obj?: any | null) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    return this;
  }
}
