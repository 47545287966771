import * as React from "react";
import { FC } from "react";
import { Grid, Theme, useMediaQuery } from "@material-ui/core";
import { FormField } from "../../../type/FormField";
import styled from "styled-components";

interface IframeInputWrapperProps {
  formField: FormField;
  doNotUseWrap?: boolean;
  noMarginTop?: boolean;
  noMargin?: boolean;
  noMarginVertical?: boolean;
}

const StyledGrid = styled(Grid)<{
  $noMargin?: boolean;
  $noMarginTop?: boolean;
  $noMarginVertical?: boolean;
}>`
  width: 100%;
  display: flex;
  padding: ${({ $noMargin, $noMarginTop, $noMarginVertical, ...props }) => {
    let margin = [2.5, 2.5];
    if ($noMarginVertical) {
      margin = [0, 2.5];
    } else if ($noMarginTop) {
      margin = [0, 2.5, 2.5, 2.5];
    }
    return $noMargin ? 0 : props.theme.spacing(...margin);
  }};
`;

export const IframeInputWrapper: FC<IframeInputWrapperProps> = ({
  children,
  formField,
  doNotUseWrap,
  noMargin,
  noMarginTop,
  noMarginVertical,
}) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const xlDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  if (doNotUseWrap) {
    return <>{children}</>;
  }
  if (
    (xlDown && !formField.xlWidth) ||
    (mdDown && !formField.mdWidth) ||
    (smDown && !formField.smWidth)
  ) {
    return null;
  }

  return (
    <StyledGrid
      $noMargin={noMargin}
      $noMarginTop={noMarginTop}
      $noMarginVertical={noMarginVertical}
      xl={formField.xlWidth || false}
      md={formField.mdWidth || false}
      sm={formField.smWidth || false}
      item
    >
      {children}
    </StyledGrid>
  );
};
