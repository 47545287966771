export class TreeItem<T> {
  item: T;
  children: TreeItem<T>[] = [];

  constructor(item: T, children: TreeItem<T>[] = []) {
    this.item = item;
    this.children = children;
  }

  addItem(toAdd: T, parentIdColumnName: string, idColumnName: string) {
    if (
      (toAdd as any)[idColumnName] === (this.item as any)[parentIdColumnName]
    ) {
      this.children = [...this.children, new TreeItem(toAdd, [])];
    } else {
      this.children.forEach((item) =>
        item.addItem(toAdd, parentIdColumnName, idColumnName)
      );
    }
  }

  public static fromTreeArray<T>(
    array: { children: any; item: any },
    parentIdColumnName: string,
    idColumnName: string,
    model: new (obj: any) => T
  ) {
    return new TreeItem(
      new model(array["item"]),
      array["children"].map((item: any) =>
        TreeItem.fromTreeArray(item, parentIdColumnName, idColumnName, model)
      )
    );
  }
}
