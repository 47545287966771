//endpoints
export const ENDPOINT_FORM = "/form";
export const ENDPOINT_RESEND_SMS = "/resend-sms";
export const ENDPOINT_VERIFY_SMS = "/verify-sms";
export const ENDPOINT_FINAL_PAGE_LINK = "/final-page-link";

//list
export const REDUX_FORMS = "REDUX_FORMS";
export const REDUX_FORMS_LAST_PAGE = "REDUX_FORMS_LAST_PAGE";
export const REDUX_FORMS_PAGE = "REDUX_FORMS_PAGE";
export const REDUX_FORMS_LIST = "REDUX_FORMS_LIST";
export const REDUX_FORMS_PAGE_SIZE = "REDUX_FORMS_PAGE_SIZE";

//detail
export const REDUX_FORM = "REDUX_FORM";
export const REDUX_FORM_FINAL_PAGE_LINKS = "REDUX_FORM_FINAL_PAGE_LINKS";
export const REDUX_FORM_FINAL_PAGE_LINKS_DIALOG =
  "REDUX_FORM_FINAL_PAGE_LINKS_DIALOG";
export const REDUX_FORM_FINAL_PAGE_LINKS_TO_EDIT =
  "REDUX_FORM_FINAL_PAGE_LINKS_TO_EDIT";
