// Auth components
import React from "react";

export const PostbackTracking = React.lazy(
  () => /* webpackChunkName: 'PostbackTracking'*/ import("./PostbackTracking")
);

export const PostbackClickLeads = React.lazy(
  () =>
    /* webpackChunkName: 'PostbackClickLeads'*/ import("./PostbackClickLeads")
);

export const PostbackLeads = React.lazy(
  () => /* webpackChunkName: 'PostbackLeads'*/ import("./PostbackLeads")
);
