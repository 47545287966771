export class AutoRequestHeaderConfig {
  name: string = "";
  value: string = "";
  requestConfigId: null | number = null;
  id: null | number = null;

  constructor(obj?: any) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    const { id, name, value } = this;
    return { id, name, value };
  }

  toTemplateJson() {
    const { name, value } = this;
    return { name, value };
  }
}
