import React, { FC, useMemo } from "react";
import { Flex } from "../../../../../core/components/layout/Flex";
import IframeTimer from "./IframeTimer";
import styled from "styled-components";
import { Typography, useTheme } from "@material-ui/core";
import { addThemeColorsToText } from "../../../utils/addThemeColorsToText";
import FormPercentages from "./FormPercentages";
import { LoadCzMoneyImage } from "../inputs/sliderLoanSubcomponents/LoadCzMoneyImage";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../redux/store";
import { TYPE_SLIDER_MONEY } from "../../../constant/formFieldType";
import UserOfferConfig from "../../../type/UserOfferConfig";
import { IframeHeaderLogos } from "./IframeHeaderLogos";

interface Props {
  formData: UserOfferConfig;
  currentPage: number;
  formValues: Record<string, any>;
  onlyMoney?: boolean;
}

interface IframeHeadLogoProps {
  $smOrder: number;
}

const Title = styled(Typography)`
  font-weight: 400 !important;
`;

export const IframeHeadLogo = styled.img<IframeHeadLogoProps>`
  height: 60px;
  max-width: 220px;
  margin: auto 20px;
  object-fit: contain;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 8px;
    height: 76px;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    ${({ $smOrder }) => ($smOrder ? "order: " + $smOrder : "")};
    padding-left: ${({ $smOrder }) => ($smOrder === 0 ? "16px" : "0")};
    max-width: 50%;
    height: unset;
    margin: unset;
    padding-top: 20px;
  }
`;

interface UseMoneySliderValueProps {
  formData: UserOfferConfig;
}

export const useMoneySliderValue = ({ formData }: UseMoneySliderValueProps) => {
  const form = formData.form;
  const moneyCzSlider = useSelector(
    (state: AppStateType) => state.iframe.moneyCzSlider
  );
  const showMoneyCzSlider = useMemo(
    () =>
      (form?.formFields || []).some((item) => item.type === TYPE_SLIDER_MONEY),
    [form]
  );
  return { showMoneyCzSlider, moneyCzSlider };
};

const IframeHeader: FC<Props> = ({
  formData,
  onlyMoney = false,
  currentPage,
  formValues,
}) => {
  const form = formData.form;
  const theme = useTheme();
  const { moneyCzSlider, showMoneyCzSlider } = useMoneySliderValue({
    formData,
  });

  if (!form) {
    return null;
  }

  return (
    <Flex fullWidth={true} flexDirection={"column"} marginBottom={"small"}>
      {!onlyMoney && (
        <IframeHeaderLogos
          formData={formData}
          timer={
            formData.getShowTimerOnSeconds() ? (
              <IframeTimer formData={formData} />
            ) : undefined
          }
        />
      )}
      {!onlyMoney && formData.getHeaderText() && (
        <Title
          align={"center"}
          variant="h3"
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: addThemeColorsToText(formData.getHeaderText() || "", theme),
          }}
        />
      )}
      <FormPercentages
        form={form}
        currentPage={currentPage}
        formValues={formValues}
      />
      {showMoneyCzSlider && <LoadCzMoneyImage money={moneyCzSlider || 0} />}
    </Flex>
  );
};

export default React.memo(IframeHeader);
