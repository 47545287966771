import * as React from "react";
import { FC } from "react";
import { IframeInputWrapper } from "./IframeInputWrapper";
import { IframeFiledProps } from "../IframeInputsList";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Paper } from "@material-ui/core";
import styled from "styled-components";

const BANK_CODES = [
  {
    value: "0800",
    sortBy: 28.98,
    title: "0800 - Česká spořitelna",
  },
  {
    value: "0600",
    sortBy: 17.6,
    title: "0600 - MONETA Money Bank ",
  },
  {
    value: "0300",
    sortBy: 14.6,
    title: "0300 - ČSOB",
  },
  {
    value: "3030",
    sortBy: 8.12,
    title: "3030 - Air Bank",
  },
  {
    value: "2010",
    sortBy: 5.4,
    title: "2010 - Fio banka ",
  },
  {
    value: "5500",
    sortBy: 5.0,
    title: "5500 - Raiffeisenbank",
  },
  {
    value: "6100",
    sortBy: 4.11,
    title: "6100 - Equa banka",
  },
  {
    value: "6210",
    sortBy: 3.7,
    title: "6210 - mBank",
  },
  {
    value: "0100",
    sortBy: 14.5,
    title: "0100 - Komerční banka",
  },
  {
    value: "0710",
    title: "0710 - Česká národní banka",
  },
  {
    value: "2060",
    title: "2060 - Citfin",
  },
  {
    value: "2070",
    title: "2070 - TRINITY BANK",
  },
  {
    value: "2100",
    title: "2100 - Hypoteční banka",
  },
  {
    value: "2200",
    title: "2200 - Peněžní dům",
  },
  {
    value: "2220",
    title: "2220 - Artesa",
  },
  {
    value: "2240",
    title: "2240 - Poštovní banka",
  },
  {
    value: "2250",
    sortBy: 3.5,
    title: "2250 - Creditas",
  },
  {
    value: "2260",
    title: "2260 - NEY spořitelní družstvo",
  },
  {
    value: "2600",
    title: "2600 - City bank",
  },
  {
    value: "2700",
    sortBy: 3.45,
    title: "2700 - UniCredit Bank",
  },
  {
    value: "3050",
    sortBy: 1.0,
    title: "3050 - Hello bank",
  },
  {
    value: "3500",
    sortBy: 1.0,
    title: "3500 - ING",
  },
  {
    value: "4000",
    sortBy: 1.0,
    title: "4000 - Expobank",
  },
  {
    value: "5800",
    sortBy: 1.0,
    title: "5800 - J & T Banka",
  },
  {
    value: "6000",
    title: "6000 - PPF banka",
  },
  {
    value: "6200",
    title: "6200 - COMMERZBANK",
  },
  {
    value: "6300",
    title: "6300 - BNP Paribas S.A.",
  },
  {
    value: "6800",
    sortBy: 1.0,
    title: "6800 - Sberbank",
  },
  {
    value: "8040",
    title: "8040 - Oberbank",
  },
].sort((a, b) => (b?.sortBy || 0) - (a?.sortBy || 0));

const PaperStyled = styled(Paper)`
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
`;

export const IframeBankCode: FC<IframeFiledProps> = ({
  formField,
  value,
  onChange,
}) => {
  return (
    <IframeInputWrapper formField={formField}>
      <Autocomplete
        fullWidth={true}
        options={BANK_CODES}
        PaperComponent={PaperStyled}
        getOptionLabel={(option): string => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth={true}
            label={formField.visibleDescription}
            variant="outlined"
            onBlur={(e) => {
              if (BANK_CODES.some((item) => item.value === e.target.value)) {
                onChange(e.target.value);
              }
            }}
          />
        )}
        value={BANK_CODES.find((item) => item.value === value) || null}
        onChange={(e, value) => onChange(value?.value || null)}
      />
    </IframeInputWrapper>
  );
};
