import React, { FC, useState } from "react";
import { Flex } from "../../../../../core/components/layout/Flex";
import styled from "styled-components";
import { IconButton, Input, Tooltip, Typography } from "@material-ui/core";
import {
  Translate,
  useTranslate,
} from "../../../../../core/translate/Translate";
import Send from "@material-ui/icons/Send";
import { useDispatch } from "react-redux";
import { actionSnackbarShow } from "../../../../../core/layouts/store/actions/snackbar.actions";
import { postIframeError } from "../../../utils/postIframeError";
import { basicHandleError } from "../../../../../core/api/utils/fetchApi";
import { useHistoryLocation } from "../../../../../core/history";

interface Props {
  leadId?: number;
  formValues: Record<string, any>;
}

const Root = styled(Flex)<{ $hideBg: boolean }>`
  background: #f1f1f1;
  height: 160px;
  padding: 40px;
  margin: ${({ theme, $hideBg }) => ($hideBg ? 0 : theme.spacing(0, 4, 28, 4))};
  ${(props) => props.theme.breakpoints.down("md")} {
    margin: 0;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    height: auto;
    text-align: center;
    padding: 40px 16px;
  }
`;

const InputWrapper = styled(Flex)`
  background: #fff;
  height: 100%;
  width: 100%;
  max-width: 595px;
  align-items: center;
  border-radius: 4px;
  max-height: 100%;
  padding: 4px 15px 4px 20px;
  overflow: auto;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: 37px;
    min-height: 180px;
    max-height: 180px;
    max-width: unset;
    overflow-y: auto;
    padding: 21px 15px 15px 20px;
  }
`;

const InputStyled = styled(Input)`
  width: 100%;
  & textarea {
    font-size: 16px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    align-self: flex-start;
    & textarea {
      font-size: 16px;
    }
  }
`;

const Text = styled(Typography)`
  font-size: 24px;
  padding-right: 15px;
  margin: auto;
  color: #000;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 20px;
  }
`;

const SendStyled = styled(Send)`
  ${({ theme }) => theme.breakpoints.down("xs")} {
    width: 33px;
    height: 33px;
  }
`;

const findValueInObject = (
  object: Record<string, any>,
  possibleKeys: string[]
): string | null => {
  for (let i = 0; i < possibleKeys.length; i++) {
    if (object[possibleKeys[i]]) {
      return String(object[possibleKeys[i]]);
    }
  }
  return null;
};

const IframeFooter: FC<Props> = ({ leadId, formValues }) => {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const yourMessage = useTranslate({ id: "iframe.yourMessage" });

  const onSubmit = () => {
    const phone =
      findValueInObject(formValues, ["phone", "cell_phone", "tel"]) || "";
    const email =
      findValueInObject(formValues, ["email", "email_address"]) || "";
    const firstName = findValueInObject(formValues, ["first_name"]) || "";
    const lastName = findValueInObject(formValues, ["last_name"]) || "";

    postIframeError({ message, email, phone, lastName, firstName }).then(
      (res) => {
        if (res.ok) {
          dispatch(
            actionSnackbarShow({
              translateId: "iframe.sendErrorReportSent",
              variant: "success",
            })
          );
          setMessage("");
        } else {
          dispatch(basicHandleError(res));
        }
      }
    );
  };
  const location = useHistoryLocation<{ hideBg: "true" | null }>();

  return (
    <Root
      fullWidth={true}
      flexDirection={"row"}
      marginBottom={"small"}
      $hideBg={location.search?.hideBg === "true"}
    >
      <Text variant={"body2"}>
        <Translate id={"iframe.reportErrorInfo"} />
      </Text>
      <InputWrapper>
        <InputStyled
          disableUnderline={true}
          placeholder={yourMessage}
          value={message}
          multiline={true}
          onChange={(e) => setMessage(e.target.value)}
        />

        <Tooltip title={<Translate id={"iframe.sendErrorReport"} />}>
          <IconButton disabled={!message} onClick={onSubmit}>
            <SendStyled />
          </IconButton>
        </Tooltip>
      </InputWrapper>
    </Root>
  );
};

export default React.memo(IframeFooter);
