import { FC, useRef } from "react";
import { Flex } from "../../../../../core/components/layout/Flex";
import { Theme, useMediaQuery } from "@material-ui/core";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import UserOfferConfig from "../../../type/UserOfferConfig";
import { AppStateType } from "../../../../../redux/store";
import * as React from "react";
import { IframeFormButton } from "../../iframe/IframeSubmitButton";
import { IFRAME_PHONE_NAME } from "../../../constant";
import { actionVerifySms } from "../../../store/action/verifySms.action";

const FlexRoot = styled(Flex)`
  margin-top: 80px;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin-top: 20px;
  }
`;

interface IframeButtonsProps {
  pin: string;
  formData: UserOfferConfig;
}

export const IframeSubmit: FC<IframeButtonsProps> = ({ pin, formData }) => {
  const dispatch = useDispatch();
  const formValues = useSelector(
    (state: AppStateType) => state.iframe.formValues
  );
  const accessToken =
    useSelector((state: AppStateType) => state.iframe.loader.accessToken) || "";
  const phone = formValues[IFRAME_PHONE_NAME];
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const startTime = useRef(Date.now());

  return (
    <FlexRoot
      justifyContent={"center"}
      marginLeft={"large"}
      marginRight={"large"}
      marginBottom={"large"}
      position={"relative"}
      flexDirection={!smDown ? "row" : "column-reverse"}
      flexWrap={smDown ? "wrap-reverse" : undefined}
    >
      <IframeFormButton
        onClick={() => {
          dispatch(
            actionVerifySms(
              accessToken,
              phone,
              pin,
              Date.now() - startTime.current
            )
          );
        }}
        disabled={pin === ""}
        isLoading={false}
        text={formData.getGetMoneyText()}
        underSubmitText={formData.getTextUnderSubmit()}
      />
    </FlexRoot>
  );
};
