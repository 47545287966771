export class CascadeOfferItemFilterFormValue {
  valueName: string = "";
  value: string = "";
  operator: string = "";
  id: number | null = null;

  constructor(obj?: any | null) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    return this;
  }
}
