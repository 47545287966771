export const sendDataToParentPage = (
  status: "started" | "lead" | "declined" | null,
  event: "filled-input" | "submitted" | "next-page" | "height-change",
  data: Record<string, string | null | number> | string | null
) => {
  const toSend = {
    status,
    event,
    data,
  };
  window.parent.postMessage(JSON.stringify(toSend), "*");
};
