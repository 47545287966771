import styled from "styled-components";
import * as React from "react";
import { FC, useContext } from "react";
import { ValueLabelProps } from "@material-ui/core";
import { SliderStateContext } from "../IframeSlider";

const SliderValueLabel = styled.span`
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  font-weight: 700;
  white-space: pre;
  height: 48px;
  padding: 5px 18px;
  font-size: 28px;
  border-radius: ${(props) => props.theme.spacing(1)}px;
`;

export const SliderValue: FC<ValueLabelProps> = ({ children, value }) => {
  const context = useContext(SliderStateContext);
  return React.cloneElement(
    children,
    {
      className: children.props.className,
    },
    <React.Fragment>
      {children.props.children}
      <SliderValueLabel
        ref={(ref) => {
          if (ref && context.onChange) {
            context.onChange(ref.offsetWidth);
          }
        }}
      >
        {value}
      </SliderValueLabel>
    </React.Fragment>
  );
};
