import styled from "styled-components";
import { SystemMarginType } from "../theme/Spacing";
import { Button, ButtonProps } from "@material-ui/core";
import { FC } from "react";
import { Translate } from "../translate/Translate";

export interface MyButtonProps extends ButtonProps {
  $marginTop?: SystemMarginType;
  $marginBottom?: SystemMarginType;
  $marginLeft?: SystemMarginType;
  $marginRight?: SystemMarginType;
  $width?: number | string;
  translateId?: string;
}

const ButtonStyled = styled(Button)<MyButtonProps>`
  ${({ $marginTop, theme }) =>
    $marginTop && `margin-top: ${theme.margin($marginTop)};`}
  ${({ $marginBottom, theme }) =>
    $marginBottom && `margin-bottom: ${theme.margin($marginBottom)};`}
  ${({ $marginLeft, theme }) =>
    $marginLeft && `margin-left: ${theme.margin($marginLeft)};`}
  ${({ $marginRight, theme }) =>
    $marginRight && `margin-right: ${theme.margin($marginRight)};`}
  ${({ $width }) =>
    $width && `width: ${typeof $width !== "string" ? $width + "px" : $width};`}
`;

export const MyButton: FC<MyButtonProps> = ({
  variant = "outlined",
  color = "primary",
  translateId,
  children,
  ...props
}) => (
  <ButtonStyled variant={variant} color={color} {...props}>
    {translateId ? <Translate id={translateId} /> : children}
  </ButtonStyled>
);
