import { createBrowserHistory } from "history";
import { HistoryLocation } from "./type";

const history = createBrowserHistory();

export const useHistoryLocation = <T,>() => {
  return locationToHistoryLocation<T>();
};

const locationToHistoryLocation = <T,>(): HistoryLocation<T> => {
  const search = history.location.search.substring(1);

  const urlParams = new URLSearchParams(search);
  const searchJson: Record<string, any> = {};
  urlParams.forEach((value, key, parent) => {
    searchJson[key] = value;
  });

  const tryParseToArray = (s: string) => {
    if (s.length <= 2) {
      if (s === "[]") {
        return [];
      }
      return s;
    }
    if (s.substr(0, 1) === "[" && s.substr(s.length - 1, 1) === "]") {
      return s.substr(1, s.length - 2).split(",");
    }
    return s;
  };

  return {
    pathname: history.location.pathname,
    hash: history.location.hash,
    search: Object.keys(searchJson).reduce((all, current) => {
      return {
        ...all,
        [current]: tryParseToArray(searchJson[current]),
      };
    }, {}) as T,
  };
};

const historyLocationToLocation = <T,>(
  location: HistoryLocation<T>
): string => {
  const search = location.search || {};
  const searchKeys = Object.keys(search);

  return (
    location.pathname +
    (searchKeys.length !== 0
      ? "?" +
        searchKeys.reduce((result, key, index) => {
          const keyValue = (search as any)[key];
          return (
            result +
            (index !== 0 ? "&" : "") +
            key +
            ("=" +
              (Array.isArray(keyValue)
                ? "[" + keyValue.join(",") + "]"
                : keyValue))
          );
        }, "")
      : "") +
    (location.hash
      ? location.hash.startsWith("#")
        ? location.hash
        : "#" + location.hash
      : "")
  );
};

type NewUrlArguments<T = Record<string, string>> =
  | string
  | HistoryLocation<T>
  | ((historyLocation: HistoryLocation<any>) => HistoryLocation<T>);

export const historyPush = <T,>(arg: NewUrlArguments<T>) => {
  if (typeof arg === "string") {
    history.push(arg);
  } else if (typeof arg === "function") {
    history.push(historyLocationToLocation(arg(locationToHistoryLocation())));
  } else {
    history.push(historyLocationToLocation(arg));
  }
};

export const historyReplace = <T,>(arg: NewUrlArguments<T>) => {
  if (typeof arg === "string") {
    history.replace(arg);
  } else if (typeof arg === "function") {
    history.replace(
      historyLocationToLocation(arg(locationToHistoryLocation()))
    );
  } else {
    history.replace(historyLocationToLocation(arg));
  }
};

export const historyOnlyForRouter = history;
