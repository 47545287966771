import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { MuiButtonSpacingType } from "../../../types/styles";
import { Helmet } from "react-helmet";

import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useParams } from "react-router";
import { Translate } from "../../../core/translate/Translate";

const Button = styled(MuiButton)<MuiButtonSpacingType>(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

export interface Page500Params {
  translateId?: string;
  notShowGoHome?: string;
}

function Page500() {
  const { translateId, notShowGoHome } = useParams<Page500Params>();
  return (
    <Wrapper>
      <Helmet title="500 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        500
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        <Translate id={"core.error500"} />
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        <Translate id={translateId ? translateId : "core.error500Text"} />
      </Typography>

      {notShowGoHome && (
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="secondary"
          mt={2}
        >
          <Translate id={"core.backToHome"} />
        </Button>
      )}
    </Wrapper>
  );
}

export default Page500;
