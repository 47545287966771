import { ENDPOINT_FILE } from "../../../constants";
import { AppDispatchType } from "../../../../../redux/store";
import { LockKey } from "../../../../../core/layouts/utils/useIsLocked";
import {
  actionLock,
  actionUnlock,
} from "../../../../../core/layouts/store/actions/lockable.actions";
import { basicHandleError, put } from "../../../../../core/api/utils/fetchApi";
import { File } from "../../../type/File";
import { OFFER } from "../../../../../core/constants/modules";
import {
  actionReducerObjectArrayPushOrUpdate,
  actionReducerObjectSet,
} from "../../../../../redux/tools/createReducer/createReducerObject";
import { REDUX_OFFER_OFFER } from "../../../../offers/constants";
import { REDUX_LAYOUT_EDIT_FILE } from "../../../../../core/constants";

export const getLockKeyActionPutFile = (id: null | number): LockKey => {
  return "put" + ENDPOINT_FILE("", 0) + id;
};

export const actionPutFile = (parentId: number, data: File) => async (
  dispatch: AppDispatchType
) => {
  dispatch(actionLock(getLockKeyActionPutFile(data.id)));
  const res = await put(
    ENDPOINT_FILE(data.module, parentId) + "/" + data.id,
    data.toJson()
  );
  dispatch(actionUnlock(getLockKeyActionPutFile(data.id)));
  if (res.ok) {
    const file = new File(res.data);
    switch (file.module) {
      case OFFER:
        dispatch(
          actionReducerObjectArrayPushOrUpdate(
            REDUX_OFFER_OFFER,
            file,
            "files",
            "id"
          )
        );
        break;
    }
    dispatch(actionReducerObjectSet(REDUX_LAYOUT_EDIT_FILE, null));
  } else {
    dispatch(basicHandleError(res));
  }
};
