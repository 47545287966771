import React, { FC } from "react";
import { MyDialog } from "../../../core/components/MyDialog";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../redux/store";
import { actionReducerObjectSet } from "../../../redux/tools/createReducer/createReducerObject";
import { REDUX_LAYOUT_EDIT_FILE } from "../../../core/constants";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { MyTextFieldBase } from "../../../core/components/inputs/inputHelp/MyTextFieldBase";
import { Translate } from "../../../core/translate/Translate";
import { Formik } from "formik";
import { getFileValidationScheme } from "../../../core/utils/fileValidationSchema";
import { File } from "../type/File";
import {
  actionPutFile,
  getLockKeyActionPutFile,
} from "../store/action/file/putFile.action";
import { LoadingButton } from "../../../core/components/LoadingButton";

export const EditFileDialog: FC = () => {
  const dispatch = useDispatch();
  const editFile = useSelector(
    (state: AppStateType) => state.layout.openEditFile
  );
  const openFileParentId = useSelector(
    (state: AppStateType) => state.layout.openFileParentId
  );
  const onClose = () => {
    dispatch(actionReducerObjectSet(REDUX_LAYOUT_EDIT_FILE, null));
  };
  const onSave = (file: File) => {
    if (openFileParentId) {
      dispatch(actionPutFile(openFileParentId, file));
    }
  };

  return (
    <MyDialog
      maxWidth={"sm"}
      fullWidth={true}
      open={!!editFile}
      onClose={onClose}
      titleTranslateId={"core.attachFile"}
      hideCloseButton={true}
    >
      <Formik
        key={String(editFile?.id)}
        initialValues={{
          name: editFile?.name || "",
          description: editFile?.description || "",
        }}
        validationSchema={getFileValidationScheme(["name", "description"])}
        onSubmit={(values) => {
          onSave(new File({ ...editFile, ...values }));
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          isValid,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <MyTextFieldBase
                  name="name"
                  label={<Translate id={"core.fileName"} />}
                  value={values.name || ""}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <MyTextFieldBase
                  name="description"
                  label={<Translate id={"core.fileDescription"} />}
                  value={values.description || ""}
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="primary">
                  <Translate id={"core.cancel"} />
                </Button>
                <LoadingButton
                  lockableKey={getLockKeyActionPutFile(editFile?.id || null)}
                  disabled={!isValid}
                  type={"submit"}
                  color="primary"
                >
                  <Translate id={"core.save"} />
                </LoadingButton>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </MyDialog>
  );
};
