import { RequestConnectionProperty } from "./RequestConnectionProperty";
import { AutoRequestHeaderConfig } from "./AutoRequestHeaderConfig";
import { ResponseHandler } from "./ResponseHandler";

export class AutoRequestConfig {
  id: number | null = null;
  url: string = "";
  requestConnectionProperties: RequestConnectionProperty[] = [];
  requestConnectionPropertiesUrl: RequestConnectionProperty[] = [];
  headers: AutoRequestHeaderConfig[] = [];
  responseHandlers: ResponseHandler[] = [];
  timeoutMs: number = 3000;
  sendPayloadAs: string | null = null;

  constructor(obj?: any) {
    obj && Object.assign(this, obj);
    if (Array.isArray(obj?.requestConnectionProperties)) {
      this.requestConnectionProperties = obj.requestConnectionProperties.map(
        (item: any) => new RequestConnectionProperty(item)
      );
    }
    if (Array.isArray(obj?.requestConnectionPropertiesUrl)) {
      this.requestConnectionPropertiesUrl = obj.requestConnectionPropertiesUrl.map(
        (item: any) => new RequestConnectionProperty(item)
      );
    }
    if (Array.isArray(obj?.headers)) {
      this.headers = obj.headers.map(
        (item: any) => new AutoRequestHeaderConfig(item)
      );
    }
    if (Array.isArray(obj?.responseHandlers)) {
      this.responseHandlers = obj.responseHandlers.map(
        (item: any) => new ResponseHandler(item)
      );
    }
  }

  toTemplateJson() {
    const {
      headers,
      requestConnectionProperties,
      requestConnectionPropertiesUrl,
      sendPayloadAs,
      responseHandlers,
      timeoutMs,
      url,
    } = this;
    return {
      headers: headers.map((item) => item.toTemplateJson()),
      requestConnectionProperties: requestConnectionProperties.map((item) =>
        item.toTemplateJson()
      ),
      requestConnectionPropertiesUrl: requestConnectionPropertiesUrl.map((item) =>
        item.toTemplateJson()
      ),
      sendPayloadAs,
      responseHandlers: responseHandlers.map((item) => item.toTemplateJson()),
      timeoutMs,
      url,
    };
  }

  toJson() {
    const {
      headers,
      requestConnectionProperties,
      sendPayloadAs,
      responseHandlers,
      timeoutMs,
      requestConnectionPropertiesUrl,
      url,
      id,
    } = this;
    return {
      id,
      headers: headers.map((item) => item.toJson()),
      requestConnectionProperties: requestConnectionProperties.map((item) =>
        item.toJson()
      ),
      requestConnectionPropertiesUrl: requestConnectionPropertiesUrl.map((item) =>
        item.toJson()
      ),
      sendPayloadAs,
      responseHandlers: responseHandlers.map((item) => item.toJson()),
      timeoutMs,
      url,
    };
  }
}
