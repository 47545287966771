import * as React from "react";
import { FC, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Link,
  Paper,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { IframeInputWrapper } from "./IframeInputWrapper";
import { IframeFiledProps } from "../IframeInputsList";
import styled from "styled-components";
import { Flex } from "../../../../../core/components/layout/Flex";
import { addSettingValueToText } from "../../../utils/addSettingValueToText";
import { FormCustomTermsAndCondition } from "../../../type/FormCustomTermsAndCondition";
import { Collapse } from "@material-ui/core";
import { Translate } from "../../../../../core/translate/Translate";

interface IframeCheckBoxProps {
  noMargin?: boolean;
  formCustomTermsAndConditions: FormCustomTermsAndCondition[];
}

const Required = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: 700;
`;

const CheckboxStyled = styled(Checkbox)`
  ${({ theme }) => theme.breakpoints.down("xs")} {
    padding: 0 2px 0 10px;
  }
`;

export const ContentWrapper = styled("div")`
  max-height: 200px;
  overflow-y: auto;
  padding: 8px;
  font-size: 18px;

  & h1 {
    font-size: 1.2rem;
  }
`;

const ButtonAgree = styled(Button)`
  margin: 8px;
  font-size: 15px;
`;

const PaperStyled = styled(Paper)`
  background-color: #f5f5f5;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;

const Label = styled("div")`
  font-size: 22px;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const LinkIframeCheckBox = styled(Link)<any>`
  color: inherit;
  text-decoration: underline;
`;

export const IframeCheckBox: FC<IframeCheckBoxProps & IframeFiledProps> = ({
  onChange,
  formField,
  settings,
  formCustomTermsAndConditions,
  value,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedHtml, setSelectedHtml] = useState<string>("");
  useEffect(() => {
    if (formField.defaultValue && value === undefined) {
      onChange(
        formField.defaultValue === "1" || formField.defaultValue === "true"
      );
    }
  }, [formField.defaultValue, value, onChange]);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  return (
    <IframeInputWrapper formField={formField} noMarginVertical={!smDown}>
      <Flex fullWidth={true} justifyContent={"center"} flexDirection={"column"}>
        <Flex marginLeft={"auto"} marginRight={"auto"}>
          <CheckboxStyled
            className={"iframe-check-box"}
            checked={value || false}
            onChange={(e) => onChange(e.target.checked)}
          />
          <Label
            onClick={() => {
              onChange(!value);
            }}
          >
            {formField.required || formField.fakeRequired ? (
              <Required>*</Required>
            ) : (
              ""
            )}
            <span>
              {addSettingValueToText(
                formField.visibleDescription || "",
                (val: string) => {
                  if (!open) {
                    setOpen(true);
                    const setting = settings.find((item) => item.name === val);
                    setSelectedHtml(setting?.value || "");
                  } else {
                    setOpen(false);
                  }
                }
              )}
            </span>
            {formCustomTermsAndConditions
              .filter((item) => item.formFieldName === formField.systemName)
              .reduce((all, item, index) => {
                const link = (
                  <LinkIframeCheckBox
                    key={item.id}
                    component={"span"}
                    onClick={() => {
                      if (!open) {
                        setOpen(true);
                        setSelectedHtml(item.value || "");
                      } else {
                        setOpen(false);
                      }
                    }}
                  >
                    [{index + 1}]
                  </LinkIframeCheckBox>
                );
                return index !== 0 ? (
                  <React.Fragment key={item.id}>
                    {all}, {link}
                  </React.Fragment>
                ) : (
                  <React.Fragment key={item.id}>
                    {all}
                    {link}
                  </React.Fragment>
                );
              }, <React.Fragment key={"last"}>&nbsp;</React.Fragment>)}
          </Label>
        </Flex>
        <Collapse in={open} collapsedHeight={0}>
          <PaperStyled elevation={2}>
            <ContentWrapper>
              <div
                id={"terms-and-condition"}
                dangerouslySetInnerHTML={{ __html: selectedHtml }}
              />
            </ContentWrapper>

            <ButtonAgree
              onClick={() => {
                setOpen(false);
                onChange(true);
              }}
              variant="contained"
              color="primary"
            >
              <Translate id={"iframe.agree"} />
            </ButtonAgree>
          </PaperStyled>
        </Collapse>
      </Flex>
    </IframeInputWrapper>
  );
};
