export const CHART_BAR = "bar";
export const CHART_LINEAR = "line";
export const CHART_HORIZONTAL_BAR = "horizontalBar";
export const CHART_RADAR = "radar";
export const CHART_DOUGHNUT = "doughnut";
export const CHART_POLAR_AREA = "polarArea";
export const CHART_BUBBLE = "bubble";
export const CHART_PIE = "pie";
export const CHART_SCATTER = "scatter";

export const ENDPOINT_SAVE_TABLE = "/auto-connected-table/chart-config";

export const REDUX_CONNECTED_TABLE_SAVES = "REDUX_CONNECTED_TABLE_SAVES";
export const REDUX_CONNECTED_TABLE_DIALOG = "REDUX_CONNECTED_TABLE_DIALOG";
