export const PAGE_OFFERS_LIST = "list";
export const PAGE_OFFERS_DETAIL = "detail";
export const PAGE_OFFERS_EDIT = "edit";
export const PAGE_OFFER_USER_CONFIG_APPROVE = "approve";

export const ENDPOINT_OFFER = "/offer";
export const ENDPOINT_OFFER_USER_CONFIG = "/user-offer-config-approve";
export const ENDPOINT_CUSTOM_TERMS_AND_CONDITIONS =
  "/custom-terms-and-condition";
export const ENDPOINT_USER_OFFER_CONFIG = "/user-config";
export const ENDPOINT_OFFER_FILTER_OPTIONS = "/offer/offer-filter-options";
export const ENDPOINT_OFFER_ADVANTAGE = "/offer/advantage";
export const ENDPOINT_OFFER_CATEGORY = "/offer/category";
export const ENDPOINT_OFFER_PRODUCT = "/offer/product";
export const ENDPOINT_OFFER_COUNTRY = "/offer/country";
export const ENDPOINT_OFFER_CONNECTION_OFFER = "/offer-connection-offer";
export const ENDPOINT_OFFER_FILE = (offerId: number) =>
  "/offer/" + offerId + "/file-uploader";
export const ENDPOINT_OFFER_FILE_FOR_PROPS = (offerId: number) => (
  propsName: string
) => "/offer/" + offerId + "/file-uploader-for-props/" + propsName;
export const ENDPOINT_USER_OFFER_CONFIG_FILE_FOR_PROPS = (
  offerId: number,
  userConfigId: string
) => (propsName: string) =>
  "/offer/" +
  offerId +
  "/user-config/" +
  userConfigId +
  "/file-uploader-for-props/" +
  propsName;

export const REDUX_OFFER_OFFERS = "REDUX_OFFER_OFFERS";
export const REDUX_OFFERLAST_PAGE = "REDUX_OFFER_OFFERS_LAST_PAGE";
export const REDUX_OFFER_OFFERS_LIGHT = "REDUX_OFFER_OFFERS_LIGHT";
export const REDUX_OFFER_OFFER = "REDUX_OFFER_OFFER";
export const REDUX_SUBMIT_OFFER_FROM_HEADER = "REDUX_SUBMIT_OFFER_FROM_HEADER";
export const REDUX_OFFER_CONNECTION_OFFER = "REDUX_OFFER_CONNECTION_OFFER";
export const REDUX_OFFER_PREVIEW_IFRAME_SETTING =
  "REDUX_OFFER_PREVIEW_IFRAME_SETTING";
export const REDUX_OFFER_EDIT_FORM_IFRAME_SETTING_DIALOG =
  "REDUX_OFFER_EDIT_FORM_IFRAME_SETTING_DIALOG";
export const REDUX_OFFER_ADVANTAGE = "REDUX_OFFER_ADVANTAGE";
export const REDUX_OFFER_OPEN_CREATE_NEW = "REDUX_OFFER_OPEN_CREATE_NEW";
export const REDUX_OFFER_OPEN_CREATE_NEW_CASCADE =
  "REDUX_OFFER_OPEN_CREATE_NEW_CASCADE";
export const REDUX_OFFER_PAGE = "REDUX_OFFER_PAGE";
export const REDUX_OFFER_PAGE_SIZE = "REDUX_OFFER_PAGE_SIZE";
export const REDUX_OFFER_STATS = "REDUX_OFFER_STATS";
export const REDUX_OFFER_FILTER_AVAILABLE_COUNTRIES =
  "REDUX_OFFER_FILTER_AVAILABLE_COUNTRIES";
export const REDUX_OFFER_FILTER_AVAILABLE_CATEGORIES =
  "REDUX_OFFER_FILTER_AVAILABLE_CATEGORIES";
export const REDUX_OFFER_FILTER_AVAILABLE_PRODUCTS =
  "REDUX_OFFER_FILTER_AVAILABLE_PRODUCTS";

export const REDUX_OFFER_CATEGORIES = "REDUX_OFFER_CATEGORIES";
export const REDUX_OFFER_PRODUCTS = "REDUX_OFFER_PRODUCTS";
export const REDUX_OFFER_COUNTRIES = "REDUX_OFFER_COUNTRIES";

export const REDUX_OFFER_USER_CONFIG_APPROVE =
  "REDUX_OFFER_USER_CONFIG_APPROVE";
export const REDUX_OFFER_USER_CONFIG_APPROVE_PAGE =
  "REDUX_OFFER_USER_CONFIG_APPROVE_PAGE";
export const REDUX_OFFER_USER_CONFIG_APPROVE_LAST_PAGE =
  "REDUX_OFFER_USER_CONFIG_APPROVE_LAST_PAGE";
export const REDUX_OFFER_USER_CONFIG_APPROVE_PAGE_SIZE =
  "REDUX_OFFER_USER_CONFIG_APPROVE_PAGE_SIZE";
export const REDUX_OFFER_USER_CONFIG_OPEN_APPROVE =
  "REDUX_OFFER_USER_CONFIG_OPEN_APPROVE";

export const REDUX_OFFER_USER_OFFER_CONFIGS = "REDUX_OFFER_USER_OFFER_CONFIG";
export const REDUX_OFFER_CUSTOM_TERMS_AND_CONDITIONS =
  "REDUX_OFFER_CUSTOM_TERMS_AND_CONDITIONS";
export const REDUX_OFFER_CUSTOM_TERMS_AND_CONDITION_DIALOG =
  "REDUX_OFFER_CUSTOM_TERMS_AND_CONDITION_DIALOG";

export const REDUX_OFFER_FILTER_SELECTED_COUNTRIES =
  "REDUX_OFFER_FILTER_SELECTED_COUNTRIES";
export const REDUX_OFFER_FILTER_SELECTED_CATEGORIES =
  "REDUX_OFFER_FILTER_SELECTED_CATEGORIES";
export const REDUX_OFFER_FILTER_SELECTED_PRODUCTS =
  "REDUX_OFFER_FILTER_SELECTED_PRODUCTS";
export const REDUX_OFFER_FILTER_SEARCH = "REDUX_OFFER_FILTER_SEARCH";
