import { ReduxAction } from "../../type";
import {
  DELETE_ALL_ITEMS,
  PUSH_ITEM_OR_UPDATE,
  PUSH_ITEM_OR_UPDATE_LIST,
  RESET,
  SET,
  SET_ITEM_PROPERTY,
  SET_PAGE,
  SET_PAGE_SIZE,
} from "../../constants";
import { historyPush } from "../../../core/history";
import { HistoryLocation } from "../../../core/history/type";

type ReduxActionOperationApiArray =
  | typeof SET
  | typeof DELETE_ALL_ITEMS
  | typeof PUSH_ITEM_OR_UPDATE
  | typeof SET_ITEM_PROPERTY
  | typeof PUSH_ITEM_OR_UPDATE_LIST
  | typeof SET_PAGE_SIZE
  | typeof SET_PAGE
  | typeof RESET;

interface Payload<T> {
  items: T[];
  page: number;
  pageSize: number;
  lastPage: number;
}

export interface ReduxApiArrayState<T> {
  items: T[];
  page: number;
  pageSize: number;
  lastPage: number;
}
export interface ReduxActionApiArraySetPage extends ReduxAction {
  operation: typeof SET_PAGE | typeof SET_PAGE_SIZE;
  payload: number;
}

export const setPageToUrl = (page: number) => {
  historyPush<any>((historyLocation: HistoryLocation<any>) => {
    return {
      ...historyLocation,
      historyLocation,
      search: {
        ...historyLocation.search,
        page,
      },
    };
  });
};

export const setPageSizeToUrl = (pageSize: number) => {
  historyPush<any>((historyLocation: HistoryLocation<any>) => {
    return {
      ...historyLocation,
      historyLocation,
      search: {
        ...historyLocation.search,
        pageSize,
      },
    };
  });
};

export const actionReducerApiArraySetPage = (
  type: string,
  payload: number
): ReduxActionApiArraySetPage => ({
  type,
  operation: SET_PAGE,
  payload,
});

export const actionReducerApiArraySetPageSize = (
  type: string,
  payload: number
): ReduxActionApiArraySetPage => ({
  type,
  operation: SET_PAGE_SIZE,
  payload,
});

export interface ReduxActionApiArrayBasic<T> extends ReduxAction {
  operation: typeof SET | typeof RESET;
  payload: Payload<T>;
}

export const actionReducerApiArraySet = <T>(
  type: string,
  payload: Payload<T>
): ReduxActionApiArrayBasic<T> => ({
  type,
  operation: SET,
  payload,
});

export interface ReduxActionApiArrayDeleteItem<T> extends ReduxAction {
  operation: typeof DELETE_ALL_ITEMS;
  propertyId?: string;
  payload: Payload<T>;
}

export const actionReducerApiArrayDelete = <T>(
  type: string,
  idOrItemToDelete: any,
  propertyId?: string
): ReduxActionApiArrayDeleteItem<T> => {
  console.log("sss", {
    type,
    operation: DELETE_ALL_ITEMS,
    propertyId,
    payload: idOrItemToDelete,
  });
  return {
    type,
    operation: DELETE_ALL_ITEMS,
    propertyId,
    payload: idOrItemToDelete,
  };
};

export interface ReduxActionApiArrayPush extends ReduxAction {
  operation: typeof PUSH_ITEM_OR_UPDATE;
  propertyId?: string;
  payload: any;
}

export const actionReducerApiArrayPushOrUpdate = <T>(
  type: string,
  payload: T,
  propertyId?: string
): ReduxActionApiArrayPush => ({
  type,
  operation: PUSH_ITEM_OR_UPDATE,
  propertyId,
  payload,
});

export interface ReduxActionApiArrayPushList<T> extends ReduxAction {
  operation: typeof PUSH_ITEM_OR_UPDATE_LIST;
  propertyId?: string;
  payload: Payload<T>;
}

export const actionReducerApiArrayPushOrUpdateList = <T>(
  type: string,
  payload: any,
  propertyId?: string
): ReduxActionApiArrayPushList<T> => ({
  type,
  operation: PUSH_ITEM_OR_UPDATE_LIST,
  propertyId,
  payload,
});

export interface ReduxActionApiArraySetItemProperty<T> extends ReduxAction {
  operation: typeof SET_ITEM_PROPERTY;
  propertyName: string;
  propertyId: string;
  idToUpdate: string | number;
  payload: Payload<T>;
}

export const actionReducerApiArraySetItemProperty = <T>(
  type: string,
  idToUpdate: string | number,
  payload: any,
  propertyName: string,
  propertyId: string = "id"
): ReduxActionApiArraySetItemProperty<T> => ({
  type,
  operation: SET_ITEM_PROPERTY,
  propertyName,
  idToUpdate,
  propertyId,
  payload,
});

export type ReduxActionApiArray<T> =
  | ReduxActionApiArrayBasic<T>
  | ReduxActionApiArrayPush
  | ReduxActionApiArrayPushList<T>
  | ReduxActionApiArraySetItemProperty<T>
  | ReduxActionApiArrayDeleteItem<T>;

export const createReducerApiArray = <T>(
  actionType: string,
  defaultState: ReduxApiArrayState<T> = {
    items: [],
    page: 1,
    pageSize: 25,
    lastPage: 1,
  }
) => (
  state = defaultState,
  action: ReduxActionApiArray<T>
): ReduxApiArrayState<T> => {
  if (action.type === actionType) {
    const actions: Record<
      ReduxActionOperationApiArray,
      () => ReduxApiArrayState<T>
    > = {
      [SET]: () => {
        if (action.payload.items === undefined) {
          throw new Error(
            `Can not set undefined to reducer. Reducer: ${actionType}, operation: ${SET}`
          );
        }
        return action.payload;
      },
      [PUSH_ITEM_OR_UPDATE_LIST]: () => {
        const propertyId = "propertyId" in action ? action.propertyId : "";
        const itemsToUpdate: any[] = action.payload.items;
        let items: T[];
        if (propertyId) {
          //add or update by key
          items = itemsToUpdate.reduce((all, itemToUpdate) => {
            if (
              all.some(
                (item: any) => item?.[propertyId] === itemToUpdate?.[propertyId]
              )
            ) {
              //Update
              return all.map((item: any) =>
                item?.[propertyId] === itemToUpdate?.[propertyId]
                  ? itemToUpdate
                  : item
              );
            }
            return [itemToUpdate, ...all];
          }, state);
        } else {
          items = itemsToUpdate.reduce((all, itemToUpdate) => {
            //add or update by key
            if (all.some((item: any) => item === itemToUpdate)) {
              //Update
              return all.map((item: any) =>
                item === itemToUpdate ? itemToUpdate : item
              );
            }
            //push
            return [itemToUpdate, ...all];
          }, state);
        }
        return {
          ...state,
          items,
        };
      },
      [SET_PAGE_SIZE]: () => {
        return { pageSize: action.payload, page: 1, items: [], lastPage: 1 };
      },
      [SET_PAGE]: () => {
        return { ...state, page: action.payload, items: [] };
      },
      [PUSH_ITEM_OR_UPDATE]: () => {
        const propertyId = "propertyId" in action ? action.propertyId : "";
        if (propertyId) {
          //add or update by key
          if (
            state.items.some(
              (item: any) => item?.[propertyId] === action.payload?.[propertyId]
            )
          ) {
            //Update
            return {
              ...state,
              items: state.items.map((item: any) =>
                item?.[propertyId] === action.payload?.[propertyId]
                  ? action.payload
                  : item
              ),
            };
          }
          //push
          return { ...state, items: [action.payload, ...state.items] };
        } else {
          //add or update by key
          if (state.items.some((item: any) => item === action.payload)) {
            //Update
            return {
              ...state,
              items: state.items.map((item: any) =>
                item === action.payload ? action.payload : item
              ),
            };
          }
          //push
          return { ...state, items: [action.payload, ...state.items] };
        }
      },
      [SET_ITEM_PROPERTY]: () => {
        const propertyId =
          "propertyId" in action
            ? (action as ReduxActionApiArraySetItemProperty<T>).propertyId
            : "";
        const idToUpdate =
          "idToUpdate" in action
            ? (action as ReduxActionApiArraySetItemProperty<T>).idToUpdate
            : "";
        const propertyName =
          "propertyName" in action ? action.propertyName : "";
        return {
          ...state,
          items: state.items.map((item: any) => {
            if (item?.[propertyId] === idToUpdate) {
              //update
              return {
                ...item,
                [propertyName]: action.payload.items,
              };
            }
            return item;
          }),
        };
      },
      [DELETE_ALL_ITEMS]: () => {
        const propertyId = "propertyId" in action ? action.propertyId : "";
        let items: T[];
        if (propertyId) {
          items = state.items.filter(
            (item: any) => item?.[propertyId] !== action.payload
          );
        } else {
          items = state.items.filter((item: any) => item !== action.payload);
        }
        return { ...state, items };
      },
      [RESET]: () => defaultState,
    };
    if (actions[action.operation]) {
      return actions[action.operation]();
    } else {
      throw new Error(
        `Unknown operation ${action.operation} for reducer: ${action.type}`
      );
    }
  }
  return state;
};
