import { combineReducers } from "redux";
import {
  REDUX_IFRAME_ALTERNATIVE_OFFER_LAST_NAME,
  REDUX_IFRAME_ALTERNATIVE_OFFER_NEXT,
} from "../../constant";
import { createReducerString } from "../../../../redux/tools/createReducer/createReducerString";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";
import { Offer } from "../../type/Offer";

export const alternativeOfferReducer = combineReducers({
  oldOfferName: createReducerString(
    REDUX_IFRAME_ALTERNATIVE_OFFER_LAST_NAME,
    ""
  ),
  nextOffer: createReducerObject<Offer | null>(
    REDUX_IFRAME_ALTERNATIVE_OFFER_NEXT,
    null
  ),
});
