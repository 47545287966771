import { ENDPOINT_FORM, REDUX_FORM_FORMS } from "../../../constants/form";
import { get, basicHandleError } from "../../../../../core/api/utils/fetchApi";
import { AppDispatchType } from "../../../../../redux/store";
import {
  actionLock,
  actionUnlock,
} from "../../../../../core/layouts/store/actions/lockable.actions";
import { LockKey } from "../../../../../core/layouts/utils/useIsLocked";
import { Form } from "../../../../formsIframe/type/Form";
import { actionReducerArraySet } from "../../../../../redux/tools/createReducer/createReducerArray";

export const getLockKeyActionGetForms = (): LockKey => {
  return "gets" + ENDPOINT_FORM;
};

export const getFormsAction = () => async (dispatch: AppDispatchType) => {
  dispatch(actionLock(getLockKeyActionGetForms()));
  const res = await get(ENDPOINT_FORM);
  dispatch(actionUnlock(getLockKeyActionGetForms()));
  if (res.ok) {
    const forms = res.data.map((item: any) => new Form(item));
    dispatch(actionReducerArraySet(REDUX_FORM_FORMS, forms));
  } else {
    dispatch(basicHandleError(res));
  }
};
