export const ENDPOINT_LOGIN = "/auth/login";
export const ENDPOINT_REGISTER = "/auth/register";
export const ENDPOINT_RESEND_PASSWORD = "/auth/resend-password";
export const ENDPOINT_USER_NEW_API_TOKEN = "/user/new-api-token";
export const ENDPOINT_RESET_PASSWORD = "/auth/reset-password";
export const ENDPOINT_IS_VALID_RESET_PASSWORD = "/auth/reset-password";
export const ENDPOINT_PROFILE = "/auth/profile";
export const ENDPOINT_USER = "/user";
export const ENDPOINT_SEND_NEW_EMAIL_VERIFY_TOKEN =
  "/auth/send-new-email-verify-token";

export const URL_ERROR_500 = "/auth/error-500";
export const URL_ERROR_404 = "/auth/error-404";
export const URL_PROFILE = "/setting/profile";

export const REDUX_AUTH_LOGGED_USER = "REDUX_AUTH_LOGGED_USER";

export const PAGE_LOGIN = "login";
export const PAGE_REGISTER = "register";
export const PAGE_RESEND_PASSWORD = "resend-password";
export const PAGE_RESET_PASSWORD = "reset-password";
export const PAGE_VERIFY_EMAIL = "verify-email";

export const ROLE_PARTNER = "partner";
export const ROLE_ADVERTISER = "advertiser";

export const REDUX_USER_PICKER_POSITION = "REDUX_USER_PICKER_POSITION";
export const REDUX_USER_USERS = "REDUX_USER_USERS";
export const REDUX_USER_PICKER_NAME = "REDUX_USER_PICKER_SELECTED_ID";
export const REDUX_USER_PICKER_USERS = "REDUX_USER_PICKER_USERS";
