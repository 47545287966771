import { getUrlAuth, getUrlProfile } from "../utils/getUrlAuth";
import {
  Page404,
  Page500,
  Profile,
  ResendPassword,
  ResetPassword,
  SignIn,
  SignUp,
  VerifyEmail,
} from "../container";
import {
  PAGE_LOGIN,
  PAGE_REGISTER,
  PAGE_RESEND_PASSWORD,
  PAGE_RESET_PASSWORD,
  PAGE_VERIFY_EMAIL,
  URL_ERROR_404,
  URL_ERROR_500,
} from "../constants";
import { RouteType } from "../../../types/routes";

export const authRoutes: RouteType = {
  id: "Auth",
  path: "/auth",
  children: [
    {
      path: getUrlAuth(PAGE_LOGIN),
      component: SignIn,
    },
    {
      path: getUrlAuth(PAGE_REGISTER),
      component: SignUp,
    },
    {
      path: getUrlAuth(PAGE_RESET_PASSWORD),
      component: ResetPassword,
    },
    {
      path: getUrlAuth(PAGE_RESEND_PASSWORD),
      component: ResendPassword,
    },
    {
      path: URL_ERROR_404,
      component: Page404,
    },
    {
      path: URL_ERROR_500,
      component: Page500,
    },
    {
      path: getUrlAuth(PAGE_VERIFY_EMAIL),
      component: VerifyEmail,
    },
  ],
  component: null,
};

export const profileRoutes: RouteType = {
  id: "Profile",
  path: getUrlProfile(),
  component: Profile,
  children: null,
};
