import { combineReducers } from "redux";
import { createReducerApiArray } from "../../../../redux/tools/createReducer/createReducerApiArray";
import { REDUX_REPORT_CASCADE_ITEM_REPORT_DIALOG, REDUX_REPORT_PERFORMANCE } from "../../constants";
import { Performance } from "../../type/Performance";
import { createReducerDialog } from "../../../../redux/tools/createReducer/createReducerDialog";
import { Offer } from "../../../formsIframe/type/Offer";

export const reportsReducer = combineReducers({
  performance: createReducerApiArray<Performance>(REDUX_REPORT_PERFORMANCE),

  //cascade
  cascadeItemReportDialog: createReducerDialog<Offer>(REDUX_REPORT_CASCADE_ITEM_REPORT_DIALOG),
});
