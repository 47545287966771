import styled from "styled-components";
import * as React from "react";
import { ReactComponent as RightArrowSwg } from "../../../../svg/right-arrow.svg";

const useGetCash = (money: number) => {
  let c1000 = 0;
  let c500 = 0;
  while (money > 0) {
    if (money >= 1000) {
      money -= 1000;
      c1000++;
    } else if (money >= 500) {
      money -= 500;
      c500++;
    }
  }
  return {
    c500,
    c1000,
  };
};

interface RootProps {
  $marginTop: number;
}

const Root = styled.div<RootProps>`
  height: 225px;
  margin-top: ${({ $marginTop }) => $marginTop}px;
  display: flex;
  justify-content: center;
  position: relative;
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    transform-origin: top;
    transform: scale(0.78);
    height: 164px;
  }
`;

interface MoneyProps {
  left: number;
  top: number;
  angle: number;
}
const MONEY_WIDTH = 116;

const Money = styled.img<MoneyProps>`
  position: absolute;
  left: ${({ left }) => left - 25}px;
  top: ${({ top }) => top}px;
  transform: rotateY(0deg) rotate(${({ angle }) => -angle}deg);
  width: ${MONEY_WIDTH}px;
  background: transparent;
`;

const MoneyContainer = styled.div`
  position: relative;
`;

const RightArrowSwgStyled = styled(RightArrowSwg)`
  fill: ${(props) => props.theme.palette.primary.main};
  transform: rotate(90deg);
  width: 87px;
  margin-top: -20px;
  position: absolute;
`;

interface LoadCzMoneyImageProps {
  money: number;
  marginTop?: number;
  hideArrow?: boolean;
}

export const LoadCzMoneyImage = ({
  money,
  hideArrow,
  marginTop = 0,
}: LoadCzMoneyImageProps) => {
  const cash = useGetCash(money);
  const radius = 120;
  const angle = 180 / 19;
  return (
    <Root $marginTop={marginTop}>
      {!hideArrow && <RightArrowSwgStyled />}

      <MoneyContainer>
        {[...Array(cash.c1000)].map((item, index) => (
          <Money
            key={`c1000${index}`}
            src={"/static/img/forms/money/1000_form.jpg"}
            top={radius * Math.sin((Math.PI * 2 * angle * index) / 360)}
            left={
              -radius * Math.cos((Math.PI * 2 * angle * index) / 360) +
              25 -
              MONEY_WIDTH / 2
            }
            angle={angle * index}
          />
        ))}
        {[...Array(cash.c500)].map((item, index) => (
          <Money
            key={`c500${index}`}
            src={"/static/img/forms/money/500_form.jpg"}
            top={
              radius *
              Math.sin((Math.PI * 2 * angle * (index + cash.c1000)) / 360)
            }
            left={
              -radius *
                Math.cos((Math.PI * 2 * angle * (index + cash.c1000)) / 360) +
              25 -
              MONEY_WIDTH / 2
            }
            angle={angle * (index + cash.c1000)}
          />
        ))}
      </MoneyContainer>
    </Root>
  );
};
