import { FC } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

interface LoadingProps {
  $position?: "relative" | "absolute";
}

const Root = styled("div")<LoadingProps>`
  position: ${(props) => props.$position};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loading: FC<LoadingProps> = ({ $position = "relative" }) => {
  return (
    <Root $position={$position}>
      <CircularProgress color={"primary"} />
    </Root>
  );
};
