import {
  PAGE_LOGIN,
  PAGE_REGISTER,
  PAGE_RESEND_PASSWORD,
  PAGE_RESET_PASSWORD,
  PAGE_VERIFY_EMAIL,
  URL_PROFILE,
} from "../constants";

type AuthPages =
  | typeof PAGE_LOGIN
  | typeof PAGE_REGISTER
  | typeof PAGE_RESEND_PASSWORD
  | typeof PAGE_VERIFY_EMAIL
  | typeof PAGE_RESET_PASSWORD;

export const getUrlAuth = (page: AuthPages) => {
  return "/auth/" + page;
};

export const getUrlProfile = () => {
  return URL_PROFILE;
};
