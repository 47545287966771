import { FC } from "react";
import { MyDialog } from "./MyDialog";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../redux/store";
import { actionReducerObjectSet } from "../../redux/tools/createReducer/createReducerObject";
import { REDUX_LAYOUT_CONFIRM_DIALOG } from "../constants";

export interface MyConfirmDialogProps {
  open: boolean;
  titleTranslateId?: string;
  textTranslateId?: string;
  text?: string;
  title?: string;
  hideCloseButton?: boolean;
  onOk?: () => void;
  okTranslateId?: string;
}

export const MyConfirmDialog: FC = () => {
  const confirmDialog = useSelector(
    (state: AppStateType) => state.layout.confirmDialog
  );
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(actionReducerObjectSet(REDUX_LAYOUT_CONFIRM_DIALOG, null));
  };
  const onOk = () => {
    if (confirmDialog?.onOk) {
      confirmDialog.onOk();
    }
    onClose();
    dispatch(actionReducerObjectSet(REDUX_LAYOUT_CONFIRM_DIALOG, null));
  };
  return (
    <MyDialog
      {...(confirmDialog || { open: false })}
      onOk={confirmDialog?.onOk ? onOk : undefined}
      onClose={onClose}
    />
  );
};
