import React, { FC, ReactNode } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Translate } from "../translate/Translate";
import { DialogProps, Theme, useMediaQuery } from "@material-ui/core";

interface MyDialogProps {
  open: boolean;
  onClose: () => void;
  titleTranslateId?: string;
  textTranslateId?: string;
  text?: ReactNode;
  title?: ReactNode;
  hideCloseButton?: boolean;
  onOk?: () => void;
  okTranslateId?: string;
  disabledOk?: boolean;
  cancelText?: string;
}

export const MyDialog: FC<DialogProps & MyDialogProps> = ({
  okTranslateId,
  open,
  onOk,
  titleTranslateId,
  textTranslateId,
  hideCloseButton,
  onClose,
  children,
  text,
  title,
  disabledOk,
  cancelText = "core.cancel",
  ...dialogProps
}) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"sm"}
      fullWidth={true}
      fullScreen={smDown}
      {...dialogProps}
    >
      {(title || titleTranslateId) && (
        <DialogTitle>
          {!titleTranslateId ? title : <Translate id={titleTranslateId} />}
        </DialogTitle>
      )}
      <DialogContent>
        {(text || textTranslateId) && (
          <DialogContentText style={{ whiteSpace: "pre-line" }}>
            {!textTranslateId ? text : <Translate id={textTranslateId} />}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      {!(hideCloseButton && !onOk) && (
        <DialogActions>
          {!hideCloseButton && (
            <Button onClick={onClose} color="primary">
              <Translate id={cancelText} />
            </Button>
          )}
          {onOk && (
            <Button onClick={onOk} color="primary" disabled={disabledOk}>
              <Translate id={okTranslateId ? okTranslateId : "core.ok"} />
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
