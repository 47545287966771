import * as React from "react";
import { FC } from "react";
import { Typography, useTheme } from "@material-ui/core";
import { IframeInputWrapper } from "./IframeInputWrapper";
import { IframeFiledProps } from "../IframeInputsList";
import { Variant } from "@material-ui/core/styles/createTypography";
import { addThemeColorsToText } from "../../../utils/addThemeColorsToText";
import { Flex } from "../../../../../core/components/layout/Flex";
import { addValueFromFormToText } from "../../../utils/addValueFromFormToText";
import { addLinkToLinkOffer } from "../../../utils/addLinkToLinkOffer";

interface IframeTextProps {
  variant: Variant;
  formValues: Record<string, any>;
}

export const IframeTitle: FC<IframeFiledProps & IframeTextProps> = ({
  formField,
  variant,
  formValues,
  userId,
}) => {
  const theme = useTheme();
  return (
    <IframeInputWrapper formField={formField}>
      <Flex
        justifyContent={"center"}
        fullWidth={true}
        marginTop={"xxl"}
        marginBottom={"medium"}
      >
        <Typography variant={variant} align={"center"}>
          <span
            dangerouslySetInnerHTML={{
              __html: addLinkToLinkOffer(
                addValueFromFormToText(
                  addThemeColorsToText(
                    formField.visibleDescription || "",
                    theme
                  ),
                  formValues
                ),
                userId
              ),
            }}
          />
        </Typography>
      </Flex>
    </IframeInputWrapper>
  );
};
