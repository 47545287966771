import { API_ROOT } from "../constants";
import { actionSnackbarShow } from "../../layouts/store/actions/snackbar.actions";
import { AppDispatchType } from "../../../redux/store";

export interface RequestResponse {
  code?: number;
  data: any;
  metadata?: any;
  ok: boolean;
  error: any;
}

export const post = async (
  url: string,
  body: any,
  throwErrorMessage: boolean = false
): Promise<RequestResponse> => {
  let code = undefined;
  let ok = false;
  let dataRes = undefined;
  let metadataRes = undefined;
  let error = null;
  await fetch(API_ROOT + url, {
    body: JSON.stringify(body),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      code = response.status;
      ok = response.ok;
      return response.json();
    })
    .then((responseJson) => {
      const { data, ...metadata } = responseJson;
      dataRes = data;
      metadataRes = metadata;
    })
    .catch((e) => {
      error = e;
      ok = false;
      if (throwErrorMessage) {
        //TODO try find api message
        throw new Error(error);
      }
    });
  return {
    code,
    metadata: metadataRes,
    data: dataRes,
    ok,
    error,
  };
};

export const put = async (url: string, body: any): Promise<RequestResponse> => {
  let code = undefined;
  let ok = false;
  let dataRes = undefined;
  let metadataRes = undefined;
  let error = null;
  await fetch(API_ROOT + url, {
    body: JSON.stringify(body),
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      code = response.status;
      ok = response.ok;
      return response.json();
    })
    .then((responseJson) => {
      const { data, ...metadata } = responseJson;
      dataRes = data;
      metadataRes = metadata;
    })
    .catch((e) => {
      error = e;
      ok = false;
    });
  return {
    code,
    metadata: metadataRes,
    data: dataRes,
    ok,
    error,
  };
};

export const patch = async (
  url: string,
  body: any
): Promise<RequestResponse> => {
  let code = undefined;
  let ok = false;
  let dataRes = undefined;
  let metadataRes = undefined;
  let error = null;
  await fetch(API_ROOT + url, {
    body: JSON.stringify(body),
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      code = response.status;
      ok = response.ok;
      return response.json();
    })
    .then((responseJson) => {
      const { data, ...metadata } = responseJson;
      dataRes = data;
      metadataRes = metadata;
    })
    .catch((e) => {
      error = e;
      ok = false;
    });
  return {
    code,
    metadata: metadataRes,
    data: dataRes,
    ok,
    error,
  };
};

export const del = async (
  url: string,
  body?: any
): Promise<RequestResponse> => {
  let code = undefined;
  let ok = false;
  let dataRes = undefined;
  let metadataRes = undefined;
  let error = null;
  await fetch(API_ROOT + url, {
    body: body && JSON.stringify(body),
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      code = response.status;
      ok = response.ok;
      return response.json();
    })
    .then((responseJson) => {
      const { data, ...metadata } = responseJson;
      dataRes = data;
      metadataRes = metadata;
    })
    .catch((e) => {
      error = e;
      ok = false;
    });
  return {
    code,
    metadata: metadataRes,
    data: dataRes,
    ok,
    error,
  };
};

export type GetUrlParameters = Record<
  string,
  | string
  | number
  | boolean
  | null
  | (string | number | boolean | null)[]
  | undefined
>;

export const get = async (
  url: string,
  urlParameters: GetUrlParameters = {}
): Promise<RequestResponse> => {
  let code = undefined;
  let dataRes = undefined;
  let metadataRes = undefined;
  let ok = false;
  let error = null;
  let parameterSpace = url.includes("?") ? "&" : "?";
  const setUpString = (item: string | number) => {
    return encodeURIComponent(String(item));
  };
  const parameters = Object.keys(urlParameters).reduce(
    (all: string, current: string) => {
      const item = urlParameters[current];
      if (item === undefined) {
        return all;
      }
      const ret =
        all +
        parameterSpace +
        setUpString(current) +
        "=" +
        (item instanceof Array
          ? "[" + item.map((item) => setUpString(String(item))).join(",") + "]"
          : setUpString(String(item)));
      parameterSpace = "&";
      return ret;
    },
    ""
  );
  await fetch((url.startsWith("http") ? url : API_ROOT + url) + parameters, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      code = response.status;
      ok = response.ok;
      return response.json();
    })
    .then((responseJson) => {
      const { data, ...metadata } = responseJson;
      dataRes = data;
      metadataRes = metadata;
    })
    .catch((e) => {
      error = e;
      ok = false;
    });
  return {
    metadata: metadataRes,
    data: dataRes,
    code,
    ok,
    error,
  };
};

export const basicHandleError = (res: RequestResponse) => (
  dispatch: AppDispatchType
) => {
  switch (res.code) {
    case 401:
      dispatch(
        actionSnackbarShow({
          translateId: "core.error401YouNeedLoginIn",
          variant: "error",
        })
      );
      break;
    case 403:
      dispatch(
        actionSnackbarShow({
          translateId: "core.error403YouDoNotHavePermissions",
          variant: "error",
        })
      );
      break;
    case 404:
      dispatch(
        actionSnackbarShow({
          translateId: "core.error404RequestError",
          variant: "error",
        })
      );
      break;
    case 500:
      dispatch(
        actionSnackbarShow({ translateId: "core.error500", variant: "error" })
      );
      break;
    case null:
      dispatch(actionSnackbarShow({ message: res.error, variant: "error" }));
      break;
    default:
      if (res?.data?.errors) {
        res.data.errors.forEach((error: string) => {
          dispatch(actionSnackbarShow({ message: error, variant: "error" }));
        });
      } else {
        const message = res?.error?.toString();
        dispatch(
          actionSnackbarShow({
            message,
            translateId: message ? undefined : "core.error500",
            variant: "error",
          })
        );
      }
  }
};
