import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { AppStateType } from "../../../../../redux/store";
import { actionReducerObjectSet } from "../../../../../redux/tools/createReducer/createReducerObject";
import { MyDialog } from "../../../../../core/components/MyDialog";
import { Translate } from "../../../../../core/translate/Translate";
import { MyFormik } from "../../../../../core/components/inputs/MyFormik";
import { FINAL_PAGE_LINK } from "../../../../../core/constants/modules";
import { actionReducerDialogClose } from "../../../../../redux/tools/createReducer/createReducerDialog";
import {
  IFRAME_PHONE_NAME,
  REDUX_IFRAME_SMS_VERIFY_CHANGE_PHONE,
  REDUX_IFRAME_VALUES,
} from "../../../constant";
import { MyTextFieldFormik } from "../../../../../core/components/inputs/inputHelp/formik/MyTextFieldFormik";
import styled from "styled-components/macro";
import { actionLogSmsEvent } from "../../../store/action/logSmsEvent.action";

const ButtonStyled = styled(Button)`
  font-size: 15px;
`;

export const IframeChangePhoneDialog: FC = () => {
  const dispatch = useDispatch();
  const formValues = useSelector(
    (state: AppStateType) => state.iframe.formValues
  );
  const dialog = useSelector(
    (state: AppStateType) => state.iframe.finalPage.changeSmsVerify
  );
  const onClose = () => {
    dispatch(actionReducerDialogClose(REDUX_IFRAME_SMS_VERIFY_CHANGE_PHONE));
  };
  const accessToken =
    useSelector((state: AppStateType) => state.iframe.loader.accessToken) || "";

  return (
    <MyDialog
      maxWidth={"sm"}
      fullWidth={true}
      open={dialog.open}
      onClose={onClose}
      titleTranslateId={"iframe.changePhoneNumber"}
      hideCloseButton={true}
    >
      <MyFormik
        initialValues={{ phone: dialog.itemToEdit || "" }}
        module={FINAL_PAGE_LINK}
        onSubmit={(values) => {
          dispatch(actionLogSmsEvent(accessToken, "number-change"));
          dispatch(
            actionReducerObjectSet(REDUX_IFRAME_VALUES, {
              ...formValues,
              [IFRAME_PHONE_NAME]: values.phone,
            })
          );
          onClose();
        }}
      >
        <DialogContent>
          <MyTextFieldFormik
            name={"phone"}
            translateId={"iframe.newPhone"}
            inputMode={"numeric"}
          />
        </DialogContent>
        <DialogActions>
          <ButtonStyled onClick={onClose} color="default">
            <Translate id={"core.cancel"} />
          </ButtonStyled>
          <ButtonStyled type={"submit"} color={"primary"} variant={"contained"}>
            <Translate id={"core.save"} />
          </ButtonStyled>
        </DialogActions>
      </MyFormik>
    </MyDialog>
  );
};
