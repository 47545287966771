// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// redux
export const REDUX_LOCKABLE_LOCKED = "REDUX_LOCKABLE_LOCKED";
export const REDUX_LAYOUT_SNACKBARS = "REDUX_LAYOUT_SNACKBARS";
export const REDUX_LAYOUT_CONFIRM_DIALOG = "REDUX_LAYOUT_CONFIRM_DIALOG";
export const REDUX_LAYOUT_EDIT_FILE = "REDUX_LAYOUT_EDIT_FILE";
export const REDUX_LAYOUT_EDIT_FILE_PARENT = "REDUX_LAYOUT_EDIT_FILE_PARENT";
export const REDUX_LAYOUT_HEADER = "REDUX_LAYOUT_HEADER";
export const REDUX_LAYOUT_BREADCRUMBS = "REDUX_LAYOUT_BREADCRUMBS";
