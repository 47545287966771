import { FC } from "react";
import { BaseInitHook } from "../utils/baseInitHook";

interface InitPageProps {
  useInit: BaseInitHook;
}

/**
 * use this to download page data before is download webpack chunk with page.
 * @param useInit
 * @constructor
 */
export const InitPage: FC<InitPageProps> = ({ useInit }) => {
  useInit();
  return null;
};
