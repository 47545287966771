import { Flex } from "../../../../core/components/layout/Flex";
import {
  Button,
  CircularProgress,
  Hidden,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Translate } from "../../../../core/translate/Translate";
import { postIframeContentForm } from "../../utils/postIframeContentForm";
import { putIframeContentForm } from "../../utils/putIframeContentForm";
import { Lock } from "@material-ui/icons";
import * as React from "react";
import {
  FC,
  MouseEventHandler,
  ReactNode,
  useContext,
  useRef,
  useState,
} from "react";
import { RequestResponse } from "../../../../core/api/utils/fetchApi";
import { IconButtonClose } from "../../../../core/components/iconButtons";
import { SnackbarKey, useSnackbar } from "notistack";
import UserOfferConfig from "../../type/UserOfferConfig";
import { FormField } from "../../type/FormField";
import styled from "styled-components";
import { ReactComponent as RightArrowSwg } from "../../svg/right-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import store, { AppStateType } from "../../../../redux/store";
import { ScrollUpContext } from "../../layout/FormsLayout";
import { sendDataToParentPage } from "../../utils/sendDataToParentPage";
import { actionReducerStringSet } from "../../../../redux/tools/createReducer/createReducerString";
import { IFRAME_PAGE_LOADING, REDUX_IFRAME_PAGE } from "../../constant";

interface IframeSubmitButtonProps {
  leadId?: number;
  currentPage: number;
  setCurrentPage: (number: number) => void;
  formData: UserOfferConfig;
  pages: FormField[][];
  formValues: Record<string, any>;
}

interface RightArrowSwgStyledProps {
  $left: boolean;
}

const RightArrowSwgStyled = styled(RightArrowSwg)<RightArrowSwgStyledProps>`
  fill: ${(props) => props.theme.palette.primary.main};
  transform: ${({ $left }) => (!$left ? "rotate(180deg)" : "unset")};
  height: 76px;
  margin: ${(props) => props.theme.spacing(0, 15)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin: ${(props) => props.theme.spacing(0, 10)};
  }
`;

interface IframeDownArrowSwgStyledProps {
  height?: number;
  marginTop?: number;
}

export const IframeDownArrowSwgStyled = styled(
  RightArrowSwg
)<IframeDownArrowSwgStyledProps>`
  fill: ${(props) => props.theme.palette.primary.main};
  transform: rotate(90deg);
  height: ${({ height = 70 }) => height}px;
  margin-top: ${({ marginTop = 0 }) => marginTop}px;
  margin-bottom: 20px;
`;

const SubTitle = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.main};
  margin-top: ${(props) => props.theme.margin("medium")};
  text-align: center;
  font-size: 16px !important;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 14px !important;
    margin-bottom: 30px;
  }
`;

const FlexRoot = styled(Flex)`
  margin-top: 80px;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin-top: 20px;
  }
`;

const ButtonBack = styled(Button)`
  font-size: 17px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    position: absolute;
    left: 0px;
    margin-top: 0;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    position: absolute;
    left: 0px;
    top: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    position: absolute;
    left: 40px;
    top: 20px;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin-bottom: ${({ theme }) => theme.margin("xxl")};
    position: unset;
  }
`;

const ButtonSubmit = styled(Button)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 30px;
    padding: 18px 11px;
  }
`;

const LockStyled = styled(Lock)`
  margin-right: 8px;
`;

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    position: "relative",
  },
  buttonLoading: {
    position: "absolute",
  },
  disabled: {
    background: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    opacity: 0.4,
  },
}));

interface IframeFormButtonProps {
  onClick: MouseEventHandler;
  disabled?: boolean;
  isLoading?: boolean;
  text?: ReactNode;
  underSubmitText?: ReactNode;
}

export const IframeFormButton: FC<IframeFormButtonProps> = ({
  onClick,
  disabled,
  underSubmitText,
  isLoading,
  text,
}) => {
  const classes = useStyles();
  return (
    <Flex flexDirection={"column"} alignItems={"center"}>
      <Hidden smUp={true}>
        <IframeDownArrowSwgStyled />
      </Hidden>
      <Flex flexDirection={"row"} alignItems={"center"}>
        <Hidden smDown={true}>
          <RightArrowSwgStyled $left={true} />
        </Hidden>
        <ButtonSubmit
          className={"form-submit-button"}
          variant={"contained"}
          color={"primary"}
          onClick={onClick}
          classes={{
            disabled: classes.disabled,
            root: classes.buttonRoot,
          }}
          disabled={disabled}
        >
          {isLoading && (
            <CircularProgress
              className={classes.buttonLoading}
              color={"secondary"}
            />
          )}
          <LockStyled />
          {text}
        </ButtonSubmit>
        <Hidden smDown={true}>
          <RightArrowSwgStyled $left={false} />
        </Hidden>
      </Flex>
      {underSubmitText && (
        <SubTitle variant={"subtitle2"}>{underSubmitText}</SubTitle>
      )}
    </Flex>
  );
};

export const IframeSubmitButton: FC<IframeSubmitButtonProps> = ({
  currentPage,
  setCurrentPage,
  leadId,
  formData,
  pages,
  formValues,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentShowedSnackbars = useRef<SnackbarKey[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const errors = useSelector((state: AppStateType) => state.iframe.errors);
  const timer = useSelector((state: AppStateType) => state.iframe.timer);
  const accessToken = useSelector(
    (state: AppStateType) => state.iframe.loader.accessToken
  );

  const handleSubmitResult = (res: RequestResponse) => {
    setIsLoading(false);
    if (res.ok) {
      dispatch(actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_LOADING));
      sendDataToParentPage(
        "lead",
        "submitted",
        store.getState().iframe.canParentPageReceiveLeads
          ? store.getState().iframe.formValues
          : null
      );
    } else {
      currentShowedSnackbars.current.forEach((key) => {
        closeSnackbar(key);
      });
      currentShowedSnackbars.current = [];
      if (res.data?.errors) {
        res.data.errors.forEach((error: string) => {
          const key = enqueueSnackbar(error, {
            variant: "error",
            persist: true,
            action: (key) => (
              <IconButtonClose
                onClick={() => {
                  closeSnackbar(key);
                }}
              />
            ),
          });
          currentShowedSnackbars.current.push(key);
        });
      } else {
        enqueueSnackbar(<Translate id={"form.failToSaveLead"} />, {
          variant: "error",
        });
      }
    }
  };
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { scrollUp } = useContext(ScrollUpContext);

  if (!formData.form) {
    return null;
  }

  return (
    <FlexRoot
      justifyContent={"center"}
      marginLeft={"large"}
      marginRight={"large"}
      marginBottom={"large"}
      position={"relative"}
      flexDirection={!smDown ? "row" : "column-reverse"}
      flexWrap={smDown ? "wrap-reverse" : undefined}
    >
      {currentPage !== 0 ? (
        <Flex
          alignItems={"center"}
          justifyContent={smDown ? undefined : "center"}
        >
          <ButtonBack
            onClick={() => {
              setCurrentPage(currentPage - 1);
              if (scrollUp) {
                scrollUp();
              }
            }}
          >
            <Translate id={"core.back"} />
          </ButtonBack>
        </Flex>
      ) : (
        <Flex />
      )}
      <IframeFormButton
        onClick={() => {
          if (currentPage + 1 === pages.length) {
            setIsLoading(true);
            //submit
            if (typeof leadId !== "number") {
              postIframeContentForm(
                formData,
                { timer, accessToken, ...formValues },
                true
              ).then(handleSubmitResult);
            } else {
              putIframeContentForm(
                leadId,
                formData,
                { timer, accessToken, ...formValues },
                true
              ).then(handleSubmitResult);
            }
          } else {
            sendDataToParentPage(
              "started",
              "next-page",
              formData.canUseReceiveLeads()
                ? {
                    timer,
                    ...formValues,
                  }
                : null
            );
            setCurrentPage(currentPage + 1);
            if (scrollUp) {
              scrollUp();
            }
          }
        }}
        disabled={
          !formData.form.isPageValid(currentPage, formValues) ||
          errors.filter((systemNameWithError) =>
            pages[currentPage].some(
              (formFilled) => formFilled.systemName === systemNameWithError
            )
          ).length !== 0
        }
        isLoading={isLoading}
        text={formData.getGetMoneyText()}
        underSubmitText={formData.getTextUnderSubmit()}
      />
      <Hidden smDown={true}>
        <Flex />
      </Hidden>
    </FlexRoot>
  );
};
