import { Flex } from "../../../../../core/components/layout/Flex";
import * as React from "react";
import UserOfferConfig from "../../../type/UserOfferConfig";
import { FC, useState } from "react";
import { FinalPageInput } from "./FinalPageInput";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../redux/store";
import { PriceString } from "../../../../currency/components/PriceString";
import { Translate } from "../../../../../core/translate/Translate";
import {
  IframeSubtitle,
  StrongPrimary,
} from "../../../container/IframeLoading";
import { BaseIconButton } from "../../../../../core/components/iconButtons";
import { AddCircle } from "@material-ui/icons";
import { Collapse } from "@material-ui/core";
import styled from "styled-components/macro";

interface FinalPageFilledFieldsProps {
  formData: UserOfferConfig;
}

const PlusButton = styled(BaseIconButton)`
  display: inline-block;
`;

const Content = styled(Flex)`
  margin-top: 42px;
  margin-bottom: 0px;
`;

const IframeSubtitle2 = styled(IframeSubtitle)`
  font-size: 20px !important;
  margin-top: 0;
`;

export const FinalPageFilledFields: FC<FinalPageFilledFieldsProps> = ({
  formData,
}) => {
  const [open, setOpen] = useState(false);
  const formValues = useSelector(
    (state: AppStateType) => state.iframe.formValues
  );
  return (
    <>
      <IframeSubtitle2 variant={"subtitle2"} align={"center"}>
        <Translate id={"iframe.finalPageOfferInfoText1"} />{" "}
        <Translate id={"iframe.finalPageOfferInfoText1"} />{" "}
        <strong>
          <Translate id={"iframe.finalPageOfferInfoText2"} />
        </strong>{" "}
        <Translate id={"iframe.finalPageOfferInfoText3"} />{" "}
        <StrongPrimary>
          {(formValues["first_name"] || "") +
            " " +
            (formValues["last_name"] || "")}{" "}
        </StrongPrimary>
        <Translate id={"iframe.finalPageOfferInfoText4"} />
        <PlusButton
          tooltipTranslateId={"iframe.showFiledData"}
          flexProps={{ inLine: true }}
          onClick={() => {
            setOpen(!open);
          }}
          Icon={AddCircle}
        />
      </IframeSubtitle2>
      <Collapse in={open}>
        <Content
          marginLeft={"auto"}
          marginRight={"auto"}
          fullWidth={true}
          maxWidth={"600px"}
          flexWrap={"wrap"}
        >
          <FinalPageInput
            systemName={"cell_phone"}
            value={formValues["cell_phone"]}
            formData={formData}
          />
          <FinalPageInput
            systemName={"email"}
            value={formValues["email"]}
            formData={formData}
          />
          <FinalPageInput
            systemName={"monthly_income"}
            value={PriceString({
              currency: formData.offer?.currency,
              price: formValues["monthly_income"],
            })}
            formData={formData}
          />
          <FinalPageInput
            systemName={"expenses"}
            value={PriceString({
              currency: formData.offer?.currency,
              price: formValues["expenses"],
            })}
            formData={formData}
          />
          <FinalPageInput
            systemName={"street"}
            value={formValues["street"]}
            formData={formData}
          />
          <FinalPageInput
            systemName={"account_number"}
            value={
              (formValues["account_number"] || "") +
              "/" +
              (formValues["bank_code"] || "")
            }
            formData={formData}
          />
        </Content>
      </Collapse>
    </>
  );
};
