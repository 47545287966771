import { RefObject, useEffect, useLayoutEffect, useRef, useState } from "react";
import { sendDataToParentPage } from "../../modules/formsIframe/utils/sendDataToParentPage";

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const useHeight = (elementRef: RefObject<HTMLDivElement>) => {
  const [height, setHeight] = useState(0);
  const setHeightRef = useRef(setHeight);
  setHeightRef.current = setHeight;

  useEffect(() => {
    const interval = setInterval(() => {
      if (elementRef.current) {
        const { height: newH } = elementRef.current.getBoundingClientRect();
        if (newH !== height) {
          setHeight(newH);
          sendDataToParentPage(null, "height-change", newH + "px");
        }
      }
    }, 200);

    return () => clearInterval(interval);
  }, [setHeight, height, elementRef]);

  useLayoutEffect(() => {
    const updateHeight = () => {
      if (elementRef && elementRef.current) {
        const { height } = elementRef.current.getBoundingClientRect();
        setHeight(height);
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    window.addEventListener("load", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
      window.removeEventListener("load", updateHeight);
    };
  }, [elementRef, setHeight]);

  return [height];
};
