import { ENDPOINT_SETTING, REDUX_SETTINGS_SETTINGS } from "../../constants";
import { actionReducerArraySet } from "../../../../redux/tools/createReducer/createReducerArray";
import { basicHandleError, get } from "../../../../core/api/utils/fetchApi";
import {
  actionLock,
  actionUnlock,
} from "../../../../core/layouts/store/actions/lockable.actions";
import { LockKey } from "../../../../core/layouts/utils/useIsLocked";
import { AppDispatchType } from "../../../../redux/store";
import { Setting } from "../../type/Setting";

export const getLockKeyActionGetSettings = (): LockKey => {
  return "gets" + ENDPOINT_SETTING;
};

export interface OffersActionFilter {
  country?: string[];
  search?: string;
}

export const getSettingsAction = () => async (dispatch: AppDispatchType) => {
  dispatch(actionLock(getLockKeyActionGetSettings()));
  const res = await get(ENDPOINT_SETTING);
  dispatch(actionUnlock(getLockKeyActionGetSettings()));
  if (res.ok) {
    const offers = res.data.map((item: any) => new Setting(item));
    dispatch(actionReducerArraySet(REDUX_SETTINGS_SETTINGS, offers));
  } else {
    dispatch(basicHandleError(res));
  }
};
