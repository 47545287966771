import { combineReducers } from "redux";
import { createReducerArray } from "../../../../redux/tools/createReducer/createReducerArray";
import {
  REDUX_FORM_FINAL_PAGE_LINKS,
  REDUX_FORM_FINAL_PAGE_LINKS_DIALOG,
  REDUX_FORM_FINAL_PAGE_LINKS_TO_EDIT,
} from "../../constants";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";
import { FinalePageLink } from "../../type/FinalePageLink";
import { createReducerBoolean } from "../../../../redux/tools/createReducer/createReducerBoolean";

export const finalPageLinksReducer = combineReducers({
  //list
  finalPageLinks: createReducerArray<FinalePageLink>(
    REDUX_FORM_FINAL_PAGE_LINKS,
    []
  ),
  finalPageLinkToEdit: createReducerObject<FinalePageLink | null>(
    REDUX_FORM_FINAL_PAGE_LINKS_TO_EDIT,
    null
  ),
  openCreateDialog: createReducerBoolean(
    REDUX_FORM_FINAL_PAGE_LINKS_DIALOG,
    false
  ),
});
