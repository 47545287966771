import {
  URL_POSTBACK_CLICK_LEAD,
  URL_POSTBACK_LEAD,
  URL_POSTBACK_TRACKING,
} from "../constant";

export const getUrlPostBackLead = () => {
  return URL_POSTBACK_LEAD;
};

export const getUrlPostBackTracking = () => {
  return URL_POSTBACK_TRACKING;
};

export const getUrlPostBackClickLead = () => {
  return URL_POSTBACK_CLICK_LEAD;
};
