export const ENDPOINT_INVOICE = "/invoice";
export const ENDPOINT_INVOICE_UPLOADER = "/file-uploader";
export const ENDPOINT_INVOICE_TO_PAY = "/invoice-to-pay";
export const ENDPOINT_INVOICE_ADMIN_MARK_AS = "/mark-as";
export const ENDPOINT_INVOICE_ADMIN = "/invoice-admin";
export const REDUX_INVOICE_CREATE_DIALOG = "INVOICE_REDUX_CREATE_DIALOG";
export const REDUX_INVOICE_ADMIN_VIEW_INFO = "REDUX_INVOICE_ADMIN_VIEW_INFO";
export const REDUX_INVOICES = "REDUX_INVOICES";

//settingName
export const SETTING_INVOICE_COMPANY = "invoice-company";
export const SETTING_INVOICE_ADDRESS = "invoice-address";
export const SETTING_INVOICE_CIN = "invoice-cin";
export const SETTING_INVOICE_VAT_ID = "invoice-vat-id";
export const SETTING_INVOICE_ACCOUNT_NUMBER = "invoice-account-number";
export const SETTING_INVOICE_IBAN = "invoice-iban";
export const SETTING_INVOICE_SWIFT_BIC = "invoice-swift-bic";
export const SETTING_INVOICE_BANK_NAME = "invoice-bank-name";
export const SETTING_INVOICE_ADDRESS_NAME = "invoice-address-name";
