import React, { FC, MouseEventHandler, ReactNode, useState } from "react";
import styled from "styled-components/macro";
import { rgba } from "polished";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { darken } from "polished";
import { RouteType } from "../../../../types/routes";
import "../../../../vendor/perfect-scrollbar.css";
import {
  Chip,
  Hidden,
  List as MuiList,
  ListItem,
  Menu,
  ListItemText,
  Popover,
} from "@material-ui/core";
import {
  AccountCircle,
  ContactMail,
  Contacts,
  ExpandLess,
  ExpandMore,
  InsertChart,
  InsertLink,
  LocalOffer,
  Menu as MenuIcon,
  PeopleAlt,
  Receipt,
  Send,
  ThumbUp,
  TrendingUp,
} from "@material-ui/icons";
import { getUrlProfile } from "../../../../modules/auth/utils/getUrlAuth";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/store";
import { getUrlDashboard } from "../../../../modules/dashboard/utils/getUrlDashboard";
import { Translate } from "../../../translate/Translate";
import Person from "@material-ui/icons/Person";
import {
  getUrlOfferList,
  getUrlOfferUserConfigsNeedToApprove,
} from "../../../../modules/offers/utils/getUrlOfferList";
import { useSettingByName } from "../../../../modules/setting/utils/useSettingByName";
import { SETTING_DISPLAY_NAME } from "../../../../modules/setting/constants";
import AppBarComponent from "./AppBar";
import { BaseIconButton } from "../../iconButtons";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import { getUrlForms } from "../../../../modules/form/utils/getUrls";
import {
  getUrlPostBackClickLead,
  getUrlPostBackLead,
  getUrlPostBackTracking,
} from "../../../../modules/postbacks/utils/getUrlPostBack";
import { getUrlPayoutManager } from "../../../../modules/payout/utils/getUrlPayoutManager";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import {
  getUrlReportLeadHandler,
  getUrlReportLeads,
} from "../../../../modules/reports/utils/getUrlPostBack";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import {
  getUrlInvoiceList,
  getUrlInvoicesAdminView,
} from "../../../../modules/invoice/utils/getUrlInvoiceList";
import {
  getUrlConnectionCreator,
  getUrlConnectionCreatorLog,
} from "../../../../modules/connectionCreator/utils/getUrls";

const Drawer = styled.div`
  border-right: 0;
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.sidebar.background};

  > div {
    border-right: 0;
  }
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
  flex-grow: 1;
`;

const Items = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  height: 100%;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    padding-left: 0;
  }
`;

const Brand = styled(ListItem)<{
  button?: boolean;
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: unset;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(0)}px;
  justify-content: center;
  cursor: pointer;
  width: unset;

  text-overflow: ellipsis;
  display: block;
  margin: auto 0;
  white-space: pre;
  overflow: hidden;
  max-width: 350px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-right: 0px;
    padding-left: ${(props) => props.theme.spacing(3)}px;
  }
`;

type CategoryType = {
  activeClassName?: string;
  button?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
};

const Category = styled(ListItem)<CategoryType>`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  width: unset;
  background-color: ${(props) => props.theme.sidebar.background};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.05, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.08, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-left: ${(props) => props.theme.spacing(4)}px;
    padding-right: ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;

  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const MenuStyled = styled(Menu)`
  .MuiList-root {
    background-color: ${(props) => props.theme.sidebar.background};
  }
`;

type SidebarCategoryPropsType = {
  name: string | ReactNode;
  icon: JSX.Element;
  badge?: string | number;
  button: true;
  onClick?: MouseEventHandler<HTMLDivElement>;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
  subMenu?: ReactNode[];
};

const SidebarCategory: React.FC<SidebarCategoryPropsType> = ({
  name,
  icon,
  badge,
  subMenu,
  ...rest
}) => {
  const [open, setOpen] = useState<HTMLDivElement | null>(null);
  return (
    <>
      {subMenu && (
        <MenuStyled open={!!open} anchorEl={open} onClose={() => setOpen(null)}>
          {subMenu}
        </MenuStyled>
      )}
      <Category
        activeClassName={"active"}
        {...rest}
        onClick={subMenu ? (e) => setOpen(e.currentTarget) : rest.onClick}
      >
        {icon}
        <CategoryText>{name}</CategoryText>
        {subMenu ? open ? <CategoryIconMore /> : <CategoryIconLess /> : null}
        {badge ? <CategoryBadge label={badge} /> : ""}
      </Category>
    </>
  );
};
const SideBarMenu: FC = () => {
  const user = useSelector((state: AppStateType) => state.auth.loggedUser);
  return (
    <List disablePadding>
      <Items>
        <SidebarCategory
          name={<Translate id={"dashboard.dashboard"} />}
          to={getUrlDashboard()}
          component={NavLink}
          icon={<InsertChart />}
          exact
          button
        />
        <SidebarCategory
          name={<Translate id={"offer.offers"} />}
          to={getUrlOfferList()}
          component={NavLink}
          icon={<LocalOffer />}
          exact
          button
        />
        <SidebarCategory
          name={<Translate id={"menu.affiliate"} />}
          icon={<Contacts />}
          subMenu={[
            ...(user?.isAdmin
              ? [
                  <SidebarCategory
                    name={<Translate id={"offer.userConfigApprove"} />}
                    to={getUrlOfferUserConfigsNeedToApprove()}
                    component={NavLink}
                    icon={<ThumbUp />}
                    exact
                    button
                    key={"userConfigApprove"}
                  />,
                  <SidebarCategory
                    name={<Translate id={"payout.manager"} />}
                    to={getUrlPayoutManager()}
                    component={NavLink}
                    icon={<AccountBalanceWalletIcon />}
                    exact
                    button
                    key={"payout.manager"}
                  />,
                  <SidebarCategory
                    name={<Translate id={"invoice.payoutRequestList"} />}
                    to={getUrlInvoicesAdminView()}
                    component={NavLink}
                    icon={<Receipt />}
                    exact
                    button
                    key={"invoice.payoutRequestList"}
                  />,
                ]
              : []),

            <SidebarCategory
              name={<Translate id={"invoice.invoiceList"} />}
              to={getUrlInvoiceList()}
              component={NavLink}
              icon={<Receipt />}
              exact
              button
              key={"invoice"}
            />,
            <SidebarCategory
              name={<Translate id={"auth.profile"} />}
              to={getUrlProfile()}
              component={NavLink}
              icon={<Person />}
              exact
              button
              key={"invoice"}
            />,
          ]}
          button
        />
        <SidebarCategory
          name={<Translate id={"core.tools"} />}
          icon={<AccountCircle />}
          subMenu={[
            user?.isAdmin
              ? [
                  <SidebarCategory
                    name={<Translate id={"form.formConfig"} />}
                    to={getUrlForms()}
                    component={NavLink}
                    icon={<SettingsApplicationsIcon />}
                    exact
                    button
                    key={"formConfig"}
                  />,
                  <SidebarCategory
                    name={<Translate id={"postback.listClickLeads"} />}
                    to={getUrlPostBackClickLead()}
                    component={NavLink}
                    icon={<InsertLink />}
                    exact
                    button
                    key={"postback.listClickLeads"}
                  />,
                  <SidebarCategory
                    name={<Translate id={"postback.listLeads"} />}
                    to={getUrlPostBackLead()}
                    component={NavLink}
                    icon={<PeopleAlt />}
                    exact
                    button
                    key={"postback.listLeads"}
                  />,
                  <SidebarCategory
                    name={<Translate id={"connection.createConnection"} />}
                    to={getUrlConnectionCreator()}
                    component={NavLink}
                    icon={<SettingsEthernetIcon />}
                    exact
                    button
                    key={"connection.createConnection"}
                  />,
                ]
              : [],
            <SidebarCategory
              name={<Translate id={"postback.tracking"} />}
              to={getUrlPostBackTracking()}
              component={NavLink}
              icon={<Send />}
              exact
              button
              key={"postback.tracking"}
            />,
          ]}
          button
        />
        {user?.isAdmin ? (
          <SidebarCategory
            name={<Translate id={"menu.report"} />}
            icon={<TrendingUp />}
            subMenu={[
              <SidebarCategory
                name={<Translate id={"offer.enableReceiveLead"} />}
                to={getUrlReportLeads()}
                component={NavLink}
                icon={<ContactMail />}
                exact
                button
                key={"offerRequests"}
              />,
              <SidebarCategory
                name={<Translate id={"report.leadHandlerErrorLog"} />}
                to={getUrlReportLeadHandler()}
                component={NavLink}
                icon={<SettingsEthernetIcon />}
                exact
                button
                key={"formConfig"}
              />,
              <SidebarCategory
                name={<Translate id={"connection.log"} />}
                to={getUrlConnectionCreatorLog()}
                component={NavLink}
                icon={<SettingsEthernetIcon />}
                exact
                button
                key={"getUrlConnectionCreatorLog"}
              />,
            ]}
            button
          />
        ) : (
          <SidebarCategory
            name={<Translate id={"offer.enableReceiveLead"} />}
            to={getUrlReportLeads()}
            component={NavLink}
            icon={<ContactMail />}
            exact
            button
            key={"offerRequests"}
          />
        )}
      </Items>
    </List>
  );
};

type SidebarPropsType = {
  classes?: string;
  staticContext: string;
  location: {
    pathname: string;
  };
  routes: Array<RouteType>;
};

const Sidebar: React.FC<RouteComponentProps & SidebarPropsType> = ({
  classes,
  staticContext,
  location,
  ...rest
}) => {
  const [open, setOpen] = useState<Element | null>(null);
  const setting = useSettingByName(SETTING_DISPLAY_NAME);

  return (
    <Drawer {...rest}>
      {setting && (
        <Brand component={NavLink} to={getUrlDashboard()} button>
          {setting.value}
        </Brand>
      )}
      <Hidden smDown={true}>
        <SideBarMenu />
      </Hidden>
      <Hidden mdUp={true}>
        <BaseIconButton
          Icon={MenuIcon}
          color={"inherit"}
          tooltipTranslateId={"core.menu"}
          onClick={(e) => setOpen(e.currentTarget)}
        />
        <Popover anchorEl={open} open={!!open} onClose={() => setOpen(null)}>
          <SideBarMenu />
        </Popover>
      </Hidden>
      <AppBarComponent />
    </Drawer>
  );
};

export default withRouter(Sidebar);
