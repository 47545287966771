import * as React from "react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { IframeInputsList } from "./IframeInputsList";
import { useMemo } from "react";
import IframeHeader from "./header/IframeHeader";
import { postIframeContentForm } from "../../utils/postIframeContentForm";
import { putIframeContentForm } from "../../utils/putIframeContentForm";
import { useHistoryLocation } from "../../../../core/history";
import { Helmet } from "react-helmet";
import { IframeSubmitButton } from "./IframeSubmitButton";
import { useDispatch, useSelector } from "react-redux";
import {
  actionIframeAddFilledFrom,
  actionIframeRemoveFilledFrom,
} from "../../store/action/addIframeError.action";
import IframeFooter from "./footer/IframeFooter";
import { sendDataToParentPage } from "../../utils/sendDataToParentPage";
import {
  FormsIframePageProps,
  IframeRoot,
} from "../../container/FormsIframeContainer";
import { AppStateType } from "../../../../redux/store";
import { actionReducerNumberSet } from "../../../../redux/tools/createReducer/createReducerNumber";
import {
  REDUX_IFRAME_VALUES,
  REDUX_IFRAME_LEAD_ID,
  REDUX_IFRAME_LEAD_WEBSOCKET_ID,
} from "../../constant";
import { actionReducerObjectSet } from "../../../../redux/tools/createReducer/createReducerObject";
import { actionReducerStringSet } from "../../../../redux/tools/createReducer/createReducerString";

const FormsIframe: FC<FormsIframePageProps> = ({ formData }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const callbackAutoSaveRef = React.useRef<() => void>(() => {});
  const dispatch = useDispatch();

  const leadId = useSelector((state: AppStateType) => state.iframe.leadId);
  const formValues = useSelector(
    (state: AppStateType) => state.iframe.formValues
  );
  const accessToken = useSelector(
    (state: AppStateType) => state.iframe.loader.accessToken
  );
  const lastSaveFormValues = useRef<Record<string, any>>(formValues);
  const setFormValues = useCallback(
    (value: Record<string, any>) => {
      dispatch(actionReducerObjectSet(REDUX_IFRAME_VALUES, value));
    },
    [dispatch]
  );

  useEffect(() => {
    sendDataToParentPage(
      "started",
      "filled-input",
      formData.canUseReceiveLeads() ? formValues : null
    );
    // eslint-disable-next-line
  }, []);

  callbackAutoSaveRef.current = () => {
    if (!formData) {
      return;
    }
    if (formValues === lastSaveFormValues.current) {
      //this values already saved
      return;
    }
    lastSaveFormValues.current = formValues;
    if (typeof leadId !== "number") {
      postIframeContentForm(formData, { ...formValues }, false).then((res) => {
        if (res.ok) {
          if (Number.isInteger(Number.parseInt(res?.data?.leadId))) {
            dispatch(
              actionReducerNumberSet(
                REDUX_IFRAME_LEAD_ID,
                Number.parseInt(res.data.leadId)
              )
            );
          }
          dispatch(
            actionReducerStringSet(
              REDUX_IFRAME_LEAD_WEBSOCKET_ID,
              res.data?.accessToken || null
            )
          );
        }
      });
    } else {
      putIframeContentForm(
        leadId,
        formData,
        { ...formValues, accessToken },
        false
      ).then((res) => {
        dispatch(
          actionReducerStringSet(
            REDUX_IFRAME_LEAD_WEBSOCKET_ID,
            res.data?.accessToken || null
          )
        );
      });
    }
  };

  // auto save
  useEffect(() => {
    const timeout = setInterval(() => {
      callbackAutoSaveRef.current();
    }, 30 * 1000);
    return () => clearInterval(timeout);
  }, []);

  const pages = useMemo(() => {
    if (!formData?.form) {
      return [];
    }
    return formData.form.getPages(formValues);
  }, [formValues, formData?.form]);

  const handleChange = (name: string, value: string) => {
    if (!formValues[name] && value) {
      //was not filled
      dispatch(actionIframeAddFilledFrom(name));
    } else if (formValues[name] && !value) {
      //is not filled
      dispatch(actionIframeRemoveFilledFrom(name));
    }
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const location = useHistoryLocation<{ hideBg: "true" | null }>();

  if (!formData.form || !formData.offer) {
    return null;
  }

  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle="">
        <title itemProp="name">{formData.getMetaTitle() || ""}</title>
        <meta
          name="description"
          content={formData.getMetaDescription() || ""}
        />
      </Helmet>
      <IframeRoot
        $formData={formData}
        $noMarginBottom={currentPage === 0}
        $hideBg={location?.search?.hideBg === "true"}
      >
        <IframeHeader
          formData={formData}
          currentPage={currentPage}
          formValues={formValues}
        />
        <IframeInputsList
          key={currentPage}
          onChange={handleChange}
          formValues={formValues}
          form={formData.form}
          formFields={pages[currentPage]}
          offer={formData.offer}
          settings={formData.settingNeedByForms}
          formCustomTermsAndConditions={formData.formCustomTermsAndConditions}
          userId={formData.userId}
        />
        <IframeSubmitButton
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          leadId={leadId || undefined}
          formValues={formValues}
          pages={pages}
          formData={formData}
        />
      </IframeRoot>
      {currentPage !== 0 && (
        <IframeFooter formValues={formValues} leadId={leadId || undefined} />
      )}
    </>
  );
};
export default FormsIframe;
