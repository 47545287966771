import { useEffect } from "react";
import { getTranslatesAction } from "../translate/store/actions/getTranslates.action";
import { useDispatch, useSelector } from "react-redux";
import { getSettingsAction } from "../../modules/setting/store/action/getSettings.action";
import { getCurrency } from "../../modules/currency/store/actions/getCurrency.action";
import { getValidationAction } from "../../modules/validation/store/action/getValidation.action";
import { AppStateType } from "../../redux/store";
import { useCookies } from "react-cookie";

export const useInitApp = () => {
  const dispatch = useDispatch();
  const [, setCookie] = useCookies(["timezone"]);
  const { id } =
    useSelector((state: AppStateType) => state.auth.loggedUser) || {};
  useEffect(() => {
    setCookie("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);
    dispatch(getTranslatesAction());
    dispatch(getSettingsAction());
  }, [dispatch, setCookie]);

  useEffect(() => {
    if (id) {
      dispatch(getCurrency());
      dispatch(getValidationAction());
    }
  }, [dispatch, id]);
};
