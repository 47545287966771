import { ReduxAction } from "../../type";
import {
  DELETE_FROM_ARRAY,
  PUSH_TO_ARRAY_IF_NOT_EXIST,
  RESET,
  SET,
  SET_PROPERTY,
} from "../../constants";

type ReduxActionOperationObject =
  | typeof SET
  | typeof DELETE_FROM_ARRAY
  | typeof SET_PROPERTY
  | typeof PUSH_TO_ARRAY_IF_NOT_EXIST
  | typeof RESET;

export interface ReduxActionObjectBasic extends ReduxAction {
  operation: typeof SET | typeof RESET;
}

export interface ReduxActionObjectProperty extends ReduxAction {
  operation: typeof DELETE_FROM_ARRAY;
  propertyName: string;
  arrayItemPropertyName?: string;
  payload: string | number | null;
}
export const actionReducerObjectArrayDelete = (
  type: string,
  payload: any,
  propertyName: string,
  arrayItemPropertyName?: string
): ReduxActionObjectProperty => ({
  type,
  operation: DELETE_FROM_ARRAY,
  payload,
  arrayItemPropertyName,
  propertyName,
});

export interface ReduxActionObjectArrayPush extends ReduxAction {
  operation: typeof PUSH_TO_ARRAY_IF_NOT_EXIST;
  propertyName: string;
  arrayItemPropertyName?: string;
  payload: any;
}
export const actionReducerObjectArrayPushOrUpdate = (
  type: string,
  payload: any,
  propertyName: string,
  arrayItemPropertyName?: string
): ReduxActionObjectArrayPush => ({
  type,
  operation: PUSH_TO_ARRAY_IF_NOT_EXIST,
  payload,
  arrayItemPropertyName,
  propertyName,
});

export interface ReduxActionObjectSet extends ReduxAction {
  operation: typeof SET;
}
export const actionReducerObjectSet = (
  type: string,
  payload: any
): ReduxActionObjectSet => ({
  type,
  operation: SET,
  payload,
});

export interface ReduxActionObjectReset extends ReduxAction {
  operation: typeof RESET;
}
export const actionReducerObjectReset = (
  type: string,
  payload: any
): ReduxActionObjectReset => ({
  type,
  operation: RESET,
  payload,
});

export interface ReduxActionObjectSetProperty extends ReduxAction {
  operation: typeof SET_PROPERTY;
  propertyName: string;
}
export const actionReducerObjectSetProperty = (
  type: string,
  propertyName: string,
  payload: any
): ReduxActionObjectSetProperty => ({
  type,
  propertyName,
  operation: SET_PROPERTY,
  payload,
});

export type ReduxActionObject =
  | ReduxActionObjectBasic
  | ReduxActionObjectSet
  | ReduxActionObjectReset
  | ReduxActionObjectSetProperty
  | ReduxActionObjectProperty;

export const createReducerObject = <T>(
  actionType: string,
  defaultState: T,
  getInstance?: new (values: T) => T
) => (state = defaultState, action: ReduxActionObject): T => {
  if (action.type === actionType) {
    const actions: Record<ReduxActionOperationObject, () => T> = {
      [SET]: () => {
        if (action.payload === undefined) {
          throw new Error(
            `Can not set undefined to reducer. Reducer: ${actionType}, operation: ${SET}`
          );
        }
        return action.payload;
      },
      [DELETE_FROM_ARRAY]: () => {
        const propertyName =
          "propertyName" in action ? action.propertyName : "";
        const arrayItemPropertyName =
          "arrayItemPropertyName" in action ? action.arrayItemPropertyName : "";
        const res = {
          ...(state as any),
          [propertyName]: (Array.isArray((state as any)[propertyName])
            ? (state as any)[propertyName]
            : []
          ).filter(
            (item: any) =>
              (arrayItemPropertyName ? item[arrayItemPropertyName] : item) !==
              action.payload
          ),
        };
        if (!getInstance) {
          return res;
        }
        return new getInstance(res);
      },
      [PUSH_TO_ARRAY_IF_NOT_EXIST]: () => {
        const propertyName =
          "propertyName" in action ? action.propertyName : "";
        const arrayItemPropertyName =
          "arrayItemPropertyName" in action ? action.arrayItemPropertyName : "";
        const array = Array.isArray((state as any)[propertyName])
          ? (state as any)[propertyName]
          : [];
        const exist = array.some((item: any) =>
          arrayItemPropertyName
            ? item[arrayItemPropertyName] ===
              action.payload[arrayItemPropertyName]
            : item === action.payload
        );
        const res = {
          ...(state as any),
          [propertyName]: exist
            ? array.map((item: any) =>
                (
                  arrayItemPropertyName
                    ? item[arrayItemPropertyName] ===
                      action.payload[arrayItemPropertyName]
                    : item === action.payload
                )
                  ? action.payload
                  : item
              )
            : [...array, action.payload],
        };
        if (!getInstance) {
          return res;
        }
        return new getInstance(res);
      },
      [SET_PROPERTY]: () => {
        const propertyName =
          "propertyName" in action ? action.propertyName : "";
        const res = {
          ...(state as any),
          [propertyName]: action.payload,
        };
        if (!getInstance) {
          return res;
        }
        return new getInstance(res);
      },
      [RESET]: () => defaultState,
    };
    if (actions[action.operation]) {
      return actions[action.operation]();
    } else {
      throw new Error(
        `Unknown operation ${action.operation} for reducer: ${action.type}`
      );
    }
  }
  return state;
};
