export class FormFieldConfig {
  id?: number;
  formFieldId?: string;
  type?: string;
  numberConfig?: number;
  stringConfig?: string;

  constructor(obj: any) {
    obj && Object.assign(this, obj);
  }
}
