import React, { FC } from "react";
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@material-ui/core";
import { Flex } from "../../layout/Flex";
import { Translate } from "../../../translate/Translate";
import { Loading } from "../../Loading";

export interface MyTextFieldPropsOnly {
  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
  noMargin?: boolean;
  isSaving?: boolean;
}

export type MyTextFieldBaseProps = MyTextFieldPropsOnly & TextFieldProps;

export const MyTextFieldBase: FC<MyTextFieldBaseProps> = ({
  inputMode,
  noMargin,
  isSaving,
  ...props
}) => {
  return (
    <Flex
      marginBottom={noMargin ? undefined : "medium"}
      marginRight={!props.fullWidth ? "small" : undefined}
      marginLeft={!props.fullWidth ? "small" : undefined}
      fullWidth={props.fullWidth}
    >
      <TextField
        inputMode={"numeric"}
        variant={"outlined"}
        {...props}
        inputProps={{
          ...(props.inputProps || {}),
          inputMode: inputMode,
        }}
        InputProps={
          isSaving
            ? {
                ...(props?.InputProps || {}),
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <Tooltip title={<Translate id={"core.saving"} />}>
                      <Loading />
                    </Tooltip>
                  </InputAdornment>
                ),
                readOnly: true,
              }
            : props?.InputProps
        }
      />
    </Flex>
  );
};
