export const OFFER_LEAD_TYPE_API = "api";
export const OFFER_LEAD_TYPE_LINK = "link";
export const OFFER_LEAD_TYPE_IFRAME = "iframe";

export type OfferLeadType =
  | typeof OFFER_LEAD_TYPE_LINK
  | typeof OFFER_LEAD_TYPE_IFRAME
  | typeof OFFER_LEAD_TYPE_API;

export const OFFER_TYPE_USER_CASCADE = "user-cascade";
export const OFFER_TYPE_DEFAULT = null;

export const OFFER_LEAD_TYPES = [OFFER_LEAD_TYPE_LINK, OFFER_LEAD_TYPE_IFRAME];
