import { File } from "../../file/type/File";

export class FinalePageLink {
  id: number | null = null;
  company: string = "";
  charge: string = "";
  link: string = "";
  status: string = "";
  formId: number | null = null;
  weight: number = 0;
  change: number = 100;
  logoFile: null | File = null;

  constructor(obj?: any) {
    obj && Object.assign(this, obj);
    if (obj?.logoFile) {
      this.logoFile = new File(obj.logoFile);
    }
  }

  toJson(type?: string) {
    const { company, charge, change, link, formId, weight, id, status } = this;
    switch (type) {
      case "POST":
        return { formId };
      case "PUTs":
        return {
          company,
          charge,
          link,
          change,
          id,
          formId,
          weight,
          status,
        };
      default:
        return {
          company,
          charge,
          link,
          change,
          formId,
          weight,
          status,
        };
    }
  }
}
