import { LoadCzMoneyImage } from "../../iframe/inputs/sliderLoanSubcomponents/LoadCzMoneyImage";
import React from "react";
import styled from "styled-components/macro";

interface MoneyAnimationProps {
  percentage: number;
}

const MoneyAnimation = styled.div<MoneyAnimationProps>`
  transform-origin: top;
  margin-top: -30px;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;

  @keyframes example {
    0% {
      transform: rotate(
        ${({ percentage }) => (1 - percentage) * (-90 - 50)}deg
      );
    }
    50% {
      transform: rotate(
        ${({ percentage }) => (1 - percentage) * (-90 + 50)}deg
      );
    }
    100% {
      transform: rotate(
        ${({ percentage }) => (1 - percentage) * (-90 - 50)}deg
      );
    }
  }
`;

export const MoneyLoadingAnimation = () => {
  const money = 8000;
  return (
    <MoneyAnimation percentage={8000 / 20000}>
      <LoadCzMoneyImage money={money} hideArrow={true} marginTop={-30} />
    </MoneyAnimation>
  );
};
