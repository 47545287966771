import { AppDispatchType } from "../../../../redux/store";
import { basicHandleError, post } from "../../../../core/api/utils/fetchApi";
import { ENDPOINT_VERIFY_SMS } from "../../../form/constants";

export const actionLogSmsEvent = (accessToken: string, event: string) => async (
  dispatch: AppDispatchType
) => {
  const res = await post(ENDPOINT_VERIFY_SMS + "/" + accessToken, {
    event,
  });
  if (!res.ok) {
    dispatch(basicHandleError(res));
  }
};
