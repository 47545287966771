export const REDUX_POSTBACK_LEAD = "REDUX_POSTBACK_LEAD";
export const REDUX_POSTBACK_LEAD_DIALOG = "REDUX_POSTBACK_LEAD_DIALOG";

export const REDUX_POSTBACK_LEAD_CLICK = "REDUX_POSTBACK_LEAD";
export const REDUX_POSTBACK_LEAD_CLICK_DIALOG =
  "REDUX_POSTBACK_LEAD_CLICK_DIALOG";

export const REDUX_POSTBACK = "REDUX_POSTBACK_LEAD";
export const REDUX_POSTBACK_DIALOG = "REDUX_POSTBACK_DIALOG";
export const REDUX_POSTBACK_DOC_DIALOG = "REDUX_POSTBACK_DOC_DIALOG";

export const URL_POSTBACK_LEAD = "/postback/lead";
export const URL_POSTBACK_CLICK_LEAD = "/postback/click-lead";
export const URL_POSTBACK_TRACKING = "/postback/tracking";

export const ENDPOINT_POSTBACK_LEAD = "/lead/post-back";
export const ENDPOINT_LEAD_HANDLER_REPORT = "/lead-handler-report";
export const ENDPOINT_CASCADE_REPORT = "/cascade-report";
export const ENDPOINT_POSTBACK = "/post-back";
export const ENDPOINT_POSTBACK_LEAD_CLICK = "/lead-click/post-back";

//mark statuses
export const REDUX_LEAD_MARK_STATUSES = "REDUX_LEAD_MARK_STATUSES";
export const REDUX_CLICK_LEAD_MARK_STATUSES = "REDUX_CLICK_LEAD_MARK_STATUSES";
export const REDUX_LEAD_MARK_STATUSES_DIALOG =
  "REDUX_LEAD_MARK_STATUSES_DIALOG";
export const REDUX_CLICK_LEAD_MARK_STATUSES_DIALOG =
  "REDUX_CLICK_LEAD_MARK_STATUSES_DIALOG";
export const ENDPOINT_LEAD_MARK_STATUSES_FILE_UPLOAD =
  "/lead-mark-statuses-upload-file";
export const ENDPOINT_LEAD_MARK_STATUSES = "/lead-mark-statuses";
export const ENDPOINT_DOWNLOAD_CSV_TEMPLATE =
  "/api/docs/change-lead-statuses.csv";

export const POSTBACK_ALL_PARAMETERS = [
  "subId",
  "id",
  "isFromCascade",
  "currency",
  "payout",
  "saleAmount",
  "refuseReason",
  "offerId",
  "offerName",
  "affiliateId",
  "affiliateName",
  "category",
  "leadType",
  "leadStatus",
  "affiliateLeadId",
  "saleAmount",
  "distributionType",
];
