import {
  TYPE_BANK_ACCOUNT,
  TYPE_BANK_CODE,
  TYPE_CZ_ZIP,
  TYPE_CZK_PRICE,
  TYPE_EMAIL,
  TYPE_HOUSE_NUMBER,
  TYPE_IDENTIFY_CARD_NUMBER,
  TYPE_NIN,
  TYPE_ONLY_NUMBER,
  TYPE_ONLY_TEXT,
  TYPE_PHONE,
  TYPE_PHONE_CZ,
} from "../../../../constant/formFieldType";
import { ReactNode } from "react";
import { InputAdornment, Typography } from "@material-ui/core";
import * as React from "react";
import emailMask from "text-mask-addons/dist/emailMask";
import { createNumberMask } from "text-mask-addons";
import styled from "styled-components";

interface MaskIframeTextFieldConfig {
  startAdornment?: ReactNode;
  mask?: (string | RegExp)[] | ((rawValue: string) => (string | RegExp)[]);
  charsToRemove: (string | RegExp)[];
  maxLength?: number;
  minLength?: number;
  inputmode?:
    | "none"
    | "text"
    | "email"
    | "tel"
    | "url"
    | "numeric"
    | "decimal"
    | "search";
  isValid?: (value: string) => boolean;
}

const czkPriceMask = createNumberMask({
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: " ",
  prefix: "",
  suffix: " Kč",
  allowDecimal: false,
  integerLimit: 7,
  allowNegative: false,
});

const InputAdornmentStyled = styled(Typography)`
  font-size: 24px;
  line-height: 1;
`;

export const useMaskIframeTextFieldConfig = (
  type: string
): MaskIframeTextFieldConfig => {
  switch (type) {
    case TYPE_EMAIL:
      return {
        charsToRemove: [],
        mask: emailMask,
        inputmode: "email",
      };
    case TYPE_CZ_ZIP:
      return {
        inputmode: "numeric",
        mask: [/[0-9]/, /\d/, /\d/, " ", /\d/, /\d/],
        charsToRemove: [" ", "_"],
      };
    case TYPE_ONLY_TEXT:
      return {
        charsToRemove: [/[0-9,.<>%$!\][?';"]/g],
      };
    case TYPE_ONLY_NUMBER:
      return {
        inputmode: "numeric",
        charsToRemove: [/[^0-9/]/g],
      };
    case TYPE_PHONE:
      return {
        maxLength: 18,
        charsToRemove: [/[^0-9+()/]/g],
        inputmode: "tel",
      };
    case TYPE_BANK_ACCOUNT:
      return {
        inputmode: "numeric",
        charsToRemove: [/[^0-9-–/]/g],
        maxLength: 17,
        isValid: (value) => {
          const onlyNumber =
            "0000000000000000" + value.replace("-", "").replace("–", "");
          const onlyNumberToUse = onlyNumber.substr(onlyNumber.length - 16);
          const charArray = onlyNumberToUse.split("").reverse();
          const weight = charArray.map((char, index) => {
            return Math.pow(2, index) % 11;
          });
          const res =
            charArray
              .map((char, index) => parseInt(char) * weight[index])
              .reduce((all, item) => all + item, 0) % 11;
          return res === 0;
        },
      };
    case TYPE_BANK_CODE:
      return {
        maxLength: 4,
        inputmode: "numeric",
        charsToRemove: [/[^0-9/]/g],
      };
    case TYPE_PHONE_CZ:
      return {
        startAdornment: (
          <InputAdornment
            disableTypography={true}
            component={InputAdornmentStyled}
            position="start"
          >
            +420
          </InputAdornment>
        ),
        mask: [
          /[0-9]/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
        ],
        inputmode: "tel",
        charsToRemove: [" ", "_"],
        minLength: 9,
      };
    case TYPE_CZK_PRICE:
      return {
        inputmode: "numeric",
        charsToRemove: [" ", "_", "K", "č"],
        mask: czkPriceMask,
      };
    case TYPE_IDENTIFY_CARD_NUMBER:
      return {
        inputmode: "numeric",
        charsToRemove: ["_"],
        mask: [/[1-2]/, /[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        minLength: 9,
      };
    case TYPE_NIN:
      return {
        inputmode: "numeric",
        charsToRemove: ["_"],
        isValid: (value) => {
          if (value.length < 10) {
            return false;
          }
          if (value.length === 10) {
            return true;
          }
          return parseInt(value.replace("/", "")) % 11 === 0;
        },
        mask: (rawValue: string) => {
          if (rawValue.replace("_", "").length !== 10) {
            return [
              /[0-9]/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "/",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ];
          } else {
            return [
              /[0-9]/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "/",
              /\d/,
              /\d/,
              /\d/,
            ];
          }
        },
      };
    case TYPE_HOUSE_NUMBER:
      return {
        charsToRemove: ["_"],
        inputmode: "text",
        mask: (rawValue: string) => {
          if (rawValue.length === 0) {
            return [/[0-9]/];
          }
          return [
            /[0-9]/,
            ...rawValue
              .slice(1)
              .split("")
              .filter((char) => char !== "_")
              .map(() => /[0-9A-Za-z]/),
          ];
        },
      };
  }
  return {
    charsToRemove: [],
  };
};
