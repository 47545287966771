import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { GlobalStyleProps } from "../../../types/styles";
import { RouteType } from "../../../types/routes";
import Sidebar from "../../components/fromTheme/components/Sidebar";
import { CssBaseline, Fab, withWidth } from "@material-ui/core";
import BaseAuthorizeLayout from "../BaseAuthorizeLayout";
import { NotVerifyEmail } from "../../../modules/auth/container/NotVerifyEmail";
import { Header } from "../components/header/Header";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 12px;

  ${({ theme }: { theme: any }) => theme.breakpoints.up("lg")} {
    padding-top: 30px;
    padding-left: 140px;
    padding-right: 140px;
  }
`;

const GoUp = styled(Fab)`
  position: fixed;
  right: 40px;
  bottom: 40px;
`;

type DashboardPropsType = {
  routes: Array<RouteType>;
  width: "md" | "xs" | "sm" | "lg" | "xl";
};

const AppLayout: React.FC<DashboardPropsType> = ({ routes, children }) => {
  return (
    <BaseAuthorizeLayout>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <Sidebar routes={routes} />
        <AppContent>
          <Header />
          <NotVerifyEmail />
          {children}
          <GoUp
            color="primary"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <ArrowUpward />
          </GoUp>
        </AppContent>
      </Root>
    </BaseAuthorizeLayout>
  );
};

export default withWidth()(AppLayout);
