import { combineReducers } from "redux";
import { REDUX_INVOICE_ADMIN_VIEW_INFO, REDUX_INVOICE_CREATE_DIALOG, REDUX_INVOICES } from "../../constants";
import { createReducerDialog } from "../../../../redux/tools/createReducer/createReducerDialog";
import { Invoice } from "../../type/Invoice";
import { createReducerApiArray } from "../../../../redux/tools/createReducer/createReducerApiArray";

export const invoiceReducer = combineReducers({
  invoices: createReducerApiArray<Invoice>(REDUX_INVOICES),
  openCreateNewOffer: createReducerDialog<Invoice>(REDUX_INVOICE_CREATE_DIALOG),
  invoicesAdminViewDialog: createReducerDialog<Invoice>(REDUX_INVOICE_ADMIN_VIEW_INFO),
});
