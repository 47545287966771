import { combineReducers } from "redux";
import { createReducerArray } from "../../../../redux/tools/createReducer/createReducerArray";
import {
  REDUX_OFFER_FILTER_AVAILABLE_CATEGORIES,
  REDUX_OFFER_FILTER_AVAILABLE_COUNTRIES,
  REDUX_OFFER_FILTER_AVAILABLE_PRODUCTS,
  REDUX_OFFER_FILTER_SEARCH,
  REDUX_OFFER_FILTER_SELECTED_CATEGORIES,
  REDUX_OFFER_FILTER_SELECTED_COUNTRIES,
  REDUX_OFFER_FILTER_SELECTED_PRODUCTS,
} from "../../constants";
import { OfferCategory } from "../../type/OfferCategory";
import { OfferProduct } from "../../type/OfferProduct";
import { createReducerString } from "../../../../redux/tools/createReducer/createReducerString";
import { OfferCountry } from "../../type/OfferCountry";

export const offersFilter = combineReducers({
  availableCountries: createReducerArray<OfferCountry>(
    REDUX_OFFER_FILTER_AVAILABLE_COUNTRIES,
    []
  ),
  availableCategories: createReducerArray<OfferCategory>(
    REDUX_OFFER_FILTER_AVAILABLE_CATEGORIES,
    []
  ),
  availableProducts: createReducerArray<OfferProduct>(
    REDUX_OFFER_FILTER_AVAILABLE_PRODUCTS,
    []
  ),

  selectedCountries: createReducerArray<string>(
    REDUX_OFFER_FILTER_SELECTED_COUNTRIES,
    []
  ),
  selectedCategories: createReducerArray<number>(
    REDUX_OFFER_FILTER_SELECTED_CATEGORIES,
    []
  ),
  selectedProducts: createReducerArray<number>(
    REDUX_OFFER_FILTER_SELECTED_PRODUCTS,
    []
  ),

  search: createReducerString(REDUX_OFFER_FILTER_SEARCH, ""),
});
