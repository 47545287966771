import * as React from "react";
import { FC } from "react";
import { IframeInputWrapper } from "./IframeInputWrapper";
import { IframeFiledProps } from "../IframeInputsList";
import { PriceString } from "../../../../currency/components/PriceString";
import { IframeSlider } from "./IframeSlider";
import { Offer } from "../../../type/Offer";
import { useDispatch } from "react-redux";
import { actionSetMoneyCzSliderValue } from "../../../store/action/addIframeError.action";

interface IframeSliderLoadProps {
  offer: Offer;
}

export const IframeSliderLoad: FC<IframeFiledProps & IframeSliderLoadProps> = (
  props
) => {
  const dispatch = useDispatch();
  const onChange = (value: number) => {
    props.onChange(value);
    dispatch(actionSetMoneyCzSliderValue(value));
  };
  return (
    <IframeInputWrapper formField={props.formField} noMarginTop={true}>
      <IframeSlider
        {...{ ...props, onChange }}
        doNotUseWrap={true}
        valueLabelFormat={(value) =>
          PriceString({ price: value, currency: props.offer.currency })
        }
      />
    </IframeInputWrapper>
  );
};
