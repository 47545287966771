import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarKey, useSnackbar } from "notistack";
import { AppStateType } from "../../../../redux/store";
import { snackbarRemove } from "../../../layouts/store/actions/snackbar.actions";
import { Translate } from "../../../translate/Translate";

let displayed: SnackbarKey[] = [];

const SnackbarShower = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (store: AppStateType) => store.layout.snackbars
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = useCallback((id: SnackbarKey) => {
    displayed = [...displayed, id];
  }, []);

  const removeDisplayed = useCallback((id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  }, []);

  useEffect(() => {
    notifications.forEach(
      ({
        key,
        variant,
        translateId,
        message,
        options = {},
        dismissed = false,
      }) => {
        if (dismissed) {
          closeSnackbar(key);
          return;
        }

        if (displayed.includes(key)) return;

        enqueueSnackbar(
          translateId ? <Translate id={translateId} /> : message,
          {
            key,
            ...options,
            variant,
            onClose: (event, reason, myKey) => {
              if (options.onClose) {
                options.onClose(event, reason, myKey);
              }
            },
            onExited: (event, myKey) => {
              dispatch(snackbarRemove(myKey));
              removeDisplayed(myKey);
            },
          }
        );

        storeDisplayed(key);
      }
    );
  }, [
    notifications,
    closeSnackbar,
    enqueueSnackbar,
    removeDisplayed,
    dispatch,
    storeDisplayed,
  ]);

  return null;
};

export default SnackbarShower;
