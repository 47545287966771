import { AppDispatchType } from "../../../../redux/store";
import { actionReducerStringSet } from "../../../../redux/tools/createReducer/createReducerString";
import {
  IFRAME_PAGE_FINAL_PAGE,
  REDUX_IFRAME_FINALE_PAGE_RES_FAIL,
  REDUX_IFRAME_FINALE_PAGE_RES_SUCCESS,
  REDUX_IFRAME_FINALE_PAGE_TYPE,
  REDUX_IFRAME_PAGE,
  IFRAME_PAGE_SUCCESS,
  REDUX_IFRAME_FINALE_PAGE_LINKS,
  REDUX_IFRAME_FINAL_PAGE_DISTRIBUTION_TYPE,
} from "../../constant";
import { actionReducerNumberSet } from "../../../../redux/tools/createReducer/createReducerNumber";
import { Offer } from "../../type/Offer";
import { FINAL_PAGE_RESULT_CASCADE } from "../../constant/cascadeResult";
import { CascadeResult } from "../../type/CascadeResult";
import { actionReducerArraySet } from "../../../../redux/tools/createReducer/createReducerArray";
import { FinalePageLink } from "../../../form/type/FinalePageLink";
import {
  DISTRIBUTION_TYPE_EXCLUSIVE,
  DISTRIBUTION_TYPE_USER_CENTRIC,
} from "../../../offers/type/cascade/CascadeOfferItemDistributionType";
import { redirectIframe } from "./handleWebsocketMessage.action";

export const handleWebsocketMessageFinalePage = (data: any) => (
  dispatch: AppDispatchType
) => {
  const finalPageLinks = (Array.isArray(data?.message?.finalPageLinks)
    ? data.message.finalPageLinks
    : []
  ).map((item: any) => new FinalePageLink(item));
  dispatch(
    actionReducerArraySet(REDUX_IFRAME_FINALE_PAGE_LINKS, finalPageLinks)
  );
  //show final page
  dispatch(
    actionReducerNumberSet(
      REDUX_IFRAME_FINALE_PAGE_TYPE,
      FINAL_PAGE_RESULT_CASCADE
    )
  );
  dispatch(
    actionReducerNumberSet(
      REDUX_IFRAME_FINAL_PAGE_DISTRIBUTION_TYPE,
      data?.message["type"]
    )
  );

  const success: CascadeResult[] = Array.isArray(data?.message?.resSuccess)
    ? data.message.resSuccess
    : [];
  const resFail: CascadeResult[] = Array.isArray(data?.message?.resFail)
    ? data?.message?.resFail
    : [];
  if (data?.message["type"] === DISTRIBUTION_TYPE_USER_CENTRIC) {
    dispatch(handleShowResultForFinalPage(success, resFail));
  } else if (data?.message["type"] === DISTRIBUTION_TYPE_EXCLUSIVE) {
    dispatch(redirectIframe(data?.message?.lead?.redirectUrl));
  } else {
    const successWithUrlCount = success.reduce(
      (all, item) => all + (!!item.redirectUrl ? 1 : 0),
      0
    );
    if (successWithUrlCount === 0) {
      dispatch(actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_SUCCESS));
    } else if (successWithUrlCount === 1) {
      //only one offer success with redirect url -> redirect
      const resToUse = success.find((item) => !!item.redirectUrl);
      if (resToUse) {
        dispatch(redirectIframe(resToUse.redirectUrl));
      } else {
        dispatch(
          actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_SUCCESS)
        );
      }
    } else {
      dispatch(handleShowResultForFinalPage(success, resFail));
    }
  }
};

const handleShowResultForFinalPage = (
  success: CascadeResult[],
  resFail: CascadeResult[]
) => (dispatch: AppDispatchType) => {
  dispatch(
    actionReducerNumberSet(
      REDUX_IFRAME_FINALE_PAGE_RES_SUCCESS,
      success.map((item) => ({
        ...item,
        offer: new Offer(item.offer),
      }))
    )
  );
  dispatch(
    actionReducerNumberSet(
      REDUX_IFRAME_FINALE_PAGE_RES_FAIL,
      resFail.map((item) => ({
        ...item,
        offer: new Offer(item.offer),
      }))
    )
  );
  dispatch(actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_FINAL_PAGE));
};
