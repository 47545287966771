import { RouteType } from "../../../types/routes";
import {
  getUrlConnectionCreator,
  getUrlConnectionCreatorDetail, getUrlConnectionCreatorLog
} from "../utils/getUrls";
import { ConnectionCreatorDetail, ConnectionCreatorsTable, RequestTableLog } from "../container";

export const connectionCreatorRoutes: RouteType = {
  id: "ConnectionCreators",
  path: "/connection-creators",
  children: [
    {
      path: getUrlConnectionCreator(),
      component: ConnectionCreatorsTable,
    },
    {
      path: getUrlConnectionCreatorDetail(":connectionCreatorId?"),
      component: ConnectionCreatorDetail,
    },
    {
      path: getUrlConnectionCreatorLog(),
      component: RequestTableLog,
    },
  ],
  component: null,
};
