import React, { FC, useState } from "react";
import { Flex } from "../../../../core/components/layout/Flex";
import {
  FormsIframePageProps,
  IframeRoot,
} from "../../container/FormsIframeContainer";
import IframeHeader, {
  useMoneySliderValue,
} from "../iframe/header/IframeHeader";
import { Helmet } from "react-helmet";
import { useHistoryLocation } from "../../../../core/history";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/store";
import {
  IframeSubtitle,
  IframeTitle,
  StrongPrimary,
} from "../../container/IframeLoading";
import { IframeHeaderLogos } from "../iframe/header/IframeHeaderLogos";
import { Translate } from "../../../../core/translate/Translate";
import { Price } from "../../../currency/components/Price";
import { Documents } from "./subComponents/Documents";
import { IframeButtons } from "./subComponents/IframeButtons";
import { IframeSubmit } from "./subComponents/IframeSubmit";
import { IframeChangePhoneDialog } from "./subComponents/IframeChangePhoneDialog";
import { ReactComponent as PartyIcon } from "../../svg/party-icon.svg";
import { IFRAME_PHONE_NAME } from "../../constant";

const PartyIconRight = styled(PartyIcon)`
  height: 30px;
  width: 30px;
  margin-left: 15px;
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    min-height: 30px;
    min-width: 30px;
  }
`;

const PartyIconLeft = styled(PartyIconRight)`
  transform: rotateY(180deg);
  margin-right: 15px;
  margin-left: 0px;
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    margin-right: 5px;
  }
`;

const IframeTitleStyled = styled(IframeTitle)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  padding: 0 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    padding: 0 10px;
  }
`;

export const IframeSubtitleStyled = styled(IframeSubtitle)`
  margin-top: 0;
  margin-bottom: 24px;
`;

const RootPadding = styled.div<{
  $top?: boolean;
  $bottom?: boolean;
  $sides?: boolean;
}>`
  padding: ${({ theme, $sides, $bottom, $top }) =>
    theme.spacing(
      $top ? 4 : 0,
      $sides ? 4 : 0,
      $sides ? 4 : 0,
      $bottom ? 4 : 0
    )};
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    padding: 0;
  }
  width: 100%;
`;

export const IframeSmsVerify: FC<FormsIframePageProps> = ({ formData }) => {
  const location = useHistoryLocation<{ hideBg: "true" | null }>();
  const formValues = useSelector(
    (state: AppStateType) => state.iframe.formValues
  );
  const { moneyCzSlider } = useMoneySliderValue({
    formData,
  });
  const [pin, setPin] = useState("");
  const phone = formValues[IFRAME_PHONE_NAME] || "";
  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle="">
        <title itemProp="name">{formData.getMetaTitle() || ""}</title>
        <meta
          name="description"
          content={formData.getMetaDescription() || ""}
        />
      </Helmet>
      <IframeChangePhoneDialog />
      <IframeRoot
        $formData={formData}
        $noMarginBottom={true}
        $noPadding={true}
        $hideBg={location?.search?.hideBg === "true"}
      >
        <Flex fullWidth={true} flexDirection={"column"} alignItems={"center"}>
          <RootPadding $top={true} $sides={true}>
            <IframeHeaderLogos formData={formData} />
          </RootPadding>
          <ContentContainer>
            <RootPadding $top={true} $sides={true}>
              <IframeTitleStyled variant={"h3"} align={"center"}>
                <PartyIconLeft />
                <span>
                  <StrongPrimary>
                    <Translate id={"iframe.smsVerifyTitle1"} />{" "}
                  </StrongPrimary>
                  <Translate id={"iframe.smsVerifyTitle2"} />{" "}
                  <strong>
                    <Price
                      price={moneyCzSlider || 0}
                      currency={formData?.offer?.currency}
                    />
                  </strong>{" "}
                  <Translate id={"iframe.smsVerifyTitle3"} />{" "}
                  <strong>
                    <Translate id={"iframe.smsVerifyTitle4"} />{" "}
                  </strong>
                </span>
                <PartyIconRight />
              </IframeTitleStyled>

              <IframeSubtitle variant={"subtitle1"} align={"center"}>
                <Translate id={"iframe.smsVerifyText1"} />
                {"\xa0"}
                <strong>
                  +420&nbsp;{phone.replace(/\B(?=(\d{3})+(?!\d))/g, "\xa0")}
                </strong>
              </IframeSubtitle>

              <IframeHeader
                formData={formData}
                currentPage={2}
                formValues={formValues}
                onlyMoney={true}
              />
            </RootPadding>
          </ContentContainer>

          <ContentContainer>
            <Documents pin={pin} setPin={setPin} />
          </ContentContainer>
          <IframeButtons />
          <IframeSubmit formData={formData} pin={pin} />
        </Flex>
      </IframeRoot>
    </>
  );
};
