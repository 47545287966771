export const getUrlReportLeads = () => {
  return "/report-lead";
};

export const getUrlReportLeadHandler = () => {
  return "/lead-handler-report";
};

export const getUrlReports = () => {
  return "/reports";
};
