import { put } from "../../../core/api/utils/fetchApi";
import UserOfferConfig from "../type/UserOfferConfig";
import { getSubmitFormUrl } from "./postIframeContentForm";
import { getSubIdToRequest } from "./getSubIdFromUrl";

export const putIframeContentForm = (
  leadId: number,
  userOfferConfig: UserOfferConfig,
  formFilledValues: Record<string, any>,
  saleNow: boolean
) => {
  return put(
    getSubmitFormUrl(userOfferConfig) +
      "/" +
      leadId +
      "?saleNow=" +
      saleNow +
      getSubIdToRequest(),
    formFilledValues
  );
};
