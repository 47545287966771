import { ResponseConnectionProperty } from "./ResponseConnectionProperty";

export class CustomPostback {
  id: number | null = null;
  token: string = "";
  requestConnectionPropertiesFromBody: ResponseConnectionProperty[] = [];
  requestConnectionPropertiesFromUrl: ResponseConnectionProperty[] = [];

  constructor(obj?: any) {
    obj && Object.assign(this, obj);
    if (Array.isArray(obj?.requestConnectionPropertiesFromUrl)) {
      this.requestConnectionPropertiesFromUrl = obj.requestConnectionPropertiesFromUrl.map(
        (item: any) => new ResponseConnectionProperty(item)
      );
    }
    if (Array.isArray(obj?.requestConnectionPropertiesFromBody)) {
      this.requestConnectionPropertiesFromBody = obj.requestConnectionPropertiesFromBody.map(
        (item: any) => new ResponseConnectionProperty(item)
      );
    }
  }

  toJson() {
    const {
      token,
      id,
      requestConnectionPropertiesFromBody,
      requestConnectionPropertiesFromUrl,
    } = this;
    return {
      token,
      id,
      requestConnectionPropertiesFromBody: requestConnectionPropertiesFromBody.map(
        (item) => item.toJson()
      ),
      requestConnectionPropertiesFromUrl: requestConnectionPropertiesFromUrl.map(
        (item) => item.toJson()
      ),
    };
  }

  toTemplateJson() {
    const {
      token,
      requestConnectionPropertiesFromBody,
      requestConnectionPropertiesFromUrl,
    } = this;
    return {
      token,
      requestConnectionPropertiesFromBody: requestConnectionPropertiesFromBody.map(
        (item) => item.toTemplateJson()
      ),
      requestConnectionPropertiesFromUrl: requestConnectionPropertiesFromUrl.map(
        (item) => item.toTemplateJson()
      ),
    };
  }
}
