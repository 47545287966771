import { ENDPOINT_CURRENCY, REDUX_CURRENCY_CURRENCY } from "../../constants";
import { get, basicHandleError } from "../../../../core/api/utils/fetchApi";
import { AppDispatchType } from "../../../../redux/store";
import {
  actionLock,
  actionUnlock,
} from "../../../../core/layouts/store/actions/lockable.actions";
import { LockKey } from "../../../../core/layouts/utils/useIsLocked";
import { actionReducerArraySet } from "../../../../redux/tools/createReducer/createReducerArray";
import { Currency } from "../../type/Currency";

export const getLockKeyActionGetCurrency = (): LockKey => {
  return "gets" + ENDPOINT_CURRENCY;
};

export const getCurrency = () => async (dispatch: AppDispatchType) => {
  dispatch(actionLock(getLockKeyActionGetCurrency()));
  const res = await get(ENDPOINT_CURRENCY);
  dispatch(actionUnlock(getLockKeyActionGetCurrency()));
  if (res.ok) {
    const currency = res.data.map((item: any) => new Currency(item));
    dispatch(actionReducerArraySet(REDUX_CURRENCY_CURRENCY, currency));
  } else {
    dispatch(basicHandleError(res));
  }
};
