import { useSelector } from "react-redux";
import { AppStateType } from "../../../redux/store";

export type LockKey = string;

export const useIsLocked = (key?: LockKey) => {
  return useSelector((state: AppStateType) =>
    !key ? [] : state.lockable.locked
  ).some((item) => item === key);
};
