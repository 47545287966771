import {
  PAGE_OFFER_USER_CONFIG_APPROVE,
  PAGE_OFFERS_DETAIL,
  PAGE_OFFERS_EDIT,
  PAGE_OFFERS_LIST,
} from "../constants";

export const getUrlOfferList = () => {
  return "/offers/" + PAGE_OFFERS_LIST;
};

export const getUrlOfferUserConfigsNeedToApprove = () => {
  return "/offers/" + PAGE_OFFER_USER_CONFIG_APPROVE;
};

export const getUrlOfferDetail = (offerId: number | ":id") => {
  return "/offers/" + PAGE_OFFERS_DETAIL + "/" + offerId;
};

export const getUrlOfferEdit = (offerId: number | ":id") => {
  return "/offers/" + PAGE_OFFERS_EDIT + "/" + offerId;
};
