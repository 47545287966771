import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkDispatch } from "redux-thunk";
import { authReducer } from "../../modules/auth/store/reducer/auth.reducer";
import { themeReducer } from "../../core/theme/store/reducer/theme.reducer";
import { lockableReducer } from "../../core/layouts/store/reducer/lockable.reducer";
import { layoutReducer } from "../../core/layouts/store/reducer/layout.reducer";
import { translatesReducer } from "../../core/translate/store/reducer/translate.reducer";
import { iframeReducer } from "../../modules/formsIframe/store/store/iframe.reducer";
import { offerReducer } from "../../modules/offers/store/reducer/offers.reducer";
import { currencyReducer } from "../../modules/currency/store/reducer/currency.reducer";
import { settingsReducer } from "../../modules/setting/store/reducer/settings.reducer";
import { formReducer } from "../../modules/offers/store/reducer/forms.reducer";
import { validationReducer } from "../../modules/validation/store/reducer/validation.reducer";
import { userReducer } from "../../modules/auth/store/reducer/user.reducer";
import { formsReducer } from "../../modules/form/store/reducer/forms.reducer";
import { selectsReducer } from "../../core/components/inputs/inputHelp/formik/store/reducer/selectsReducer.reducer";
import { postBackReducer } from "../../modules/postbacks/store/reducer/postback.reducer";
import { payoutManager } from "../../modules/payout/store/reducer/payoutManager.reducer";
import { invoiceReducer } from "../../modules/invoice/store/redux/invoices.reducer";
import { advanceTableReducer } from "../../core/components/advanceTable/store/reducer/advanceTable.reducer";
import { reportsReducer } from "../../modules/reports/store/reducer/reports.reducer";
import { connectionCreatorsReducer } from "../../modules/connectionCreator/store/reducer/connectionCreatorsReducer.reducer";

const composeEnhancers = composeWithDevTools({});

const rootReducer = combineReducers({
  auth: authReducer,
  themeReducer,
  lockable: lockableReducer,
  layout: layoutReducer,
  iframe: iframeReducer,
  translate: translatesReducer,
  offer: offerReducer,
  postBack: postBackReducer,
  currency: currencyReducer,
  setting: settingsReducer,
  form: formReducer,
  validation: validationReducer,
  user: userReducer,
  forms: formsReducer,
  selects: selectsReducer,
  invoice: invoiceReducer,
  reports: reportsReducer,
  connectionCreators: connectionCreatorsReducer,
  payoutManager,
  advanceTable: advanceTableReducer,
});
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export type AppDispatchType = ThunkDispatch<any, any, any>;

export default store;

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;
