export class FormCustomTermsAndCondition {
  id?: number;
  userOfferConfigId?: string;
  value: string = "";
  name: string = "";
  formFieldName: string | null = null;

  constructor(obj: any) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    const { id, ...toSend } = this;
    return toSend;
  }
}
