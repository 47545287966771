export class ResponseConnectionPropertyCheck {
  public static OPERATOR_IS_TRUE = "is-true";
  public static OPERATOR_IS_FALSE = "is-false";
  //number
  public static OPERATOR_IS_SMALLER_THAN = "is-smaller-than";
  public static OPERATOR_IS_BIGGER_THAN = "is-bigger-than";
  public static OPERATOR_IS_BETWEEN_THAN = "is-between-than";
  public static OPERATOR_EQUALS = "equals";
  public static OPERATOR_IS = "is";

  public static OPERATOR_NO_FIELD = [
    ResponseConnectionPropertyCheck.OPERATOR_IS_TRUE,
    ResponseConnectionPropertyCheck.OPERATOR_IS_FALSE,
  ];

  public static OPERATOR_ONE_FIELD = [
    ResponseConnectionPropertyCheck.OPERATOR_IS_SMALLER_THAN,
    ResponseConnectionPropertyCheck.OPERATOR_IS,
    ResponseConnectionPropertyCheck.OPERATOR_EQUALS,
    ResponseConnectionPropertyCheck.OPERATOR_IS_BIGGER_THAN,
  ];

  accessor: string = "";
  requestConfigId: null | number = null;
  type: string | null = "basic";
  responseHandlerId: null | number = null;
  //for checking result
  neededToSuccessResult: boolean = false;
  operator: string | null = null;
  value: string = "";
  value2: string = "";
  //for type object
  id: number | null = null;

  constructor(obj?: any) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    const { id, accessor, operator, value2, value, type } = this;
    return {
      id,
      accessor,
      operator,
      value2,
      value,
      type,
    };
  }

  toTemplateJson() {
    const { accessor, operator, value2, value, type } = this;
    return {
      accessor,
      operator,
      value2,
      value,
      type,
    };
  }
}
