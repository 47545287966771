import { FC } from "react";
import { Flex } from "../../../../../core/components/layout/Flex";
import { TextField } from "@material-ui/core";
import styled from "styled-components/macro";

interface DocumentsProps {
  setPin: (val: string) => void;
  pin: string;
}

// const Wrapper = styled.div`
//   flex-direction: column;
//   display: flex;
// `;

const TextFieldStyled = styled(TextField)`
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  & input {
    font-size: 42px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 8px;
  }
`;

export const Documents: FC<DocumentsProps> = ({ pin, setPin }) => {
  return (
    <Flex fullWidth={true} flexDirection={"column"} justifyContent={"center"}>
      {/*<Flex marginLeft={"auto"} marginRight={"auto"}> TODO add*/}
      {/*  <Flex marginTop={"auto"} marginBottom={"auto"}>*/}
      {/*    <Translate id={"iframe.docInfo"} />*/}
      {/*  </Flex>*/}
      {/*  <IconButton>*/}
      {/*    <ArrowDropDownCircleIcon />*/}
      {/*  </IconButton>*/}
      {/*</Flex>*/}
      {/*<Wrapper></Wrapper>*/}
      <TextFieldStyled
        value={pin}
        onChange={(e) => setPin(e.target.value)}
        variant="outlined"
      />
    </Flex>
  );
};
