import { combineReducers } from "redux";
import { createReducerApiArray } from "../../../../../redux/tools/createReducer/createReducerApiArray";
import { SaveChartConfig } from "../../type/save/SaveChartConfig";
import {
  REDUX_CONNECTED_TABLE_DIALOG,
  REDUX_CONNECTED_TABLE_SAVES,
} from "../../constants";
import { createReducerDialog } from "../../../../../redux/tools/createReducer/createReducerDialog";

export const advanceTableReducer = combineReducers({
  saves: createReducerApiArray<SaveChartConfig>(REDUX_CONNECTED_TABLE_SAVES),
  dialog: createReducerDialog<SaveChartConfig>(REDUX_CONNECTED_TABLE_DIALOG),
});
