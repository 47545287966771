import { combineReducers } from "redux";
import { createReducerArray } from "../../../../redux/tools/createReducer/createReducerArray";
import {
  REDUX_LAYOUT_BREADCRUMBS,
  REDUX_LAYOUT_CONFIRM_DIALOG,
  REDUX_LAYOUT_EDIT_FILE,
  REDUX_LAYOUT_EDIT_FILE_PARENT,
  REDUX_LAYOUT_HEADER,
  REDUX_LAYOUT_SNACKBARS,
} from "../../../constants";
import { SnackbarMessage } from "../../../components/layout/snackbar/type";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";
import { File } from "../../../../modules/file/type/File";
import { HeaderType } from "../../type/HeaderType";
import { MyConfirmDialogProps } from "../../../components/MyConfirmDialog";
import { createReducerNumber } from "../../../../redux/tools/createReducer/createReducerNumber";
import { BreadcrumbsItemType } from "../../type/BreadcrumbsItemType";

export const layoutReducer = combineReducers({
  snackbars: createReducerArray<SnackbarMessage>(REDUX_LAYOUT_SNACKBARS, []),
  openEditFile: createReducerObject<File | null>(
    REDUX_LAYOUT_EDIT_FILE,
    null,
    File
  ),
  openFileParentId: createReducerNumber(REDUX_LAYOUT_EDIT_FILE_PARENT, null),

  confirmDialog: createReducerObject<MyConfirmDialogProps | null>(
    REDUX_LAYOUT_CONFIRM_DIALOG,
    null
  ),

  //header
  header: createReducerObject<HeaderType | null>(REDUX_LAYOUT_HEADER, null),
  breadcrumbs: createReducerArray<BreadcrumbsItemType>(
    REDUX_LAYOUT_BREADCRUMBS,
    []
  ),
});
