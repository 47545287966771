import { combineReducers } from "redux";
import { createReducerArray } from "../../../../redux/tools/createReducer/createReducerArray";
import {
  REDUX_OFFER_USER_CONFIG_APPROVE,
  REDUX_OFFER_USER_CONFIG_APPROVE_LAST_PAGE,
  REDUX_OFFER_USER_CONFIG_APPROVE_PAGE_SIZE,
  REDUX_OFFER_USER_CONFIG_APPROVE_PAGE,
  REDUX_OFFER_USER_CONFIG_OPEN_APPROVE,
} from "../../constants";
import { createReducerNumber } from "../../../../redux/tools/createReducer/createReducerNumber";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";
import UserOfferConfig from "../../../formsIframe/type/UserOfferConfig";

export const userOfferConfig = combineReducers({
  //OfferUserConfigsNeedToApproveTable
  userConfigForApprove: createReducerArray<UserOfferConfig>(
    REDUX_OFFER_USER_CONFIG_APPROVE,
    []
  ),
  page: createReducerNumber(REDUX_OFFER_USER_CONFIG_APPROVE_PAGE, null),
  lastPage: createReducerNumber(
    REDUX_OFFER_USER_CONFIG_APPROVE_LAST_PAGE,
    null
  ),
  pageSize: createReducerNumber(
    REDUX_OFFER_USER_CONFIG_APPROVE_PAGE_SIZE,
    null
  ),
  openToApprove: createReducerObject<UserOfferConfig | null>(
    REDUX_OFFER_USER_CONFIG_OPEN_APPROVE,
    null
  ),
});
