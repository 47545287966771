export const DO_NOT_USE_IF_REFUSED_REASON = "do-not-use-if-refused-reason";

export class CascadeOfferItemFilterCapping {
  time: number | null = null;
  maxCount: number | null = null;
  timeUnit: string = "";
  id: number | null = null;

  constructor(obj?: any | null) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    return this;
  }
}
