//offer
export const OFFER = "offer";
export const OFFER_CONNECTION_OFFER = "offerConnectionOffer";
export const OFFER_CONNECTION = "offerConnection";
export const OFFER_CONNECTION_POST = "offerConnectionPost";
export const LEAD_POST_BACK = "leadPostback";
export const CLICK_LEAD_POST_BACK = "clickLeadPostback";
export const POST_BACK = "postback";
export const FINAL_PAGE_LINK = "finalPageLink";
export const INVOICE = "invoice";
export const USER_OFFER_CONFIG = "user-offer-config";
export const OFFER_ADVANTAGE = "advantages";
export const OFFER_LOADING_PAGE = "loadingPages";
export const OFFER_ALLOWED_MARKETING_CHANNELS = "allowedMarketingChannels";
export const OFFER_PAYOUT_CONFIG = "offerPayoutConfigs";
export const OFFER_CASCADE_OFFER_ITEMS = "cascadeOfferItems";
export const OFFER_FINAL_PAGE_LINK = "finalPageLink";
export const LEAD_MARK_STATUSES = "leadMarkStatuses";
export const RECEIVE_LEAD = "receiveLead";
export const PAYOUT_MANAGER = "payoutManager";
export const CASCADE_OFFER_ITEM_FILTER = "cascadeOfferItemFilterValidation";
export const REQUEST_CONNECTION_PROPERTY_MODIFY =
  "requestConnectionPropertyModify";
export const CASCADE_OFFER_ITEM_FILTER_FORM_VALUE =
  "cascadeOfferItemFilterFormValue";
export const CASCADE_OFFER_ITEM_FILTER_AFFILIATE =
  "CascadeOfferItemFilterAffiliate";
export const CASCADE_OFFER_ITEM_FILTER_EXTERNAL =
  "cascadeOfferItemFilterExternal";
export const CASCADE_OFFER_ITEM_FILTER_CAPPING =
  "cascadeOfferItemFilterCapping";
export const CASCADE_OFFER_ITEM_FILTER_HISTORY =
  "cascadeOfferItemFilterHistory";
export const CASCADE_OFFER_ITEM_FILTER_TIME_BLACK_LIST =
  "cascadeOfferItemFilterTimeBlackList";
export const CASCADE_OFFER_ITEM_FILTER_RATIO_BETWEEN_FIELDS =
  "cascadeOfferItemFilterRatioBetweenFields";
//core
export const AUTO_CONNECTED_TABLE = "autoConnectedTable";
