import * as React from "react";
import { FC } from "react";
import { IframeFiledProps } from "../IframeInputsList";
import { IframeInputWrapper } from "./IframeInputWrapper";
import { useEffect } from "react";
import { Flex } from "../../../../../core/components/layout/Flex";
import styled from "styled-components";
import { Typography, useTheme } from "@material-ui/core";
import { addThemeColorsToText } from "../../../utils/addThemeColorsToText";

interface SelectItemProps {
  $selected: boolean;
}

const SelectItem = styled.div<SelectItemProps>`
  color: ${({ theme, $selected }) =>
    $selected ? theme.palette.primary.contrastText : theme.palette.text.main};
  background: ${({ theme, $selected }) =>
    $selected ? theme.palette.primary.main : "#E0E0E0"};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  font-size: 18px;
  padding: 17px 20px;
  margin: 2px 2px;
  cursor: pointer;
`;

export const IframeSelect: FC<IframeFiledProps> = ({
  onChange,
  formField,
  value,
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (formField.defaultValue && value === undefined) {
      onChange(formField.defaultValue);
    }
  }, [formField.defaultValue, value, onChange]);

  return (
    <IframeInputWrapper formField={formField}>
      <Flex flexDirection={"column"} fullWidth={true}>
        <Flex
          justifyContent={"center"}
          fullWidth={true}
          marginTop={"xxl"}
          marginBottom={"large"}
        >
          <Typography variant={"h3"} align={"center"}>
            <strong>
              {addThemeColorsToText(formField.visibleDescription || "", theme)}
            </strong>
          </Typography>
        </Flex>
        <Flex
          fullWidth={true}
          flexDirection={"row"}
          flexWrap={"wrap"}
          justifyContent={"center"}
        >
          {formField.formFieldSelectItems.map((item) => (
            <SelectItem
              key={item.value}
              $selected={item.value === value}
              onClick={() => onChange(item.value)}
            >
              {item.visibleDescription}
            </SelectItem>
          ))}
        </Flex>
      </Flex>
    </IframeInputWrapper>
  );
};
