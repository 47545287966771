import { ENDPOINT_OFFER_PRODUCT } from "../../../../constants";
import {
  get,
  basicHandleError,
} from "../../../../../../core/api/utils/fetchApi";
import { AppDispatchType } from "../../../../../../redux/store";
import { REDUX_OFFER_PRODUCTS } from "../../../../constants";
import { OfferProduct } from "../../../../type/OfferProduct";
import { LockKey } from "../../../../../../core/layouts/utils/useIsLocked";
import {
  actionLock,
  actionUnlock,
} from "../../../../../../core/layouts/store/actions/lockable.actions";
import { actionReducerObjectSet } from "../../../../../../redux/tools/createReducer/createReducerObject";

export const getLockKeyActionGetOfferProducts = (): LockKey => {
  return "get" + ENDPOINT_OFFER_PRODUCT;
};

export const getOfferProductsAction = () => async (
  dispatch: AppDispatchType
) => {
  dispatch(actionLock(getLockKeyActionGetOfferProducts()));
  const res = await get(ENDPOINT_OFFER_PRODUCT);
  if (res.ok) {
    const items = res.data.map((item: any) => new OfferProduct(item));
    dispatch(actionReducerObjectSet(REDUX_OFFER_PRODUCTS, items));
  } else {
    dispatch(basicHandleError(res));
  }
  dispatch(actionUnlock(getLockKeyActionGetOfferProducts()));
};
