import { OfferConnection } from "../../connectionCreator/type/OfferConnection";

export class OfferConnectionOffer {
  postbackToken: string | null = null;
  offerConnectionId: number | null = null;
  id: number | null = null;
  offerConnection: OfferConnection | null = null;

  constructor(obj?: any) {
    obj && Object.assign(this, obj);
    if (obj?.offerConnection) {
      this.offerConnection = new OfferConnection(obj.offerConnection);
    }
  }

  toJson() {
    const { offerConnectionId } = this;
    return {
      offerConnectionId,
    };
  }
}
