import React from "react";

export const ReportLeads = React.lazy(
  () => /* webpackChunkName: 'ReportLeads'*/ import("./ReportLeads")
);

export const ReportsList = React.lazy(
  () => /* webpackChunkName: 'ReportsList'*/ import("./ReportsList")
);

export const ReportLeadHandlers = React.lazy(
  () =>
    /* webpackChunkName: 'ReportLeadHandlers'*/ import("./ReportLeadHandlers")
);
