import {
  actionReducerArrayDelete,
  actionReducerArrayPushOrUpdate,
  ReduxActionArrayPush,
} from "../../../../redux/tools/createReducer/createReducerArray";
import { REDUX_LOCKABLE_LOCKED } from "../../../constants";

export const actionLock = (keyToLock: string): ReduxActionArrayPush => {
  return actionReducerArrayPushOrUpdate(REDUX_LOCKABLE_LOCKED, keyToLock);
};

export const actionUnlock = (keyToUnlock: string) => {
  return actionReducerArrayDelete(REDUX_LOCKABLE_LOCKED, keyToUnlock);
};
