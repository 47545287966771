import { ReduxAction } from "../../type";
import { RESET, SET, TOGGLE } from "../../constants";

type ReduxActionOperationBoolean = typeof SET | typeof TOGGLE | typeof RESET;

export interface ReduxActionBoolean extends ReduxAction {
  operation: ReduxActionOperationBoolean;
}

export interface ReduxActionBooleanSet extends ReduxAction {
  operation: typeof SET;
}
export const actionReducerBooleanSet = (
  type: string,
  payload: any
): ReduxActionBooleanSet => ({
  type,
  operation: SET,
  payload,
});

export interface ReduxActionBooleanReset extends ReduxAction {
  operation: typeof RESET;
}
export const actionReducerBooleanReset = (
  type: string,
  payload: any
): ReduxActionBooleanReset => ({
  type,
  operation: RESET,
  payload,
});

export interface ReduxActionBooleanToggle extends ReduxAction {
  operation: typeof TOGGLE;
}
export const actionReducerBooleanToggle = (
  type: string,
  payload: any
): ReduxActionBooleanToggle => ({
  type,
  operation: TOGGLE,
  payload,
});

export const createReducerBoolean = (
  actionType: string,
  defaultState: boolean
) => (state = defaultState, action: ReduxActionBoolean): boolean => {
  if (action.type === actionType) {
    const actions: Record<ReduxActionOperationBoolean, () => boolean> = {
      [SET]: () => {
        if (action.payload === undefined) {
          throw new Error(
            `Can not set undefined to reducer. Reducer: ${actionType}, operation: ${SET}`
          );
        }
        return action.payload;
      },
      [TOGGLE]: () => !state,
      [RESET]: () => defaultState,
    };
    if (actions[action.operation]) {
      return actions[action.operation]();
    } else {
      throw new Error(
        `Unknown operation ${action.operation} for reducer: ${action.type}`
      );
    }
  }
  return state;
};
