import { RouteType } from "../../../types/routes";
import {
  getUrlOfferDetail,
  getUrlOfferEdit,
  getUrlOfferList,
  getUrlOfferUserConfigsNeedToApprove,
} from "../utils/getUrlOfferList";
import {
  Offers,
  OfferEdit,
  OfferDetail,
  OfferUserConfigsNeedToApprove,
} from "../container";
import { useInitOfferList } from "../utils/init/useInitOfferList";
import { getUseInitOfferDetail } from "../utils/init/useInitOfferDetail";
import { useInitOfferUserConfigsNeedToApprove } from "../utils/init/useInitOfferUserConfigsNeedToApprove";

export const offerRoutes: RouteType = {
  id: "Offer",
  path: "/offer",
  children: [
    {
      useInit: useInitOfferList,
      path: getUrlOfferList(),
      component: Offers,
    },
    {
      useInit: useInitOfferUserConfigsNeedToApprove,
      path: getUrlOfferUserConfigsNeedToApprove(),
      component: OfferUserConfigsNeedToApprove,
    },
    {
      useInit: getUseInitOfferDetail(false),
      path: getUrlOfferEdit(":id"),
      component: OfferEdit,
    },
    {
      useInit: getUseInitOfferDetail(true),
      path: getUrlOfferDetail(":id"),
      component: OfferDetail,
    },
  ],
  component: null,
};
