export class Currency {
  display?: string;
  howDisplay?: string;
  id: number | null = null;
  iso?: string;

  constructor(obj: any) {
    obj && Object.assign(this, obj);
  }
}
