import { combineReducers } from "redux";
import { createReducerArray } from "../../../../redux/tools/createReducer/createReducerArray";
import { Offer } from "../../../formsIframe/type/Offer";
import {
  REDUX_OFFER_ADVANTAGE,
  REDUX_OFFER_CATEGORIES,
  REDUX_OFFER_COUNTRIES,
  REDUX_OFFER_CUSTOM_TERMS_AND_CONDITION_DIALOG,
  REDUX_OFFER_CUSTOM_TERMS_AND_CONDITIONS,
  REDUX_OFFER_OFFER,
  REDUX_OFFER_OFFERS,
  REDUX_OFFERLAST_PAGE,
  REDUX_OFFER_OFFERS_LIGHT,
  REDUX_OFFER_OPEN_CREATE_NEW,
  REDUX_OFFER_OPEN_CREATE_NEW_CASCADE,
  REDUX_OFFER_PAGE,
  REDUX_OFFER_PAGE_SIZE,
  REDUX_OFFER_PRODUCTS,
  REDUX_OFFER_USER_OFFER_CONFIGS,
  REDUX_SUBMIT_OFFER_FROM_HEADER,
  REDUX_OFFER_PREVIEW_IFRAME_SETTING,
  REDUX_OFFER_STATS,
  REDUX_OFFER_EDIT_FORM_IFRAME_SETTING_DIALOG,
  REDUX_OFFER_CONNECTION_OFFER,
} from "../../constants";
import { offersFilter } from "./offersFilter.reducer";
import { createReducerNumber } from "../../../../redux/tools/createReducer/createReducerNumber";
import { createReducerBoolean } from "../../../../redux/tools/createReducer/createReducerBoolean";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";
import { OfferAdvantage } from "../../type/OfferAdvantage";
import { OfferProduct } from "../../type/OfferProduct";
import { OfferCategory } from "../../type/OfferCategory";
import { OfferCountry } from "../../type/OfferCountry";
import UserOfferConfig from "../../../formsIframe/type/UserOfferConfig";
import { FormCustomTermsAndCondition } from "../../../formsIframe/type/FormCustomTermsAndCondition";
import { userOfferConfig } from "./userOfferConfig.reducer";
import { createReducerDialog } from "../../../../redux/tools/createReducer/createReducerDialog";
import { createReducerApiArray } from "../../../../redux/tools/createReducer/createReducerApiArray";
import { OfferConnectionOffer } from "../../type/OfferConnectionOffer";

export const offerReducer = combineReducers({
  openCreateNewOffer: createReducerBoolean(REDUX_OFFER_OPEN_CREATE_NEW, false),
  openCreateNewOfferCascade: createReducerBoolean(
    REDUX_OFFER_OPEN_CREATE_NEW_CASCADE,
    false
  ),

  //list
  offers: createReducerArray<Offer>(REDUX_OFFER_OFFERS, []),
  page: createReducerNumber(REDUX_OFFER_PAGE, null),
  lastPage: createReducerNumber(REDUX_OFFERLAST_PAGE, null),
  pageSize: createReducerNumber(REDUX_OFFER_PAGE_SIZE, null),
  offersFilter,
  offersStats: createReducerApiArray<Offer>(REDUX_OFFER_STATS),

  //dialog
  offerConnectionOfferDialog: createReducerDialog<OfferConnectionOffer>(
    REDUX_OFFER_CONNECTION_OFFER
  ),

  //offer select
  offersLight: createReducerArray<Offer>(REDUX_OFFER_OFFERS_LIGHT, []),

  //detail
  offer: createReducerObject<Offer | null>(REDUX_OFFER_OFFER, null, Offer),
  advantage: createReducerArray<OfferAdvantage>(REDUX_OFFER_ADVANTAGE, []),
  userOfferConfigs: createReducerArray<UserOfferConfig>(
    REDUX_OFFER_USER_OFFER_CONFIGS,
    []
  ),
  submitFromHeader: createReducerBoolean(REDUX_SUBMIT_OFFER_FROM_HEADER, false),
  iframeConfDetailDialog: createReducerDialog<UserOfferConfig>(
    REDUX_OFFER_PREVIEW_IFRAME_SETTING
  ),
  openEditFormIframeSettingDialog: createReducerDialog<Offer>(
    REDUX_OFFER_EDIT_FORM_IFRAME_SETTING_DIALOG
  ),

  //customTermsAndCondition
  customTermsAndConditions: createReducerArray<FormCustomTermsAndCondition>(
    REDUX_OFFER_CUSTOM_TERMS_AND_CONDITIONS,
    []
  ),
  openCustomTermsDialog: createReducerObject<
    "new" | FormCustomTermsAndCondition | null
  >(REDUX_OFFER_CUSTOM_TERMS_AND_CONDITION_DIALOG, null),

  //offer property
  products: createReducerArray<OfferProduct>(REDUX_OFFER_PRODUCTS, []),
  categories: createReducerArray<OfferCategory>(REDUX_OFFER_CATEGORIES, []),
  countries: createReducerArray<OfferCountry>(REDUX_OFFER_COUNTRIES, []),
  userOfferConfig,
});
