import { FormFieldConfig } from "./FormFieldConfig";
import { FormFieldSelectItem } from "./FormFieldSelectItem";
import { GridSize } from "@material-ui/core";
import {
  TYPE_PHONE,
  TYPE_TEXT,
  TYPE_BANK_ACCOUNT,
  TYPE_EMAIL,
  TYPE_ADDRESS,
  TYPE_BANK_CODE,
  TYPE_CHECKBOX,
  TYPE_CUSTOM_HTML,
  TYPE_CZ_ZIP,
  TYPE_CZK_PRICE,
  TYPE_DATE,
  TYPE_HOUSE_NUMBER,
  TYPE_IDENTIFY_CARD_NUMBER,
  TYPE_NIN,
  TYPE_NUMBER,
  TYPE_ONLY_NUMBER,
  TYPE_ONLY_TEXT,
  TYPE_PHONE_CZ,
  TYPE_SELECT,
  TYPE_SLIDER,
  TYPE_SLIDER_MONEY,
  TYPE_SPACING,
  TYPE_TEXT_TITLE,
  TYPE_TIMER,
  TYPE_USER_AGENT,
  TYPE_CHECKBOX_TERMS,
} from "../constant/formFieldType";

export class FormField {
  id: number | null = null;
  required?: boolean;
  fakeRequired?: boolean;
  visibleDescription?: string;
  systemName: string = "";
  docNote: string = "";
  exampleValue: string = "";
  type?: string;
  weight?: number;
  xlWidth?: GridSize;
  smWidth?: GridSize;
  mdWidth?: GridSize;
  formId?: number;
  formFieldConfigs: FormFieldConfig[] = [];
  formFieldSelectItems: FormFieldSelectItem[] = [];
  page: number = 0;
  notShowIfSystemNameEquals?: string = undefined;
  showOnlyIfSystemNameEquals?: string = undefined;
  defaultValue?: any;

  constructor(obj: any) {
    obj && Object.assign(this, obj);
    if (this.formFieldConfigs) {
      this.formFieldConfigs = this.formFieldConfigs.map(
        (item) => new FormFieldConfig(item)
      );
    }
    if (this.formFieldSelectItems) {
      this.formFieldSelectItems = this.formFieldSelectItems.map(
        (item) => new FormFieldSelectItem(item)
      );
    }
  }

  isRequired() {
    return this.required;
  }

  getType() {
    switch (this.type) {
      case TYPE_TEXT:
      case TYPE_PHONE:
      case TYPE_PHONE_CZ:
      case TYPE_USER_AGENT:
      case TYPE_ADDRESS:
      case TYPE_EMAIL:
      case TYPE_BANK_CODE:
      case TYPE_BANK_ACCOUNT:
      case TYPE_CZ_ZIP:
      case TYPE_ONLY_TEXT:
      case TYPE_HOUSE_NUMBER:
      case TYPE_DATE:
      case TYPE_NIN:
      case TYPE_IDENTIFY_CARD_NUMBER:
        return "string";
      case TYPE_NUMBER:
      case TYPE_SLIDER:
      case TYPE_SLIDER_MONEY:
      case TYPE_CZK_PRICE:
      case TYPE_ONLY_NUMBER:
        return "number";
      case TYPE_CHECKBOX:
      case TYPE_CHECKBOX_TERMS:
        return "boolean";
      case TYPE_SELECT:
        return "enum";
      case TYPE_TEXT_TITLE:
      case TYPE_SPACING:
      case TYPE_TIMER:
      case TYPE_CUSTOM_HTML:
      default:
        return null;
    }
  }

  doNotUse(formFiledValues: Record<string, any>): boolean {
    const runNotShowIfSystemNameEqualsRes = this.notShowIfSystemNameEquals
      ? this.runHideConditions(formFiledValues, this.notShowIfSystemNameEquals)
      : false;
    return runNotShowIfSystemNameEqualsRes
      ? true
      : this.showOnlyIfSystemNameEquals
      ? !this.runHideConditions(
          formFiledValues,
          this.showOnlyIfSystemNameEquals
        )
      : false;
  }

  runHideConditions(formFiledValues: Record<string, any>, condition: string) {
    const config = condition.split("=");
    if (config.length === 1) {
      return !!formFiledValues[config[0]];
    } else if (config.length === 2) {
      const getAliasValues = (value: string) => {
        //if you change it you need change also on api!
        const ret: any[] = [value];
        if (value === "1") {
          ret.push(true);
          ret.push(1);
        } else if (value === "0") {
          ret.push(false);
          ret.push(0);
        }
        return ret;
      };
      const valueNeededToDisable = config[1].split(",");
      const valueNeededToDisableAlias: any[] = [
        ...valueNeededToDisable.map((item) => getAliasValues(item)).flat(),
      ];
      return valueNeededToDisableAlias.includes(formFiledValues[config[0]]);
    }
    return false;
  }
}
