import { combineReducers } from "redux";
import { createReducerArray } from "../../../../redux/tools/createReducer/createReducerArray";
import { Form } from "../../../formsIframe/type/Form";
import {
  REDUX_FORM,
  REDUX_FORMS,
  REDUX_FORMS_LAST_PAGE,
  REDUX_FORMS_LIST,
  REDUX_FORMS_PAGE,
  REDUX_FORMS_PAGE_SIZE,
} from "../../constants";
import { createReducerNumber } from "../../../../redux/tools/createReducer/createReducerNumber";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";
import { finalPageLinksReducer } from "./finalPageLinks.reducer";
import { createReducerApiArray } from "../../../../redux/tools/createReducer/createReducerApiArray";

export const formsReducer = combineReducers({
  //list TODO remove and use formsList
  forms: createReducerArray<Form>(REDUX_FORMS, []),
  lastPage: createReducerNumber(REDUX_FORMS_LAST_PAGE, null),
  pageSize: createReducerNumber(REDUX_FORMS_PAGE_SIZE, null),
  page: createReducerNumber(REDUX_FORMS_PAGE, null),

  formsList: createReducerApiArray<Form>(REDUX_FORMS_LIST),

  //detail
  form: createReducerObject<Form | null>(REDUX_FORM, null),
  finalPageLinksReducer,
});
