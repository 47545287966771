// Auth components
import React from "react";

export const Invoices = React.lazy(
  () => /* webpackChunkName: 'Invoices'*/ import("./Invoices")
);

export const InvoicesAdminView = React.lazy(
  () => /* webpackChunkName: 'InvoicesAdminView'*/ import("./InvoicesAdminView")
);
