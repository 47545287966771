import { Flex } from "../../../../../core/components/layout/Flex";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { CascadeResult } from "../../../type/CascadeResult";
import { FC } from "react";
import {
  Translate,
  useTranslateFunction,
} from "../../../../../core/translate/Translate";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../redux/store";
import { useMoneySliderValue } from "../../iframe/header/IframeHeader";
import UserOfferConfig from "../../../type/UserOfferConfig";
import { PriceString } from "../../../../currency/components/PriceString";
import { Close } from "@material-ui/icons";
import { FinalePageLink } from "../../../../form/type/FinalePageLink";
import { DISTRIBUTION_TYPE_USER_CENTRIC } from "../../../../offers/type/cascade/CascadeOfferItemDistributionType";
import { postUserCentricResult } from "../../../store/action/postUserCentricResult.action";

interface OffersListProps {
  result: CascadeResult[];
  disabled: boolean;
  formData: UserOfferConfig;
  finalPageLinks: FinalePageLink[];
}

const ButtonStyled = styled(Button)`
  padding: 8px ${({ disabled }) => (disabled ? 12 : 20)}px;
  font-size: 17px;
  opacity: 1;
  min-width: unset;
  border-radius: 6px;
`;

const Root = styled(Flex)<{ $disabled: boolean }>`
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  margin-bottom: ${({ $disabled }) => ($disabled ? 50 : 0)}px;
  margin-top: ${({ $disabled }) => (!$disabled ? 50 : 0)}px;
  max-width: 100%;
  overflow-x: auto;
`;
const Text = styled.span`
  font-size: 19px;

  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    font-size: 15px;
  }
`;

const Logo = styled.img`
  padding: 6px 16px;
  font-size: 15px;
  height: 70px;
`;

const TableCellStyled = styled(TableCell)`
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    padding: 5px 10px;
  }
`;

export const OffersList: FC<OffersListProps> = ({
  result,
  disabled,
  formData,
  finalPageLinks,
}) => {
  const formValues = useSelector(
    (state: AppStateType) => state.iframe.formValues
  );
  const { moneyCzSlider } = useMoneySliderValue({
    formData,
  });
  const dispatch = useDispatch();
  const t = useTranslateFunction();
  const finalPageType = useSelector(
    (state: AppStateType) => state.iframe.finalPage.finalPageDistributionType
  );
  const accessToken = useSelector(
    (state: AppStateType) => state.iframe.loader.accessToken
  );
  if (
    (result.length === 0 && finalPageLinks.length === 0) ||
    !formData.offer ||
    !formData.offer.currency
  ) {
    return null;
  }
  const loan =
    PriceString({
      price: moneyCzSlider || 0,
      currency: formData.offer.currency,
    }) +
    " " +
    t("iframe.loanFor") +
    " " +
    (formValues["period"] || 1) +
    " " +
    t("iframe.loanForDay");
  return (
    <Root fullWidth={true} $disabled={disabled}>
      <Table>
        {disabled ? (
          <TableHead>
            <TableRow>
              <TableCellStyled align="center" colSpan={5}>
                <Text>
                  <Translate id={"iframe.thisOfferRefusedYou"} />
                </Text>
              </TableCellStyled>
            </TableRow>
          </TableHead>
        ) : (
          <TableHead>
            <TableRow>
              <TableCellStyled />
              <TableCellStyled align="right">
                <Text>
                  <strong>
                    <Translate id={"iframe.loan"} />
                  </strong>
                </Text>
              </TableCellStyled>
              <TableCellStyled align="right">
                <Text>
                  <strong>
                    <Translate id={"iframe.charge"} />
                  </strong>
                </Text>
              </TableCellStyled>
              <TableCellStyled align="right">
                <Text>
                  <strong>
                    <Translate id={"iframe.loanSuccessProbability"} />
                  </strong>
                </Text>
              </TableCellStyled>
              <TableCellStyled />
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {result.map((row) => (
            <TableRow hover={!disabled} key={row.offer.id}>
              <TableCellStyled align="right" component="th" scope="row">
                {row.offer.logoFile && (
                  <Logo src={row.offer.logoFile?.getPublicPath(false)} />
                )}
              </TableCellStyled>
              <TableCellStyled align="right">
                <Text>{loan}</Text>
              </TableCellStyled>
              <TableCellStyled align="right">
                <Text>-</Text>
              </TableCellStyled>
              <TableCellStyled align="right">
                <Text>90 %</Text>
              </TableCellStyled>
              <TableCellStyled align="right">
                <ButtonStyled
                  variant={"contained"}
                  disabled={disabled}
                  color={"primary"}
                  onClick={() => {
                    if (finalPageType === DISTRIBUTION_TYPE_USER_CENTRIC) {
                      if (accessToken && row.cascadeOfferItemId) {
                        dispatch(
                          postUserCentricResult(
                            accessToken,
                            row.cascadeOfferItemId
                          )
                        );
                      }
                    } else {
                      window.open(row.redirectUrl, "_blank")?.focus();
                    }
                  }}
                >
                  {disabled ? <Close /> : <Translate id={"iframe.finish"} />}
                </ButtonStyled>
              </TableCellStyled>
            </TableRow>
          ))}
          {finalPageLinks
            .sort((a, b) => a.weight - b.weight)
            .map((finalPageLink: FinalePageLink) => (
              <TableRow hover={!disabled} key={"test" + finalPageLink.id}>
                <TableCellStyled align="right" component="th" scope="row">
                  {finalPageLink.logoFile && (
                    <Logo src={finalPageLink.logoFile?.getPublicPath(false)} />
                  )}
                </TableCellStyled>
                <TableCellStyled align="right">
                  <Text>{loan}</Text>
                </TableCellStyled>
                <TableCellStyled align="right">
                  <Text>{finalPageLink.charge}</Text>
                </TableCellStyled>
                <TableCellStyled align="right">
                  <Text>{finalPageLink.change} %</Text>
                </TableCellStyled>
                <TableCellStyled align="right">
                  <ButtonStyled
                    variant={"contained"}
                    disabled={disabled}
                    color={"primary"}
                    onClick={() => {
                      window.open(finalPageLink.link, "_blank")?.focus();
                    }}
                  >
                    {disabled ? <Close /> : <Translate id={"iframe.finish"} />}
                  </ButtonStyled>
                </TableCellStyled>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Root>
  );
};
