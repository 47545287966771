import { combineReducers } from "redux";
import { createReducerApiArray } from "../../../../redux/tools/createReducer/createReducerApiArray";
import {
  REDUX_CLICK_LEAD_MARK_STATUSES_DIALOG,
  REDUX_LEAD_MARK_STATUSES,
  REDUX_LEAD_MARK_STATUSES_DIALOG,
  REDUX_POSTBACK,
  REDUX_POSTBACK_DIALOG,
  REDUX_POSTBACK_DOC_DIALOG,
  REDUX_POSTBACK_LEAD,
  REDUX_POSTBACK_LEAD_CLICK,
  REDUX_POSTBACK_LEAD_CLICK_DIALOG,
  REDUX_POSTBACK_LEAD_DIALOG,
} from "../../constant";
import { Lead } from "../../type/Lead";
import { createReducerDialog } from "../../../../redux/tools/createReducer/createReducerDialog";
import { ClickLead } from "../../type/ClickLead";
import { Postback } from "../../type/Postback";
import { LeadMarkStatuses } from "../../type/LeadMarkStatuses";

export const postBackReducer = combineReducers({
  lead: createReducerApiArray<Lead>(REDUX_POSTBACK_LEAD),
  leadDialog: createReducerDialog<Lead>(REDUX_POSTBACK_LEAD_DIALOG),

  leadClick: createReducerApiArray<ClickLead>(REDUX_POSTBACK_LEAD_CLICK),
  leadClickDialog: createReducerDialog<ClickLead>(
    REDUX_POSTBACK_LEAD_CLICK_DIALOG
  ),

  postback: createReducerApiArray<Postback>(REDUX_POSTBACK),
  postbackDialog: createReducerDialog<Postback>(REDUX_POSTBACK_DIALOG),
  codeConnectionDialog: createReducerDialog<Postback | null>(
    REDUX_POSTBACK_DOC_DIALOG
  ),

  leadMarkStatusesDialog: createReducerDialog<LeadMarkStatuses>(
    REDUX_LEAD_MARK_STATUSES_DIALOG
  ),
  leadMarkStatuses: createReducerApiArray<LeadMarkStatuses>(
    REDUX_LEAD_MARK_STATUSES
  ),
  clickMarkStatusesDialog: createReducerDialog<Postback>(
    REDUX_CLICK_LEAD_MARK_STATUSES_DIALOG
  ),
});
