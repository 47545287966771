export class OfferAllowedMarketingChannel {
  id?: number = undefined;
  offerId: number | null = null;
  allowed: boolean = true;
  source: string = "";
  note: string = "";

  constructor(obj?: any | null) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    const { offerId, ...json } = this;
    return { ...json };
  }
}
