import { basicHandleError, get } from "../../../api/utils/fetchApi";
import {
  ENDPOINT_TRANSLATES,
  REDUX_TRANSLATE_CURRENT_LANGUAGE_INDEX,
  REDUX_TRANSLATE_LANGUAGES,
  REDUX_TRANSLATE_TRANSLATES,
} from "../../contants";
import { AppDispatchType } from "../../../../redux/store";
import { actionReducerArraySet } from "../../../../redux/tools/createReducer/createReducerArray";
import { actionReducerNumberSet } from "../../../../redux/tools/createReducer/createReducerNumber";
import { actionReducerObjectSet } from "../../../../redux/tools/createReducer/createReducerObject";

export const getTranslatesAction = () => (dispatch: AppDispatchType) => {
  get(ENDPOINT_TRANSLATES).then((res) => {
    if (res.ok) {
      dispatch(
        actionReducerObjectSet(REDUX_TRANSLATE_TRANSLATES, res.data.translates)
      );
      dispatch(
        actionReducerArraySet(REDUX_TRANSLATE_LANGUAGES, res.data.languages)
      );
      dispatch(
        actionReducerNumberSet(REDUX_TRANSLATE_CURRENT_LANGUAGE_INDEX, 0)
      );
    } else {
      dispatch(basicHandleError(res));
    }
  });
};
