import { RouteType } from "../../../types/routes";
import { getUrlDashboard } from "../utils/getUrlDashboard";
import { Dashboard } from "../container";

export const dashboardRoutes: RouteType = {
  id: "Dashboard",
  path: getUrlDashboard(),
  component: Dashboard,
  children: null,
};
