import { RouteType } from "../../../types/routes";
import { PayoutManager } from "../container";
import { getUrlPayoutManager } from "../utils/getUrlPayoutManager";

export const payoutRoutes: RouteType = {
  id: "payout",
  path: "/payout",
  children: [
    {
      path: getUrlPayoutManager(),
      component: PayoutManager,
    },
  ],
  component: null,
};
