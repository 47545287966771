import { VALIDATION_TYPE_STRING, ValidationType } from "../ValidationType";
import BaseSchema from "yup/lib/schema";

export const DEFAULT_VALUE_IS_NOT_SET = "_DEFAULT_VALUE_IS_NOT_SET";

export abstract class VsiBase {
  type: ValidationType = VALIDATION_TYPE_STRING;
  translateId: string = "";
  nullable: boolean = false;
  forceValue: any = DEFAULT_VALUE_IS_NOT_SET;
  required: boolean = false;

  abstract toYup(key: string): BaseSchema | undefined;
}
