import { FC } from "react";
import { Flex } from "../../../../../core/components/layout/Flex";
import { Translate } from "../../../../../core/translate/Translate";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../redux/store";
import { actionReducerDialogOpenEdit } from "../../../../../redux/tools/createReducer/createReducerDialog";
import {
  IFRAME_PHONE_NAME,
  REDUX_IFRAME_SMS_VERIFY_CHANGE_PHONE,
} from "../../../constant";
import { actionResendSms } from "../../../store/action/resendSms.action";

const Link = styled.span`
  cursor: pointer;
  text-decoration: underline;
  flex-direction: column;
  display: flex;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 4px;
  margin-top: 4px;
`;

const Point = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  margin: auto 8px;
`;

const Root = styled(Flex)`
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 15px;

  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const IframeButtons: FC = () => {
  const dispatch = useDispatch();
  const formValues = useSelector(
    (state: AppStateType) => state.iframe.formValues
  );
  const accessToken =
    useSelector((state: AppStateType) => state.iframe.loader.accessToken) || "";
  const phone = formValues[IFRAME_PHONE_NAME];
  return (
    <Root
      fullWidth={true}
      flexDirection={"row"}
      justifyContent={"center"}
      flexWrap={"wrap"}
    >
      <Link
        onClick={(e) => {
          dispatch(actionResendSms(phone, accessToken));
        }}
      >
        <Translate id={"iframe.resendSms"} />
      </Link>
      <Point />
      <Link
        onClick={(e) => {
          dispatch(
            actionReducerDialogOpenEdit(
              REDUX_IFRAME_SMS_VERIFY_CHANGE_PHONE,
              phone
            )
          );
        }}
      >
        <Translate id={"iframe.changePhoneNumber"} />
      </Link>
      {/*<Point /> TODO add*/}
      {/*<Link onClick={(e) => {}}>*/}
      {/*  <Translate id={"iframe.smsDidNotArrive"} />*/}
      {/*</Link>*/}
    </Root>
  );
};
