import { ENDPOINT_OFFER_COUNTRY } from "../../../../constants";
import {
  get,
  basicHandleError,
} from "../../../../../../core/api/utils/fetchApi";
import { AppDispatchType } from "../../../../../../redux/store";
import { REDUX_OFFER_COUNTRIES } from "../../../../constants";
import { LockKey } from "../../../../../../core/layouts/utils/useIsLocked";
import {
  actionLock,
  actionUnlock,
} from "../../../../../../core/layouts/store/actions/lockable.actions";
import { actionReducerObjectSet } from "../../../../../../redux/tools/createReducer/createReducerObject";
import { OfferCountry } from "../../../../type/OfferCountry";

export const getLockKeyActionGetOfferCountries = (): LockKey => {
  return "get" + ENDPOINT_OFFER_COUNTRY;
};

export const getOfferCountriesAction = () => async (
  dispatch: AppDispatchType
) => {
  dispatch(actionLock(getLockKeyActionGetOfferCountries()));
  const res = await get(ENDPOINT_OFFER_COUNTRY);
  dispatch(actionUnlock(getLockKeyActionGetOfferCountries()));
  if (res.ok) {
    const items = res.data.map((item: any) => new OfferCountry(item));
    dispatch(actionReducerObjectSet(REDUX_OFFER_COUNTRIES, items));
  } else {
    dispatch(basicHandleError(res));
  }
};
