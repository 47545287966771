import {
  URL_CONNECTION_CREATORS,
  URL_CONNECTION_CREATORS_LOG,
} from "../constants";

export const getUrlConnectionCreator = () => {
  return URL_CONNECTION_CREATORS;
};

export const getUrlConnectionCreatorDetail = (formId: number | string) => {
  return URL_CONNECTION_CREATORS + "/" + formId;
};

export const getUrlConnectionCreatorLog = () => {
  return URL_CONNECTION_CREATORS_LOG;
};
