import * as React from "react";
import { FC } from "react";
import { useTheme } from "@material-ui/core";
import { IframeInputWrapper } from "./IframeInputWrapper";
import { IframeFiledProps } from "../IframeInputsList";
import { addThemeColorsToText } from "../../../utils/addThemeColorsToText";

interface IframeCustomHtmlProps {
  noMargin: boolean;
}

export const IframeCustomHtml: FC<IframeFiledProps & IframeCustomHtmlProps> = ({
  onChange,
  form,
  formField,
  noMargin,
}) => {
  const theme = useTheme();
  return (
    <IframeInputWrapper formField={formField} noMargin={noMargin}>
      <div
        dangerouslySetInnerHTML={{
          __html: addThemeColorsToText(
            formField.visibleDescription || "",
            theme
          ),
        }}
      />
    </IframeInputWrapper>
  );
};
