//endpoints
export const ENDPOINT_CONNECTION_CREATORS = "/offer-connection";
export const ENDPOINT_CUSTOM_POSTBACK = "/custom-postback";
export const ENDPOINT_GENERATE_NEW_TOKEN = "/generate-new-token";

//url
export const URL_CONNECTION_CREATORS = "/connection-creator";
export const URL_CONNECTION_CREATORS_LOG = "/connection-creator-log";

//list
export const REDUX_CONNECTION_CREATORS = "REDUX_CONNECTION_CREATORS";
export const REDUX_CONNECTION_CREATOR_DIALOG = "REDUX_CREATOR_DIALOG";
export const REDUX_CONNECTION_CREATOR_DIALOG_TEMPLATE =
  "REDUX_CONNECTION_CREATOR_DIALOG_TEMPLATE";
export const REDUX_CONNECTION_CREATOR_REQUEST_LOGS =
  "REDUX_CONNECTION_CREATOR_REQUEST_LOGS";
export const REDUX_CONNECTION_PROPERTY_MODIFY_DIALOG =
  "REDUX_CONNECTION_PROPERTY_MODIFY_DIALOG";
//detail
export const REDUX_CONNECTION_CREATOR_DETAIL =
  "REDUX_CONNECTION_CREATOR_DETAIL";

export const REDUX_CONNECTION_TEMPLATE_DIALOG =
  "REDUX_CONNECTION_TEMPLATE_DIALOG";
