import { combineReducers } from "redux";
import { createReducerNumber } from "../../../../redux/tools/createReducer/createReducerNumber";
import {
  REDUX_IFRAME_COUNT_SEARCH_IN,
  REDUX_IFRAME_CURRENT_SEARCH,
  REDUX_IFRAME_LEAD_WEBSOCKET_ID,
  REDUX_IFRAME_LOADER_TIMER,
} from "../../constant";
import { createReducerString } from "../../../../redux/tools/createReducer/createReducerString";

export const loaderReducer = combineReducers({
  currentSearchIn: createReducerString(REDUX_IFRAME_CURRENT_SEARCH, "-"),
  timer: createReducerNumber(REDUX_IFRAME_LOADER_TIMER, 120),
  accessToken: createReducerString(REDUX_IFRAME_LEAD_WEBSOCKET_ID, null),
  countToSearch: createReducerNumber(REDUX_IFRAME_COUNT_SEARCH_IN, 1),
});
