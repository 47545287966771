import React, { useEffect, useState } from "react";
import { IframeTimer } from "../../iframe/header/IframeTimer";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../redux/store";

export const IframeLoaderTimer = () => {
  const timerShouldBeSetOn = useSelector(
    (state: AppStateType) => state.iframe.loader.timer
  );
  const [timer, setTimer] = useState<number | null>(timerShouldBeSetOn || 0);

  useEffect(() => {
    setTimer(timerShouldBeSetOn || 0);
  }, [timerShouldBeSetOn]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((timer) => {
        if (timer === null) {
          return null;
        }
        if (timer <= 0) {
          return 0;
        }
        return timer - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const seconds = timer ? timer % 60 : 0;
  return (
    <IframeTimer>
      {!timer
        ? "0:00"
        : `${(timer - (timer % 60)) / 60}:${
            seconds < 10 ? "0" + seconds : seconds
          }`}
    </IframeTimer>
  );
};
