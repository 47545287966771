export class CascadeOfferItemFilterExternal {
  url: string = "";
  defaultTimeout: string = "true";
  id: number | null = null;

  constructor(obj?: any | null) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    return this;
  }
}
