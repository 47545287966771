// Auth components
import React from "react";

export const ConnectionCreatorsTable = React.lazy(
  () =>
    /* webpackChunkName: 'ConnectionCreatorsTable'*/ import(
      "./ConnectionCreators"
    )
);

export const ConnectionCreatorDetail = React.lazy(
  () => /* webpackChunkName: 'FormDetail'*/ import("./ConnectionCreatorDetail")
);

export const RequestTableLog = React.lazy(
  () =>
    /* webpackChunkName: 'RequestTableLog'*/ import(
      "./../components/log/RequestTableLog"
    )
);
