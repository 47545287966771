import { AutoRequestConfig } from "../../../core/connectionCreator/type/AutoRequestConfig";

export class OfferConnectionSendStep {
  public static EVENT_SEND = "send";
  public static EVENT_WAIT = "wait";
  public static EVENT_GO_TO_STEP = "go-to-step";

  event: string | null = null;
  stepNumber: number | null = null;
  timeInMs: number | null = null;
  order: number | null = null;
  id: number | null = null;
  offerConnectionSendId: number | null = null;
  offerConnectionCheckId: number | null = null;
  send: AutoRequestConfig | null = null;
  sendId: number | null = null;

  constructor(obj?: any) {
    obj && Object.assign(this, obj);
    if (obj?.send) {
      this.send = new AutoRequestConfig(obj.send);
    }
  }

  toTemplateJson() {
    const { event, stepNumber, order, send, timeInMs } = this;
    return {
      send: send?.toTemplateJson() || null,
      event,
      stepNumber,
      order,
      timeInMs,
    };
  }

  toJson() {
    const { event, stepNumber, order, send, timeInMs, id } = this;
    return {
      send: send?.toJson() || null,
      event,
      stepNumber,
      timeInMs,
      order,
      id,
    };
  }
}
