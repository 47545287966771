export const REPORT_TYPE_FORM = "form";
export const URL_USER_REPORT = "/user-report";
export const URL_OFFER_REPORT = "/offer-report";
export const URL_REPORT_PERFORMANCE = "/report/performance";
export const URL_REPORT_FORM = "/report/form-report";
export const URL_SMS_REPORT = "/report/sms-report";

export const REDUX_REPORT_PERFORMANCE = "REDUX_REPORT_PERFORMANCE";
export const REDUX_REPORT_CASCADE_ITEM_REPORT_DIALOG =
  "REDUX_REPORT_CASCADE_ITEM_REPORT_DIALOG";
