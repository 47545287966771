import { FC } from "react";
import UserOfferConfig from "../type/UserOfferConfig";
import { ENDPOINT_IFRAME_WEBSOCKET } from "../constant";
import { IframeWebsocketController } from "./IframeWebsocketController";
import { SETTING_SYSTEM_URL } from "../../setting/constants";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../redux/store";

interface IframeWebsocketContainerProps {
  formData: UserOfferConfig;
}

const useWebsocketProtocol = () =>
  /^https:\/\//.test(window.location.origin) ? "wss://" : "ws://";

export const IframeWebsocketContainer: FC<IframeWebsocketContainerProps> = ({
  formData,
}) => {
  const setting = formData.getSettingByName(SETTING_SYSTEM_URL);
  const accessToken = useSelector(
    (state: AppStateType) => state.iframe.loader.accessToken
  );
  const protocol = useWebsocketProtocol();
  if (!setting || !accessToken) {
    return null;
  }
  return (
    <IframeWebsocketController
      accessToken={accessToken}
      url={
        setting.value
          ?.replace("http://", protocol)
          .replace("https://", protocol) + ENDPOINT_IFRAME_WEBSOCKET
      }
    />
  );
};
