import React, { FC } from "react";
import { Flex } from "../../../../core/components/layout/Flex";
import {
  FormsIframePageProps,
  IframeRoot,
} from "../../container/FormsIframeContainer";
import { Helmet } from "react-helmet";
import { useHistoryLocation } from "../../../../core/history";
import styled from "styled-components/macro";
import { IframeTitle } from "../../container/IframeLoading";
import { Translate } from "../../../../core/translate/Translate";
import { IframeHeaderLogos } from "../iframe/header/IframeHeaderLogos";

const ContentContainer = styled.div`
  padding: 0 90px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    padding: 0 10px;
  }
`;

export const IframeOfferIsNotActive: FC<FormsIframePageProps> = ({
  formData,
}) => {
  const location = useHistoryLocation<{ hideBg: "true" | null }>();
  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle="">
        <title itemProp="name">{formData.getMetaTitle() || ""}</title>
        <meta
          name="description"
          content={formData.getMetaDescription() || ""}
        />
      </Helmet>
      <IframeRoot
        $formData={formData}
        $noMarginBottom={true}
        $hideBg={location?.search?.hideBg === "true"}
      >
        <Flex fullWidth={true} flexDirection={"column"} alignItems={"center"}>
          <IframeHeaderLogos formData={formData} />
          <ContentContainer>
            <IframeTitle variant={"h3"} align={"center"}>
              <Translate id={"iframe.offerIsNoActive"} />
            </IframeTitle>
          </ContentContainer>
        </Flex>
      </IframeRoot>
    </>
  );
};
