import {
  OFFER_PAYOUT_STATUS_ACTIVE,
  OfferPayoutStatusType,
} from "./status/OfferPayoutStatuses";
import { LeadStatusType, STATUS_LEAD } from "./status/LeadStatuses";
import { User } from "../../auth/type/User";

export class OfferPayoutConfig {
  id?: number = undefined;
  offerId: number | null = null;
  status: LeadStatusType = STATUS_LEAD;
  tracking: OfferPayoutStatusType = OFFER_PAYOUT_STATUS_ACTIVE;
  offerType: string = "";
  payout: number | null = null;
  userId: number | null = null;
  useAsPercentage: boolean = false;
  user: User | null = null;

  constructor(obj?: any | null) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    const { tracking, offerId, user, ...json } = this;
    return json;
  }
}
