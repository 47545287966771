export class OfferLoadingPage {
  id: number | null = null;
  name: string = "";
  clickIdColumnName: string = "clickId";
  defaultToken: string = "";
  url: string = "";
  status: string = "active";
  offerId: string = "";

  constructor(obj: any | null) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    const { status, offerId, ...json } = this;
    return { ...json };
  }
}
