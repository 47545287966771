import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../redux/store";
import { Flex } from "../components/layout/Flex";
import { MyBackdrop } from "../components/MyBackdrop";
import { isLoggedAction } from "../../modules/auth/store/action/isLogged.action";

const BaseAuthorizeLayout: React.FC = ({ children }) => {
  const loggedUser = useSelector(
    (state: AppStateType) => state.auth.loggedUser
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!loggedUser) {
      dispatch(isLoggedAction());
    }
  }, [loggedUser, dispatch]);
  if (!loggedUser) {
    return (
      <Flex fullWidth={true} justifyContent={"center"} position={"relative"}>
        <MyBackdrop open={true} />
      </Flex>
    );
  }

  return <>{children}</>;
};

export default BaseAuthorizeLayout;
