import { VsiBase } from "./VsiBase";
import * as yup from "yup";
import { Translate } from "../../../../core/translate/Translate";
import BaseSchema from "yup/lib/schema";

export class VsiDate extends VsiBase {
  defaultValue: string | null = null;

  constructor(obj: any) {
    super();
    obj && Object.assign(this, obj);
  }

  toYup(key: string): BaseSchema {
    const translatePrefix = "field";
    const basicTranslateParameter = [{ translateId: this.translateId }];

    let ret: BaseSchema = yup
      .date()
      .typeError(() => (
        <Translate
          id={"core." + translatePrefix + "IsNotDate"}
          params={basicTranslateParameter}
        />
      ));
    if (this.nullable) {
      ret = ret.nullable();
    }
    return ret;
  }
}
