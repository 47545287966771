import { combineReducers } from "redux";
import { REDUX_VALIDATION_VALIDATIONS } from "../../constants";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";
import { VsiBase } from "../../type/elemetnts/VsiBase";

export const validationReducer = combineReducers({
  validation: createReducerObject<Record<string, Record<string, VsiBase>>>(
    REDUX_VALIDATION_VALIDATIONS,
    {}
  ),
});
