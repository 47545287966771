import { VsiBase } from "./VsiBase";
import * as yup from "yup";
import { Translate } from "../../../../core/translate/Translate";
import BaseSchema from "yup/lib/schema";

export class VsiIds extends VsiBase {
  displayValue: string = "name";

  constructor(obj: any) {
    super();
    obj && Object.assign(this, obj);
  }

  toYup(key: string): BaseSchema {
    const translatePrefix = "field";
    const basicTranslateParameter = [{ translateId: this.translateId }];

    let ret = yup.array().of(yup.number()).typeError(() => {
      return (
        <Translate
          id={"core." + translatePrefix + "IsNotNumber"}
          params={basicTranslateParameter}
        />
      );
    });
    if (this.nullable) {
      ret = ret.nullable() as any;
    }

    return ret;
  }
}
