import { useSelector } from "react-redux";
import { AppStateType } from "../../../redux/store";
import { PriceString } from "./PriceString";
import { FC } from "react";
import { Currency } from "../type/Currency";

interface PriceProps {
  currencyId?: number | null;
  currency?: Currency;
  price?: number;
}

export const Price: FC<PriceProps> = (props) => {
  return <>{usePrice(props)}</>;
};

export const usePrice = ({
  currency: currencyProps,
  price,
  currencyId,
}: PriceProps): string => {
  const currency = useSelector(
    (state: AppStateType) => state.currency.currency
  ).find((item) => item.id === currencyId);

  return PriceString({ currency: currencyProps || currency, price });
};
