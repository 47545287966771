import { combineReducers } from "redux";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";
import { User } from "../../type/User";
import {
  REDUX_USER_PICKER_POSITION,
  REDUX_USER_PICKER_NAME,
  REDUX_USER_PICKER_USERS,
  REDUX_USER_USERS,
} from "../../constants";
import { PopoverPosition } from "@material-ui/core";
import { createReducerArray } from "../../../../redux/tools/createReducer/createReducerArray";
import { createReducerString } from "../../../../redux/tools/createReducer/createReducerString";
import { createReducerApiArray } from "../../../../redux/tools/createReducer/createReducerApiArray";

export const userReducer = combineReducers({
  userPickerPosition: createReducerObject<PopoverPosition | null>(
    REDUX_USER_PICKER_POSITION,
    null
  ),
  userForSelect: createReducerArray<User>(REDUX_USER_PICKER_USERS, []),
  name: createReducerString(REDUX_USER_PICKER_NAME, null),
  users: createReducerApiArray<User>(REDUX_USER_USERS),
});
