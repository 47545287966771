import {
  ENDPOINT_IFRAME_AGREE_ALTERNATIVE_OFFER,
  ENDPOINT_IFRAME_SELECT_OFFER_FOR_USER_CENTRIC_CASCADE,
  IFRAME_PAGE_FINAL_PAGE,
  IFRAME_PAGE_LOADING,
  REDUX_IFRAME_PAGE,
} from "../../constant";
import { AppDispatchType } from "../../../../redux/store";
import {
  actionLock,
  actionUnlock,
} from "../../../../core/layouts/store/actions/lockable.actions";
import { basicHandleError, post } from "../../../../core/api/utils/fetchApi";
import { actionReducerStringSet } from "../../../../redux/tools/createReducer/createReducerString";
import { LockKey } from "../../../../core/layouts/utils/useIsLocked";

export const getLockKeyActionPostUserCentricResult = (): LockKey => {
  return "post" + ENDPOINT_IFRAME_AGREE_ALTERNATIVE_OFFER;
};

export const postUserCentricResult = (
  accessToken: string,
  cascadeItemOfferId: number
) => async (dispatch: AppDispatchType) => {
  dispatch(actionLock(getLockKeyActionPostUserCentricResult()));
  dispatch(actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_LOADING));
  const res = await post(
    ENDPOINT_IFRAME_SELECT_OFFER_FOR_USER_CENTRIC_CASCADE,
    {
      accessToken,
      cascadeItemOfferId,
    }
  );
  dispatch(actionUnlock(getLockKeyActionPostUserCentricResult()));
  if (res.ok) {
    dispatch(actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_LOADING));
  } else {
    dispatch(actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_FINAL_PAGE));
    dispatch(basicHandleError(res));
  }
};
