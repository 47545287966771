import { OFFER_LEAD_TYPE_API, OfferLeadType } from "./OfferLeadType";

export class OfferAdvantage {
  id?: number = undefined;
  offerId: number | null = null;
  text: string = "";
  type: OfferLeadType = OFFER_LEAD_TYPE_API;
  disAdvantage: boolean = false;
  weight: number = 0;
  ownToken: boolean = false;

  constructor(obj: any | null) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    const { offerId, ...json } = this;
    return { ...json };
  }
}
