import { combineReducers } from "redux";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";
import { User } from "../../type/User";
import { REDUX_AUTH_LOGGED_USER } from "../../constants";

export const authReducer = combineReducers({
  loggedUser: createReducerObject<User | null>(
    REDUX_AUTH_LOGGED_USER,
    null,
    User
  ),
});
