import * as React from "react";
import { FC, useEffect, useState } from "react";
import FormsIframe from "../components/iframe/FormsIframe";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components/macro";
import createFormTheme from "../theme";
import UserOfferConfig from "../type/UserOfferConfig";
import { getUserOfferConfig } from "../utils/getUserOfferConfig";
import { historyReplace, useHistoryLocation } from "../../../core/history";
import { Page404Params } from "../../auth/container/Page404";
import { URL_ERROR_404, URL_ERROR_500 } from "../../auth/constants";
import Page500, { Page500Params } from "../../auth/container/Page500";
import { useParams } from "react-router";
import styled from "styled-components";
import { CircularProgress, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../redux/store";
import {
  IFRAME_PAGE_ERROR,
  IFRAME_PAGE_FINAL_PAGE,
  IFRAME_PAGE_IFRAME,
  IFRAME_PAGE_LOADING,
  IFRAME_PAGE_ALTERNATIVE_OFFER,
  IFRAME_PAGE_REDIRECT,
  IframePageType,
  REDUX_IFRAME_ALTERNATIVE_OFFER_LAST_NAME,
  REDUX_IFRAME_ALTERNATIVE_OFFER_NEXT,
  REDUX_IFRAME_CURRENT_SEARCH,
  REDUX_IFRAME_FINALE_PAGE_RES_FAIL,
  REDUX_IFRAME_FINALE_PAGE_RES_SUCCESS,
  REDUX_IFRAME_FORM_NUMBER,
  REDUX_IFRAME_LOADER_TIMER,
  REDUX_IFRAME_PAGE,
  REDUX_IFRAME_REDIRECT_URL,
  REDUX_IFRAME_COUNT_SEARCH_IN,
  IFRAME_PAGE_SUCCESS,
  IFRAME_PAGE_OFFER_IN_NOT_ACTIVE,
  REDUX_IFRAME_FINALE_PAGE_LINKS,
  REDUX_IFRAME_VALUES,
  IFRAME_PAGE_SMS_VERIFY,
  IFRAME_PHONE_NAME,
  REDUX_IFRAME_LEAD_WEBSOCKET_ID,
  REDUX_IFRAME_CAN_PARENT_PAGE_USE_RECEIVE_LEADS,
} from "../constant";
import { IframeLoading } from "./IframeLoading";
import { IframeWebsocketContainer } from "../websocket/IframeWebsocketContainer";
import { IframeRedirect } from "../components/redirect/IframeRedirect";
import { IframeFinalPage } from "../components/finalPage/IframeFinalPage";
import { IframeAlternativeOffer } from "../components/alternativeOfferPage/IframeAlternativeOffer";
import { actionReducerStringSet } from "../../../redux/tools/createReducer/createReducerString";
import { actionReducerNumberSet } from "../../../redux/tools/createReducer/createReducerNumber";
import { Offer } from "../type/Offer";
import { IframeSuccess } from "../components/success/IframeSuccess";
import { STATUS_ACTIVE } from "../../offers/type/status/OfferStatuses";
import { IframeOfferIsNotActive } from "../components/inactive/IframeOfferIsNotActive";
import { actionReducerArraySet } from "../../../redux/tools/createReducer/createReducerArray";
import { FinalePageLink } from "../../form/type/FinalePageLink";
import { actionReducerObjectSet } from "../../../redux/tools/createReducer/createReducerObject";
import { IframeSmsVerify } from "../components/smsVerify/IframeSmsVerify";
import { actionReducerBooleanSet } from "../../../redux/tools/createReducer/createReducerBoolean";

interface IframeRootProps {
  $formData: UserOfferConfig;
  $noMarginBottom: boolean;
  $noPadding?: boolean;
  $hideBg: boolean;
}

export const IframeRoot = styled(Paper)<IframeRootProps>`
  padding: ${({ theme, $noPadding }) => theme.spacing($noPadding ? 0 : 4)}px;
  margin: ${({ theme, $noMarginBottom, $hideBg }) =>
    $hideBg ? 0 : theme.spacing(15, 4, $noMarginBottom ? 15 : 0, 4)};
  border-color: ${({ $formData, theme }) =>
    $formData.getBorderColor() || theme.palette.primary.main};
  border-width: ${({ $formData, theme }) => $formData.getBorderColor() || 0}px;
  width: 100%;
  max-width: 100%;
  box-shadow: unset;

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: ${({ $hideBg }) => (!$hideBg ? "calc(100% - 32px)" : "100%")};
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 0;
    padding: 0px;
    max-width: 100%;
  }
`;

const Loading = styled(CircularProgress)`
  margin: auto;
`;

export interface FormsIframePageProps {
  formData: UserOfferConfig;
}

const usePage = (
  page: IframePageType | null
): { Component: FC<FormsIframePageProps> | null } => {
  switch (page) {
    case IFRAME_PAGE_IFRAME:
      return { Component: FormsIframe };
    case IFRAME_PAGE_LOADING:
      return { Component: IframeLoading };
    case IFRAME_PAGE_REDIRECT:
      return { Component: IframeRedirect };
    case IFRAME_PAGE_FINAL_PAGE:
      return { Component: IframeFinalPage };
    case IFRAME_PAGE_SMS_VERIFY:
      return { Component: IframeSmsVerify };
    case IFRAME_PAGE_OFFER_IN_NOT_ACTIVE:
      return { Component: IframeOfferIsNotActive };
    case IFRAME_PAGE_ERROR:
      return { Component: Page500 };
    case IFRAME_PAGE_ALTERNATIVE_OFFER:
      return { Component: IframeAlternativeOffer };
    case IFRAME_PAGE_SUCCESS:
      return { Component: IframeSuccess };
    default:
      return { Component: null };
  }
};

const FormsIframeContainer: FC = () => {
  const [formData, setFormData] = useState<UserOfferConfig>();

  const dispatch = useDispatch();
  const {
    search: { forcePage, loadingCount, defaultValues },
  } = useHistoryLocation<{
    forcePage?: IframePageType;
    loadingCount?: string;
    defaultValues: any;
  }>();
  const formValues = useSelector(
    (state: AppStateType) => state.iframe.formValues
  );

  useEffect(() => {
    //TODO delete
    if (forcePage) {
      dispatch(
        actionReducerNumberSet(REDUX_IFRAME_CURRENT_SEARCH, "Půjčka XY")
      );
      dispatch(actionReducerNumberSet(REDUX_IFRAME_LOADER_TIMER, 120));
      dispatch(actionReducerNumberSet(REDUX_IFRAME_FORM_NUMBER, 17000));
      switch (forcePage) {
        case IFRAME_PAGE_FINAL_PAGE:
          dispatch(
            actionReducerNumberSet(REDUX_IFRAME_FINALE_PAGE_RES_SUCCESS, [
              {
                redirectUrl: "https://seynam.cz",
                offer: new Offer({ name: "Půjčka XY 2" }),
              },
            ])
          );
          dispatch(
            actionReducerArraySet(REDUX_IFRAME_FINALE_PAGE_LINKS, [
              new FinalePageLink({
                company: "Firma s.r.o.",
                charge: "100$",
                link: "https://seznam.cz",
              }),
            ])
          );
          dispatch(
            actionReducerNumberSet(REDUX_IFRAME_FINALE_PAGE_RES_FAIL, [
              {
                redirectUrl: "https://seynam.cz",
                offer: new Offer({ name: "Půjčka XY" }),
              },
            ])
          );
          dispatch(
            actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_FINAL_PAGE)
          );
          break;
        case IFRAME_PAGE_SUCCESS:
          dispatch(
            actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_SUCCESS)
          );
          break;
        case IFRAME_PAGE_REDIRECT:
          dispatch(
            actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_REDIRECT)
          );
          dispatch(
            actionReducerStringSet(
              REDUX_IFRAME_REDIRECT_URL,
              "https://seznam.cz"
            )
          );
          break;
        case IFRAME_PAGE_ALTERNATIVE_OFFER:
          dispatch(
            actionReducerNumberSet(
              REDUX_IFRAME_ALTERNATIVE_OFFER_LAST_NAME,
              "Půjčka XY"
            )
          );
          dispatch(
            actionReducerNumberSet(
              REDUX_IFRAME_ALTERNATIVE_OFFER_NEXT,
              new Offer({ name: "Půjčka XY" })
            )
          );
          dispatch(
            actionReducerStringSet(
              REDUX_IFRAME_PAGE,
              IFRAME_PAGE_ALTERNATIVE_OFFER
            )
          );
          break;
        case IFRAME_PAGE_LOADING:
          dispatch(
            actionReducerNumberSet(
              REDUX_IFRAME_COUNT_SEARCH_IN,
              loadingCount ? Number.parseInt(loadingCount) : 1
            )
          );
          dispatch(
            actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_LOADING)
          );
          break;
        case IFRAME_PAGE_SMS_VERIFY:
          dispatch(
            actionReducerObjectSet(REDUX_IFRAME_VALUES, {
              ...formValues,
              [IFRAME_PHONE_NAME]: "111111111",
            })
          );
          dispatch(
            actionReducerStringSet(REDUX_IFRAME_PAGE, IFRAME_PAGE_SMS_VERIFY)
          );
          dispatch(
            actionReducerStringSet(
              REDUX_IFRAME_LEAD_WEBSOCKET_ID,
              "f7eea97fbc09471367c4380bd2d47e7734ad41179e7594a86861d0da70b2"
            )
          );
          break;
      }
    }
    // eslint-disable-next-line
  }, []);

  const params = useParams<{ id: string }>();
  useEffect(() => {
    if (params.id) {
      const urlParams = new URLSearchParams(window.location.search);
      const offerId = urlParams.get("offerId");
      getUserOfferConfig(
        params.id,
        offerId ? Number.parseInt(offerId) : undefined
      ).then((res) => {
        if (res.ok) {
          const conf = new UserOfferConfig(res.data);

          const preview = urlParams.get("preview");
          if (preview && offerId) {
            const confPreview = new UserOfferConfig(JSON.parse(preview));
            conf.setPreview(confPreview);

            if (conf?.offer?.status) {
              conf.offer.status = "active";
            }
          }
          setFormData(conf);
          if (conf?.offer?.status !== STATUS_ACTIVE) {
            dispatch(
              actionReducerStringSet(
                REDUX_IFRAME_PAGE,
                IFRAME_PAGE_OFFER_IN_NOT_ACTIVE
              )
            );
          }
          dispatch(
            actionReducerBooleanSet(
              REDUX_IFRAME_CAN_PARENT_PAGE_USE_RECEIVE_LEADS,
              conf.canUseReceiveLeads()
            )
          );
        } else {
          if (res.code === 404) {
            historyReplace<Page404Params>({
              pathname: URL_ERROR_404,
              search: {
                translateId: "form.errorLoadingForm",
                notShowGoHome: "true",
              },
            });
          } else {
            historyReplace<Page500Params>({
              pathname: URL_ERROR_500,
              search: {
                translateId: "form.errorLoadingForm",
                notShowGoHome: "true",
              },
            });
          }
        }
      });
    }
  }, [params.id, dispatch]);

  useEffect(() => {
    let defaultValuesJson: Record<string, any> = {};
    if (defaultValues === undefined) {
      return;
    }
    try {
      defaultValuesJson = JSON.parse(defaultValues);
    } catch (e) {
      console.log(e);
    }
    if (typeof defaultValuesJson === "object" && defaultValuesJson !== null) {
      const toSet: Record<string, any> = {};
      Object.keys(defaultValuesJson).forEach((key) => {
        if (
          typeof defaultValuesJson[key] === "number" ||
          typeof defaultValuesJson[key] === "boolean" ||
          typeof defaultValuesJson[key] === "string" ||
          defaultValuesJson[key] === null
        ) {
          toSet[key] = defaultValuesJson[key];
        }
      });
      dispatch(actionReducerObjectSet(REDUX_IFRAME_VALUES, toSet));
      if (toSet?.requested_amount || toSet?.requested_amount === 0) {
        dispatch(
          actionReducerNumberSet(
            REDUX_IFRAME_FORM_NUMBER,
            toSet?.requested_amount
          )
        );
      }
    }
    // on load set default values from url
    // eslint-disable-next-line
  }, []);

  const page = useSelector((state: AppStateType) => state.iframe.page);
  const { Component } = usePage(page);

  if (!formData) {
    return <Loading color="primary" />;
  }
  if (!formData.form || !formData.offer) {
    historyReplace<Page404Params>({
      pathname: URL_ERROR_404,
      search: { translateId: "form.errorLoadingForm", notShowGoHome: "true" },
    });
    return null;
  }

  return (
    <MuiThemeProvider
      theme={createFormTheme(
        formData.getPrimaryColor(),
        formData.getSecondaryColor()
      )}
    >
      <ThemeProvider
        theme={createFormTheme(
          formData.getPrimaryColor(),
          formData.getSecondaryColor()
        )}
      >
        <IframeWebsocketContainer formData={formData} />
        {Component && <Component formData={formData} />}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
export default FormsIframeContainer;
