import { Flex } from "../../../../core/components/layout/Flex";
import React, { useState } from "react";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { Translate } from "../../../../core/translate/Translate";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import NavigateNextTwoToneIcon from "@material-ui/icons/NavigateNextTwoTone";
import NavigateBeforeTwoToneIcon from "@material-ui/icons/NavigateBeforeTwoTone";
import styled from "styled-components/macro";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const TextContent = styled.div`
  text-align: center;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
`;

const WbIncandescentIconStyled = styled(WbIncandescentIcon)`
  transform: rotate(180deg);
  color: #fdc311;
  font-size: 37px;
  margin-right: 9px;
  margin-bottom: -3px;
`;

const Title = styled(Typography)`
  margin-bottom: 30px;
`;

const Root = styled(Flex)`
  margin-bottom: 110px;
  margin-top: 90px;
`;

const texts = [
  "Pro zvýšení šancí na získání půjčky si můžete v rámci vaší banky vytvořit separátní účet, odkud můžete platit náklady, které mohou ztížit získání půjčky. Jako jsou sázky, splátky půjček apod. Pak tyto náklady nebudou vidět ve výpisu hlavního účtu.",
];

export const IframeDidYouKnow = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % texts.length);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? texts.length - 1 : prevActiveStep - 1
    );
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Root flexDirection={"column"}>
      <Title variant={"h3"} align={"center"}>
        <WbIncandescentIconStyled />
        <Translate id={"iframe.iframeDidYouKnow"} />
      </Title>
      <Flex
        fullWidth={true}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {texts.length > 1 && (
          <Tooltip title={<Translate id={"iframe.backDoYouKnow"} />}>
            <IconButton onClick={handleNext}>
              <NavigateBeforeTwoToneIcon />
            </IconButton>
          </Tooltip>
        )}
        <AutoPlaySwipeableViews
          axis={"x-reverse"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {texts.map((step, index) => (
            <TextContent key={index}>
              <div>{Math.abs(activeStep - index) <= 2 ? step : step}</div>
            </TextContent>
          ))}
        </AutoPlaySwipeableViews>
        {texts.length > 1 && (
          <Tooltip title={<Translate id={"iframe.nextDoYouKnow"} />}>
            <IconButton onClick={handleBack}>
              <NavigateNextTwoToneIcon />
            </IconButton>
          </Tooltip>
        )}
      </Flex>
    </Root>
  );
};
