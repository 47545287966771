import styled from "styled-components/macro";
import { Alert } from "@material-ui/lab";

interface MyAlertProps {
  $fullWidth?: boolean;
}

export const MyAlert = styled(Alert)<MyAlertProps>`
  width: ${(props) => (props.$fullWidth ? "100%" : "unset")};
`;
