export type SystemMarginType =
  | "unset"
  | "auto"
  | "mini"
  | "small"
  | "medium"
  | "large"
  | "xl"
  | "xxl";

export const systemMargin: Record<
  SystemMarginType,
  number | "auto" | "unset"
> = {
  mini: 8,
  auto: "auto",
  unset: "unset",
  small: 12,
  medium: 20,
  large: 28,
  xl: 32,
  xxl: 48,
};

export const getSystemMarginValue = (margin: SystemMarginType) => {
  if (margin === "auto" || margin === "unset") {
    return margin;
  }
  return systemMargin[margin] + "px";
};
