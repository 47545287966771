import { combineReducers } from "redux";
import {
  IFRAME_PAGE_IFRAME,
  IframePageType,
  REDUX_IFRAME_VALUES,
  REDUX_IFRAME_FORM_ERRORS,
  REDUX_IFRAME_FORM_FILLED_FORMS,
  REDUX_IFRAME_FORM_NUMBER,
  REDUX_IFRAME_FORM_TIMER,
  REDUX_IFRAME_LEAD_ID,
  REDUX_IFRAME_PAGE,
  REDUX_IFRAME_REDIRECT_URL,
  REDUX_IFRAME_CAN_PARENT_PAGE_USE_RECEIVE_LEADS,
} from "../../constant";
import { createReducerArray } from "../../../../redux/tools/createReducer/createReducerArray";
import { createReducerNumber } from "../../../../redux/tools/createReducer/createReducerNumber";
import { loaderReducer } from "./loader.reducer";
import { createReducerString } from "../../../../redux/tools/createReducer/createReducerString";
import { createReducerObject } from "../../../../redux/tools/createReducer/createReducerObject";
import { alternativeOfferReducer } from "./alternativeOffer.reducer";
import { finalPageReducer } from "./finalPage.reducer";

export const iframeReducer = combineReducers({
  errors: createReducerArray<string>(REDUX_IFRAME_FORM_ERRORS, []),
  filledInputs: createReducerArray<string>(REDUX_IFRAME_FORM_FILLED_FORMS, []),
  moneyCzSlider: createReducerNumber(REDUX_IFRAME_FORM_NUMBER, 0),
  timer: createReducerNumber(REDUX_IFRAME_FORM_TIMER, null),
  redirectUrl: createReducerString(REDUX_IFRAME_REDIRECT_URL, null),
  loader: loaderReducer,
  alternativeOffer: alternativeOfferReducer,
  finalPage: finalPageReducer,

  leadId: createReducerNumber(REDUX_IFRAME_LEAD_ID, null),
  formValues: createReducerObject<Record<string, any>>(REDUX_IFRAME_VALUES, {}),
  canParentPageReceiveLeads: createReducerObject<Record<string, any>>(
    REDUX_IFRAME_CAN_PARENT_PAGE_USE_RECEIVE_LEADS,
    {}
  ),

  page: createReducerString<IframePageType>(
    REDUX_IFRAME_PAGE,
    IFRAME_PAGE_IFRAME
  ),
});
