import React, { FC } from "react";
import { Flex } from "../../../../core/components/layout/Flex";
import {
  FormsIframePageProps,
  IframeRoot,
} from "../../container/FormsIframeContainer";
import { Helmet } from "react-helmet";
import { useHistoryLocation } from "../../../../core/history";
import styled from "styled-components/macro";
import { IframeSubtitle, IframeTitle } from "../../container/IframeLoading";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/store";
import { Translate } from "../../../../core/translate/Translate";
import { IframeHeaderLogos } from "../iframe/header/IframeHeaderLogos";
import IframeHeader from "../iframe/header/IframeHeader";

const ContentContainer = styled.div`
  padding: 0 90px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }: { theme: any }) => theme.breakpoints.down("sm")} {
    padding: 0 10px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
`;

export const IframeSuccess: FC<FormsIframePageProps> = ({ formData }) => {
  const location = useHistoryLocation<{ hideBg: "true" | null }>();
  const formValues = useSelector(
    (state: AppStateType) => state.iframe.formValues
  );
  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle="">
        <title itemProp="name">{formData.getMetaTitle() || ""}</title>
        <meta
          name="description"
          content={formData.getMetaDescription() || ""}
        />
      </Helmet>
      <IframeRoot
        $formData={formData}
        $noMarginBottom={true}
        $hideBg={location?.search?.hideBg === "true"}
      >
        <Flex fullWidth={true} flexDirection={"column"} alignItems={"center"}>
          <IframeHeaderLogos formData={formData} />
          <ContentContainer>
            <IframeTitle variant={"h3"} align={"center"}>
              <Translate id={"iframe.successPage"} />
            </IframeTitle>
            <IframeSubtitle variant={"subtitle1"} align={"center"}>
              <Translate id={"iframe.successPageText"} />
            </IframeSubtitle>
            <HeaderContainer>
              <IframeHeader
                onlyMoney={true}
                formData={formData}
                currentPage={2}
                formValues={formValues}
              />
            </HeaderContainer>
          </ContentContainer>
        </Flex>
      </IframeRoot>
    </>
  );
};
