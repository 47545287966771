import { useValidation } from "../../../modules/validation/utils/useValidation";
import { Formik } from "formik";
import React, { ReactNode, useMemo } from "react";
import { FormikHelpers, FormikProps } from "formik/dist/types";
import {
  DEFAULT_VALUE_IS_NOT_SET,
  VsiBase,
} from "../../../modules/validation/type/elemetnts/VsiBase";

interface MyFormikContextProps {
  module?: string;
  validation?: Record<string, VsiBase>;
  yup?: any;
}
export const MyFormikContext = React.createContext<MyFormikContextProps>({});

interface MyFormikProps<T> {
  module: string;
  initialValues: T;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  children: ReactNode;
  innerRef?: React.Ref<FormikProps<T>>;
}

export const MyFormik = <T extends Record<string, any>>({
  children,
  onSubmit,
  initialValues,
  module,
  innerRef,
}: MyFormikProps<T>) => {
  const { validation, yup } = useValidation(module);
  console.log('RENDER MyFormik');

  const editedInitialValues = useMemo(() => {
    if (!validation) {
      return initialValues;
    }
    const keys = Object.keys(initialValues);
    const result: Record<string, any> = {};
    keys.forEach((key) => {
      result[key] = initialValues[key];
      const vsi: VsiBase = validation[key];
      if (vsi && vsi?.forceValue !== DEFAULT_VALUE_IS_NOT_SET) {
        result[key] = vsi.forceValue;
      }
      if (!initialValues.id) {
        if (
          vsi &&
          "defaultValue" in vsi &&
          vsi["defaultValue"] !== DEFAULT_VALUE_IS_NOT_SET
        ) {
          result[key] = vsi["defaultValue"];
        }
      }
    });
    return result as T;
  }, [initialValues, validation]);

  return (
    <Formik
      initialValues={editedInitialValues}
      validationSchema={yup}
      onSubmit={onSubmit}
      validateOnMount={false}
      innerRef={innerRef}
    >
      {({ handleSubmit, errors }) => (
        <form noValidate onSubmit={handleSubmit}>
          <MyFormikContext.Provider value={{ module, validation, yup }}>
            {children}
          </MyFormikContext.Provider>
        </form>
      )}
    </Formik>
  );
};
