import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { BaseInitHook } from "../../../../core/utils/baseInitHook";
import { getOfferCategoriesAction } from "../../store/action/offer/category/getOfferCategories.action";
import { getOfferCountriesAction } from "../../store/action/offer/country/getOfferCountries.action";
import { getOfferProductsAction } from "../../store/action/offer/product/getOfferProducts.action";

export const useInitOfferList: BaseInitHook = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getOfferFilterOptions());
    dispatch(getOfferCategoriesAction());
    dispatch(getOfferCountriesAction());
    dispatch(getOfferProductsAction());
  }, [dispatch]);
};
