export const ENDPOINT_USER_OFFER_CONFIG = "/form/user-offer-config";
export const ENDPOINT_SUBMIT_FORM = "/lead/submit-lead";
export const ENDPOINT_USER_REPORT_ERROR = "/form/user-report-error";
export const ENDPOINT_FOR_LEAD_TRACK = "/l/lead-postback";
export const ENDPOINT_IFRAME_AGREE_ALTERNATIVE_OFFER =
  "/lead/agree-alternative-offer";
export const ENDPOINT_IFRAME_SELECT_OFFER_FOR_USER_CENTRIC_CASCADE =
  "/form/select-offer-for-user-centric-cascade";

export const URL_IFRAME = "/lead/iframe/:id";
export const URL_IFRAME_PREVIEW_TERMS = "/preview-terms/:id";
export const URL_FORMS = "/forms";

export const IFRAME_TIMER_LAST_START = "IFRAME_TIMER_LAST_START";

export const REDUX_IFRAME_FORM_ERRORS = "REDUX_IFRAME_FORM_ERRORS";
export const REDUX_IFRAME_FORM_FILLED_FORMS = "REDUX_IFRAME_FORM_FILLED_FORMS";
export const REDUX_IFRAME_FORM_NUMBER = "REDUX_IFRAME_FORM_NUMBER";
export const REDUX_IFRAME_FORM_TIMER = "REDUX_IFRAME_FORM_TIMER";

export const REDUX_IFRAME_LEAD_ID = "REDUX_IFRAME_LEAD_ID";
export const REDUX_IFRAME_VALUES = "REDUX_IFRAME_CURRENT_PAGE";
export const REDUX_IFRAME_CAN_PARENT_PAGE_USE_RECEIVE_LEADS =
  "REDUX_IFRAME_CAN_PARENT_PAGE_USE_RECEIVE_LEADS";

export const IFRAME_PAGE_IFRAME = "IFRAME_PAGE_IFRAME";
export const IFRAME_PAGE_OFFER_IN_NOT_ACTIVE =
  "IFRAME_PAGE_OFFER_IN_NOT_ACTIVE";
export const IFRAME_PAGE_LOADING = "IFRAME_PAGE_LOADING";
export const IFRAME_PAGE_ALTERNATIVE_OFFER = "IFRAME_PAGE_ALTERNATIVE_OFFER";
export const IFRAME_PAGE_NO_OFFER_FOR_YOU = "IFRAME_PAGE_NO_OFFER_FOR_YOU";
export const IFRAME_PAGE_REDIRECT = "IFRAME_PAGE_REDIRECT";
export const IFRAME_PAGE_SUCCESS = "IFRAME_PAGE_SUCCESS";
export const IFRAME_PAGE_ERROR = "IFRAME_PAGE_ERROR";
export const IFRAME_PAGE_FINAL_PAGE = "IFRAME_PAGE_FINAL_PAGE";
export const IFRAME_PAGE_SMS_VERIFY = "IFRAME_PAGE_SMS_VERIFY";
export type IframePageType =
  | typeof IFRAME_PAGE_FINAL_PAGE
  | typeof IFRAME_PAGE_SMS_VERIFY
  | typeof IFRAME_PAGE_OFFER_IN_NOT_ACTIVE
  | typeof IFRAME_PAGE_SUCCESS
  | typeof IFRAME_PAGE_REDIRECT
  | typeof IFRAME_PAGE_ALTERNATIVE_OFFER
  | typeof IFRAME_PAGE_LOADING
  | typeof IFRAME_PAGE_ERROR
  | typeof IFRAME_PAGE_IFRAME;
export const REDUX_IFRAME_PAGE = "REDUX_IFRAME_PAGE";
export const REDUX_IFRAME_FINAL_PAGE_DISTRIBUTION_TYPE = "REDUX_IFRAME_FINAL_PAGE_DISTRIBUTION_TYPE";

export const REDUX_IFRAME_LOADER_MONEY_AMOUNT =
  "REDUX_IFRAME_LOADER_MONEY_AMOUNT";
export const REDUX_IFRAME_LOADER_CURRENCY_ID =
  "REDUX_IFRAME_LOADER_CURRENCY_ID";
export const REDUX_IFRAME_CURRENT_SEARCH = "REDUX_IFRAME_CURRENT_SEARCH";
export const REDUX_IFRAME_LOADER_TIMER = "REDUX_IFRAME_LOADER_TIMER";
export const REDUX_IFRAME_LEAD_WEBSOCKET_ID = "REDUX_IFRAME_LEAD_WEBSOCKET_ID";
export const REDUX_IFRAME_COUNT_SEARCH_IN = "REDUX_IFRAME_COUNT_SEARCH_IN";
export const REDUX_IFRAME_REDIRECT_URL = "REDUX_IFRAME_REDIRECT_URL";

export const ENDPOINT_IFRAME_WEBSOCKET = "/ws";

export const REDUX_IFRAME_ALTERNATIVE_OFFER_LAST_NAME =
  "REDUX_IFRAME_ALTERNATIVE_OFFER_LAST_NAME";
export const REDUX_IFRAME_ALTERNATIVE_OFFER_NEXT =
  "REDUX_IFRAME_ALTERNATIVE_OFFER_NEXT";

export const REDUX_IFRAME_FINALE_PAGE_RES_FAIL =
  "REDUX_IFRAME_FINALE_PAGE_RES_FAIL";
export const REDUX_IFRAME_FINALE_PAGE_RES_SUCCESS =
  "REDUX_IFRAME_FINALE_PAGE_RES_SUCCESS";
export const REDUX_IFRAME_FINALE_PAGE_RES_ALTERNATIVE =
  "REDUX_IFRAME_FINALE_PAGE_RES_ALTERNATIVE";
export const REDUX_IFRAME_FINALE_PAGE_TYPE = "REDUX_IFRAME_FINALE_PAGE_TYPE";
export const REDUX_IFRAME_FINALE_PAGE_LINKS = "REDUX_IFRAME_FINALE_PAGE_LINKS";

export const REDUX_IFRAME_SMS_VERIFY_CHANGE_PHONE =
  "REDUX_IFRAME_SMS_VERIFY_CHANGE_PHONE";
export const IFRAME_PHONE_NAME = "cell_phone";
