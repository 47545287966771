import { FC } from "react";
import { LockKey, useIsLocked } from "../layouts/utils/useIsLocked";
import { MyButton, MyButtonProps } from "./MyButton";

export interface LoadingButtonProps extends MyButtonProps {
  isLoading?: boolean;
  lockableKey?: LockKey;
}

export const LoadingButton: FC<LoadingButtonProps> = ({
  isLoading,
  lockableKey,
  ...props
}) => {
  const isLocked = useIsLocked(lockableKey);
  return (
    <MyButton {...props} disabled={isLoading || isLocked || props.disabled} />
  );
};
