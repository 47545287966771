import { PropTypes, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { SystemMarginType } from "../../theme/Spacing";
import styled from "styled-components";
import { Variant } from "@material-ui/core/styles/createTypography";
import { Translate } from "../../translate/Translate";
import { red } from "@material-ui/core/colors";

type TypographyColor =
  | "initial"
  | "inherit"
  | "primary"
  | "secondary"
  | "textPrimary"
  | "textSecondary"
  | "success"
  | "error";

interface BaseTextComponentProps {
  component?: React.ElementType;
  variant: Variant;
  align?: PropTypes.Alignment;
  color?: TypographyColor;
  marginTop?: SystemMarginType;
  marginBottom?: SystemMarginType;
  translateId?: string;
  type?: "active" | "inactive" | "error";
  whiteSpace?: string;
  params?: (string | number | { translateId: string; params?: string[] })[];
}

interface StyledTypographyProps {
  $marginTop?: SystemMarginType;
  $marginBottom?: SystemMarginType;
  component: any;
  $color?: "success";
  $whiteSpace?: string;
  $type?: "active" | "inactive" | "error";
}

const StyledTypography = styled(Typography)<StyledTypographyProps>`
  ${({ $marginTop, theme }) =>
    $marginTop && `margin-top: ${theme.margin($marginTop)};`}
  ${({ $color, theme }) => $color && `color: ${theme.palette.success.main};`}
  ${({ $whiteSpace }) => $whiteSpace && `white-space: ${$whiteSpace};`}
  ${({ $marginBottom, theme }) =>
    $marginBottom && `margin-bottom: ${theme.margin($marginBottom)};`}
  ${({ $type = "active", theme }) =>
    $type === "error"
      ? `color: ${red[500]};`
      : `opacity: ${$type === "active" ? 1 : 0.6}`}
`;

const BaseTextComponent: FC<BaseTextComponentProps> = ({
  align,
  component,
  marginBottom = "medium",
  marginTop,
  variant,
  translateId,
  type,
  children,
  color,
  params,
  whiteSpace,
}) => {
  return (
    <StyledTypography
      $marginBottom={marginBottom}
      $marginTop={marginTop}
      $type={type}
      component={component}
      $whiteSpace={whiteSpace}
      variant={variant}
      align={align}
      color={color === "success" ? undefined : color}
      $color={color !== "success" ? undefined : color}
      gutterBottom
    >
      {translateId ? <Translate id={translateId} params={params} /> : children}
    </StyledTypography>
  );
};

interface BaseTypographyProps {
  align?: PropTypes.Alignment;
  color?: TypographyColor;
  translateId?: string;
  marginTop?: SystemMarginType;
  marginBottom?: SystemMarginType;
  type?: "active" | "inactive" | "error";
  component?: React.ElementType;
  whiteSpace?: "pre";
  params?: (string | number | { translateId: string; params?: string[] })[];
}

export const H1: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"h1"} variant={"h1"} />
);

export const H2: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"h2"} variant={"h2"} />
);

export const H3: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"h3"} variant={"h3"} />
);

export const H4: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"h4"} variant={"h4"} />
);

export const H5: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"h5"} variant={"h5"} />
);

export const H6: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"h6"} variant={"h6"} />
);

export const Body1: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"p"} variant={"body1"} />
);

export const Body2: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"p"} variant={"body2"} />
);

export const Caption: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"p"} variant={"caption"} />
);

export const Overline: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"p"} variant={"overline"} />
);

export const Subtitle1: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"p"} variant={"subtitle1"} />
);

export const Subtitle2: FC<BaseTypographyProps> = (props) => (
  <BaseTextComponent {...props} component={"p"} variant={"subtitle2"} />
);
