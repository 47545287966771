import {
  actionReducerArrayDelete,
  actionReducerArrayPushOrUpdate,
} from "../../../../redux/tools/createReducer/createReducerArray";
import {
  REDUX_IFRAME_FORM_ERRORS,
  REDUX_IFRAME_FORM_FILLED_FORMS,
  REDUX_IFRAME_FORM_NUMBER,
} from "../../constant";
import { actionReducerNumberSet } from "../../../../redux/tools/createReducer/createReducerNumber";

export const actionIframeAddError = (error: string) => {
  return actionReducerArrayPushOrUpdate(REDUX_IFRAME_FORM_ERRORS, error);
};

export const actionIframeRemoveError = (error: string) => {
  return actionReducerArrayDelete(REDUX_IFRAME_FORM_ERRORS, error);
};

export const actionIframeAddFilledFrom = (filledFrom: string) => {
  return actionReducerArrayPushOrUpdate(
    REDUX_IFRAME_FORM_FILLED_FORMS,
    filledFrom
  );
};

export const actionIframeRemoveFilledFrom = (filledFrom: string) => {
  return actionReducerArrayDelete(REDUX_IFRAME_FORM_FILLED_FORMS, filledFrom);
};

export const actionSetMoneyCzSliderValue = (value: number) => {
  return actionReducerNumberSet(REDUX_IFRAME_FORM_NUMBER, value);
};
