export class User {
  id: number | null = null;
  name: string = "";
  email: string = "";
  isAdmin: boolean = false;
  isAdvertiser: boolean = false;
  emailVerified: boolean = false;
  verifyTokenExpire: string | null = null;
  apiToken: string | null = null;
  forgotPasswordTokenExpire: string | null = null;
  //stats
  leadCount: number | null = null;
  leadTotal: number | null = null;
  clickTotal: number | null = null;
  leadRepeated: number | null = null;
  leadRejected: number | null = null;
  leadAllSales: number | null = null;
  crLead: number | null = null;
  crSales: number | null = null;
  allPayouts:
    | { allPayouts: number; display: string; howDisplay: string }[]
    | null = null;

  constructor(obj: any | null) {
    obj && Object.assign(this, obj);
    if (this.leadCount && this.leadTotal) {
      this.crLead = (this.leadCount / this.leadTotal) * 100;
    } else {
      this.crLead = 0;
    }
    if (this.leadAllSales && this.leadTotal) {
      this.crSales = (this.leadAllSales / this.leadTotal) * 100;
    } else {
      this.crSales = 0;
    }
  }
}
