import * as React from "react";
import { FC } from "react";
import { IframeInputWrapper } from "./IframeInputWrapper";
import { IframeFiledProps } from "../IframeInputsList";
import { Flex } from "../../../../../core/components/layout/Flex";

export const IframeSpacing: FC<IframeFiledProps> = ({ formField }) => (
  <IframeInputWrapper noMargin={true} formField={formField}>
    <Flex
      justifyContent={"center"}
      fullWidth={true}
      marginTop={formField.defaultValue}
      marginBottom={formField.defaultValue}
    />
  </IframeInputWrapper>
);
