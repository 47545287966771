import { post } from "../../../core/api/utils/fetchApi";
import { ENDPOINT_SUBMIT_FORM } from "../constant";
import UserOfferConfig from "../type/UserOfferConfig";
import { getSubIdToRequest } from "./getSubIdFromUrl";

export const getSubmitFormUrl = (userOfferConfig: UserOfferConfig) =>
  ENDPOINT_SUBMIT_FORM +
  "/" +
  userOfferConfig?.form?.systemName +
  "/" +
  userOfferConfig.id;

export const postIframeContentForm = (
  userOfferConfig: UserOfferConfig,
  formFilledValues: Record<string, any>,
  saleNow: boolean
) => {
  return post(
    getSubmitFormUrl(userOfferConfig) +
      "?saleNow=" +
      saleNow +
      getSubIdToRequest(),
    formFilledValues
  );
};
