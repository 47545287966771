import React, { FC } from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "../../redux/store";
import { TranslateItem } from "./type";

interface TranslateProps {
  id: string;
  params?: (string | number | { translateId: string; params?: string[] })[];
}
export const useTranslate = ({ id, params = [] }: TranslateProps): string => {
  const translates = useSelector(
    (state: AppStateType) => state.translate.translates
  );
  const currentLanguageIndex = useSelector(
    (state: AppStateType) => state.translate.currentLanguageIndex
  );
  return translateFunction(translates, currentLanguageIndex)(id, params);
};

const translateFunction = (
  translates: Record<string, TranslateItem>,
  currentLanguageIndex: number | null
) => (
  id: string,
  params: (string | number | { translateId: string; params?: string[] })[] = []
): string => {
  const t = translates[id];
  if (!t) {
    return `Missing translate: ${id}`;
  }
  if (typeof currentLanguageIndex !== "number") {
    return `Not selected any language not set!`;
  }
  if (!t.translates?.[currentLanguageIndex]) {
    return `Translation: ${id} do not have translate index ${currentLanguageIndex}.`;
  }
  let translate = t.translates?.[currentLanguageIndex];
  for (let i = 0; i < params?.length; i++) {
    const paramsItem = params[i];
    if (typeof paramsItem === "string" || typeof paramsItem === "number") {
      translate = translate?.replace("%d", String(paramsItem));
    } else {
      translate = translate?.replace(
        "%d",
        translateFunction(
          translates,
          currentLanguageIndex
        )(paramsItem.translateId)
      );
    }
  }
  return translate;
};

export const useTranslateFunction = () => {
  const translates = useSelector(
    (state: AppStateType) => state.translate.translates
  );
  const currentLanguageIndex = useSelector(
    (state: AppStateType) => state.translate.currentLanguageIndex
  );
  return translateFunction(translates, currentLanguageIndex);
};

const TranslateComponent: FC<TranslateProps> = (props) => (
  <>{useTranslate(props)}</>
);

export const Translate = React.memo(TranslateComponent);
export const T = Translate;
