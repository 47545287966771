import { VsiBase } from "./VsiBase";
import * as yup from "yup";
import { Translate } from "../../../../core/translate/Translate";
import BaseSchema from "yup/lib/schema";
import { NumberSchema } from "yup";

export class VsiNumber extends VsiBase {
  min: number | null = null;
  max: number | null = null;
  constructor(obj: any) {
    super();
    obj && Object.assign(this, obj);
  }

  toYup(key: string): BaseSchema {
    const translatePrefix = "field";
    const basicTranslateParameter = [{ translateId: this.translateId }];

    let ret: NumberSchema = yup
      .number()
      .integer(() => (
        <Translate
          id={"core." + translatePrefix + "IsNotInteger"}
          params={basicTranslateParameter}
        />
      ))
      .typeError(() => (
        <Translate
          id={"core." + translatePrefix + "IsNotNumber"}
          params={basicTranslateParameter}
        />
      ));
    if (this.min !== null) {
      ret = ret.min(this.min, () => (
        <Translate
          id={"core." + translatePrefix + "IsCanNotBeSmaller"}
          params={[{ translateId: this.translateId }, String(this.min)]}
        />
      ));
    }
    if (this.max !== null) {
      ret = ret.max(this.max, () => (
        <Translate
          id={"core." + translatePrefix + "IsCanNotBeBigger"}
          params={[{ translateId: this.translateId }, String(this.max)]}
        />
      ));
    }
    if (this.nullable) {
      ret = ret.nullable() as any;
    }

    return ret;
  }
}
