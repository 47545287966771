export class CascadeOfferItemFilterRatioBetweenFields {
  numeratorFieldName: string = "expenses";
  denominatorFieldName: string = "monthly_income";
  operator: string = "";
  value: number | null = null;
  id: number | null = null;

  constructor(obj?: any | null) {
    obj && Object.assign(this, obj);
  }

  toJson() {
    return this;
  }
}
