import React, { FC, ReactNode, useContext, useState } from "react";
import { Translate } from "../../../../translate/Translate";
import { useFormikContext } from "formik";
import { MyTextFieldBase, MyTextFieldPropsOnly } from "../MyTextFieldBase";
import {
  formikValueAccessor,
  formikVsiAccessor,
} from "../../../../../modules/validation/type/elemetnts/VsiConnectedRef";
import { MyFormikContext } from "../../MyFormik";
import { VsiString } from "../../../../../modules/validation/type/elemetnts/VsiString";
import { useDispatch } from "react-redux";
import { actionSnackbarShow } from "../../../../layouts/store/actions/snackbar.actions";
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";

export interface MyTextFileConfig {
  onBeforeChange?: (val: string) => string;
  lines?: number;
  placeholder?: string;
  InputProps?: Partial<StandardInputProps>;
}

interface MyTextFieldFormikProps extends MyTextFileConfig {
  name: string;
  translateId: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  shrinkLabel?: boolean;
  customError?: ReactNode;
  onChange?: (value: string | null) => void;
}

const MyTextFieldFormikCom: FC<
  MyTextFieldPropsOnly & MyTextFieldFormikProps
> = ({
  inputMode,
  disabled,
  placeholder,
  translateId,
  noMargin,
  isSaving,
  name,
  onBeforeChange,
  lines,
  customError,
  required,
  InputProps,
  shrinkLabel,
  onChange,
}) => {
  const {
    errors,
    setFieldValue,
    values,
    touched,
    handleBlur,
  } = useFormikContext();
  const dispatch = useDispatch();

  const error = formikValueAccessor(name, errors);
  const touchedThis = formikValueAccessor(name, touched);

  const { validation } = useContext(MyFormikContext);
  const value = formikValueAccessor(name, values);
  const val = formikVsiAccessor(name, validation);
  const regex = val instanceof VsiString ? val?.getRegex() : null;
  const [valueState, setValueState] = useState(
    value ? value : value === 0 ? "0" : ""
  );

  return (
    <>
      <MyTextFieldBase
        disabled={disabled}
        isSaving={isSaving}
        InputProps={InputProps}
        noMargin={noMargin}
        inputMode={inputMode}
        placeholder={placeholder}
        multiline={!!lines && lines > 1}
        rows={lines}
        name={name}
        required={required}
        label={<Translate id={translateId} />}
        value={valueState}
        error={(touchedThis && error) || !!customError}
        fullWidth
        InputLabelProps={{
          shrink: shrinkLabel,
        }}
        helperText={(touchedThis && error) || customError}
        onBlur={(e) => {
          handleBlur(e);
          onChange && onChange(valueState);
          setFieldValue(name, valueState);
        }}
        onChange={(e) => {
          let value: null | string = onBeforeChange
            ? onBeforeChange(e.target.value)
            : e.target.value;
          if (value === "" && inputMode === "numeric") {
            value = null;
          }
          if (regex) {
            if (value && regex.test(value)) {
              setValueState(value);
            } else {
              let errorsShowed = 0;
              if (value) {
                value.split("").forEach((char) => {
                  if (!regex.test(char) && errorsShowed < 3) {
                    errorsShowed++;
                    dispatch(
                      actionSnackbarShow({
                        message: (
                          <Translate
                            id={"core.charIsDisabled"}
                            params={[char]}
                          />
                        ),
                        variant: "error",
                      })
                    );
                  }
                });
              } else {
                setValueState(value);
              }
            }
          } else {
            setValueState(value);
          }
        }}
      />
    </>
  );
};

export const MyTextFieldFormik = MyTextFieldFormikCom;
