import { VsiBase } from "./VsiBase";
import * as yup from "yup";
import { Translate } from "../../../../core/translate/Translate";
import BaseSchema from "yup/lib/schema";

export class VsiEnum extends VsiBase {
  enumPrefix: string | null = null;
  possibleValues: string[] = [];

  constructor(obj: any) {
    super();
    obj && Object.assign(this, obj);
  }

  toYup(key: string): BaseSchema {
    const translatePrefix = "field";
    return yup
      .mixed()
      .oneOf(
        this.nullable ? [...this.possibleValues, null] : this.possibleValues,
        () => (
          <Translate
            id={"core." + translatePrefix + "IsNotValidEnum"}
            params={[
              { translateId: this.translateId },
              this.possibleValues.join(", ").replace(/(.{70})..+/, "$1…"),
            ]}
          />
        )
      );
  }
}
