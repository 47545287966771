import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { MuiButtonSpacingType } from "../../../types/styles";
import { useParams } from "react-router";
import { Translate } from "../../../core/translate/Translate";

const Button = styled(MuiButton)<MuiButtonSpacingType>(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

export interface Page404Params {
  translateId?: string;
  notShowGoHome?: string;
}

//TODO use name component as 500 and use root components
function Page404() {
  const { translateId, notShowGoHome } = useParams<Page404Params>();
  return (
    <Wrapper>
      <Helmet title="404 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        <Translate id={"core.404"} />
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        <Translate id={translateId ? translateId : "core.error404Text"} />
      </Typography>

      {notShowGoHome && (
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="secondary"
          mt={2}
        >
          Return to website
        </Button>
      )}
    </Wrapper>
  );
}

export default Page404;
