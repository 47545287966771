import { RequestConnectionProperty } from "./RequestConnectionProperty";
import { RequestConnectionPropertyModify } from "./RequestConnectionPropertyModify";

export class ResponseConnectionProperty {
  accessor: string = "";
  systemName: string = "";
  requestConfigId: null | number = null;
  type: string | null = null;
  responseHandlerId: null | number = null;
  //for type object
  objectProperties: RequestConnectionProperty[] = [];
  requestConnectionPropertyModify: RequestConnectionPropertyModify[] = [];
  id: number | null = null;

  constructor(obj?: any) {
    obj && Object.assign(this, obj);
    if (Array.isArray(obj?.objectProperties)) {
      this.objectProperties = obj.objectProperties.map(
        (item: any) => new RequestConnectionProperty(item)
      );
    }
    if (Array.isArray(obj?.requestConnectionPropertyModify)) {
      this.requestConnectionPropertyModify = obj.requestConnectionPropertyModify.map(
        (item: any) => new RequestConnectionPropertyModify(item)
      );
    }
  }

  toJson() {
    const { id, accessor, systemName, requestConnectionPropertyModify } = this;
    return {
      id,
      accessor,
      requestConnectionPropertyModify: requestConnectionPropertyModify.map(
        (item) => item.toJson()
      ),
      systemName,
    };
  }

  toTemplateJson() {
    const { accessor, systemName, requestConnectionPropertyModify } = this;
    return {
      accessor,
      requestConnectionPropertyModify: requestConnectionPropertyModify.map(
        (item) => item.toTemplateJson()
      ),
      systemName,
    };
  }
}
