import { CascadeOfferItemFilter } from "./cascade/CascadeOfferItemFilter";
import { Tree } from "../../../core/type/Tree";
import { Offer } from "../../formsIframe/type/Offer";
import {
  CascadeOfferItemDistributionType,
  DISTRIBUTION_TYPE_EXCLUSIVE,
} from "./cascade/CascadeOfferItemDistributionType";

export class OfferCascadeItem {
  id: number | null = null;
  parentOfferId: number | null = null;
  cascadeItemOfferId: number | null = null;
  order: number | null = null;
  ownToken: string | null = null;
  active: boolean = true;
  redirect: string = "";
  distributionType: CascadeOfferItemDistributionType = DISTRIBUTION_TYPE_EXCLUSIVE;
  smsVerify: boolean = false;
  payoutStatus: string | null = null;
  payout: number | null = null;
  openFilters: boolean = false;
  offer: Offer | null = null;
  filters: Tree<CascadeOfferItemFilter> = new Tree(
    [],
    "parentIdColumnName",
    "idColumnName"
  );
  //stats cascade
  offerId: number | null = null;
  duplicate: number | null = null;
  acceptedLeads: number | null = null;
  errors: number | null = null;
  rejectedLeads: number | null = null;
  avgResponseTime: number | null = null;
  offeredLeads: number | null = null;
  filteredLeads: number | null = null;
  totalLeads: number | null = null;
  redirectCount: number | null = null;

  constructor(obj?: any | null) {
    obj && Object.assign(this, obj);
    if (obj?.offer) {
      this.offer = new Offer(obj.offer);
    }
    if (obj?.filters) {
      this.filters = Tree.fromTreeArray(
        obj.filters.items,
        obj.filters.parentIdColumnName,
        obj.filters.idColumnName,
        CascadeOfferItemFilter
      );
    }
  }

  toJson() {
    const {
      ownToken,
      filters,
      offer,
      parentOfferId,
      openFilters,
      duplicate,
      acceptedLeads,
      errors,
      rejectedLeads,
      avgResponseTime,
      offeredLeads,
      offerId,
      filteredLeads,
      totalLeads,
      redirectCount,
      ...json
    } = this;
    return {
      ...json,
      ownToken: !ownToken ? null : ownToken,
      filters: filters ? filters.toJson() : undefined,
    };
  }
}
