import styled from "styled-components/macro";
import { Backdrop, CircularProgress, BackdropProps } from "@material-ui/core";

interface BackdropStyledProps {
  $color?: string;
  $backgroundColor?: string;
  $zIndex?: string;
  $position?: string;
}
interface MyBackdropProps {
  colorProgress?: "primary" | "secondary" | "inherit";
}
const BackdropStyled = styled(Backdrop)<BackdropStyledProps>`
  ${({ $color }) => $color && `color: ${$color};`}
  ${({ $backgroundColor }) =>
    $backgroundColor && `background-color: ${$backgroundColor};`}
  ${({ $zIndex }) => $zIndex && `z-index: ${$zIndex};`}
  ${({ $position }) => $position && `position: ${$position};`}
`;

export const MyBackdrop = ({
  colorProgress,
  ...props
}: BackdropProps & BackdropStyledProps & MyBackdropProps) => (
  <BackdropStyled {...props}>
    <CircularProgress color={colorProgress} />
  </BackdropStyled>
);
