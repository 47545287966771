import { ENDPOINT_OFFER, REDUX_OFFER_OFFER } from "../../../constants";
import { get, basicHandleError } from "../../../../../core/api/utils/fetchApi";
import { AppDispatchType } from "../../../../../redux/store";
import {
  actionLock,
  actionUnlock,
} from "../../../../../core/layouts/store/actions/lockable.actions";
import { LockKey } from "../../../../../core/layouts/utils/useIsLocked";
import { Offer } from "../../../../formsIframe/type/Offer";
import { actionReducerObjectSet } from "../../../../../redux/tools/createReducer/createReducerObject";
import { historyPush } from "../../../../../core/history";
import { getUrlOfferList } from "../../../utils/getUrlOfferList";

export const getLockKeyActionGetOffer = (id: number | string): LockKey => {
  return "get" + ENDPOINT_OFFER + id;
};

export const getOfferAction = (id: number | string) => async (
  dispatch: AppDispatchType
) => {
  dispatch(actionLock(getLockKeyActionGetOffer(id)));
  const res = await get(ENDPOINT_OFFER + "/" + id);
  dispatch(actionUnlock(getLockKeyActionGetOffer(id)));
  if (res.ok) {
    const offer = new Offer(res.data);
    dispatch(actionReducerObjectSet(REDUX_OFFER_OFFER, offer));
  } else {
    if (res.code === 404) {
      historyPush(getUrlOfferList());
    }
    dispatch(basicHandleError(res));
  }
};
