import { TreeItem } from "./TreeItem";

export class Tree<T> {
  idColumnName: string;
  parentIdColumnName: string;
  items: TreeItem<T>[] = [];

  constructor(
    items: TreeItem<T>[],
    parentIdColumnName: string,
    idColumnName: string
  ) {
    this.items = items;
    this.parentIdColumnName = parentIdColumnName;
    this.idColumnName = idColumnName;
  }

  isEmpty() {
    return this.items.length === 0;
  }

  public static fromTreeArray<T>(
    array: { children: any; item: any }[],
    parentIdColumnName: string,
    idColumnName: string,
    model: new (obj: any) => T
  ) {
    const root = array.map((item) =>
      TreeItem.fromTreeArray(item, parentIdColumnName, idColumnName, model)
    );
    return new Tree(root, parentIdColumnName, idColumnName);
  }

  addItem(toAdd: T) {
    this.items.forEach((item) =>
      item.addItem(toAdd, this.parentIdColumnName, this.idColumnName)
    );
  }

  toJson() {
    return this;
  }
}
