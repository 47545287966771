import { ReduxAction } from "../../type";
import { RESET, SET } from "../../constants";

type ReduxActionOperationString = typeof SET | typeof RESET;

export interface ReduxActionString extends ReduxAction {
  operation: ReduxActionOperationString;
}

export interface ReduxActionStringSet extends ReduxAction {
  operation: typeof SET;
}
export const actionReducerStringSet = (
  type: string,
  payload: any
): ReduxActionStringSet => ({
  type,
  operation: SET,
  payload,
});

export interface ReduxActionStringReset extends ReduxAction {
  operation: typeof RESET;
}
export const actionReducerStringReset = (
  type: string,
  payload: any
): ReduxActionStringReset => ({
  type,
  operation: RESET,
  payload,
});

export const createReducerString = <T extends string>(
  actionType: string,
  defaultState: T | null
) => (state = defaultState, action: ReduxActionString): T | null => {
  if (action.type === actionType) {
    const actions: Record<ReduxActionOperationString, () => T | null> = {
      [SET]: () => {
        if (action.payload === undefined) {
          throw new Error(
            `Can not set undefined to reducer. Reducer: ${actionType}, operation: ${SET}`
          );
        }
        return action.payload;
      },
      [RESET]: () => defaultState,
    };
    if (actions[action.operation]) {
      return actions[action.operation]();
    } else {
      throw new Error(
        `Unknown operation ${action.operation} for reducer: ${action.type}`
      );
    }
  }
  return state;
};
