import { VsiBase } from "./VsiBase";
import { createVsiInstance } from "../../store/action/getValidation.action";
import BaseSchema from "yup/lib/schema";
import * as yup from "yup";

export class VsiArray extends VsiBase {
  scheme: Record<string, VsiBase> = {};

  constructor(obj: any) {
    super();
    obj && Object.assign(this, obj);
    this.scheme = Object.keys(obj.scheme).reduce((all, key: string) => {
      return {
        ...all,
        [key]: createVsiInstance(obj.scheme[key]),
      };
    }, {});
  }

  toYup(key: string) {
    const keys = Object.keys(this.scheme);
    let ret: BaseSchema = yup.array().of(
      yup.object().shape(
        keys.reduce((all, key) => {
          if (
            // (onlyForInputs && !onlyForInputs.includes(key)) ||
            !this.scheme?.[key]
          ) {
            return all;
          }
          return {
            ...all,
            [key]: this.scheme[key].toYup(key),
          };
        }, {})
      )
    );
    return ret;
  }
}
