import { API_ROOT } from "../../../core/api/constants";

export const addLinkToLinkOffer = (text: string, userId: number) =>
  text
    ? text.replace(/\${(.*)}/g, (fullTag, tag) => {
        const tagKey = tag.split(":");
        if (tagKey.length !== 2 || tagKey[0] !== "linkToLinkOffer") {
          return fullTag;
        }
        return (
          API_ROOT +
          `/offer/handle-offer-link-without-user-config?offerId=${tagKey[1]}&affiliatePartnerId=${userId}`
        );
      })
    : "";
