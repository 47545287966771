import * as React from "react";
import { FC, useState } from "react";
import {
  Button,
  createStyles,
  darken,
  Slider,
  Typography,
  withStyles,
} from "@material-ui/core";
import { IframeInputWrapper } from "./IframeInputWrapper";
import { IframeFiledProps } from "../IframeInputsList";
import { FormField } from "../../../type/FormField";
import { Form } from "../../../type/Form";
import {
  TYPE_MAX_NUMBER,
  TYPE_MIN_NUMBER,
  TYPE_POST_FIX,
  TYPE_STEP,
} from "../../../constant/formFieldConfigType";
import styled from "styled-components";
import { SliderValue } from "./sliderSubcomponents/SilderValue";
import { Flex } from "../../../../../core/components/layout/Flex";
import { useEffect } from "react";
import { lighten } from "@material-ui/core/styles/colorManipulator";

const useSliderConfig = (formField: FormField, form: Form) => {
  const max =
    formField.formFieldConfigs.find((item) => item.type === TYPE_MAX_NUMBER)
      ?.numberConfig || 1;
  const min =
    formField.formFieldConfigs.find((item) => item.type === TYPE_MIN_NUMBER)
      ?.numberConfig || 0;
  const step =
    formField.formFieldConfigs.find((item) => item.type === TYPE_STEP)
      ?.numberConfig || 1;
  const postFix = formField.formFieldConfigs.find(
    (item) => item.type === TYPE_POST_FIX
  )?.stringConfig;
  return {
    max,
    min,
    step,
    postFix,
  };
};

export const SliderStateContext = React.createContext<{
  onChange?: (size: number) => void;
}>({});

interface IframeSliderProps {
  doNotUseWrap?: boolean;
  valueLabelFormat?: (value: number) => string;
}

export const IframeSlider: FC<IframeFiledProps & IframeSliderProps> = ({
  onChange,
  form,
  formField,
  offer,
  doNotUseWrap = false,
  value,
  valueLabelFormat,
}) => {
  useEffect(() => {
    if (formField.defaultValue !== null && value === undefined) {
      onChange(Number.parseFloat(formField.defaultValue));
    }
  }, [formField.defaultValue, value, onChange]);
  const [thumbWidth, setThumbWidth] = useState(100);
  const config = useSliderConfig(formField, form);
  const valueToTex = valueLabelFormat
    ? valueLabelFormat
    : (val: number) =>
        config.postFix ? `${val} ${config.postFix}` : String(val);
  return (
    <IframeInputWrapper formField={formField} doNotUseWrap={doNotUseWrap}>
      <Flex flexDirection={"column"} fullWidth={true} marginTop={"xxl"}>
        {formField.visibleDescription && (
          <Typography variant="h3" gutterBottom align={"center"}>
            <strong>{formField.visibleDescription}</strong>
          </Typography>
        )}

        <Flex
          fullWidth={true}
          marginTop={"large"}
          alignItems={"center"}
          position={"relative"}
        >
          <Flex position={"relative"}>
            <PlusButton
              color={"secondary"}
              variant={"contained"}
              onClick={() => {
                const targetValue = value - config.step;
                if (targetValue >= config.min) {
                  onChange(targetValue);
                } else {
                  onChange(config.min);
                }
              }}
            >
              -
            </PlusButton>
            <PlusButtonText variant={"body1"}>
              {valueToTex(config.min)}
            </PlusButtonText>
          </Flex>
          <SliderStateContext.Provider
            value={{
              onChange: (size: number) => {
                setThumbWidth(size);
              },
            }}
          >
            <SliderStyled
              thumbWidth={thumbWidth}
              step={config.step}
              max={config.max}
              min={config.min}
              onChange={(e, value) => onChange(value)}
              valueLabelFormat={valueToTex}
              value={value || 0}
              valueLabelDisplay={"auto"}
              ValueLabelComponent={SliderValue}
            />
          </SliderStateContext.Provider>
          <Rail />
          <Track />
          <Flex position={"relative"}>
            <PlusButton
              color={"secondary"}
              variant={"contained"}
              onClick={() => {
                const targetValue = value + config.step;
                if (targetValue <= config.max) {
                  onChange(targetValue);
                } else {
                  onChange(config.max);
                }
              }}
            >
              +
            </PlusButton>
            <PlusButtonText variant={"body1"}>
              {valueToTex(config.max)}
            </PlusButtonText>
          </Flex>
        </Flex>
      </Flex>
    </IframeInputWrapper>
  );
};

const PlusButton = styled(Button)`
  padding: ${(props) => props.theme.spacing(0, 1, 1.5, 1)};
  font-size: 3rem;
  line-height: 1;
  min-width: 39px;
  width: 48px;
  font-weight: 400;
  height: 48px;
  z-index: 100;
`;

const PlusButtonText = styled(Typography)`
  position: absolute;
  top: 50px;
  width: 85px;
  margin-left: -17px;
  font-size: 20px;
  text-align: center;
`;

const Rail = styled(Typography)`
  height: 14px;
  background: ${(props) => props.theme.palette.primary.main};
  width: 90px;
  position: absolute;
  left: 48px;
`;
const Track = styled(Typography)`
  height: 14px;
  background: ${({ theme }) =>
    theme.palette.type === "light"
      ? lighten(theme.palette.primary.main, 0.62)
      : darken(theme.palette.primary.main, 0.5)};
  width: 150px;
  position: absolute;
  right: 0;
  background: #e9e9e9;
`;

const SliderStyled = withStyles(
  (theme) =>
    createStyles({
      root: ({ thumbWidth }: { thumbWidth: number }) => ({
        color: theme.palette.primary.main,
        height: 14,
        marginLeft: thumbWidth / 2,
        marginRight: thumbWidth / 2 - 1,
      }),
      thumb: ({ thumbWidth }: { thumbWidth: number }) => ({
        zIndex: 50,
        height: 24,
        width: "unset",
        backgroundColor: "#fff",
        border: "0px solid currentColor",
        marginTop: -5,
        marginLeft: -thumbWidth / 2,
        "&:focus, &:hover": {
          boxShadow: "inherit",
        },
      }),
      track: {
        height: 14,
        borderRadius: 0,
      },
      rail: {
        height: 14,
        borderRadius: 0,
        background: "#e9e9e9",
        opacity: 1,
      },
    }),
  { withTheme: true }
)(Slider);
