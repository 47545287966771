import { ENDPOINT_LOGIN, PAGE_LOGIN } from "../../constants";
import { del } from "../../../../core/api/utils/fetchApi";
import { AppDispatchType } from "../../../../redux/store";
import {
  actionLock,
  actionUnlock,
} from "../../../../core/layouts/store/actions/lockable.actions";
import { actionUserToReducer } from "./login.action";
import { actionSnackbarShow } from "../../../../core/layouts/store/actions/snackbar.actions";
import { historyPush } from "../../../../core/history";
import { LockKey } from "../../../../core/layouts/utils/useIsLocked";
import { getUrlAuth } from "../../utils/getUrlAuth";

export interface LoginProps {
  email: string;
  password: string;
}

export const getLockKeyActionIsLogged = (): LockKey => {
  return "delete" + ENDPOINT_LOGIN;
};

export const actionLogout = () => async (dispatch: AppDispatchType) => {
  dispatch(actionLock(getLockKeyActionIsLogged()));
  const res = await del(ENDPOINT_LOGIN);
  dispatch(actionUnlock(getLockKeyActionIsLogged()));
  if (res.ok) {
    historyPush(getUrlAuth(PAGE_LOGIN));
    dispatch(actionUserToReducer(null));
    return true;
  } else {
    dispatch(
      actionSnackbarShow({
        translateId: "core.failToLogout",
        variant: "error",
      })
    );
    return false;
  }
};
