import { post } from "../../../core/api/utils/fetchApi";
import { ENDPOINT_USER_REPORT_ERROR } from "../constant";

interface PostUserErrorReport {
  message: string;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
}

export const postIframeError = (body: PostUserErrorReport) => {
  return post(ENDPOINT_USER_REPORT_ERROR, body);
};
