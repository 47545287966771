import { VsiBase } from "./VsiBase";
import * as yup from "yup";
import { Translate } from "../../../../core/translate/Translate";
import BaseSchema from "yup/lib/schema";
import StringSchema from "yup/lib/string";

export class VsiString extends VsiBase {
  contains: string[] = [];
  defaultValue: string | null = null;
  minLength: number | null = null;
  maxLength: number | null = null;
  regexMatch: string | null = null;
  required: boolean = false;

  constructor(obj: any) {
    super();
    obj && Object.assign(this, obj);
  }

  getRegex() {
    if (this.regexMatch === null) {
      return null;
    }
    const main = this.regexMatch.match(/\/(.+)\/.*/)?.[1] || "";
    const options = this.regexMatch.match(/\/.+\/(.*)/)?.[1] || "/gm";
    return new RegExp(main, options);
  }

  toYup(key: string): BaseSchema {
    const translatePrefix = "field";
    const basicTranslateParameter = [{ translateId: this.translateId }];

    let ret: StringSchema = yup
      .string()
      .default(this.defaultValue || "")
      .typeError(() => (
        <Translate
          id={"core." + translatePrefix + "IsNotString"}
          params={basicTranslateParameter}
        />
      ));
    if (this.required === true) {
      ret = ret.required(() => (
        <Translate
          id={"core." + translatePrefix + "IsRequired"}
          params={basicTranslateParameter}
        />
      ));
    } else {
      ret = ret.notRequired();
    }
    if (this.maxLength !== null) {
      ret = ret.max(this.maxLength, () => {
        return (
          <Translate
            id={"core." + translatePrefix + "CanNotBeLonger"}
            params={[{ translateId: this.translateId }, String(this.maxLength)]}
          />
        );
      }) as any;
    }
    if (this.minLength !== null) {
      ret = ret.min(this.minLength, () => {
        return (
          <Translate
            id={"core." + translatePrefix + "CanNotBeShorter"}
            params={[{ translateId: this.translateId }, String(this.minLength)]}
          />
        );
      }) as any;
    }
    if (this.nullable) {
      ret = ret.nullable() as any;
    }
    if (this.regexMatch !== null) {
      const regexToUse = this.getRegex();
      if (regexToUse) {
        ret = ret.matches(regexToUse, {
          excludeEmptyString: !this.required,
          message: () => (
            <Translate
              id={"core." + translatePrefix + "DoNotMath"}
              params={[
                {
                  translateId: this.translateId,
                },
                this.regexMatch || "",
              ]}
            />
          ),
        });
      }
    }
    this.contains.forEach((contains) => {
      const escapeRegExp = (string: string): string => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      };
      ret = ret.matches(new RegExp(escapeRegExp(contains)), {
        excludeEmptyString: !this.required,
        message: () => (
          <Translate
            id={"core." + translatePrefix + "NotContains"}
            params={[
              {
                translateId: this.translateId,
              },
              contains,
            ]}
          />
        ),
      });
    });
    return ret;
  }
}
