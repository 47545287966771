import { Currency } from "../../currency/type/Currency";
import { OfferPayoutConfig } from "../../offers/type/OfferPayoutConfig";
import { OfferAdvantage } from "../../offers/type/OfferAdvantage";
import { OfferLoadingPage } from "../../offers/type/OfferLoadingPage";
import {
  OfferStatusType,
  STATUS_INACTIVE,
} from "../../offers/type/status/OfferStatuses";
import {
  LeadStatusType,
  STATUS_LEAD,
} from "../../offers/type/status/LeadStatuses";
import { OfferAllowedMarketingChannel } from "../../offers/type/OfferAllowedMarketingChannel";
import { File } from "../../file/type/File";
import { Optional } from "../../../core/type/helpTypes";
import { FormSetting } from "./FormSetting";
import { OfferCascadeItem } from "../../offers/type/OfferCascadeItem";
import {
  OFFER_ACCESS_TYPE_ALL,
  OfferAccessType,
} from "../../offers/type/status/OfferAccessType";
import { AlternativeOfferType } from "../../offers/type/enum/AlternativeOfferType";
import { User } from "../../auth/type/User";
import { OFFER_TYPE_USER_CASCADE_MIX } from "../../offers/type/enum/OfferType";
import { OfferConnectionOffer } from "../../offers/type/OfferConnectionOffer";

export class Offer extends FormSetting {
  public static LEAD_HANDLE_TYPE_LEAD_HANDLER = "lead-handler";
  public static LEAD_HANDLE_TYPE_OFFER_CONNECTION = "offer-connection";

  type?: string;
  ownerId?: number;
  formId: number | null = null;
  name: string = "";
  visibleName: string = "";
  activeFrom: string = "";
  currencyId: number | null = null;
  accessType: OfferAccessType = OFFER_ACCESS_TYPE_ALL;
  logoImagePath: any = null;
  enableLinkOffer?: boolean = false;
  smsVerify: boolean = false;
  enableApiOffer?: boolean = false;
  enableIframeOffer?: boolean = false;
  enableLinkOfferOwnToken?: boolean = false;
  enableApiOfferOwnToken?: boolean = false;
  enableIframeOfferOwnToken?: boolean = false;
  disableAlternativeOfferInDetail?: boolean = false;
  linkOfferInfo?: string = "";
  apiOfferInfoAfter: string = "";
  iframeOfferInfoAfter: string = "";
  linkOfferInfoAfter: string = "";
  cookiesDays: number = 1;
  revenue: number | null = 0;
  logoFileId: number | null = null;
  apiOfferInfo?: string = "";
  iframeOfferInfo?: string = "";
  description: string = "";
  advertiser: string | null = null;
  clientProfile: string | null = null;
  country: null | string = "cz";
  leadHandler: string | null = null;
  offerProductId: null | number = null;
  offerCategoryId: null | number = null;
  id: number | null = null;
  currency?: Currency;
  owner: null | User = null;
  offerPayoutConfigs: OfferPayoutConfig[] = [];
  advantages: OfferAdvantage[] | null = [];
  loadingPages: OfferLoadingPage[] | null = [];
  allowedMarketingChannels: OfferAllowedMarketingChannel[] | null = [];
  cascadeOfferItems: OfferCascadeItem[] | null = [];
  offerPrivateAccess: number[] | null = [];
  files: File[] | null = [];
  logoFile: File | null = null;
  //clapping
  clappingStatus: LeadStatusType = STATUS_LEAD;
  status: OfferStatusType = STATUS_INACTIVE;
  capping: null | number = 0;
  cappingLeadCount: null | number = null;
  //stats
  leadCount: number | null = null;
  leadTotal: number | null = null;
  clickTotal: number | null = null;
  leadRepeated: number | null = null;
  leadRejected: number | null = null;
  leadAllSales: number | null = null;
  leadSales: number | null = null;
  redirectCount: number | null = null;
  crLead: number | null = null;
  crSales: number | null = null;
  allPayouts:
    | { allPayouts: number; display: string; howDisplay: string }[]
    | null = null;
  allPayoutsLead:
    | { allPayouts: number; display: string; howDisplay: string }[]
    | null = null;
  allPayoutsClick:
    | { allPayouts: number; display: string; howDisplay: string }[]
    | null = null;
  leadHandleType: string | null = "lead-handler";
  offerConnectionId: number | null = null;
  offerConnectionOfferId: number | null = null;
  offerConnectionOffer: OfferConnectionOffer | null = null;
  //stats cascade
  duplicate: number | null = null;
  acceptedLeads: number | null = null;
  errors: number | null = null;
  rejectedLeads: number | null = null;
  avgResponseTime: number | null = null;
  offeredLeads: number | null = null;
  filteredLeads: number | null = null;
  totalLeads: number | null = null;

  alternativeOfferId: number | null = null;
  alternativeType: AlternativeOfferType | null = null;

  //not api values
  enableAlternativeOffer: boolean = false;
  enableCapping: boolean = false;

  constructor(obj: any) {
    super();
    obj && Object.assign(this, obj);
    this.enableCapping = !!(obj?.clappingStatus || obj?.capping);
    this.enableAlternativeOffer = !!(
      obj?.alternativeOfferId || obj?.alternativeType
    );
    if (obj.currency) {
      this.currency = new Currency(obj.currency);
    }
    if (obj.offerConnectionOffer) {
      this.offerConnectionOffer = new OfferConnectionOffer(
        obj.offerConnectionOffer
      );
    }
    if (obj.offerPayoutConfigs) {
      this.offerPayoutConfigs = obj.offerPayoutConfigs.map(
        (item: any) => new OfferPayoutConfig(item)
      );
    }
    if (obj.advantages) {
      this.advantages = obj.advantages.map(
        (item: any) => new OfferAdvantage(item)
      );
    }
    if (this.leftLogo) {
      this.leftLogo = new File(this.leftLogo);
    }
    if (this.logoFile) {
      this.logoFile = new File(this.logoFile);
    }
    if (this.centerLogo) {
      this.centerLogo = new File(this.centerLogo);
    }
    if (obj.loadingPages) {
      this.loadingPages = obj.loadingPages.map(
        (item: any) => new OfferLoadingPage(item)
      );
    }
    if (obj.cascadeOfferItems) {
      this.cascadeOfferItems = obj.cascadeOfferItems.map(
        (item: any) => new OfferCascadeItem(item)
      );
    }
    if (obj.allowedMarketingChannels) {
      this.allowedMarketingChannels = obj.allowedMarketingChannels.map(
        (item: any) => new OfferAllowedMarketingChannel(item)
      );
    }
    if (obj.files) {
      this.files = obj.files.map((item: any) => new File(item));
    }
    this.crSales =
      this.leadAllSales && this.leadTotal
        ? (this.leadAllSales / this.leadTotal) * 100
        : 0;
    this.crLead =
      this.leadCount && this.leadTotal
        ? (this.leadCount / this.leadTotal) * 100
        : 0;
    if (this.type === OFFER_TYPE_USER_CASCADE_MIX) {
      this.enableApiOffer = true;
      this.enableIframeOffer = true;
    }
  }

  canUserEdit(user: User) {
    if (user.isAdmin) {
      return true;
    }
    return this.ownerId === user.id;
  }

  getPayoutsForUser(userId: number, offerType: string = "all") {
    const allItems =
      offerType === "all"
        ? this.offerPayoutConfigs
        : this.offerPayoutConfigs.filter(
            (item) => item.offerType === offerType
          );
    const allFilterByUserId = allItems.filter((item) => item.userId === userId);
    return allItems.filter((item) => {
      if (item.userId) {
        return item.userId === userId;
      } else {
        return !allFilterByUserId.some((i) => i.offerType === item.offerType);
      }
    });
  }

  isCappingFull() {
    return (
      this.enableCapping &&
      this.cappingLeadCount !== null &&
      this.capping !== null &&
      this.enableCapping &&
      !Number.isNaN(this.capping) &&
      !Number.isNaN(this.cappingLeadCount) &&
      this.capping < this.cappingLeadCount
    );
  }

  toJson() {
    const {
      enableCapping,
      enableAlternativeOffer,
      activeFrom,
      secondaryColor,
      borderColor,
      primaryColor,
      logoImagePath,
      centerLogo,
      logoFileId,
      logoFile,
      files,
      owner,
      currency,
      id,
      leftLogo,
      leadCount,
      leadTotal,
      clickTotal,
      leadRepeated,
      duplicate,
      acceptedLeads,
      errors,
      rejectedLeads,
      avgResponseTime,
      offeredLeads,
      filteredLeads,
      totalLeads,
      leadRejected,
      leadAllSales,
      crLead,
      crSales,
      allPayouts,
      cappingLeadCount,
      allPayoutsClick,
      leadSales,
      redirectCount,
      allPayoutsLead,
      offerConnectionOffer,
      offerConnectionOfferId,
      ...props
    } = this;

    return {
      ...props,
      primaryColor: primaryColor || null,
      borderColor: borderColor || null,
      secondaryColor: secondaryColor || null,
      activeFrom: activeFrom ? new Date(activeFrom).toISOString() : null,
      capping: enableCapping ? this.capping || null : null,
      clappingStatus: enableCapping ? this.clappingStatus || null : null,
      alternativeOfferId: enableAlternativeOffer
        ? this.alternativeOfferId || null
        : null,
      alternativeType: enableAlternativeOffer
        ? this.alternativeType || null
        : null,
      advantages:
        this.advantages?.map((item: OfferAdvantage) => item.toJson()) || [],
      allowedMarketingChannels:
        this.allowedMarketingChannels?.map(
          (item: OfferAllowedMarketingChannel) => item.toJson()
        ) || [],
      offerConnectionOffer: offerConnectionOffer?.toJson() || null,
      loadingPages:
        this.loadingPages?.map((item: OfferLoadingPage) => item.toJson()) || [],
      offerPrivateAccess: this.offerPrivateAccess,
      cascadeOfferItems:
        this.cascadeOfferItems?.map((item: OfferCascadeItem) =>
          item.toJson()
        ) || [],
      offerPayoutConfigs:
        this.offerPayoutConfigs?.map((item: OfferPayoutConfig) =>
          item.toJson()
        ) || [],
    };
  }
}

export type OfferApi = Optional<Offer, "toJson">;
