import * as React from "react";
import { FC, useEffect, useRef, useState } from "react";
import {
  createStyles,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { IframeInputWrapper } from "../IframeInputWrapper";
import { IframeFiledProps } from "../../IframeInputsList";
import { FormField } from "../../../../type/FormField";
import {
  TYPE_MAX_CHAR_LENGTH,
  TYPE_MIN_CHAR_LENGTH,
} from "../../../../constant/formFieldConfigType";
import MaskedInput from "react-text-mask";
import { useMaskIframeTextFieldConfig } from "./useMaskIframeTextFieldConfig";
import { TYPE_EMAIL } from "../../../../constant/formFieldType";
import { Close, Done } from "@material-ui/icons";
import {
  actionIframeAddError,
  actionIframeRemoveError,
} from "../../../../store/action/addIframeError.action";
import { useDispatch } from "react-redux";

const useSliderConfig = (formField: FormField) => {
  const max =
    formField.formFieldConfigs.find(
      (item) => item.type === TYPE_MAX_CHAR_LENGTH
    )?.numberConfig || 2048;
  const min =
    formField.formFieldConfigs.find(
      (item) => item.type === TYPE_MIN_CHAR_LENGTH
    )?.numberConfig || 0;
  return {
    max,
    min,
  };
};

interface IframeTextFiledProps {
  type: string;
}

const useStyles = makeStyles((theme) => {
  return createStyles({
    textFieldRoot: ({ success }: { success: boolean }) => ({
      "&:hover label": {
        color: theme.palette.text.primary,
      },
      "&:hover": {
        color: theme.palette.text.primary,
      },
    }),
    done: {
      color: theme.palette.success.main,
      width: "1.4em",
      height: "1.4em",
    },
    error: {
      width: "1.4em",
      height: "1.4em",
    },
    textFieldOutline: ({ success }: { success: boolean }) => ({
      borderColor: success ? theme.palette.success.main : undefined,
    }),
  });
});

const IframeTextFiled: FC<IframeFiledProps & IframeTextFiledProps> = ({
  onChange,
  formField,
  value,
  type,
}) => {
  const dispatch = useDispatch();
  const config = useSliderConfig(formField);
  useEffect(() => {
    if (formField.defaultValue && value !== undefined) {
      //set default value
      onChange(formField.defaultValue);
    }
  }, [formField.defaultValue, value, onChange]);
  const valueRef = useRef(value);
  valueRef.current = value;
  const {
    mask,
    startAdornment,
    maxLength,
    minLength,
    charsToRemove,
    inputmode,
    isValid: isValidTest,
  } = useMaskIframeTextFieldConfig(type);
  const [changed, setChanged] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const isValid = () => {
    if (minLength && value && value.length && value.length < minLength) {
      return false;
    }
    if (
      type === TYPE_EMAIL &&
      (!value || value.endsWith(".") || value.includes("@."))
    ) {
      return false;
    }
    if (isValidTest && value && !isValidTest(value)) {
      return false;
    }
    return !(formField.isRequired() && !value);
  };
  const error = changed && !isFocused && !isValid();
  const saveError = changed && !isValid();
  const success = changed && !error;
  const classes = useStyles({ success });

  useEffect(() => {
    if (saveError) {
      dispatch(actionIframeAddError(formField.systemName));
    } else {
      dispatch(actionIframeRemoveError(formField.systemName));
    }
  }, [saveError, formField.systemName, dispatch]);

  return (
    <IframeInputWrapper formField={formField}>
      <TextField
        error={error}
        className={classes.textFieldRoot}
        fullWidth={true}
        label={formField.visibleDescription}
        onChange={(e) => {
          const newValue = charsToRemove.reduce(
            (value: string, currentValue) =>
              value.replace(new RegExp(currentValue, "g"), ""),
            e.target.value
          );
          const max = maxLength || config.max;
          !max || newValue.length > max ? onChange(value) : onChange(newValue);
        }}
        value={value || ""}
        variant="outlined"
        inputProps={{
          type,
          mask,
          inputMode: inputmode,
          onBlur: () => {
            setChanged(true);
            setIsFocused(false);
          },
          onFocus: (e) => {
            setIsFocused(true);
            if (!valueRef.current || valueRef.current.length === 0) {
              setTimeout(function () {
                e.target.setSelectionRange(0, 0);
              }, 0);
            }
          },
        }}
        InputProps={{
          classes: {
            notchedOutline: classes.textFieldOutline,
          },
          inputComponent: mask && (TextMaskCustom as any),
          startAdornment: startAdornment,
          endAdornment: error ? (
            <InputAdornment color={"inherit"} position="end">
              <Close color={"error"} className={classes.error} />
            </InputAdornment>
          ) : (
            success && (
              <InputAdornment color={"inherit"} position="end">
                <Done className={classes.done} />
              </InputAdornment>
            )
          ),
        }}
      />
    </IframeInputWrapper>
  );
};

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask: string[];
  type: string;
}

const TextMaskCustom = (props: TextMaskCustomProps) => {
  const { inputRef, mask, type, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={"_"}
      showMask
    />
  );
};

export default React.memo(IframeTextFiled);
