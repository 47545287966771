import { ReduxAction } from "../../type";
import { CLOSE, OPEN, RESET } from "../../constants";

type ReduxActionOperationDialog = typeof OPEN | typeof CLOSE | typeof RESET;

export interface ReduxActionDialog extends ReduxAction {
  operation: ReduxActionOperationDialog;
}

export interface ReduxActionDialogOpen extends ReduxAction {
  operation: typeof OPEN;
}
const actionReducerDialogOpen = (
  type: string,
  valueToEdit?: any
): ReduxActionDialogOpen => ({
  type,
  operation: OPEN,
  payload: valueToEdit,
});

export const actionReducerDialogOpenEdit = <T>(
  type: string,
  valueToEdit: T
): ReduxActionDialogOpen => actionReducerDialogOpen(type, valueToEdit);

export const actionReducerDialogOpenCreate = (
  type: string
): ReduxActionDialogOpen => actionReducerDialogOpen(type);

export interface ReduxActionDialogClose extends ReduxAction {
  operation: typeof CLOSE;
}
export const actionReducerDialogClose = (
  type: string
): ReduxActionDialogClose => ({
  type,
  operation: CLOSE,
  payload: null,
});

export interface ReduxActionDialogReset extends ReduxAction {
  operation: typeof RESET;
}
export const actionReducerDialogReset = (
  type: string,
  payload: any
): ReduxActionDialogReset => ({
  type,
  operation: RESET,
  payload,
});

interface State<T> {
  open: boolean;
  itemToEdit: T | null;
}

export const createReducerDialog = <T>(
  actionType: string,
  defaultState: State<T> = {
    open: false,
    itemToEdit: null,
  }
) => (state: State<T> = defaultState, action: ReduxActionDialog): State<T> => {
  if (action.type === actionType) {
    const actions: Record<ReduxActionOperationDialog, () => State<T>> = {
      [OPEN]: () => {
        return {
          open: true,
          itemToEdit: action.payload,
        };
      },
      [CLOSE]: () => {
        return {
          open: false,
          itemToEdit: null,
        };
      },
      [RESET]: () => defaultState,
    };
    if (actions[action.operation]) {
      return actions[action.operation]();
    } else {
      throw new Error(
        `Unknown operation ${action.operation} for reducer: ${action.type}`
      );
    }
  }
  return state;
};
