import { OFFER } from "../../../core/constants/modules";
import { ENDPOINT_FILE_DOWNLOAD } from "../constants";

export class File {
  id: number | null = null;
  name: string = "";
  fileSize: number = 0;
  description: string = "";
  type: string = "";
  created: null | string = null;
  module: string = OFFER;
  path: string | null = null;
  isPublic: boolean = false;

  constructor(obj: any | null) {
    obj && Object.assign(this, obj);
  }

  getPrimaryText() {
    return this.description || this.name;
  }

  getPublicPath(download = false) {
    return (
      ENDPOINT_FILE_DOWNLOAD + "/" + this.id + "?download=" + String(download)
    );
  }

  getSecondaryText() {
    return this.description
      ? this.name
      : this.getFileSizeDisplay() + " - " + this.getCreatedDisplay();
  }
  getCreatedDisplay() {
    if (!this.created) {
      return "-";
    }
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(this.created).toLocaleDateString(undefined, options);
  }

  getFileSizeDisplay() {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (this.fileSize === null) return "? Bytes";
    if (this.fileSize === 0) return "0 Byte";
    const i = Math.round(Math.floor(Math.log(this.fileSize) / Math.log(1024)));
    return (
      Math.round((this.fileSize / Math.pow(1024, i)) * 100) / 100 +
      " " +
      sizes[i]
    );
  }

  toJson() {
    return this;
  }
}
