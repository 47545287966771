export const DISTRIBUTION_TYPE_EXCLUSIVE = "exclusive";
export const DISTRIBUTION_TYPE_NON_EXCLUSIVE = "non-exclusive";
export const DISTRIBUTION_TYPE_USER_CENTRIC = "user-centric";
export const DISTRIBUTION_TYPE_AUCTION = "auction";
export const DISTRIBUTION_TYPES = [
  DISTRIBUTION_TYPE_EXCLUSIVE,
  DISTRIBUTION_TYPE_NON_EXCLUSIVE,
  DISTRIBUTION_TYPE_USER_CENTRIC,
  DISTRIBUTION_TYPE_AUCTION,
];

export type CascadeOfferItemDistributionType =
  | typeof DISTRIBUTION_TYPE_EXCLUSIVE
  | typeof DISTRIBUTION_TYPE_NON_EXCLUSIVE
  | typeof DISTRIBUTION_TYPE_USER_CENTRIC
  | typeof DISTRIBUTION_TYPE_AUCTION;
