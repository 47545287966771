import { ENDPOINT_LOGIN, REDUX_AUTH_LOGGED_USER } from "../../constants";
import { basicHandleError, post } from "../../../../core/api/utils/fetchApi";
import { AppDispatchType } from "../../../../redux/store";
import {
  actionLock,
  actionUnlock,
} from "../../../../core/layouts/store/actions/lockable.actions";
import { actionReducerObjectSet } from "../../../../redux/tools/createReducer/createReducerObject";
import { User } from "../../type/User";
import { historyPush } from "../../../../core/history";
import { getUrlDashboard } from "../../../dashboard/utils/getUrlDashboard";
import { actionSnackbarShow } from "../../../../core/layouts/store/actions/snackbar.actions";
import { LockKey } from "../../../../core/layouts/utils/useIsLocked";

export interface LoginProps {
  email: string;
  password: string;
}

export const getLockKeyActionLogin = (): LockKey => {
  return "post" + ENDPOINT_LOGIN;
};

export const loginAction = (data: LoginProps) => async (
  dispatch: AppDispatchType
) => {
  dispatch(actionLock(getLockKeyActionLogin()));
  const res = await post(ENDPOINT_LOGIN, data);
  dispatch(actionUnlock(getLockKeyActionLogin()));
  if (res.ok) {
    dispatch(actionUserToReducer(new User(res.data)));
    historyPush(getUrlDashboard());
    return true;
  } else {
    switch (res.code) {
      case 403:
        dispatch(
          actionSnackbarShow({
            translateId: "core.wrongPassword",
            variant: "error",
          })
        );
        break;
      default:
        dispatch(basicHandleError(res));
    }
  }
};

export const actionUserToReducer = (user: User | null) => {
  return actionReducerObjectSet(REDUX_AUTH_LOGGED_USER, user);
};
