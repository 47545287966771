import { Flex } from "../../../../../core/components/layout/Flex";
import React, { FC, ReactNode } from "react";
import { IframeHeadLogo } from "./IframeHeader";
import { Theme, useMediaQuery } from "@material-ui/core";
import UserOfferConfig from "../../../type/UserOfferConfig";
import styled from "styled-components";

interface IframeHeaderLogosProps {
  formData: UserOfferConfig;
  timer?: ReactNode;
}

const EmptySpace = styled.div`
  width: 220px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: unset;
  }
`;

export const IframeHeaderLogos: FC<IframeHeaderLogosProps> = ({
  formData,
  timer,
}) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  return (
    <Flex
      fullWidth={true}
      justifyContent={smDown ? "center" : "space-between"}
      marginBottom={"xxl"}
      flexWrap={"wrap"}
    >
      {formData.getLeftPathLogo() ? (
        <IframeHeadLogo src={formData.getLeftPathLogo()} $smOrder={0} />
      ) : (
        <EmptySpace />
      )}
      {formData.getCenterLogoPath() ? (
        <IframeHeadLogo src={formData.getCenterLogoPath()} $smOrder={10} />
      ) : (
        <EmptySpace />
      )}
      {timer ? timer : <EmptySpace />}
    </Flex>
  );
};
